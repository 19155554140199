import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import App from './App.js'
import './styles/globals.css'
import './styles/main.css'

import HomeComponent from './components/HomeComponent.js'
import EarnComponent from './components/EarnComponent.js'
import VaultComponent from './components/VaultComponent.js'
import ErrorComponent from './components/ErrorComponent.js'
import BorrowComponent from './components/BorrowComponent.js'
import LiquidateComponent from './components/LiquidateComponent.js'
import ProvideLiquidityComponent from './components/ProvideLiquidityComponent.js'
import StabilityModulePage from './pages/StabilityModulePage.tsx'
import SwapPage from './pages/SwapPage.tsx'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <HomeComponent />,
      },
      {
        path: 'borrow',
        element: <BorrowComponent />,
      },
      {
        path: 'vault/:id',
        element: <VaultComponent />,
      },
      {
        path: 'earn',
        element: <EarnComponent />,
      },
      {
        path: 'earn/vault-liquidations',
        element: <LiquidateComponent />,
      },
      {
        path: 'earn/stability-module',
        element: <StabilityModulePage />,
      },
      {
        path: 'earn/stx-uwu-lp',
        element: <ProvideLiquidityComponent />,
      },
      {
        path: 'swap',
        element: <SwapPage />,
      },
      {
        path: '*',
        element: <ErrorComponent />,
      }
    ]
  },
])

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root')
)
