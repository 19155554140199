import { Session } from '../../interfaces/session'
import { ALERT_TYPES, SWAP_ALERT_DESCRIPTIONS } from '../../constants/alerts'
import { Alert } from '../../interfaces/alert'
import { LPToken, Token } from '../../interfaces/tokens'
import { User } from '../../interfaces/user'
import { getAmountExceedsUserBalance, getIsUSMSwapRoute, getIsUSMTokenApproved, getAmountExceedsUSMReserves, getAmountLeavesEnoughSTXForGas, getHighPriceImpact, getIsSwapTokenApproved, getIsLPTokenEnabled, getIsStackswapSwapRoute, getAmountExceedsLiquidity, getSlippageToleranceIsZero, getHighSlippageTolerance, getSlippageToleranceExceedsMaximum, getNoActiveLiquiditySources, getIsAlexSwapRoute, getIsBitFlowSwapRoute } from '../../utils/alertUtils'
import { SwapAmounts, SwapSettings } from '../../interfaces/swap'

export const getUSMAlerts = (session: Session, user: User, inputToken: Token, outputToken: Token, amounts: SwapAmounts): { section: number; type: Alert; message: string; }[] => {
  const alerts: { section: number; type: Alert; message: string; }[] = []

  if (getAmountExceedsUserBalance(user, inputToken, amounts.amount)) {
    alerts.push({ section: 0, type: ALERT_TYPES.WARNING, message: SWAP_ALERT_DESCRIPTIONS.EXCEEDS_BALANCE })
  }

  if (!getIsUSMSwapRoute(inputToken, outputToken) || !getIsUSMTokenApproved(session?.swap?.usm, inputToken) || !getIsUSMTokenApproved(session?.swap?.usm, outputToken)) {
    alerts.push({ section: 0, type: ALERT_TYPES.WARNING, message: SWAP_ALERT_DESCRIPTIONS.INVALID_ROUTE })
  } else {
    if (getAmountExceedsUSMReserves(session?.swap?.usm, outputToken, amounts.est.formatted)) {
      alerts.push({ section: 0, type: ALERT_TYPES.WARNING, message: SWAP_ALERT_DESCRIPTIONS.EXCEEDS_LIQUIDITY })
    }

    /*if (!getIsUSMPriceMarketPrice(session, inputToken, outputToken)) {
      const tokenSymbol = inputToken.name === TokenName.UWU ? outputToken.symbol : inputToken.symbol
      const alert = SWAP_ALERT_DESCRIPTION.DIFFERENT_PRICE.replace('${symbol}', tokenSymbol)

      alerts.push({ message: alert, type: ALERT_TYPES.NOTICE })
    }*/
  }

  return alerts
}

export const getStackswapSwapAlerts = (user: User, lpToken: LPToken, inputToken: Token, outputToken: Token, amounts: SwapAmounts): { section: number; type: Alert; message: string; }[] => {
  const alerts: { section: number; type: Alert; message: string; }[] = []

  if (getAmountExceedsUserBalance(user, inputToken, amounts.amount)) {
    alerts.push({ section: 0, type: ALERT_TYPES.WARNING, message: SWAP_ALERT_DESCRIPTIONS.EXCEEDS_BALANCE })
  }

  if (getAmountLeavesEnoughSTXForGas(user, inputToken, amounts.amount)) {
    alerts.push({ section: 0, type: ALERT_TYPES.WARNING, message: SWAP_ALERT_DESCRIPTIONS.ENOUGH_STX_FOR_GAS })
  }

  if (getAmountExceedsLiquidity(amounts.priceImpact)) {
    alerts.push({ section: 0, type: ALERT_TYPES.WARNING, message: SWAP_ALERT_DESCRIPTIONS.EXCEEDS_LIQUIDITY })
  } else if (getHighPriceImpact(amounts.priceImpact)) {
    alerts.push({ section: 1, type: ALERT_TYPES.NOTICE, message: SWAP_ALERT_DESCRIPTIONS.HIGH_PRICE_IMPACT })
  }

  if (!getIsStackswapSwapRoute(inputToken, outputToken) || !getIsLPTokenEnabled(lpToken) || !getIsSwapTokenApproved(inputToken) || !getIsSwapTokenApproved(outputToken)) {
    alerts.push({ section: 0, type: ALERT_TYPES.WARNING, message: SWAP_ALERT_DESCRIPTIONS.INVALID_ROUTE })
  }
  
  return alerts
}

export const getAlexSwapAlerts = (user: User, lpToken: LPToken, inputToken: Token, outputToken: Token, amounts: SwapAmounts): { section: number; type: Alert; message: string; }[] => {
  const alerts: { section: number; type: Alert; message: string; }[] = []

  if (getAmountExceedsUserBalance(user, inputToken, amounts.amount)) {
    alerts.push({ section: 0, type: ALERT_TYPES.WARNING, message: SWAP_ALERT_DESCRIPTIONS.EXCEEDS_BALANCE })
  }

  if (getAmountLeavesEnoughSTXForGas(user, inputToken, amounts.amount)) {
    alerts.push({ section: 0, type: ALERT_TYPES.WARNING, message: SWAP_ALERT_DESCRIPTIONS.ENOUGH_STX_FOR_GAS })
  }

  if (getAmountExceedsLiquidity(amounts.priceImpact)) {
    alerts.push({ section: 0, type: ALERT_TYPES.WARNING, message: SWAP_ALERT_DESCRIPTIONS.EXCEEDS_LIQUIDITY })
  } else if (getHighPriceImpact(amounts.priceImpact)) {
    alerts.push({ section: 1, type: ALERT_TYPES.NOTICE, message: SWAP_ALERT_DESCRIPTIONS.HIGH_PRICE_IMPACT })
  }

  if (!getIsAlexSwapRoute(inputToken, outputToken) || !getIsLPTokenEnabled(lpToken) || !getIsSwapTokenApproved(inputToken) || !getIsSwapTokenApproved(outputToken)) {
    alerts.push({ section: 0, type: ALERT_TYPES.WARNING, message: SWAP_ALERT_DESCRIPTIONS.INVALID_ROUTE })
  }

  return alerts
}

export const getBitFlowSwapAlerts = (user: User, lpToken: LPToken, inputToken: Token, outputToken: Token, amounts: SwapAmounts): { section: number; type: Alert; message: string; }[] => {
  const alerts: { section: number; type: Alert; message: string; }[] = []

  if (getAmountExceedsUserBalance(user, inputToken, amounts.amount)) {
    alerts.push({ section: 0, type: ALERT_TYPES.WARNING, message: SWAP_ALERT_DESCRIPTIONS.EXCEEDS_BALANCE })
  }

  if (getAmountLeavesEnoughSTXForGas(user, inputToken, amounts.amount)) {
    alerts.push({ section: 0, type: ALERT_TYPES.WARNING, message: SWAP_ALERT_DESCRIPTIONS.ENOUGH_STX_FOR_GAS })
  }

  if (getAmountExceedsLiquidity(amounts.priceImpact)) {
    alerts.push({ section: 0, type: ALERT_TYPES.WARNING, message: SWAP_ALERT_DESCRIPTIONS.EXCEEDS_LIQUIDITY })
  } else if (getHighPriceImpact(amounts.priceImpact)) {
    alerts.push({ section: 1, type: ALERT_TYPES.NOTICE, message: SWAP_ALERT_DESCRIPTIONS.HIGH_PRICE_IMPACT })
  }

  if (!getIsBitFlowSwapRoute(inputToken, outputToken) || !getIsLPTokenEnabled(lpToken) || !getIsSwapTokenApproved(inputToken) || !getIsSwapTokenApproved(outputToken)) {
    alerts.push({ section: 0, type: ALERT_TYPES.WARNING, message: SWAP_ALERT_DESCRIPTIONS.INVALID_ROUTE })
  }

  return alerts
}

export const getSwapSettingsAlerts = (swapSettings: SwapSettings): { section: number; type: Alert; message: string; }[] => {
  const alerts: { section: number; type: Alert; message: string; }[] = []

  if (getSlippageToleranceIsZero(swapSettings.slippageTolerance)) {
    alerts.push({ section: 0, type: ALERT_TYPES.WARNING_POPUP, message: SWAP_ALERT_DESCRIPTIONS.ZERO_SLIPPAGE_TOLERANCE })
  }

  if (getSlippageToleranceExceedsMaximum(swapSettings.slippageTolerance)) {
    alerts.push({ section: 0, type: ALERT_TYPES.WARNING_POPUP, message: SWAP_ALERT_DESCRIPTIONS.MAXIMUM_SLIPPAGE_TOLERANCE })
  } else if (getHighSlippageTolerance(swapSettings.slippageTolerance)) {
    alerts.push({ section: 0, type: ALERT_TYPES.NOTICE_POPUP, message: SWAP_ALERT_DESCRIPTIONS.HIGH_SLIPPAGE_TOLERANCE })
  }

  if (getNoActiveLiquiditySources(swapSettings.liquiditySources.length)) {
    alerts.push({ section: 1, type: ALERT_TYPES.WARNING_POPUP, message: SWAP_ALERT_DESCRIPTIONS.NO_ACTIVE_LIQUIDITY_SOURCES })
  }

  return alerts
}