import React, { useState, useEffect } from "react";
import { fetchSession, fetchUser } from "./lib/api.js";
import "./styles/globals.css";
import { Outlet } from "react-router-dom";
import { useAtom } from "jotai";
import { userSessionState, useConnect } from "./lib/auth.js";
import { Connect } from "@stacks/connect-react";
import { trunc } from "./lib/utils.js";
import addressList from "./lib/addressList.js";

import { NotificationProvider } from "./context/NotificationContext.js";

import HeaderComponent from "./components/HeaderComponent.js";
import FooterComponent from "./components/FooterComponent.js";
import LoginComponent from "./components/LoginComponent.js";
import AddressPopupComponent from "./popups/AddressPopupComponent.js";
import HelpButtonMenuPopupComponent from "./popups/HelpButtonMenuPopupComponent.js";
import GuidePopupComponent from "./popups/GuidePopupComponent.js";

function App() {
  const [userSession] = useAtom(userSessionState);
  const [session, setSession] = useState();
  const [user, setUser] = useState();
  const [validAddress, setValidAddress] = useState(true);
  
  const { authOptions } = useConnect();
  
  const [guidePopup, setGuidePopup] = useState(true);
  const [helpButtonMenuPopup, setHelpButtonMenuPopup] = useState(false);
  const [helpButtonMenuPopupVisible, setHelpButtonMenuPopupVisible] = useState(false);

  const [showGuide, setShowGuide] = useState({visible: false, id: null});

  useEffect(() => {
    fetchSession().then(res => setSession(res));

    if(userSession.isUserSignedIn()) {
      fetchUser(userSession.loadUserData().profile.stxAddress.mainnet).then(res => setUser(res));
    };

    let storedPreferences = JSON.parse(localStorage.getItem("uwu-preferences"));

    if (storedPreferences) {
      setGuidePopup(JSON.parse(storedPreferences.notice));
      if(storedPreferences.theme) {
        document.querySelector('html').setAttribute('data-theme', storedPreferences.theme);
        document.querySelector('body').setAttribute('data-theme', storedPreferences.theme);
      } else {
        storedPreferences.theme = "light-mode";
        localStorage.setItem("uwu-preferences", JSON.stringify(storedPreferences));
        document.querySelector('html').setAttribute('data-theme', storedPreferences.theme);
        document.querySelector('body').setAttribute('data-theme', storedPreferences.theme);
      };
    } else {
      localStorage.setItem("uwu-preferences", JSON.stringify({ portfolio: true, notice: true, theme: 'light-mode', api: 'https://api.hiro.so' }));
      setGuidePopup(true);
      document.querySelector('html').setAttribute('data-theme', 'light-mode');
      document.querySelector('body').setAttribute('data-theme', 'light-mode');
    };
  }, []);

  useEffect(() => {
    if(userSession.isUserSignedIn() && user?.address) {
      setValidAddress(addressList.includes(user?.address));
    };
  }, [user]);

  function toggleMenu() {
    if (helpButtonMenuPopup) {
      setHelpButtonMenuPopupVisible(false);
      setTimeout(() => {
        setHelpButtonMenuPopup(false);
      }, 200);
    } else {
      setHelpButtonMenuPopupVisible(true);
      setHelpButtonMenuPopup(true);
    };
  };

  return (
    <>
      <NotificationProvider>
        <Connect authOptions={authOptions}>
          <div className="display">
            {(userSession.isUserSignedIn() && validAddress && guidePopup) ? <GuidePopupComponent show={(e) => setGuidePopup(e)} context={[session, user]} id={0} /> : null}
            {showGuide.visible ? <GuidePopupComponent show={(e) => setShowGuide({visible: false, id: null})} context={[session, user]} id={showGuide.id} /> : null}
            {!validAddress ? <AddressPopupComponent address={trunc(user?.address)} /> : null}
            {userSession.isUserSignedIn() && validAddress ? <HeaderComponent session={session} user={user} /> : null}
            {userSession.isUserSignedIn() ? validAddress ? <Outlet context={[session, user]} /> : null : <LoginComponent />}
            {validAddress ? <span onClick={() => toggleMenu()} className="helpButton" style={helpButtonMenuPopup ? { borderColor: "#c3c3c3", color: "#000000" } : {}}>?</span> : null}
            {validAddress ? <FooterComponent session={session} /> : null}
            {helpButtonMenuPopup ? <HelpButtonMenuPopupComponent show={helpButtonMenuPopupVisible} toggleMenu={() => toggleMenu()} showGuide={(e) => setShowGuide({visible: true, id: e})} /> : null}
          </div>
        </Connect>
      </NotificationProvider>
    </>
  );
}

export default App;