import { swapEnabledProtocols } from '../../constants/protocols'
import { ProtocolName } from '../../enums/protocols'
import { SwapData } from '../../interfaces/swap'
import { LPToken } from '../../interfaces/tokens'
import { User } from '../../interfaces/user'
import { getAndCacheData } from './storageService'
import { getStackswapLP } from './stswService'
import { getBalances, getBNS, getUserTransactions } from './userService'
import { getUSM } from './usmService'
import { getVaults } from './vaultService'

export const getUserSession = async (address: string): Promise<User> => {
  const getUser = async () => {
    const [bns, balances, vaults, pendingTransactions, confirmedTransactions] = await Promise.all([
      getBNS(address), getBalances(address), getVaults(address), getUserTransactions(address, true), getUserTransactions(address)
    ])

    const transactions = {
      pending: pendingTransactions,
      confirmed: confirmedTransactions
    }

    return {
      address,
      bns,
      balances,
      vaults,
      transactions
    }
  }

  const data = await getAndCacheData('uwu@user', getUser, [], 5000)

  if (!data) {
    throw new Error(`Failed to get data for user with address '${address}'`)
  }

  return data
}

export const getSwapSession = async (protocols: ProtocolName[], lpTokens?: LPToken[]): Promise<SwapData> => {
  const data: SwapData = {
    pools: {},
    usm: {
      tokens: {},
      reserves: {},
      feeAddress: '',
      swapStatus: false
    }
  }

  const promises: Promise<void>[] = []

  const availableProtocols = protocols.filter(protocol => swapEnabledProtocols.includes(protocol))

  if (availableProtocols.includes(ProtocolName.STACKSWAP)) {
    if (!lpTokens) {
      throw new Error(`Required list of LP Tokens is not defined`)
    }

    lpTokens.forEach(lpToken => {
      if (lpToken.protocol === ProtocolName.STACKSWAP) {
        promises.push(getStackswapLP(lpToken).then(lp => {
          data.pools[lpToken.name] = lp
        }))
      }
    })
  }

  if (availableProtocols.includes(ProtocolName.USM)) {
    promises.push(getUSM().then(usm => {
      data.usm = usm
    }))
  }

  await Promise.all(promises)

  return data
}