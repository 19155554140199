import React, { useState, useEffect } from "react";
import { PopupAnimation } from "../lib/animation";

export default function CloseVaultPopupComponent({ show, call, collateral, debt }) {
  const [visibleClass, setVisibleClass] = useState("");

  useEffect(() => {
    setTimeout(() => setVisibleClass('visible'), 25);
  }, []);

  useEffect(() => {
    if (visibleClass === "visible") {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    };
  }, [visibleClass]);

  const handleClosePopup = () => {
    setVisibleClass('');
    setTimeout(() => show(false), 300);
  };

  return (
    <PopupAnimation visible={visibleClass} onClose={handleClosePopup}>
      <div className="popupInterface">
        <div className="popupHeader">
          <h1>Close Vault</h1>
          <svg onClick={handleClosePopup} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M6 6L18 18" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
        </div>
        <div className="popupBody" style={{ textAlign: "left", margin: "2rem" }}>
          <h3 style={{ marginBottom: "1rem" }}>To close your Vault, make sure all outstanding debt is repaid and all collateral is withdrawn. Note that closing an empty Vault is not required, and leaving it open has no effect.</h3>
          <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934", marginBottom: "0", textAlign: "left", lineHeight: "1.36" }}>By proceeding with this transaction, your Vault will be permanently closed and cannot be reopened</h1></div>
          <div style={{ marginTop: "2rem" }}>
            <a onClick={() => { collateral + debt === 0 && call(); }} className="smallButton" style={{ color: "#FFFFFF", textDecoration: "none", lineHeight: "2rem", marginRight: "1rem", backgroundColor: collateral + debt === 0 ? "#000000" : "#575757", cursor: collateral + debt === 0 ? "pointer" : "default" }}>Close Vault</a>
            <a onClick={handleClosePopup}>Cancel</a>
          </div>
        </div>
      </div>
    </PopupAnimation>
  );
};