import React, { useState, useEffect } from 'react'
import { PopupAnimation } from '../../lib/animation.js'

import SwitchComponent from '../../components/SwitchComponent.js'

import { protocolsMap, swapEnabledProtocols } from '../../constants/protocols.ts'
import { SwapSettings } from '../../interfaces/swap.ts'
import WarningComponent from '../../components/WarningComponent.tsx'
import { getSwapSettingsAlerts } from '../../infra/services/alertService.ts'
import { formatUIValue } from '../../utils/formattingUtils.ts'
import { ProtocolName } from '../../enums/protocols.ts'
import { Alert } from '../../interfaces/alert.ts'
import { ALERT_TYPES } from '../../constants/alerts.ts'
import _ from 'lodash'

const SwapSettingsPopup: React.FC<{ show: (show: boolean) => void; setSettings: (setSettings: SwapSettings) => void; swapSettings: SwapSettings }> = ({ show, setSettings, swapSettings }) => {
  const [visibleClass, setVisibleClass] = useState<string>('')

  useEffect(() => {
    setTimeout(() => setVisibleClass('visible'), 25)
  }, [])

  useEffect(() => {
    if (visibleClass === 'visible') {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [visibleClass])

  const handleClosePopup = () => {
    setVisibleClass('')
    setTimeout(() => show(false), 300)
  }
  
  const [updatedSwapSettings, setUpdatedSwapSettings] = useState<SwapSettings>({
    liquiditySources: swapSettings.liquiditySources,
    slippageTolerance: swapSettings.slippageTolerance,
    refreshRate: swapSettings.refreshRate
  })

  const [slippageToleranceInputField, setSlippageToleranceInputField] = useState<string | number>()

  const hasCustomSlippageTolerance = slippageToleranceInputField !== 1

  const refreshRates = [
    {
      name: '10s',
      value: 10000
    },
    {
      name: '30s',
      value: 30000
    },
    {
      name: '1m',
      value: 60000
    },
    {
      name: '2m',
      value: 120000
    },
    {
      name: '5m',
      value: 300000
    },
    {
      name: '10m',
      value: 600000
    }
  ]
  
  const settingsAlerts: { section: number; type: Alert; message: string }[] = getSwapSettingsAlerts(updatedSwapSettings)

  useEffect(() => {
    setSlippageToleranceInputField(swapSettings.slippageTolerance * 100)
  }, [])

  const handleNumericInput = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    const allowedKeys = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight', '.']
    const inputValue = e.currentTarget.value

    const isAllowedKey = allowedKeys.includes(e.key)
    const isSingleDot = e.key === '.' && !inputValue.includes('.')

    if (isAllowedKey && (e.key !== '.' || isSingleDot)) {
      return
    }

    if (!/^\d$/.test(e.key)) {
      e.preventDefault()
    }
  }

  const canUpdateSettings = () => {
    const hasUpdatedLiquiditySources = !_.isEqual(updatedSwapSettings.liquiditySources, swapSettings.liquiditySources)
    const hasUpdatedSlippageTolerance = updatedSwapSettings.slippageTolerance !== swapSettings.slippageTolerance
    const hasUpdatedRefreshRate = updatedSwapSettings.refreshRate !== swapSettings.refreshRate

    const unsavedChanges = hasUpdatedLiquiditySources || hasUpdatedSlippageTolerance || hasUpdatedRefreshRate

    return settingsAlerts.filter(alert => alert.type === ALERT_TYPES.WARNING_POPUP).length === 0 && unsavedChanges
  }

  const updateLiquiditySources = (source: ProtocolName) => {
    if (updatedSwapSettings.liquiditySources.includes(source)) {
      const newLiquiditySources = updatedSwapSettings.liquiditySources.filter(liquiditySource => liquiditySource !== source)

      setUpdatedSwapSettings(prev => ({ ...prev, liquiditySources: newLiquiditySources }))
    } else {
      const newLiquiditySources = [...updatedSwapSettings.liquiditySources, source]

      setUpdatedSwapSettings(prev => ({ ...prev, liquiditySources: newLiquiditySources }))
    }
  }

  const updateSlippageTolerance = (slippage: string | number) => {
    setSlippageToleranceInputField(slippage)
    
    if (slippage !== '') {
      setUpdatedSwapSettings(prev => ({
        ...prev,
        slippageTolerance: Number(slippage) / 100
      }))
    }
  }

  const updateRefreshRate = (rate: number) => {
    setUpdatedSwapSettings(prev => ({ ...prev, refreshRate: rate }))
  }

  const handleUpdateSettings = () => {
    if (canUpdateSettings()) {
      setSettings(updatedSwapSettings)
    }
  }

  return (
    <PopupAnimation visible={visibleClass} onClose={handleClosePopup}>
      <div className='popupInterface'>
        <div className='popupHeader'>
          <h1>Swap Settings</h1>
          <svg onClick={handleClosePopup} width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M18 6L6 18' stroke='currentcolor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'></path><path d='M6 6L18 18' stroke='currentcolor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'></path></svg>
        </div>
        <div className='popupBody' style={{ textAlign: 'left', margin: '0' }}>
          <div className='popupScroll popupScrollTrackPadding' style={{ border: 'none', maxHeight: '533.688px' }}>
            <h1 style={{ fontSize: '0.85rem', marginBottom: '0.5rem' }}>Slippage Tolerance</h1>
            <h3 style={{ marginBottom: '15px' }}>The maximum price change that you're willing to accept between your swaps's intended and execution price.</h3>
            <div className='apiTable' style={{ marginBottom: '0', borderRadius: '10px 10px 0px 0px' }}>
              <a onClick={() => { updateSlippageTolerance(1) }} className={`headerButton ${!hasCustomSlippageTolerance && 'active'}`}>Auto</a>
              <a onClick={() => { updateSlippageTolerance('') }} className={`headerButton ${hasCustomSlippageTolerance && 'active'}`}>Custom</a>
            </div>
            <div style={{ border: '1px solid #F0F0F0', padding: '10px', borderTop: 'none', borderRadius: '0px 0px 10px 10px', overflow: 'hidden' }}>
              <h1 style={{ display: 'inline', fontSize: '0.85rem' }}>{formatUIValue(slippageToleranceInputField)}%</h1>
            </div>
            {hasCustomSlippageTolerance && <>
              <h1 style={{ fontSize: '0.85rem', marginBottom: '0.5rem', marginTop: '15px' }}>Custom Slippage Tolerance</h1>
              <span className='borrowInput'><input maxLength='10' inputmode='decimal' placeholder='1.00' value={slippageToleranceInputField} onChange={(e) => { updateSlippageTolerance(e.target.value.toString().split('.').map((el, i) => i ? el.split('').slice(0, 2).join('') : el).join('.').replace(/^0+(?=\d)/, '')) }} onKeyDown={(e) => handleNumericInput(e)} style={{ fontSize: '1rem', padding: '10px' }} /><h3 style={{ fontSize: '1rem', marginRight: '10px' }}>%</h3></span>
            </>}
            {settingsAlerts.filter(alert => alert.section === 0).map((alert, index) => (
              <WarningComponent key={index} message={alert.message} type={alert.type} />
            ))}
            <div style={{ marginTop: '20px', paddingTop: '20px', borderTop: '1px solid #F0F0F0' }}>
              <h1 style={{ fontSize: '0.85rem', marginBottom: '0.5rem' }}>Quote Refresh Rate</h1>
              <h3 style={{ marginBottom: '15px' }}>The frequency at which a new price quote is automatically fetched for your swap.</h3>
              <div className='apiTable apiTableGrid' style={{ marginBottom: '0' }}>
                {refreshRates.map(rate => 
                    <a onClick={() => { updateRefreshRate(rate.value) }} className={`headerButton ${updatedSwapSettings.refreshRate === rate.value && 'active'}`}>{rate.name}</a>
                )}
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: '20px', paddingTop: '20px', borderTop: '1px solid #F0F0F0' }}>
              <div style={{ width: '100%' }}>
                <h1 style={{ fontSize: '0.85rem', marginBottom: '0.5rem' }}>Liquidity Sources</h1>
                <h3 style={{ marginBottom: '15px' }}>UWU Swap sources liquidity for swaps from the following exchanges and liquidity providers.</h3>
                <div className='liquiditySourcesList'>
                  {swapEnabledProtocols.map((protocolName, index) => {
                    const protocol = protocolsMap[protocolName]

                    return (
                      <span>
                        <img draggable='false' src={protocol.icon} />
                        <h3 style={{ marginRight: 'auto' }}>{protocol.name}</h3>
                        <SwitchComponent isActive={updatedSwapSettings.liquiditySources.includes(protocolName)} onToggle={() => { updateLiquiditySources(protocolName) }} />
                      </span>
                    )
                  })}
                </div>
              </div>
            </div>
            {settingsAlerts.filter(alert => alert.section === 1).map((alert, index) => (
              <WarningComponent key={index} message={alert.message} type={alert.type} />
            ))}
            <div style={{ margin: '2rem 0' }}>
              <a onClick={() => { handleUpdateSettings() }} className='smallButton' style={{ color: '#FFFFFF', textDecoration: 'none', lineHeight: '2rem', marginRight: '1rem', backgroundColor: canUpdateSettings() ? '#000000' : '#575757', cursor: canUpdateSettings() ? 'pointer' : 'default' }}>Save Changes</a>
              <a onClick={handleClosePopup}>Cancel</a>
            </div>
          </div>
        </div>
      </div>
    </PopupAnimation>
  );
};

export default SwapSettingsPopup