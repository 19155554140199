import React, { useState, useContext, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
  uintCV,
  makeStandardSTXPostCondition,
  PostConditionMode,
  FungibleConditionCode,
  createAssetInfo,
  makeContractFungiblePostCondition,
} from "@stacks/transactions";
import { stringCV } from "@stacks/transactions/dist/clarity/types/stringCV";
import { StacksMainnet } from "@stacks/network";
import { useConnect as syConnect } from "@stacks/connect-react";
import { checkHour, formatTokens, formatTokensDisplay } from "../lib/utils.js";

import { NotificationContext } from "../context/NotificationContext.js";

import LoaderComponent from "./LoaderComponent.js";
import LoaderOverlayComponent from "./LoaderOverlayComponent.js";
import DefaultPopupComponent from "../popups/DefaultPopupComponent.js";
import ConfirmationPopupComponent from "../popups/ConfirmationPopupComponent.js";
import ActivityPopupComponent from "../popups/ActivityPopupComponent.js";
import FAQPopupComponent from "../popups/FAQPopupComponent.js";
import SliderComponent from "./SliderComponent.js";

import loader from "../img/loader.svg";
import stxtoken from "../img/stx-token-logo.svg";
import uwutoken from "../img/uwu-token-logo-v2.png";
import guide from "../img/guide.png";

import { getUserTransactions } from '../infra/services/userService.ts'
import { TokenName } from '../enums/tokens.ts'

export default function BorrowComponent() {
  const [session, user] = useOutletContext();
  const [collateralInput, setCollateralInput] = useState("");
  const [debtInput, setDebtInput] = useState("");

  const { doContractCall } = syConnect();
  const { addNotification } = useContext(NotificationContext);

  const [pending, setPending] = useState([]);

  const [showLoader, setShowLoader] = useState(false);
  const [showDefaultPopup, setShowDefaultPopup] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [activityPopup, setActivityPopup] = useState(false);
  const [faqPopup, setFaqPopup] = useState(false);

  const [defaultPopupOptions, setDefaultPopupOptions] = useState({
    windowTitle: "Vault Confirmation",
    title: "Vault Confirmation",
    description: "Your transaction was sent. Please allow 10-30 minutes for the transaction to be completed.",
    tx: null
  });

  const [confirmPopupOptions, setConfirmPopupOptions] = useState({
    content: [],
    call: null
  });

  document.title = "Borrow | UWU Protocol";

  useEffect(() => {
    if (session && user) {

      fetchPending();
    };
  }, [user, session]);

  function fetchPending() {
    getUserTransactions(user?.address, true).then(res => {
      const filteredTransactions = res.filter(transaction => {
        const { contract_call } = transaction;
        const { function_name, function_args } = contract_call;
        const args = getArgumentsAsObject(function_args);

        const names = ["open-vault"];

        return names.includes(function_name);
      });

      setPending(filteredTransactions);
    });
  };

  function uintToNumber(uintString) {
    return parseInt(uintString.slice(1));
  };

  function getArgumentsAsObject(args) {
    return args.reduce((acc, arg) => {
      acc[arg.name] = arg.type === "uint" ? uintToNumber(arg.repr) : arg.repr;
      return acc;
    }, {});
  };

  const checkCharacter = (e) => {
    if (e.key === "Backspace" || e.key === "Delete" || e.key === "Tab" || e.key === "ArrowLeft" || e.key === "ArrowRight") {
      return;
    };

    if (e.key === "." && e.target.value.split(".").length - 1 === 0) {
      return;
    };

    if (!/^\d+$/.test(e.key)) {
      e.preventDefault();
    };
  };

  function getHealthFactor(ratio) {
    const difference = ratio - 150;

    if (difference > 50) {
      return "#20ba62";
    };

    if (difference > 20) {
      return "#d88934";
    };

    if (difference > 0 || difference < 0) {
      return "#d83434";
    };

    return "#000000";
  };

  function createTxCall() {
    if (debtInput >= 25 && collateralInput <= user?.balances[TokenName.STX] && user?.vaults.length < 20 && ((collateralInput * session?.oracle.price / debtInput) * 100) >= 150) {
      setConfirmPopupOptions(prev => ({
        ...prev,
        desc: "If you confirm this transaction, a new Vault will be created with the following details.",
        content: 
        [
          [
            {
              title: "You Send",
              item: `${formatTokensDisplay(collateralInput)} STX`
            },
            {
              title: "You Receive",
              item: `${formatTokensDisplay(debtInput - (debtInput * 0.01), 4)} UWU`
            }
          ],
          [
            {
              title: "Total Collateral",
              item: `${formatTokensDisplay(collateralInput)} STX`
            },
            {
              title: "Total Debt",
              item: `${formatTokensDisplay(debtInput)} UWU`
            },
            {
              title: "Collateral Ratio",
              item: `${formatTokensDisplay(((collateralInput * session?.oracle.price) / debtInput) * 100)}%`
            },
            {
              title: "Liquidation Price",
              item: `$${formatTokensDisplay((debtInput * 1.5) / collateralInput)}`
            }
          ],
          [
            {
              title: "Borrow Fee",
              item: `${formatTokensDisplay(debtInput * 0.01, 4)} UWU (1.00%)`
            }
          ]
        ],
        call: (() => callOpenVault(collateralInput, debtInput))
      }));
      setShowConfirmPopup(true);
    };
  };

  async function callOpenVault(collateral, debt) {
    setShowLoader(true);
    await doContractCall({
      contractAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
      contractName: "uwu-factory-v1-1-0",
      functionName: "open-vault",
      functionArgs: [uintCV(formatTokens(collateral * 1000000)), uintCV(formatTokens(debt * 1000000))],
      postConditionMode: PostConditionMode.Deny,
      postConditions: [makeStandardSTXPostCondition(user?.address, FungibleConditionCode.Equal, formatTokens(collateral * 1000000)), makeContractFungiblePostCondition("SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4", "uwu-factory-v1-1-0", FungibleConditionCode.LessEqual, formatTokens(debt * 1000000), createAssetInfo("SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4", "uwu-token-v1-1-0", "uwu"))],
      network: new StacksMainnet(),
      onFinish: (result) => {
        setCollateralInput("");
        setDebtInput("");
        addNotification({
          id: Date.now(),
          title: "Vault Confirmation",
          description: `You opened a new Vault with ${formatTokensDisplay(collateral)} STX of collateral and ${formatTokensDisplay(debt)} UWU as debt. View the transaction details by clicking here.`,
          date: new Date(),
          link: `https://explorer.hiro.so/txid/${result.txId}`,
          read: false
        });
        setDefaultPopupOptions(prev => ({...prev, tx: result.txId}));
        setShowConfirmPopup(false);
        setShowDefaultPopup(true);
      },
    });
    setShowLoader(false);
  };

  return (
    <>
    {activityPopup && <ActivityPopupComponent show={(e) => setActivityPopup(e)} address={user?.address} />}
    {showLoader ? <LoaderOverlayComponent /> : null}
    {showDefaultPopup ? <DefaultPopupComponent show={(e) => setShowDefaultPopup(e)} options={defaultPopupOptions} /> : null}
    {showConfirmPopup ? <ConfirmationPopupComponent show={(e) => setShowConfirmPopup(e)} options={confirmPopupOptions} /> : null}
    {faqPopup && <FAQPopupComponent show={(e) => setFaqPopup(e)} />}
    {(session && user) ?
    <div className="core">
      <ReactTooltip className="defaultTooltip" effect="solid" multiline={true} arrowColor="#000000" padding="10px 12px" />
      <div className="borrowHeaderWithSelector borrowHeader" style={{ marginBottom: "4rem" }}>
        <h1>UWU Borrow</h1>
        <h2 style={{ marginBottom: "2rem" }}>Say goodbye to interest payments. With UWU Borrow, you<br></br> can borrow against your STX at 0% interest.</h2>
        <div className="manageVaultSelector">
          <div style={{ backgroundColor: "#FFFFFF", boxShadow: "0px 4px 6px rgba(17,17,26,0.025)" }}>
            <a className="headerButton active" style={{ marginRight: "5px" }}>Open a Vault</a>
            <a href="/" className="headerButton" style={{ marginLeft: "5px" }}>Your Vaults</a>
          </div>
        </div>
      </div>
      {pending.length > 0 && <div className="pendingTxnHome pendingTxnHomeMobile" style={{ marginBottom: "15px" }}>
        <img src={loader} draggable="false" />
        <div>
          <h1>Your new {pending.length > 1 ? "Vaults are" : "Vault is"} being created</h1>
          <h2>Please allow 10-30 minutes for the transaction{pending.length > 1 && "s"} to be completed.</h2>
        </div>
        <a onClick={() => setActivityPopup(true)} className="smallButton">View Recent Activity</a>
      </div>}
      <div className="grid2">
        <div className="vaultGrid">
          <div>
            <div className="overview borrowDisplay" style={{ height: "max-content", gap: "0px" }}>
              <div class="overviewHeader">
                <h1>Vault Overview</h1>
                <div></div>
              </div>
              <div className="overviewGrid2 overviewGrid3">
                <div>
                  <h2 data-tip="The total value of STX collateral<br>in this Vault">Total Collateral</h2>
                  <h1>{formatTokensDisplay(collateralInput)} STX</h1>
                </div>
                <div className="overviewGrid2Divider"></div>
                <div>
                  <h2 data-tip="The total amount of UWU debt<br>generated by this Vault">Total Debt</h2>
                  <h1>{formatTokensDisplay(debtInput)} UWU</h1>
                </div>
                <div className="overviewGrid2Divider"></div>
                <div>
                  <h2 data-tip="The ratio of total collateral to total<br>debt held in this Vault">Collateral Ratio</h2>
                  <h1 style={{ color: debtInput && collateralInput ? getHealthFactor(((collateralInput * session?.oracle.price) / debtInput) * 100) : "#000000" }}>{formatTokensDisplay(((collateralInput * session?.oracle.price) / debtInput) * 100)}%</h1>
                </div>
                <div className="overviewGrid2Divider"></div>
                <div>
                  <h2 data-tip="The current STX price reported<br>by the oracle">Oracle Price</h2>
                  <div style={{ display: "flex", padding: "unset" }}>
                    <h1>${formatTokensDisplay(session?.oracle.price)}</h1>
                    <span className="alertDot" style={{ backgroundColor: checkHour(session?.oracle.timestamp) < 1 ? "#20ba62" : "#d88934", animation: checkHour(session?.oracle.timestamp) < 1 ? "" : "pulse 1s infinite", marginTop: "0.5px", marginLeft: "5px" }} data-tip={checkHour(session?.oracle.timestamp) < 1 ? "The Oracle Price was recently updated" : "The Oracle Price may not reflect<br>the current market price"}></span>
                  </div>
                </div>
                <div class="overviewGrid2Divider"></div>
                <div>
                  <h2 data-tip="The STX price at which this Vault<br>will be liquidated">Liquidation Price</h2>
                  {(collateralInput > 0 && debtInput > 0) ? ((((collateralInput * session?.oracle.price) / debtInput) * 100) >= 150 ? <h1>${formatTokensDisplay((debtInput * 1.5) / collateralInput)} ({formatTokensDisplay(((session?.oracle.price - ((debtInput * 1.5) / collateralInput)) * 100) / session?.oracle.price)}% below)</h1> : <h1>Liquidated</h1>) : <h1>$0.00 (0.00% below)</h1>}
                </div>
              </div>
            </div>
            <div className="borrowNotice">
              <h1>New to UWU Borrow?</h1>
              <h2 style={{ marginBottom: "1rem" }}>If you have any questions or need further information, you can find quick answers by visiting the Borrow FAQ section.</h2>
              <div className="activityItem noSelect" style={{ alignItems: "center" }} onClick={() => setFaqPopup(true)}>
                <img draggable="false" style={{ width: "30px", height: "30px", padding: "0" }} src={guide} />
                <div>
                  <h1 style={{ marginBottom: "0px" }}>Borrow FAQ</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="overview borrowDisplay gridItemFirstMobile">
            <div className="overviewHeader" style={{ paddingBottom: "20px" }}>
              <h1>Vault Configuration</h1>
              <div></div>
            </div>
            <div className="borrowInnerDiv manageVaultInnerDiv">
              <div className="borrowDivOverflow borrowDivOverflowMobile" style={{ maxHeight: "unset" }}>
                <div className="borrowInputTitle">
                  <h1>Deposit STX</h1>
                  <h2 onClick={() => setCollateralInput(user?.balances[TokenName.STX])}>Balance: {formatTokensDisplay(user?.balances[TokenName.STX])} STX</h2>
                </div>
                <span className="borrowInput borrowInputPrice" style={{ marginBottom: "15px" }}><div style={{ width: "100%" }}><input inputmode="decimal" maxLength="10" type="text" placeholder="0.00 STX" onChange={(e) => setCollateralInput(e.target.value.toString().split(".").map((el, i) => i ? el.split("").slice(0, 2).join("") : el).join(".").replace(/^0+(?=\d)/, ""))} onKeyDown={(e) => checkCharacter(e)} value={collateralInput} /><h4>≈ ${formatTokensDisplay(collateralInput * session?.prices[TokenName.STX])}</h4></div><div className="swapOutputCurrency" style={{ marginRight: "15px" }}><img draggable="false" src={stxtoken} /><h1>STX</h1></div></span>
                {collateralInput > user?.balances[TokenName.STX] && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-5px 20px 15px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>The amount entered exceeds your account balance</h1></div>}
                {(user?.balances[TokenName.STX] - collateralInput) < 1 && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-5px 20px 15px", maxWidth: "398px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>The amount entered may not leave sufficient STX to pay for the transaction fee. Please enter a smaller amount</h1></div>}
                <div className="borrowInputTitle">
                  <h1>Borrow UWU</h1>
                  <h2 onClick={() => setDebtInput(formatTokens((66.666666 / 100) * (collateralInput * session?.oracle.price)))}>Maximum: {formatTokensDisplay((66.666666 / 100) * (collateralInput * session?.oracle.price))} UWU</h2>
                </div>
                <span className="borrowInput borrowInputPrice" style={{ marginBottom: "20px" }}><div style={{ width: "100%" }}><input inputmode="decimal" maxLength="10" placeholder="0.00 UWU" onChange={(e) => setDebtInput(e.target.value.toString().split(".").map((el, i) => i ? el.split("").slice(0, 2).join("") : el).join(".").replace(/^0+(?=\d)/, ""))} onKeyDown={(e) => checkCharacter(e)} value={debtInput} /><h4>≈ ${formatTokensDisplay(debtInput * session?.prices[TokenName.UWU])}</h4></div><div className="swapOutputCurrency" style={{ marginRight: "15px" }}><img draggable="false" src={uwutoken} /><h1>UWU</h1></div></span>
                <div className="borrowInputTitle disabled">
                  <h1>Borrow Capacity</h1>
                  <h2>{formatTokensDisplay((debtInput / formatTokens((66.666666 / 100) * (collateralInput * session?.oracle.price))) * 100)}%</h2>
                </div>
                <SliderComponent sliderValue={formatTokens(debtInput)} maxSliderValue={formatTokens((66.666666 / 100) * (collateralInput * session?.oracle.price))} onChange={(value) => setDebtInput(formatTokens(value))} disabled={!collateralInput} />
                {debtInput < 25 && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-10px 20px 20px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>You have to borrow a minimum of 25 UWU</h1></div>}
                {debtInput > formatTokens((66.666666 / 100) * (collateralInput * session?.oracle.price)) && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-10px 20px 20px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>The amount entered exceeds your maximum borrow</h1></div>}
                {debtInput >= 0.5 * (session?.pools["0"].balances.usd + session?.usm.reserves.usd) && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-10px 20px 20px", maxWidth: "398px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>The amount of UWU you're about to borrow is significant compared to the available liquidity on the open-market</h1></div>}
                <div className="borrowOutputDetails" style={{ marginTop: "-5px" }}>
                  <h1>Borrow Fee</h1>
                  <h2>{formatTokensDisplay(debtInput * 0.01, 4)} UWU (1.00%)</h2>
                  <h1>Total Received</h1>
                  <h2>{formatTokensDisplay(debtInput - (debtInput * 0.01), 4)} UWU</h2>
                </div>
              </div>
            </div>
            <div className="borrowDivider"></div>
                <a className="borrowButton" style={{ backgroundColor: (debtInput >= 25 && collateralInput <= user?.balances[TokenName.STX] && user?.vaults.length < 20 && (((collateralInput * session?.oracle.price) / debtInput) * 100) >= 150 && (user?.balances[TokenName.STX] - collateralInput) >= 1) ? "#000000" : "#575757", cursor: (debtInput >= 25 && collateralInput <= user?.balances[TokenName.STX] && user?.vaults.length < 20 && (((collateralInput * session?.oracle.price) / debtInput) * 100) >= 150) ? "pointer" : "default" }} onClick={() => createTxCall()}>{collateralInput && debtInput ? "Open Vault" : "Enter an Amount"}</a>
          </div>
        </div>
      </div>
    </div>
    : <LoaderComponent />}
    </>
  );
};