import React, { useState, useContext } from "react";
import { useConnect } from "../lib/auth.js";

import { NotificationContext } from "../context/NotificationContext.js";

import HeaderComponent from "./HeaderComponent.js";
import FooterComponent from "./FooterComponent.js";

export default function LoginComponent() {
  const { handleOpenAuth } = useConnect();

  const { clearAllNotifications } = useContext(NotificationContext);

  document.title = "Sign In | UWU Protocol";

  return (
    <>
    <div className="header">
      <a href="/"><img src="https://cdn.quorumdao.org/uwu-logo-black.png" width="55px" draggable="false" /></a>
    </div>
    <div className="loginWrapper">
      <div className="loader login">
        <h1>Welcome to UWU Protocol</h1>
        <h2>Sign in with your Stacks wallet to start using UWU Protocol.</h2>
        <div className="loginSelector">
          <h1>Sign In</h1>
          <h2>Don't have a wallet yet? <span onClick={() => window.open("https://www.stacks.co/explore/find-a-wallet", "_blank")}>Start here</span></h2>
          <a onClick={() => { handleOpenAuth(); clearAllNotifications(); }} style={{ marginBottom: "10px" }}>Sign in with Leather Wallet</a>
          <a onClick={() => { handleOpenAuth(); clearAllNotifications(); }}>Sign in with Xverse Wallet</a>
        </div>
      </div>
    </div>
    </>
  );
};