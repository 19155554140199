import { swapEnabledTokens } from '../constants/tokens'
import { ProtocolName } from '../enums/protocols'
import { TokenName } from '../enums/tokens'
import { Session } from '../interfaces/session'
import { SwapRoute } from '../interfaces/swap'
import { LPToken, Token } from '../interfaces/tokens'
import { User } from '../interfaces/user'
import { USM } from '../interfaces/usm'
import { getSwapRoutes } from './swapUtils'

export const getAmountExceedsUserBalance = (user: User, token: Token, amount: number): boolean => {
  const balance = user?.balances[token.name]?.formatted

  return amount > (balance || 0)
}

export const getAmountLeavesEnoughSTXForGas = (user: User, token: Token, amount: number): boolean => {
  const balance = user?.balances[token.name]?.formatted

  return token.name === TokenName.STX && ((balance || 0) - amount < 1)
}

export const getAmountExceedsUSMReserves = (usm: USM, token: Token, amount: number): boolean => {
  const reserve = usm?.reserves[token.name]?.formatted

  return amount > (reserve || 0)
}

export const getHighPriceImpact = (amount: number): boolean => {
  return amount >= 15
}

export const getAmountExceedsLiquidity = (amount: number): boolean => {
  return amount >= 100
}

export const getIsLPTokenEnabled = (lpToken: LPToken): boolean => {
  return lpToken.enabled
}

export const getIsSwapTokenApproved = (token: Token): boolean => {
  return swapEnabledTokens.includes(token.name)
}

export const getIsUSMTokenApproved = (usm: USM, token: Token): boolean => {
  const approved = usm?.tokens[token.name]?.approved

  return approved
}

export const getIsUSMSwapRoute = (inputToken: Token, outputToken: Token): boolean => {
  const routes: SwapRoute[] = getSwapRoutes(inputToken, outputToken)

  return routes.length > 0
}

export const getIsStackswapSwapRoute = (inputToken: Token, outputToken: Token): boolean => {
  const routes: SwapRoute[] = getSwapRoutes(inputToken, outputToken)

  return routes.filter(route => route.protocol === ProtocolName.STACKSWAP).length > 0
}

export const getIsAlexSwapRoute = (inputToken: Token, outputToken: Token): boolean => {
  const routes: SwapRoute[] = getSwapRoutes(inputToken, outputToken)

  return routes.filter(route => route.protocol === ProtocolName.ALEX).length > 0
}

export const getIsBitFlowSwapRoute = (inputToken: Token, outputToken: Token): boolean => {
  const routes: SwapRoute[] = getSwapRoutes(inputToken, outputToken)
  
  return routes.filter(route => route.protocol === ProtocolName.BITFLOW).length > 0
}

export const getIsUSMPriceMarketPrice = (session: Session, inputToken: Token, outputToken: Token) => {
  const token = inputToken.name === TokenName.UWU ? outputToken : inputToken
  const price = session?.prices[token.name]
  const usmPrice = (session?.swap?.usm?.tokens[token.name].rate / 1000000)

  return price === usmPrice
}

export const getSlippageToleranceIsZero = (amount: number): boolean => {
  return amount === 0
}

export const getHighSlippageTolerance = (amount: number): boolean => {
  return amount >= 0.2
}

export const getSlippageToleranceExceedsMaximum = (amount: number): boolean => {
  return amount > 0.5
}

export const getNoActiveLiquiditySources = (amount: number): boolean => {
  return amount === 0
}