import React, { useState, useEffect, useRef, useContext } from "react";
import { PopupAnimation } from "../lib/animation.js";
import { timeSince } from "../lib/utils.js";

import { NotificationContext } from "../context/NotificationContext.js";

export default function NotificationsPopupComponent({show}) {
  const [visibleClass, setVisibleClass] = useState("");
  const menuRef = useRef();

  const { notifications, removeNotification, markAsRead, clearAllNotifications, markAllAsRead } = useContext(NotificationContext);
  const sortedNotifications = notifications.sort((a, b) => b.date - a.date);

  useEffect(() => {
    setTimeout(() => setVisibleClass('visible'), 25);
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setVisibleClass('');
        setTimeout(() => { show(false); markAllAsRead(); }, 300);
      };
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  return (
    <>
      <div className={`notifMenu ${visibleClass}`} ref={menuRef}>
        <div className="notifMenuHeader">
          <h1>Notifications</h1>
          {notifications.length > 0 && <a onClick={clearAllNotifications}>Clear All</a>}
        </div>
        <div className="notifMenuView">
          {notifications.length === 0 && <h2>You have no recent notifications</h2>}
          {notifications.length > 0 && <div>
            {sortedNotifications.map((notification) => (
              <div className="notifID" key={notification.id} onClick={() => { window.open(notification.link, "_blank"); markAsRead(notification.id); }}>
                <div>
                  {!notification.read && <span></span>}
                  <h1>{notification.title}</h1>
                </div>
                <h2>{notification.description}</h2>
                <h3>{`${timeSince(Math.floor(notification.date.getTime() / 1000))}`}</h3>
              </div>
            ))}
          </div>}
        </div>
      </div>
    </>
  );
};