import { STACKS_NETWORK } from '../../config/stacks'
import { UWU_CONTRACT_ADDRESSES, UWU_CONTRACT_NAMES, UWU_PROXY_FUNCTION_NAMES } from '../../constants/smartContracts'
import { tokensMap } from '../../constants/tokens'
import { TokenName } from '../../enums/tokens'
import { fetchRetry, readOnlyRetry } from '../../utils/fetchUtils'
import { formatTokenValue, formatValue } from '../../utils/formattingUtils'
import { getStacksRPC } from './rpcService'

/**
 * Fetches the timestamp of the latest update from the oracle
 * @returns {Promise<number>} The timestamp of the last oracle update or zero if not available
 * @throws {Error} If the network request fails or returns a non-ok status
 */
export const getOracleTimestamp = async (): Promise<number> => {
  const endpoint = `${getStacksRPC()}/extended/v1/address/${UWU_CONTRACT_ADDRESSES.DEPLOYER}.${UWU_CONTRACT_NAMES.ORACLE}/transactions?limit=1`
  const response = await fetchRetry(endpoint)

  if (!response.ok) {
    throw new Error(`Failed to fetch timestamp for latest oracle update`)
  }

  const data = await response.json()
    
  return formatValue(data.results?.[0]?.burn_block_time ?? 0)
}

export const getOraclePrice = async (): Promise<{ formatted: number; unformatted: number; }> => {
  const response = await readOnlyRetry({
    contractAddress: UWU_CONTRACT_ADDRESSES.DEPLOYER,
    contractName: UWU_CONTRACT_NAMES.PROXY,
    functionName: UWU_PROXY_FUNCTION_NAMES.GET_STX_PRICE,
    functionArgs: [],
    network: STACKS_NETWORK,
    senderAddress: UWU_CONTRACT_ADDRESSES.DEPLOYER,
  })

  if (response?.value?.value === null) {
    throw new Error(`Failed to fetch current oracle price`)
  }

  const price = response.value.value
  const stxToken = tokensMap[TokenName.STX]
  
  return {
    formatted: formatTokenValue(price, stxToken),
    unformatted: formatValue(price)
  }
}