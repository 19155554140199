import React, { useState, useEffect } from "react";
import { PopupAnimation } from "../lib/animation";

export default function FAQPopupComponent({ show, id }) {
  const [visibleClass, setVisibleClass] = useState("");

  useEffect(() => {
    setTimeout(() => setVisibleClass('visible'), 25);
  }, []);

  useEffect(() => {
    if (visibleClass === "visible") {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    };
  }, [visibleClass]);

  const handleClosePopup = () => {
    setVisibleClass('');
    setTimeout(() => show(false), 300);
  };

  return (
    <PopupAnimation visible={visibleClass} onClose={handleClosePopup}>
      <div className="popupInterface">
        <div className="popupHeader">
          <h1>Borrow FAQ</h1>
          <svg onClick={handleClosePopup} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M6 6L18 18" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
        </div>
        <div className="popupBody" style={{ textAlign: "left", margin: "0" }}>
          <div className="popupScroll popupScrollTrackPadding" style={{ borderBottom: "none", maxHeight: "502px" }}>
            <h1 style={{ marginBottom: "1rem" }}>What is a Vault?</h1>
            <h3 style={{ marginBottom: "1rem" }}>A Vault is used to take out and manage a loan. A Vault is associated with a specific Stacks addresses, and each address can have up to 20 Vaults open at the same time.</h3>
            <div className="borrowDivider" style={{ margin: "2rem -2rem", borderTop: "1px dashed #F0F0F0" }}></div>
            <h1 style={{ marginBottom: "1rem" }}>What is collateral?</h1>
            <h3 style={{ marginBottom: "1rem" }}>Collateral is an asset you offer as security when you take a loan. For UWU Borrow, only STX is accepted as collateral.</h3>
            <div className="borrowDivider" style={{ margin: "2rem -2rem", borderTop: "1px dashed #F0F0F0" }}></div>
            <h1 style={{ marginBottom: "1rem" }}>What is a collateral ratio?</h1>
            <h3 style={{ marginBottom: "10px" }}>The collateral ratio is the value of your collateral (STX) compared to your borrowed amount (UWU). It changes as the market price of STX fluctuates.</h3>
            <h3 style={{ marginBottom: "1rem" }}>You can adjust this ratio by depositing or withdrawing collateral, or by borrowing more or repaying some of your debt. To avoid liquidation, your Vault must maintain a minimum collateral ratio of 150%.</h3>
            <div className="borrowDivider" style={{ margin: "2rem -2rem", borderTop: "1px dashed #F0F0F0" }}></div>
            <h1 style={{ marginBottom: "1rem" }}>What are the borrowing limits?</h1>
            <h3 style={{ marginBottom: "10px" }}>When opening a Vault or if your existing Vault has no debt, the least you can borrow is 25 UWU. For a Vault with a debt balance of 25 UWU or higher, the minimum additional borrowing amount is 1 UWU.</h3>
            <h3 style={{ marginBottom: "1rem" }}>You can borrow as much as you want, provided your Vault has enough collateral to keep the collateral ratio above 150%.</h3>
            <div className="borrowDivider" style={{ margin: "2rem -2rem", borderTop: "1px dashed #F0F0F0" }}></div>
            <h1 style={{ marginBottom: "1rem" }}>What fees do I pay when borrowing?</h1>
            <h3 style={{ marginBottom: "10px" }}>When you borrow from a Vault, a one-time fee of 1% is subtracted from the amount you borrow.</h3>
            <h3 style={{ marginBottom: "1rem" }}>For example, if you borrow 100 UWU, you'll receive 99 UWU but still owe the full 100 UWU.</h3>
            <div className="borrowDivider" style={{ margin: "2rem -2rem", borderTop: "1px dashed #F0F0F0" }}></div>
            <h1 style={{ marginBottom: "1rem" }}>When do I have to repay my debt?</h1>
            <h3 style={{ marginBottom: "1rem" }}>You can repay your debt whenever you want, as long as your Vault's collateral ratio stays above 150%.</h3>
            <div className="borrowDivider" style={{ margin: "2rem -2rem", borderTop: "1px dashed #F0F0F0" }}></div>
            <h1 style={{ marginBottom: "1rem" }}>How do repayments work?</h1>
            <h3 style={{ marginBottom: "10px" }}>You are allowed to make partial repayments provided that your Vault's remaining debt balance is more than 25 UWU after your payment.</h3>
            <h3 style={{ marginBottom: "1rem" }}>However, if your Vault's outstanding debt is 25 UWU and you wish to make a repayment, you're required to repay the full debt balance.</h3>
            <div className="borrowDivider" style={{ margin: "2rem -2rem", borderTop: "1px dashed #F0F0F0" }}></div>
            <h1 style={{ marginBottom: "1rem" }}>What happens if my Vault is liquidated?</h1>
            <h3 style={{ marginBottom: "10px" }}>If your Vault is liquidated, you will lose ownership of the Vault, and liquidators will be able to acquire the collateral held in the Vault. To avoid liquidation, your Vault must maintain a minimum collateral ratio of 150%.</h3>
            <h3 style={{ marginBottom: "1rem" }}>If your Vault is liquidated, you will no longer have to repay the UWU that was borrowed. It's important to monitor your Vault and manage its collateral ratio to prevent liquidation.</h3>
            <div className="borrowDivider" style={{ margin: "2rem -2rem", borderTop: "1px dashed #F0F0F0" }}></div>
            <a href="https://docs.uwu.cash/uwu-protocol/borrowing" target="_blank" className="smallButton" style={{ color: "#FFFFFF", textDecoration: "none", lineHeight: "2rem", marginBottom: "2rem" }}>View Documentation</a>
          </div>
        </div>
      </div>
    </PopupAnimation>
  );
};