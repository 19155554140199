import React, { useState, useEffect, useContext, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import { PopupAnimation } from "../lib/animation";
import { fetchAddress } from "../lib/api.js";
import { trunc, timeSince, formatTokensDisplay, formatTokens } from "../lib/utils.js";
import QRCode from "react-qr-code";
import {
  uintCV,
  standardPrincipalCV,
  makeStandardSTXPostCondition,
  makeContractSTXPostCondition,
  PostConditionMode,
  FungibleConditionCode,
  callReadOnlyFunction,
  makeStandardFungiblePostCondition,
  createAssetInfo,
  makeContractFungiblePostCondition,
  stringAsciiCV,
  bufferCV,
  bufferCVFromString,
  noneCV,
  makeSTXTokenTransfer
} from "@stacks/transactions";
import { validateStacksAddress } from '@stacks/transactions';
import { principalCV } from "@stacks/transactions/dist/clarity/types/principalCV";
import { StacksMainnet, StacksTestnet } from "@stacks/network";
import { useConnect as syConnect } from "@stacks/connect-react";
import { openSTXTransfer } from '@stacks/connect';

import { NotificationContext } from "../context/NotificationContext.js";

import stxtoken from "../img/stx-token-logo.svg";
import uwutoken from "../img/uwu-token-logo-v2.png";
import susdttoken from "../img/susdt-token-logo.svg";
import aeusdctoken from "../img/aeusdc-token-logo.svg";
import xusdtoken from "../img/xusd-token-logo.png";
import xbtctoken from "../img/xbtc-token-logo.svg";
import abtctoken from "../img/abtc-token-logo.svg";
import alextoken from "../img/alex-token-logo.svg";
import stswtoken from "../img/stsw-token-logo.svg";
import vibestoken from "../img/vibes-token-logo.png";
import faritoken from "../img/fari-token-logo.png";
import welshtoken from "../img/welsh-token-logo.png";
import leotoken from "../img/leo-token-logo.png";
import gustoken from "../img/gus-token-logo.svg";
import ltotoken from "../img/stx-uwu-lpt.png";
import send from "../img/send.png";
import qr from "../img/qr.png";
import vaultlogo from "../img/vault-logo.png";
import checkmark from "../img/checkmark.png";
import loader from "../img/loader.svg";

import LoaderOverlayComponent from "../components/LoaderOverlayComponent.js";

import { TokenName } from '../enums/tokens.ts'

export default function AssetsPopupComponent({ show, address, context }) {
  const { addNotification } = useContext(NotificationContext);
  const { doContractCall } = syConnect();

  const [showLoader, setShowLoader] = useState(false);

  const [visibleClass, setVisibleClass] = useState("");
  const [session, user] = context;
  const [showPortfolio, setShowPortfolio] = useState(true);
  const [selectedTab, setSelectedTab] = useState("wallet");
  const [copyText, setCopyText] = useState("Copy Address");

  const [selectedAsset, setSelectedAsset] = useState({ name: "", balance: 0 });
  const [amountInput, setAmountInput] = useState();
  const [addressInput, setAddressInput] = useState();
  const [memoInput, setMemoInput] = useState();
  const [finalAddress, setFinalAddress] = useState("");
  const [addressError, setAddressError] = useState(false);

  const timeoutIdRef = useRef(null);

  const [defaultPopupOptions, setDefaultPopupOptions] = useState({
    windowTitle: null,
    title: null,
    description: null,
    tx: null
  });

  useEffect(() => {
    const storedPreferences = JSON.parse(localStorage.getItem("uwu-preferences"));

    if (storedPreferences) {
      setShowPortfolio(JSON.parse(storedPreferences.portfolio));
    };
  }, []);

  useEffect(() => {
    let storedPreferences = JSON.parse(localStorage.getItem("uwu-preferences"));

    if (storedPreferences) {
      if (showPortfolio) {
        storedPreferences.portfolio = true;
        localStorage.setItem("uwu-preferences", JSON.stringify(storedPreferences));
      } else {
        storedPreferences.portfolio = false;
        localStorage.setItem("uwu-preferences", JSON.stringify(storedPreferences));
      };
    };
  }, [showPortfolio]);

  useEffect(() => {
    setTimeout(() => setVisibleClass('visible'), 25);
  }, []);

  useEffect(() => {
    if (visibleClass === "visible") {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    };
  }, [visibleClass]);

  const handleClosePopup = () => {
    setVisibleClass('');
    setTimeout(() => show(false), 300);
  };

  function copyLink() {
    setCopyText("Copied");
    setTimeout(() => {
      setCopyText("Copy Address");
    }, 1000);
  };

  const checkCharacter = (e) => {
    if (e.key === "Backspace" || e.key === "Delete" || e.key === "Tab" || e.key === "ArrowLeft" || e.key === "ArrowRight") {
      return;
    };

    if (e.key === "." && e.target.value.split(".").length - 1 === 0) {
      return;
    };

    if (!/^\d+$/.test(e.key)) {
      e.preventDefault();
    };
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setAddressInput(inputValue);
    setFinalAddress("");
    setAddressError(false);

    if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);

    if (inputValue) {
      timeoutIdRef.current = setTimeout(() => {
        if (validateStacksAddress(inputValue)) {
          setFinalAddress(inputValue);
          return;
        } else {
          fetchAddress(inputValue.toLowerCase()).then(res => {
            if (res && validateStacksAddress(res)) {
              setFinalAddress(res);
            } else {
              setAddressError(true);
            };
          });
        };
      }, 2000);
    };
  };

  const handleKeyPress = (e) => {
    if (e.charCode === 32) {
      e.preventDefault();
    };
  };

  function checkCallRequirements() {
    if (amountInput > 0 && amountInput <= selectedAsset.balance) {
      if (validateStacksAddress(finalAddress) && finalAddress !== user?.address) {
        return true;
      };
    };

    return false;
  };

  function createTransaction() {
    if (selectedAsset.symbol === "STX") {
      return {
        rawAmount: formatTokens(amountInput),
        amount: formatTokens(amountInput * 1000000),
        recipient: finalAddress,
        memo: memoInput ? memoInput : ""
      };
    };

    if(selectedAsset.symbol === "UWU") {
      return {
        contractAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
        contractName: "uwu-token-v1-1-0",
        rawAmount: formatTokens(amountInput),
        amount: formatTokens(amountInput * 1000000),
        recipient: finalAddress,
        memo: memoInput ? bufferCVFromString(memoInput) : noneCV(),
        postCondition: makeStandardFungiblePostCondition(user?.address, FungibleConditionCode.Equal, formatTokens(amountInput * 1000000), createAssetInfo("SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4", "uwu-token-v1-1-0", "uwu"))
      };
    };

    if (selectedAsset.symbol === "sUSDT") {
      return {
        contractAddress: "SP2XD7417HGPRTREMKF748VNEQPDRR0RMANB7X1NK",
        contractName: "token-susdt",
        rawAmount: formatTokens(amountInput),
        amount: formatTokens(amountInput * 100000000),
        recipient: finalAddress,
        memo: memoInput ? bufferCVFromString(memoInput) : noneCV(),
        postCondition: makeStandardFungiblePostCondition(user?.address, FungibleConditionCode.Equal, formatTokens(amountInput * 100000000), createAssetInfo("SP2XD7417HGPRTREMKF748VNEQPDRR0RMANB7X1NK", "token-susdt", "bridged-usdt"))
      };
    };

    if (selectedAsset.symbol === "aeUSDC") {
      return {
        contractAddress: "SP3Y2ZSH8P7D50B0VBTSX11S7XSG24M1VB9YFQA4K",
        contractName: "token-aeusdc",
        rawAmount: formatTokens(amountInput),
        amount: formatTokens(amountInput * 1000000),
        recipient: finalAddress,
        memo: memoInput ? bufferCVFromString(memoInput) : noneCV(),
        postCondition: makeStandardFungiblePostCondition(user?.address, FungibleConditionCode.Equal, formatTokens(amountInput * 1000000), createAssetInfo("SP3Y2ZSH8P7D50B0VBTSX11S7XSG24M1VB9YFQA4K", "token-aeusdc", "aeUSDC"))
      };
    };

    if (selectedAsset.symbol === "xUSD") {
      return {
        contractAddress: "SP2TZK01NKDC89J6TA56SA47SDF7RTHYEQ79AAB9A",
        contractName: "Wrapped-USD",
        rawAmount: formatTokens(amountInput),
        amount: formatTokens(amountInput * 100000000),
        recipient: finalAddress,
        memo: memoInput ? bufferCVFromString(memoInput) : noneCV(),
        postCondition: makeStandardFungiblePostCondition(user?.address, FungibleConditionCode.Equal, formatTokens(amountInput * 100000000), createAssetInfo("SP2TZK01NKDC89J6TA56SA47SDF7RTHYEQ79AAB9A", "Wrapped-USD", "wrapped-usd"))
      };
    };

    if (selectedAsset.symbol === "xBTC") {
      return {
        contractAddress: "SP3DX3H4FEYZJZ586MFBS25ZW3HZDMEW92260R2PR",
        contractName: "Wrapped-Bitcoin",
        rawAmount: formatTokens(amountInput, 6),
        amount: formatTokens(amountInput * 100000000, 6),
        recipient: finalAddress,
        memo: memoInput ? bufferCVFromString(memoInput) : noneCV(),
        postCondition: makeStandardFungiblePostCondition(user?.address, FungibleConditionCode.Equal, formatTokens(amountInput * 100000000), createAssetInfo("SP3DX3H4FEYZJZ586MFBS25ZW3HZDMEW92260R2PR", "Wrapped-Bitcoin", "wrapped-bitcoin"))
      };
    };

    if (selectedAsset.symbol === "aBTC") {
      return {
        contractAddress: "SP2XD7417HGPRTREMKF748VNEQPDRR0RMANB7X1NK",
        contractName: "token-abtc",
        rawAmount: formatTokens(amountInput, 6),
        amount: formatTokens(amountInput * 100000000, 6),
        recipient: finalAddress,
        memo: memoInput ? bufferCVFromString(memoInput) : noneCV(),
        postCondition: makeStandardFungiblePostCondition(user?.address, FungibleConditionCode.Equal, formatTokens(amountInput * 100000000), createAssetInfo("SP2XD7417HGPRTREMKF748VNEQPDRR0RMANB7X1NK", "token-abtc", "bridged-btc"))
      };
    };

    if (selectedAsset.symbol === "ALEX") {
      return {
        contractAddress: "SP102V8P0F7JX67ARQ77WEA3D3CFB5XW39REDT0AM",
        contractName: "token-alex",
        rawAmount: formatTokens(amountInput),
        amount: formatTokens(amountInput * 100000000),
        recipient: finalAddress,
        memo: memoInput ? bufferCVFromString(memoInput) : noneCV(),
        postCondition: makeStandardFungiblePostCondition(user?.address, FungibleConditionCode.Equal, formatTokens(amountInput * 100000000), createAssetInfo("SP102V8P0F7JX67ARQ77WEA3D3CFB5XW39REDT0AM", "token-alex", "alex"))
      };
    };

    if (selectedAsset.symbol === "STSW") {
      return {
        contractAddress: "SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275",
        contractName: "stsw-token-v4a",
        rawAmount: formatTokens(amountInput),
        amount: formatTokens(amountInput * 1000000),
        recipient: finalAddress,
        memo: memoInput ? bufferCVFromString(memoInput) : noneCV(),
        postCondition: makeStandardFungiblePostCondition(user?.address, FungibleConditionCode.Equal, formatTokens(amountInput * 1000000), createAssetInfo("SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275", "stsw-token-v4a", "stsw"))
      };
    };

    if (selectedAsset.symbol === "VIBES") {
      return {
        contractAddress: "SP27BB1Y2DGSXZHS7G9YHKTSH6KQ6BD3QG0AN3CR9",
        contractName: "vibes-token",
        rawAmount: formatTokens(amountInput),
        amount: formatTokens(amountInput * 100000000),
        recipient: finalAddress,
        memo: memoInput ? bufferCVFromString(memoInput) : noneCV(),
        postCondition: makeStandardFungiblePostCondition(user?.address, FungibleConditionCode.Equal, formatTokens(amountInput * 100000000), createAssetInfo("SP27BB1Y2DGSXZHS7G9YHKTSH6KQ6BD3QG0AN3CR9", "vibes-token", "vibes-token"))
      };
    };
    
    if (selectedAsset.symbol === "FARI") {
      return {
        contractAddress: "SP213KNHB5QD308TEESY1ZMX1BP8EZDPG4JWD0MEA",
        contractName: "fari-token-mn",
        rawAmount: formatTokens(amountInput),
        amount: formatTokens(amountInput * 100000000),
        recipient: finalAddress,
        memo: memoInput ? bufferCVFromString(memoInput) : noneCV(),
        postCondition: makeStandardFungiblePostCondition(user?.address, FungibleConditionCode.Equal, formatTokens(amountInput * 100000000), createAssetInfo("SP213KNHB5QD308TEESY1ZMX1BP8EZDPG4JWD0MEA", "fari-token-mn", "fari"))
      };
    };

    if (selectedAsset.symbol === "WELSH") {
      return {
        contractAddress: "SP3NE50GEXFG9SZGTT51P40X2CKYSZ5CC4ZTZ7A2G",
        contractName: "welshcorgicoin-token",
        rawAmount: formatTokens(amountInput),
        amount: formatTokens(amountInput * 1000000),
        recipient: finalAddress,
        memo: memoInput ? bufferCVFromString(memoInput) : noneCV(),
        postCondition: makeStandardFungiblePostCondition(user?.address, FungibleConditionCode.Equal, formatTokens(amountInput * 1000000), createAssetInfo("SP3NE50GEXFG9SZGTT51P40X2CKYSZ5CC4ZTZ7A2G", "welshcorgicoin-token", "welshcorgicoin"))
      };
    };

    if (selectedAsset.symbol === "LEO") {
      return {
        contractAddress: "SP1AY6K3PQV5MRT6R4S671NWW2FRVPKM0BR162CT6",
        contractName: "leo-token",
        rawAmount: formatTokens(amountInput),
        amount: formatTokens(amountInput * 1000000),
        recipient: finalAddress,
        memo: memoInput ? bufferCVFromString(memoInput) : noneCV(),
        postCondition: makeStandardFungiblePostCondition(user?.address, FungibleConditionCode.Equal, formatTokens(amountInput * 1000000), createAssetInfo("SP1AY6K3PQV5MRT6R4S671NWW2FRVPKM0BR162CT6", "leo-token", "leo"))
      };
    };

    if (selectedAsset.symbol === "GUS") {
      return {
        contractAddress: "SP1JFFSYTSH7VBM54K29ZFS9H4SVB67EA8VT2MYJ9",
        contractName: "gus-token",
        rawAmount: formatTokens(amountInput),
        amount: formatTokens(amountInput * 1000000),
        recipient: finalAddress,
        memo: memoInput ? bufferCVFromString(memoInput) : noneCV(),
        postCondition: makeStandardFungiblePostCondition(user?.address, FungibleConditionCode.Equal, formatTokens(amountInput * 1000000), createAssetInfo("SP1JFFSYTSH7VBM54K29ZFS9H4SVB67EA8VT2MYJ9", "gus-token", "gus"))
      };
    };

    if (selectedAsset.symbol === "LTO") {
      return {
        contractAddress: "SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275",
        contractName: "liquidity-token-v5kglq1fqfp",
        rawAmount: formatTokens(amountInput),
        amount: formatTokens(amountInput * 1000000),
        recipient: finalAddress,
        memo: memoInput ? bufferCVFromString(memoInput) : noneCV(),
        postCondition: makeStandardFungiblePostCondition(user?.address, FungibleConditionCode.Equal, formatTokens(amountInput * 1000000), createAssetInfo("SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275", "liquidity-token-v5kglq1fqfp", "liquidity-token"))
      };
    };
  };

  async function callSTXTransfer() {
    const transactionData = createTransaction();

    setShowLoader(true);
    await openSTXTransfer({
      recipient: transactionData.recipient,
      amount: transactionData.amount,
      memo: transactionData.memo,
      network: new StacksMainnet(),
      appDetails: {
        name: "UWU Protocol",
        icon: "https://cdn.quorumdao.org/uwu-logo.png"
      },
      onFinish: (result) => {
        addNotification({
          id: Date.now(),
          title: "Send Confirmation",
          description: `You sent ${formatTokensDisplay(transactionData.rawAmount)} ${selectedAsset.symbol} to ${trunc(transactionData.recipient)}. View the transaction details by clicking here.`,
          date: new Date(),
          link: `https://explorer.hiro.so/txid/${result.txId}`,
          read: false
        });
        setDefaultPopupOptions(prev => ({
          ...prev,
          tx: result.txId,
          windowTitle: "Send Confirmation",
          title: "Send Confirmation",
          description: "Your transaction was sent. Please allow 10-30 minutes for the transaction to be completed."
        }));
        setSelectedTab("sendConfirm");
      },
    });
    setShowLoader(false);
  };
  
  async function callFungibleTransfer() {
    const transactionData = createTransaction();

    setShowLoader(true);
    await doContractCall({
      contractAddress: transactionData.contractAddress,
      contractName: transactionData.contractName,
      functionName: "transfer",
      functionArgs: [uintCV(transactionData.amount), principalCV(user?.address), principalCV(transactionData.recipient), transactionData.memo],
      postConditionMode: PostConditionMode.Deny,
      postConditions: [transactionData.postCondition],
      network: new StacksMainnet(),
      onFinish: (result) => {
        addNotification({
          id: Date.now(),
          title: "Send Confirmation",
          description: `You sent ${(selectedAsset.symbol === "xBTC" || selectedAsset.symbol === "aBTC") ? formatTokensDisplay(transactionData.rawAmount, 6) : formatTokensDisplay(transactionData.rawAmount)} ${selectedAsset.symbol} to ${trunc(transactionData.recipient)}. View the transaction details by clicking here.`,
          date: new Date(),
          link: `https://explorer.hiro.so/txid/${result.txId}`,
          read: false
        });
        setDefaultPopupOptions(prev => ({
          ...prev,
          tx: result.txId,
          windowTitle: "Send Confirmation",
          title: "Send Confirmation",
          description: "Your transaction was sent. Please allow 10-30 minutes for the transaction to be completed."
        }));
        setSelectedTab("sendConfirm");
      },
    });
    setShowLoader(false);
  };
  
  return (
    <>
      {showLoader && <LoaderOverlayComponent />}
      <PopupAnimation visible={visibleClass} onClose={handleClosePopup}>
        <div className="popupInterface">
          <div className="popupHeader">
            {(selectedTab !== "wallet" && selectedTab !== "sendConfirm") && <a onClick={() => selectedTab === "send" ? setSelectedTab("sendAsset") : setSelectedTab("wallet")}>{`<-`}</a>}
            <h1>{selectedTab === "wallet" && "Manage Account" || selectedTab === "receive" && "Receive" || selectedTab === "send" && `Send ${selectedAsset.symbol}` || selectedTab === "sendAsset" && "Select an Asset" || selectedTab === "sendConfirm" && "Send Confirmation"}</h1>
            <svg onClick={handleClosePopup} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M6 6L18 18" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
          </div>
          {selectedTab === "wallet" &&
          <div className="walletView">
            <div className="walletAssetsHeader">
              <div>
                <h1>{user?.bns ? trunc(user?.bns, true) : "Your Account"}</h1>
                <a href={`https://explorer.hiro.so/address/${user?.address}?chain=mainnet`} target="_blank">{trunc(user?.address)}</a>
              </div>
              <div className="buttons">
                <div style={{ marginRight: "1rem" }}>
                  <button onClick={() => setSelectedTab("receive")} className="imgButton smallButton buttonReceive"><img src={qr} draggable="false" /></button>
                  <h1>Receive</h1>
                </div>
                <div>
                    <button onClick={() => setSelectedTab("sendAsset")} className="imgButton smallButton buttonSend"><img src={send} draggable="false" /></button>
                  <h1>Send</h1>
                </div>
              </div>
            </div>
            <h2 style={{ fontSize: "0.85rem", fontWeight: "600", color: "#838383", marginTop: "-5px", marginBottom: "-12px", marginLeft: "20px" }}>Your Tokens</h2>
            <div className="activityView assetView">
              {user?.balances[TokenName.STX] > 0.01 &&
                <a className="activityItem assetItem">
                  <img draggable="false" src={stxtoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                  <div>
                    <h1>Stacks</h1>
                    <h2>STX</h2>
                  </div>
                  <div style={{ marginLeft: "auto", textAlign: "right" }}>
                    <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.STX]) + " STX" : "-"}</h1>
                    {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.STX] * session?.prices[TokenName.STX])}</h2>}
                  </div>
                </a>
              }
              {user?.balances[TokenName.UWU] > 0.01 &&
                <a className="activityItem assetItem">
                  <img draggable="false" src={uwutoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                  <div>
                    <h1>UWU Cash</h1>
                    <h2>UWU</h2>
                  </div>
                  <div style={{ marginLeft: "auto", textAlign: "right" }}>
                    <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.UWU]) + " UWU" : "-"}</h1>
                    {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.UWU] * session?.prices[TokenName.UWU])}</h2>}
                  </div>
                </a>
              }
              {user?.balances[TokenName.SUSDT] > 0.01 &&
                <a className="activityItem assetItem">
                  <img draggable="false" src={susdttoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                  <div>
                    <h1>Bridged USDT</h1>
                    <h2>sUSDT</h2>
                  </div>
                  <div style={{ marginLeft: "auto", textAlign: "right" }}>
                    <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.SUSDT]) + " sUSDT" : "-"}</h1>
                    {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.SUSDT] * session?.prices[TokenName.SUSDT])}</h2>}
                  </div>
                </a>
              }
              {user?.balances[TokenName.AEUSDC] > 0.01 &&
                <a className="activityItem assetItem">
                  <img draggable="false" src={aeusdctoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                  <div>
                    <h1>Allbridge USDC</h1>
                    <h2>aeUSDC</h2>
                  </div>
                  <div style={{ marginLeft: "auto", textAlign: "right" }}>
                    <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.AEUSDC]) + " aeUSDC" : "-"}</h1>
                    {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.AEUSDC] * session?.prices[TokenName.AEUSDC])}</h2>}
                  </div>
                </a>
              }
              {user?.balances[TokenName.XUSD] > 0.01 &&
                  <a className="activityItem assetItem">
                    <img draggable="false" src={xusdtoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                    <div>
                      <h1>Wrapped USD</h1>
                      <h2>xUSD</h2>
                    </div>
                    <div style={{ marginLeft: "auto", textAlign: "right" }}>
                      <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.XUSD]) + " xUSD" : "-"}</h1>
                      {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.XUSD] * session?.prices[TokenName.XUSD])}</h2>}
                    </div>
                  </a>
              }
              {user?.balances[TokenName.XBTC] > 0.000001 &&
                <a className="activityItem assetItem">
                  <img draggable="false" src={xbtctoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                  <div>
                    <h1>Wrapped BTC</h1>
                    <h2>xBTC</h2>
                  </div>
                  <div style={{ marginLeft: "auto", textAlign: "right" }}>
                    <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.XBTC], 6) + " xBTC" : "-"}</h1>
                    {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.XBTC] * session?.prices[TokenName.XBTC])}</h2>}
                  </div>
                </a>
              }
              {user?.balances[TokenName.ABTC] > 0.000001 &&
                <a className="activityItem assetItem">
                  <img draggable="false" src={abtctoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                  <div>
                    <h1>Bridged BTC</h1>
                    <h2>aBTC</h2>
                  </div>
                  <div style={{ marginLeft: "auto", textAlign: "right" }}>
                    <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.ABTC], 6) + " aBTC" : "-"}</h1>
                    {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.ABTC] * session?.prices[TokenName.ABTC])}</h2>}
                  </div>
                </a>
              }
              {user?.balances[TokenName.ALEX] > 0.01 &&
                <a className="activityItem assetItem">
                  <img draggable="false" src={alextoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                  <div>
                    <h1>ALEX Lab</h1>
                    <h2>ALEX</h2>
                  </div>
                  <div style={{ marginLeft: "auto", textAlign: "right" }}>
                    <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.ALEX]) + " ALEX" : "-"}</h1>
                    {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.ALEX] * session?.prices[TokenName.ALEX])}</h2>}
                  </div>
                </a>
              }
              {user?.balances[TokenName.STSW] > 0.01 &&
                <a className="activityItem assetItem">
                  <img draggable="false" src={stswtoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                  <div>
                    <h1>Stackswap</h1>
                    <h2>STSW</h2>
                  </div>
                  <div style={{ marginLeft: "auto", textAlign: "right" }}>
                    <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.STSW]) + " STSW" : "-"}</h1>
                    {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.STSW] * session?.prices[TokenName.STSW])}</h2>}
                  </div>
                </a>
              }
              {user?.balances[TokenName.VIBES] > 0.01 &&
                <a className="activityItem assetItem">
                  <img draggable="false" src={vibestoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                  <div>
                    <h1>HireVibes</h1>
                    <h2>VIBES</h2>
                  </div>
                  <div style={{ marginLeft: "auto", textAlign: "right" }}>
                    <h1>{showPortfolio ? formatTokensDisplay(user?.balances.viebs) + " VIBES" : "-"}</h1>
                    {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.VIBES] * session?.prices[TokenName.VIBES])}</h2>}
                  </div>
                </a>
              }
              {user?.balances[TokenName.FARI] > 0.01 &&
                <a className="activityItem assetItem">
                  <img draggable="false" src={faritoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                  <div>
                    <h1>Fari Token</h1>
                    <h2>FARI</h2>
                  </div>
                  <div style={{ marginLeft: "auto", textAlign: "right" }}>
                    <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.FARI]) + " FARI" : "-"}</h1>
                    {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.FARI] * session?.prices[TokenName.FARI])}</h2>}
                  </div>
                </a>
              }
              {user?.balances[TokenName.WELSH] > 0.01 &&
                <a className="activityItem assetItem">
                  <img draggable="false" src={welshtoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                  <div>
                    <h1>Welsh Corgi Coin</h1>
                    <h2>WELSH</h2>
                  </div>
                  <div style={{ marginLeft: "auto", textAlign: "right" }}>
                    <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.WELSH]) + " WELSH" : "-"}</h1>
                    {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.WELSH] * session?.prices[TokenName.WELSH])}</h2>}
                  </div>
                </a>
              }
              {user?.balances[TokenName.LEO] > 0.01 &&
                <a className="activityItem assetItem">
                  <img draggable="false" src={leotoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                  <div>
                    <h1>Leo Token</h1>
                    <h2>LEO</h2>
                  </div>
                  <div style={{ marginLeft: "auto", textAlign: "right" }}>
                    <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.LEO]) + " LEO" : "-"}</h1>
                    {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.LEO] * session?.prices[TokenName.LEO])}</h2>}
                  </div>
                </a>
              }
              {user?.balances[TokenName.GUS] > 0.01 &&
                <a className="activityItem assetItem">
                  <img draggable="false" src={gustoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                  <div>
                    <h1>Gus Token</h1>
                    <h2>GUS</h2>
                  </div>
                  <div style={{ marginLeft: "auto", textAlign: "right" }}>
                    <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.GUS]) + " GUS" : "-"}</h1>
                    {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.GUS] * session?.prices[TokenName.GUS])}</h2>}
                  </div>
                </a>
              }
                {(user?.balances[TokenName.STX] < 0.01 && user?.balances[TokenName.UWU] < 0.01 && user?.balances[TokenName.SUSDT] < 0.01 && user?.balances[TokenName.AEUSDC] < 0.01 && user?.balances[TokenName.XUSD] < 0.01 && user?.balances[TokenName.XBTC] < 0.000001 && user?.balances[TokenName.ABTC] < 0.000001 && user?.balances[TokenName.ALEX] < 0.01 && user?.balances[TokenName.STSW] < 0.01 && user?.balances[TokenName.VIBES] < 0.01 && user?.balances[TokenName.FARI] < 0.01 && user?.balances[TokenName.WELSH] < 0.01 && user?.balances[TokenName.LEO] < 0.01 && user?.balances[TokenName.GUS] < 0.01) &&
                <div className="activityItem assetItem" style={{ justifyContent: "center" }}>
                  <h2>You don't currently have any Tokens</h2>
                </div>
              }
            </div>
            <h2 style={{ fontSize: "0.85rem", fontWeight: "600", color: "#838383", marginTop: "-5px", marginBottom: "-12px", marginLeft: "20px" }}>Your LP Tokens</h2>
            <div className="activityView assetView">
              {user?.balances.lto["0"] > 0.01 && 
                <a className="activityItem assetItem">
                  <img draggable="false" src={ltotoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                  <div>
                    <h1>STX-UWU LP</h1>
                    <h2>LTO</h2>
                  </div>
                  <div style={{ marginLeft: "auto", textAlign: "right" }}>
                    <h1>{showPortfolio ? formatTokensDisplay(user?.balances.lto["0"]) + " LTO" : "-"}</h1>
                    {showPortfolio && <h2>≈ ${formatTokensDisplay((session?.pools["0"].balances.usd * (user?.balances.lto["0"] / session?.pools["0"].lpToken.shares.formatted)))}</h2>}
                  </div>
                </a>
              }
              {user?.balances.lto["0"] < 0.01 &&
                <div className="activityItem assetItem" style={{ justifyContent: "center" }}>
                  <h2>You don't currently have any LP Tokens</h2>
                </div>
              }
            </div>
            <h2 style={{ fontSize: "0.85rem", fontWeight: "600", color: "#838383", marginTop: "-5px", marginBottom: "-12px", marginLeft: "20px" }}>Your Vaults</h2>
            <div className="activityView assetView">
              {user?.vaults.map(vault =>
                <a className="activityItem assetItem">
                  <img draggable="false" src={vaultlogo} style={{ width: "26px", padding: "2px", borderRadius: "100px", backgroundColor: "#FFFFFF" }} />
                  <div>
                    <h1>Vault #{vault?.id}</h1>
                  </div>
                  <div style={{ marginLeft: "auto", textAlign: "right" }}>
                    <a href={`/vault/${vault?.id}`} className="smallButton">Manage</a>
                  </div>
                </a>
              )}
              {user?.vaults.length === 0 &&
                <div className="activityItem assetItem" style={{ justifyContent: "center" }}>
                  <h2>You don't currently have any Vaults</h2>
                </div>
              }
            </div>
          </div>
          }
          {selectedTab === "receive" &&
          <div>
            <h2 className="popupNotice">This address can only receive assets on the Stacks network</h2>
            <div className="walletAssetsReceive popupBody">
              <h1>{user?.bns ? trunc(user?.bns, true) : "Your Account"}</h1>
              <a href={`https://explorer.hiro.so/address/${user?.address}?chain=mainnet`} target="_blank">{trunc(user?.address)}</a>
              <QRCode value={user?.address} />
                <button className="smallButton" onClick={() => { copyLink(); navigator.clipboard.writeText(user?.address); }}>{copyText}</button>
            </div>
          </div>
          }
          {selectedTab === "sendAsset" &&
            <div className="walletView">
              {(user?.balances[TokenName.STX] < 0.01 && user?.balances[TokenName.UWU] < 0.01 && user?.balances[TokenName.SUSDT] < 0.01 && user?.balances[TokenName.AEUSDC] < 0.01 && user?.balances[TokenName.XUSD] < 0.01 && user?.balances.lto["0"] < 0.01) &&
                <div className="activityView" style={{ minHeight: "439px", maxHeight: "439px" }}>
                  <h2 style={{ margin: "auto" }}>You don't currently have any Assets</h2>
                </div>
              }
              {(user?.balances[TokenName.STX] > 0.01 || user?.balances[TokenName.UWU] > 0.01 || user?.balances[TokenName.SUSDT] > 0.01 || user?.balances[TokenName.AEUSDC] > 0.01 || user?.balances[TokenName.XUSD] > 0.01 || user?.balances[TokenName.XBTC] > 0.000001 || user?.balances[TokenName.ABTC] > 0.000001 || user?.balances[TokenName.ALEX] > 0.01 || user?.balances[TokenName.STSW] > 0.01 || user?.balances[TokenName.VIBES] > 0.01 || user?.balances[TokenName.FARI] > 0.01 || user?.balances[TokenName.WELSH] > 0.01 || user?.balances[TokenName.LEO] > 0.01 || user?.balances[TokenName.GUS] > 0.01) &&
                <>
                <h2 style={{ fontSize: "0.85rem", fontWeight: "600", color: "#838383", marginTop: "15px", marginBottom: "-12px", marginLeft: "20px" }}>Your Tokens</h2>
                <div className="activityView assetView" style={{ marginBottom: "15px" }}>
                  {user?.balances[TokenName.STX] > 0.01 &&
                    <a onClick={() => { setSelectedAsset({ symbol: "STX", img: stxtoken, balance: user?.balances[TokenName.STX], price: session?.prices[TokenName.STX] }); setSelectedTab("send"); setAmountInput(); setAddressInput(); setAddressError(false); setFinalAddress(); setMemoInput(); }} className="activityItem assetItem assetItemHover">
                      <img draggable="false" src={stxtoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                      <div>
                        <h1>Stacks</h1>
                        <h2>STX</h2>
                      </div>
                      <div style={{ marginLeft: "auto", textAlign: "right" }}>
                        <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.STX]) + " STX" : "-"}</h1>
                        {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.STX] * session?.prices[TokenName.STX])}</h2>}
                      </div>
                    </a>
                  }
                  {user?.balances[TokenName.UWU] > 0.01 &&
                    <a onClick={() => { setSelectedAsset({ symbol: "UWU", img: uwutoken, balance: user?.balances[TokenName.UWU], price: session?.prices[TokenName.UWU] }); setSelectedTab("send"); setAmountInput(); setAddressInput(); setAddressError(false); setFinalAddress(); setMemoInput(); }} className="activityItem assetItem assetItemHover">
                      <img draggable="false" src={uwutoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                      <div>
                        <h1>UWU Cash</h1>
                        <h2>UWU</h2>
                      </div>
                      <div style={{ marginLeft: "auto", textAlign: "right" }}>
                        <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.UWU]) + " UWU" : "-"}</h1>
                        {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.UWU] * session?.prices[TokenName.UWU])}</h2>}
                      </div>
                    </a>
                  }
                  {user?.balances[TokenName.SUSDT] > 0.01 &&
                    <a onClick={() => { setSelectedAsset({ symbol: "sUSDT", img: susdttoken, balance: user?.balances[TokenName.SUSDT], price: session?.prices[TokenName.SUSDT] }); setSelectedTab("send"); setAmountInput(); setAddressInput(); setAddressError(false); setFinalAddress(); setMemoInput(); }} className="activityItem assetItem assetItemHover">
                      <img draggable="false" src={susdttoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                      <div>
                        <h1>Bridged USDT</h1>
                        <h2>sUSDT</h2>
                      </div>
                      <div style={{ marginLeft: "auto", textAlign: "right" }}>
                        <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.SUSDT]) + " sUSDT" : "-"}</h1>
                        {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.SUSDT] * session?.prices[TokenName.SUSDT])}</h2>}
                      </div>
                    </a>
                  }
                  {user?.balances[TokenName.AEUSDC] > 0.01 &&
                    <a onClick={() => { setSelectedAsset({ symbol: "aeUSDC", img: aeusdctoken, balance: user?.balances[TokenName.AEUSDC], price: session?.prices[TokenName.AEUSDC] }); setSelectedTab("send"); setAmountInput(); setAddressInput(); setAddressError(false); setFinalAddress(); setMemoInput(); }} className="activityItem assetItem assetItemHover">
                      <img draggable="false" src={aeusdctoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                      <div>
                        <h1>Allbridge USDC</h1>
                        <h2>aeUSDC</h2>
                      </div>
                      <div style={{ marginLeft: "auto", textAlign: "right" }}>
                        <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.AEUSDC]) + " aeUSDC" : "-"}</h1>
                        {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.AEUSDC] * session?.prices[TokenName.AEUSDC])}</h2>}
                      </div>
                    </a>
                  }
                  {user?.balances[TokenName.XUSD] > 0.01 &&
                    <a onClick={() => { setSelectedAsset({ symbol: "xUSD", img: xusdtoken, balance: user?.balances[TokenName.XUSD], price: session?.prices[TokenName.XUSD] }); setSelectedTab("send"); setAmountInput(); setAddressInput(); setAddressError(false); setFinalAddress(); setMemoInput(); }} className="activityItem assetItem assetItemHover">
                      <img draggable="false" src={xusdtoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                      <div>
                        <h1>Wrapped USD</h1>
                        <h2>xUSD</h2>
                      </div>
                      <div style={{ marginLeft: "auto", textAlign: "right" }}>
                        <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.XUSD]) + " xUSD" : "-"}</h1>
                        {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.XUSD] * session?.prices[TokenName.XUSD])}</h2>}
                      </div>
                    </a>
                  }
                  {user?.balances[TokenName.XBTC] > 0.000001 &&
                    <a onClick={() => { setSelectedAsset({ symbol: "xBTC", img: xbtctoken, balance: user?.balances[TokenName.XBTC], price: session?.prices[TokenName.XBTC] }); setSelectedTab("send"); setAmountInput(); setAddressInput(); setAddressError(false); setFinalAddress(); setMemoInput(); }} className="activityItem assetItem assetItemHover">
                      <img draggable="false" src={xbtctoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                      <div>
                        <h1>Wrapped BTC</h1>
                        <h2>xBTC</h2>
                      </div>
                      <div style={{ marginLeft: "auto", textAlign: "right" }}>
                        <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.XBTC], 6) + " xBTC" : "-"}</h1>
                        {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.XBTC] * session?.prices[TokenName.XBTC])}</h2>}
                      </div>
                    </a>
                  }
                  {user?.balances[TokenName.ABTC] > 0.000001 &&
                    <a onClick={() => { setSelectedAsset({ symbol: "aBTC", img: abtctoken, balance: user?.balances[TokenName.ABTC], price: session?.prices[TokenName.ABTC] }); setSelectedTab("send"); setAmountInput(); setAddressInput(); setAddressError(false); setFinalAddress(); setMemoInput(); }} className="activityItem assetItem assetItemHover">
                      <img draggable="false" src={abtctoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                      <div>
                        <h1>Bridged BTC</h1>
                        <h2>aBTC</h2>
                      </div>
                      <div style={{ marginLeft: "auto", textAlign: "right" }}>
                        <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.ABTC], 6) + " aBTC" : "-"}</h1>
                        {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.ABTC] * session?.prices[TokenName.ABTC])}</h2>}
                      </div>
                    </a>
                  }
                  {user?.balances[TokenName.ALEX] > 0.01 &&
                    <a onClick={() => { setSelectedAsset({ symbol: "ALEX", img: alextoken, balance: user?.balances[TokenName.ALEX], price: session?.prices[TokenName.ALEX] }); setSelectedTab("send"); setAmountInput(); setAddressInput(); setAddressError(false); setFinalAddress(); setMemoInput(); }} className="activityItem assetItem assetItemHover">
                      <img draggable="false" src={alextoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                      <div>
                        <h1>ALEX Lab</h1>
                        <h2>ALEX</h2>
                      </div>
                      <div style={{ marginLeft: "auto", textAlign: "right" }}>
                        <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.ALEX]) + " ALEX" : "-"}</h1>
                        {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.ALEX] * session?.prices[TokenName.ALEX])}</h2>}
                      </div>
                    </a>
                  }
                  {user?.balances[TokenName.STSW] > 0.01 &&
                    <a onClick={() => { setSelectedAsset({ symbol: "STSW", img: stswtoken, balance: user?.balances[TokenName.STSW], price: session?.prices[TokenName.STSW] }); setSelectedTab("send"); setAmountInput(); setAddressInput(); setAddressError(false); setFinalAddress(); setMemoInput(); }} className="activityItem assetItem assetItemHover">
                      <img draggable="false" src={stswtoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                      <div>
                        <h1>Stackswap</h1>
                        <h2>STSW</h2>
                      </div>
                      <div style={{ marginLeft: "auto", textAlign: "right" }}>
                        <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.STSW]) + " STSW" : "-"}</h1>
                        {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.STSW] * session?.prices[TokenName.STSW])}</h2>}
                      </div>
                    </a>
                  }
                  {user?.balances[TokenName.VIBES] > 0.01 &&
                    <a onClick={() => { setSelectedAsset({ symbol: "VIBES", img: vibestoken, balance: user?.balances[TokenName.VIBES], price: session?.prices[TokenName.VIBES] }); setSelectedTab("send"); setAmountInput(); setAddressInput(); setAddressError(false); setFinalAddress(); setMemoInput(); }} className="activityItem assetItem assetItemHover">
                      <img draggable="false" src={vibestoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                      <div>
                        <h1>HireVibes</h1>
                        <h2>VIBES</h2>
                      </div>
                      <div style={{ marginLeft: "auto", textAlign: "right" }}>
                        <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.VIBES]) + " VIBES" : "-"}</h1>
                        {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.VIBES] * session?.prices[TokenName.VIBES])}</h2>}
                      </div>
                    </a>
                  }
                  {user?.balances[TokenName.FARI] > 0.01 &&
                    <a onClick={() => { setSelectedAsset({ symbol: "FARI", img: faritoken, balance: user?.balances[TokenName.FARI], price: session?.prices[TokenName.FARI] }); setSelectedTab("send"); setAmountInput(); setAddressInput(); setAddressError(false); setFinalAddress(); setMemoInput(); }} className="activityItem assetItem assetItemHover">
                      <img draggable="false" src={faritoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                      <div>
                        <h1>Fari Token</h1>
                        <h2>FARI</h2>
                      </div>
                      <div style={{ marginLeft: "auto", textAlign: "right" }}>
                        <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.FARI]) + " FARI" : "-"}</h1>
                        {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.FARI] * session?.prices[TokenName.FARI])}</h2>}
                      </div>
                    </a>
                  }
                  {user?.balances[TokenName.WELSH] > 0.01 &&
                    <a onClick={() => { setSelectedAsset({ symbol: "WELSH", img: welshtoken, balance: user?.balances[TokenName.WELSH], price: session?.prices[TokenName.WELSH] }); setSelectedTab("send"); setAmountInput(); setAddressInput(); setAddressError(false); setFinalAddress(); setMemoInput(); }} className="activityItem assetItem assetItemHover">
                      <img draggable="false" src={welshtoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                      <div>
                        <h1>Welsh Corgi Coin</h1>
                        <h2>WELSH</h2>
                      </div>
                      <div style={{ marginLeft: "auto", textAlign: "right" }}>
                        <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.WELSH]) + " WELSH" : "-"}</h1>
                        {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.WELSH] * session?.prices[TokenName.WELSH])}</h2>}
                      </div>
                    </a>
                  }
                  {user?.balances[TokenName.LEO] > 0.01 &&
                    <a onClick={() => { setSelectedAsset({ symbol: "LEO", img: leotoken, balance: user?.balances[TokenName.LEO], price: session?.prices[TokenName.LEO] }); setSelectedTab("send"); setAmountInput(); setAddressInput(); setAddressError(false); setFinalAddress(); setMemoInput(); }} className="activityItem assetItem assetItemHover">
                      <img draggable="false" src={leotoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                      <div>
                        <h1>LEO Token</h1>
                        <h2>LEO</h2>
                      </div>
                      <div style={{ marginLeft: "auto", textAlign: "right" }}>
                        <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.LEO]) + " LEO" : "-"}</h1>
                        {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.LEO] * session?.prices[TokenName.LEO])}</h2>}
                      </div>
                    </a>
                  }
                  {user?.balances[TokenName.GUS] > 0.01 &&
                    <a onClick={() => { setSelectedAsset({ symbol: "GUS", img: gustoken, balance: user?.balances[TokenName.GUS], price: session?.prices[TokenName.GUS] }); setSelectedTab("send"); setAmountInput(); setAddressInput(); setAddressError(false); setFinalAddress(); setMemoInput(); }} className="activityItem assetItem assetItemHover">
                      <img draggable="false" src={gustoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                      <div>
                        <h1>GUS Token</h1>
                        <h2>GUS</h2>
                      </div>
                      <div style={{ marginLeft: "auto", textAlign: "right" }}>
                        <h1>{showPortfolio ? formatTokensDisplay(user?.balances[TokenName.GUS]) + " GUS" : "-"}</h1>
                        {showPortfolio && <h2>≈ ${formatTokensDisplay(user?.balances[TokenName.GUS] * session?.prices[TokenName.GUS])}</h2>}
                      </div>
                    </a>
                  }
                </div>
                </>
              }
              {user?.balances.lto["0"] > 0.01 && 
                <>
                <h2 style={{ fontSize: "0.85rem", fontWeight: "600", color: "#838383", marginTop: "15px", marginBottom: "-12px", marginLeft: "20px" }}>Your LP Tokens</h2>
                <div className="activityView assetView">
                  {user?.balances.lto["0"] > 0.01 &&
                    <a onClick={() => { setSelectedAsset({ symbol: "LTO", img: ltotoken, balance: user?.balances.lto["0"], price: session?.pools["0"].balances.usd / session?.pools["0"].lpToken.shares.formatted }); setSelectedTab("send"); setAmountInput(); setAddressInput(); setAddressError(false); setFinalAddress(); setMemoInput(); }} className="activityItem assetItem assetItemHover">
                      <img draggable="false" src={ltotoken} style={{ width: "30px", padding: "0", borderRadius: "100px" }} />
                      <div>
                        <h1>STX-UWU LP</h1>
                        <h2>LTO</h2>
                      </div>
                      <div style={{ marginLeft: "auto", textAlign: "right" }}>
                        <h1>{showPortfolio ? formatTokensDisplay(user?.balances.lto["0"]) + " LTO" : "-"}</h1>
                        {showPortfolio && <h2>≈ ${formatTokensDisplay((session?.pools["0"].balances.usd * (user?.balances.lto["0"] / session?.pools["0"].lpToken.shares.formatted)))}</h2>}
                      </div>
                    </a>
                  }
                </div>
                </>
              }
            </div>
          }
          {selectedTab === "send" &&
            <div>
              <div className="popupScroll" style={{ maxHeight: "unset", padding: "15px 20px 20px 20px" }}>
                <div className="borrowInputTitle">
                  <h1>Send {selectedAsset.symbol}</h1>
                  <h2 onClick={() => setAmountInput((selectedAsset.symbol === "xBTC" || selectedAsset.symbol === "aBTC") ? formatTokens(selectedAsset.balance, 6) : formatTokens(selectedAsset.balance))}>Balance: {(selectedAsset.symbol === "xBTC" || selectedAsset.symbol === "aBTC") ? formatTokensDisplay(selectedAsset.balance, 6) : formatTokensDisplay(selectedAsset.balance)} {selectedAsset.symbol}</h2>
                </div>
                <span className="borrowInput borrowInputPrice" style={{ marginBottom: "20px" }}><div style={{ width: "100%" }}><input inputmode="decimal" maxLength="10" placeholder={`0.00 ${selectedAsset.symbol}`} onChange={(e) => setAmountInput(e.target.value.toString().split(".").map((el, i) => i ? el.split("").slice(0, (selectedAsset.symbol === "xBTC" || selectedAsset.symbol === "aBTC") ? 6 : 2).join("") : el).join(".").replace(/^0+(?=\d)/, ""))} onKeyDown={(e) => checkCharacter(e)} value={amountInput} /><h4>≈ ${formatTokensDisplay(amountInput * selectedAsset.price)}</h4></div><div className="swapOutputCurrency" style={{ marginRight: "15px" }}><img draggable="false" src={selectedAsset.img} /><h1>{selectedAsset.symbol}</h1></div></span>
                {amountInput > selectedAsset.balance && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-10px 0px 18px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>The amount entered exceeds your account balance</h1></div>}
                <div className="borrowInputTitle">
                  <h1>Recipient Address or Name</h1>
                </div>
                <span className="borrowInput" style={{ marginBottom: "20px" }}><input autocomplete="off" placeholder="SP4MCDR..." style={{ fontSize: "1rem", padding: "10px" }} value={addressInput} onChange={handleInputChange} onKeyPress={handleKeyPress} />{(addressInput && !finalAddress && !addressError) && <img style={{ padding: "0", backgroundColor: "transparent", margin: "0px 7px 0px 0px", width: "20px", height: "20px" }} src={loader} draggable="false" width="20px" />}{(!validateStacksAddress(addressInput) && finalAddress) && <a className="inputLink" style={{ fontSize: "0.85rem", marginRight: "10px" }} href={`https://explorer.hiro.so/address/${finalAddress}?chain=mainnet`} target="_blank">{trunc(finalAddress)}</a>}</span>
                {finalAddress === user?.address && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-10px 0px 18px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>You cannot send assets to your own account</h1></div>}
                {addressError && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-10px 0px 18px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>The address or name entered could not be found</h1></div>}
                <div className="borrowInputTitle">
                  <h1>Memo (optional)</h1>
                </div>
                <span className="borrowInput"><input autocomplete="off" maxLength="34" placeholder="For the pizza" onChange={(e) => setMemoInput(e.target.value.toString())} value={memoInput} style={{ fontSize: "1rem", padding: "10px" }} /></span>
              </div>
              <a onClick={() => selectedAsset.symbol === "STX" ? checkCallRequirements() && callSTXTransfer() : checkCallRequirements() && callFungibleTransfer()} className="borrowButton" style={{ color: "#FFFFFF", textDecoration: "none", lineHeight: "3rem", margin: "20px", fontSize: "0.85rem", minWidth: "fit-content", backgroundColor: checkCallRequirements() ? "#000000" : "#575757", cursor: checkCallRequirements() ? "pointer" : "default" }}>{!amountInput && "Enter an Amount" || !addressInput && "Enter an Address or Name" || "Send"}</a>
            </div>
          }
          {selectedTab === "sendConfirm" &&
            <div className="popupBody">
              <img src={checkmark} draggable="false" />
              <h1>{defaultPopupOptions.title}</h1>
              <h2 style={{ marginBottom: "3rem" }}>{defaultPopupOptions.description}</h2>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <a href={`https://explorer.stacks.co/txid/${defaultPopupOptions.tx}?chain=mainnet`} target="_blank" className="borrowButton" style={{ color: "#FFFFFF", textDecoration: "none", lineHeight: "3rem", margin: "auto", marginBottom: "1rem", fontSize: "0.85rem" }}>View Transaction</a>
                <a onClick={handleClosePopup}>Close</a>
              </div>
            </div>
          }
        </div>
      </PopupAnimation>
    </>
  );
};