import { getStacksRPC } from './rpcService.ts'
import { lpEnabledTokens, tokensMap, userEnabledTokens } from '../../constants/tokens.ts'
import { getLPTokenContract, getLPTokensMapArray, getTokenContract, getTokensMapArray } from '../../utils/tokenUtils.ts'
import { TokenName } from '../../enums/tokens.ts'
import { STACKS_NETWORK_TYPE } from '../../config/stacks.ts'
import { fetchRetry } from '../../utils/fetchUtils.ts'
import { getArgumentsAsObject } from '../../utils/transactionUtils.ts'
import { getUserRelevantContracts } from '../../utils/contractUtils.ts'
import { STACKSWAP_CONTRACT_ADDRESSES, STACKSWAP_CONTRACT_NAMES } from '../../constants/smartContracts.ts'
import { formatTokenValue, formatValue } from '../../utils/formattingUtils.ts'

/**
 * Fetches the Bitcoin Name Service (BNS) name associated with a given Stacks address
 * 
 * @param {string} address The Stacks address to query for associated BNS names
 * @returns {Promise<string|null>} The primary BNS name associated with the address or null if not available
 * @throws {Error} If the network request fails or returns a non-ok status
 */
export const getBNS = async (address: string): Promise<string | null> => {
  const endpoint = `${getStacksRPC()}/v1/addresses/stacks/${address}`
  const response = await fetchRetry(endpoint)

  if (!response.ok) {
    throw new Error(`Failed to fetch BNS for address '${address}'`)
  }

  const data = await response.json()

  return Array.isArray(data.names) && data.names.length > 0 ? data.names[0] : null
}

/**
 * Retrieves the Stacks address associated with a given BNS (Bitcoin Name Service) name
 * 
 * @param {string} bns The BNS name to query for the associated Stacks address
 * @returns {Promise<string|null>} The Stacks address associated with the BNS name or null if not available
 * @throws {Error} If the network request fails or returns a non-ok status
 */
export const getAddress = async (bns: string): Promise<string | null> => {
  const endpoint = `${getStacksRPC()}/v1/names/${bns}`
  const response = await fetchRetry(endpoint)

  if (!response.ok) {
    throw new Error(`Failed to fetch address for BNS '${bns}'`)
  }

  const data = await response.json()

  return data.address ? data.address : null
}

/**
 * Fetches the balances of all tokens enabled for a user from a specific Stacks address
 * 
 * @param {string} address The Stacks address to query for token balances
 * @returns {Promise<Record<string, { formatted: number; unformatted: number; }>>} The formatted and unformatted balance of each token for the given address
 * @throws {Error} If the network request fails or returns a non-ok status
 */
export const getBalances = async (address: string): Promise<Record<string, { formatted: number; unformatted: number; }>> => {
  const endpoint = `${getStacksRPC()}/extended/v1/address/${address}/balances`
  const response = await fetchRetry(endpoint)

  if (!response.ok) {
    throw new Error(`Failed to fetch balances for user '${address}'`)
  }

  const data = await response.json()

  const availableTokens = getTokensMapArray().filter((token: { name: TokenName; enabled: any }) => userEnabledTokens.includes(token.name) && token.enabled)
  const availableLPTokens = getLPTokensMapArray().filter(lpToken => lpEnabledTokens.includes(lpToken.name) && lpToken.enabled)
  
  const balances: Record<string, { formatted: number; unformatted: number; }> = {}

  availableTokens.forEach(token => {
    balances[token.name] = { formatted: 0, unformatted: 0 }
  })

  availableLPTokens.forEach(lpToken => {
    balances[lpToken.name] = { formatted: 0, unformatted: 0 }
  })

  const stxToken = tokensMap[TokenName.STX]

  if (availableTokens.some(token => token === stxToken)) {
    balances[TokenName.STX].formatted = formatTokenValue(data.stx.balance, stxToken)
    balances[TokenName.STX].unformatted = formatValue(data.stx.balance)
  }

  Object.keys(data.fungible_tokens).forEach(token => {
    availableTokens.forEach(availToken => {
      if (token === getTokenContract(availToken, STACKS_NETWORK_TYPE)) {
        balances[availToken.name].formatted = formatTokenValue(data.fungible_tokens[token].balance, availToken)
        balances[availToken.name].unformatted = formatValue(data.fungible_tokens[token].balance)
      }
    })

    availableLPTokens.forEach(availToken => {
      if (token === getLPTokenContract(availToken, STACKS_NETWORK_TYPE)) {
        balances[availToken.name].formatted = formatTokenValue(data.fungible_tokens[token].balance, availToken)
        balances[availToken.name].unformatted = formatValue(data.fungible_tokens[token].balance)
      }
    })
  })

  return balances
}

export const getUserTransactions = async (address: string, pending: boolean = false) => {
  const url = `${getStacksRPC()}/extended/v1/address/${address}`
  const endpoint = pending ? `${url}/mempool?limit=50&unanchored=true` : `${url}/transactions_with_transfers?limit=50`
  const response = await fetchRetry(endpoint)

  if (!response.ok) {
    throw new Error(`Failed to fetch ${pending ? 'pending ' : ''}transactions for address '${address}'`)
  }

  const data = await response.json()

  return data.results?.filter(tx => checkUserTransaction(tx, pending)).map(tx => pending ? tx : tx.tx) || []
}

export const checkUserTransaction = (tx, pending = false) => {
  const relevantContracts = getUserRelevantContracts()

  const isSwapContract = (id, call) => {
    if (id !== `${STACKSWAP_CONTRACT_ADDRESSES.DEPLOYER}.${STACKSWAP_CONTRACT_NAMES.SWAP}` || !call) {
      return false
    }

    const args = getArgumentsAsObject(call.function_args)

    const uwuContract = getTokenContract(tokensMap[TokenName.UWU], STACKS_NETWORK_TYPE, true)

    return args['token-y-trait'].slice(1) === uwuContract &&
      ['swap-x-for-y', 'swap-y-for-x', 'add-to-position', 'reduce-position'].includes(call?.function_name)
  }

  const isValidTransaction = (id, call) => {
    if (!id) {
      return false
    }

    if (id === getTokenContract(tokensMap[TokenName.LEO], STACKS_NETWORK_TYPE, true) && call?.function_name === 'send-many') {
      return false
    }

    return isSwapContract(id, call) || relevantContracts.includes(id)
  }

  const checkTransactionStatus = (contract_call, token_transfer, tx_status = '') => {
    const isSuccess = (tx_status === 'success' || tx_status.includes('abort')) || pending

    return token_transfer || (contract_call && isValidTransaction(contract_call.contract_id, contract_call) && isSuccess)
  }

  if (pending) {
    const { contract_call, token_transfer } = tx

    return checkTransactionStatus(contract_call, token_transfer)
  } else {
    const { tx_status, contract_call, token_transfer } = tx.tx

    return checkTransactionStatus(contract_call, token_transfer, tx_status)
  }
}