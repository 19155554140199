import { FungibleConditionCode, PostConditionMode, principalCV, uintCV } from '@stacks/transactions'
import { STACKS_NETWORK, STACKS_NETWORK_TYPE } from '../../config/stacks'
import { STACKSWAP_CONTRACT_ADDRESSES, STACKSWAP_CONTRACT_NAMES, STACKSWAP_FUNCTION_NAMES, UWU_CONTRACT_ADDRESSES } from '../../constants/smartContracts'
import { ProtocolName } from '../../enums/protocols'
import { LiquidityPool } from '../../interfaces/lp'
import { LPToken, Token } from '../../interfaces/tokens'
import { readOnlyRetry } from '../../utils/fetchUtils'
import { formatTokenValue, formatValue } from '../../utils/formattingUtils'
import { SwapTransaction } from '../../interfaces/transactions'
import { User } from '../../interfaces/user'
import { useCallback } from 'react'
import { getLPTokenContract, getTokenContract } from '../../utils/tokenUtils'
import { useConnect as stacksConnect } from '@stacks/connect-react'
import { createContractPostCondition, createUserPostCondition } from '../../utils/postConditionUtils'
import { swapEnabledTokens } from '../../constants/tokens'
import { SwapAmounts, SwapData } from '../../interfaces/swap'
import { TokenName } from '../../enums/tokens'

export const getStackswapLP = async (lpToken: LPToken): Promise<LiquidityPool> => {
  const { lptContract } = lpToken
  const contractDetails = lptContract[STACKS_NETWORK_TYPE]

  if (lpToken.protocol !== ProtocolName.STACKSWAP) {
    throw new Error(`Protocol used by LP Token '${lpToken.name}' doesn't match protocol '${ProtocolName.STACKSWAP}'`)
  }

  if (!contractDetails?.address || !contractDetails?.name) {
    throw new Error(`Contract details for LP Token '${lpToken.name}' are missing`)
  }

  const response = await readOnlyRetry({
    contractAddress: contractDetails.address,
    contractName: contractDetails.name,
    functionName: STACKSWAP_FUNCTION_NAMES.GET_LP_DATA,
    functionArgs: [],
    network: STACKS_NETWORK,
    senderAddress: UWU_CONTRACT_ADDRESSES.DEPLOYER,
  })

  if (response?.value?.data === null) {
    throw new Error(`Failed to fetch liquidity pool for LP Token '${lpToken.name}'`)
  }

  const { data: lp } = response.value

  const { xToken, yToken } = lpToken

  const formattedBalanceX = formatTokenValue(lp['balance-x'].value, xToken)
  const formattedBalanceY = formatTokenValue(lp['balance-y'].value, yToken)
  
  return {
    lpToken: {
      name: lpToken.name,
      shares: {
        formatted: formatTokenValue(lp['shares-total'].value, lpToken),
        unformatted: formatValue(lp['shares-total'].value, 4)
      },
      price: {
        formatted: formatValue(formattedBalanceX / formattedBalanceY, 4),
        unformatted: formatValue(lp['balance-x'].value / lp['balance-y'].value, 4)
      }
    },
    balances: {
      [xToken.name]: {
        formatted: formattedBalanceX,
        unformatted: formatValue(lp['balance-x'].value, 4)
      },
      [yToken.name]: {
        formatted: formattedBalanceY,
        unformatted: formatValue(lp['balance-y'].value, 4)
      }
    },
    feeBalances: {
      [xToken.name]: {
        formatted: formatTokenValue(lp['fee-balance-x'].value, xToken),
        unformatted: formatValue(lp['fee-balance-x'].value, 4)
      },
      [yToken.name]: {
        formatted: formatTokenValue(lp['fee-balance-y'].value, yToken),
        unformatted: formatValue(lp['fee-balance-y'].value, 4)
      }
    }
  }
}

export const getStackswapSwapAmounts = (swapSession: SwapData, lpToken: LPToken, inputToken: Token, outputToken: Token, amount: number, slippageTolerance: number): SwapAmounts => {
  if (isNaN(amount) || amount === 0 || amount === null || amount === '' || !swapEnabledTokens.includes(inputToken.name) || !swapEnabledTokens.includes(outputToken.name) || lpToken.protocol !== ProtocolName.STACKSWAP || !lpToken.enabled) {
    return {
      amount: amount,
      est: {
        formatted: 0,
        unformatted: 0
      },
      min: {
        formatted: 0,
        unformatted: 0
      },
      swapFee: lpToken.fee,
      priceImpact: 0,
      slippageTolerance: slippageTolerance
    }
  }

  const pool = swapSession?.pools[lpToken.name]
  const scaledAmount = amount * inputToken.decimals
  const fee = (scaledAmount * lpToken.fee)

  if (inputToken.name === lpToken.xToken.name && outputToken.name === lpToken.yToken.name) {
    const k = pool.balances[lpToken.yToken.name].unformatted / pool.balances[lpToken.xToken.name].unformatted
    const estDy = formatValue(pool.balances[lpToken.yToken.name].unformatted - (pool.balances[lpToken.xToken.name].unformatted * pool.balances[lpToken.yToken.name].unformatted) / (pool.balances[lpToken.xToken.name].unformatted + (scaledAmount - fee)), 0)
    const minDy = formatValue(estDy * (1 - slippageTolerance), 0)
    const priceImpact = ((k / (estDy / scaledAmount)) - 1) * 100 | 0

    const formattedEstDy = formatTokenValue(estDy, outputToken)
    const formattedMinDy = formatTokenValue(minDy, outputToken)
    
    return {
      amount: amount,
      est: {
        formatted: formattedEstDy,
        unformatted: estDy
      },
      min: {
        formatted: formattedMinDy,
        unformatted: minDy
      },
      swapFee: lpToken.fee,
      priceImpact: priceImpact,
      slippageTolerance: slippageTolerance
    }
  }

  if (inputToken.name === lpToken.yToken.name && outputToken.name === lpToken.xToken.name) {
    const k = pool.balances[lpToken.xToken.name].unformatted / pool.balances[lpToken.yToken.name].unformatted
    const estDx = formatValue(pool.balances[lpToken.xToken.name].unformatted - (pool.balances[lpToken.yToken.name].unformatted * pool.balances[lpToken.xToken.name].unformatted) / (pool.balances[lpToken.yToken.name].unformatted + (scaledAmount - fee)), 0)
    const minDx = formatValue(estDx * (1 - slippageTolerance), 0)
    const priceImpact = ((k / (estDx / scaledAmount)) - 1) * 100 | 0

    const formattedEstDx = formatTokenValue(estDx, outputToken)
    const formattedMinDx = formatTokenValue(minDx, outputToken)

    return {
      amount: amount,
      est: {
        formatted: formattedEstDx,
        unformatted: estDx
      },
      min: {
        formatted: formattedMinDx,
        unformatted: minDx
      },
      swapFee: lpToken.fee,
      priceImpact: priceImpact,
      slippageTolerance: slippageTolerance
    }
  }

  return {
    amount: amount,
    est: {
      formatted: 0,
      unformatted: 0
    },
    min: {
      formatted: 0,
      unformatted: 0
    },
    swapFee: lpToken.fee,
    priceImpact: 0,
    slippageTolerance: slippageTolerance
  }
}

export const useStackswapSwap = () => {
  const { doContractCall } = stacksConnect()

  const handleStackswapSwap = useCallback(
    async (user: User, options: SwapTransaction) => {
      const inputToken = options.tokens.input
      const outputToken = options.tokens.output
      const lpToken = options.tokens.lp

      if (!lpToken) {
        throw new Error(`No valid LP Token was provided for protocol '${ProtocolName.STACKSWAP}'`)
      }

      const lpTokenContractDetails = lpToken.lptContract[STACKS_NETWORK_TYPE]

      if (lpToken.protocol !== ProtocolName.STACKSWAP) {
        throw new Error(`Protocol used by LP Token '${lpToken.name}' doesn't match protocol '${ProtocolName.STACKSWAP}'`)
      }

      if (!lpTokenContractDetails?.address || !lpTokenContractDetails?.name) {
        throw new Error(`Contract details for LP Token '${lpToken.name}' are missing`)
      }

      const xTokenContract = getTokenContract(lpToken.xToken, STACKS_NETWORK_TYPE, true, lpToken.xToken.name === TokenName.STX ? ProtocolName.STACKSWAP : null)!
      const yTokenContract = getTokenContract(lpToken.yToken, STACKS_NETWORK_TYPE, true, lpToken.yToken.name === TokenName.STX ? ProtocolName.STACKSWAP : null)!
      const lpTokenContract = getLPTokenContract(lpToken, STACKS_NETWORK_TYPE, true)!
      
      const functionName = inputToken.name === lpToken.xToken.name ? STACKSWAP_FUNCTION_NAMES.SWAP_X_FOR_Y : STACKSWAP_FUNCTION_NAMES.SWAP_Y_FOR_X

      const scaledAmount = formatValue(options.amounts.amount * inputToken.decimals, 0)
      const minReceived = options.amounts.min.unformatted

      const postConditions = [
        createUserPostCondition(
          user?.address,
          FungibleConditionCode.Equal,
          scaledAmount,
          inputToken
        ),
        createContractPostCondition(
          lpTokenContractDetails.address,
          lpTokenContractDetails.name,
          FungibleConditionCode.GreaterEqual,
          minReceived,
          outputToken
        )
      ]

      const tx = await new Promise((resolve, reject) => {
        doContractCall({
          contractAddress: STACKSWAP_CONTRACT_ADDRESSES.DEPLOYER,
          contractName: STACKSWAP_CONTRACT_NAMES.SWAP,
          functionName: functionName,
          functionArgs: [
            principalCV(xTokenContract),
            principalCV(yTokenContract),
            principalCV(lpTokenContract),
            uintCV(scaledAmount),
            uintCV(minReceived)
          ],
          postConditionMode: PostConditionMode.Deny,
          postConditions: postConditions,
          network: STACKS_NETWORK,
          onFinish: (result) => {
            resolve(result)
          },
          onCancel: (error) => {
            reject(error)
          }
        })
      })

      return tx
    },
    [doContractCall]
  )

  return { handleStackswapSwap }
}