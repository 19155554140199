export const NOTIFICATION_TITLES = {
  SWAP: 'Swap Confirmation'
}

export const NOTIFICATION_DESCRIPTIONS = {
  DEFAULT: 'Transaction successful. View the transaction details by clicking here.',
  SWAP: 'You swapped ${inputValue} for ${outputValue}. View the transaction details by clicking here.'
}

export const NOTIFICATION_LINKS = {
  EXPLORER: 'https://explorer.hiro.so/txid/${txId}'
}