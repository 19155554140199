import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { useConnect } from "../lib/auth.js";
import { trunc } from "../lib/utils.js";

import gear from "../img/gear.png";
import notification from "../img/notification.png";

import { NotificationContext } from "../context/NotificationContext.js";

import HeaderMenuPopupComponent from "../popups/HeaderMenuPopupComponent.js";
import APIPopupComponent from "../popups/APIPopupComponent.js";
import ActivityPopupComponent from "../popups/ActivityPopupComponent.js";
import NotificationsPopupComponent from "../popups/NotificationsPopupComponent.js";
import AssetsPopupComponent from "../popups/AssetsPopupComponent.js";

export default function HeaderComponent({session, user}) {
  const { handleSignOut } = useConnect();

  const { hasUnreadNotifications } = useContext(NotificationContext);

  const [headerMenuPopup, setHeaderMenuPopup] = useState(false);
  const [headerMenuVisible, setHeaderMenuVisible] = useState(true);
  const [notificationsPopup, setNotificationsPopup] = useState(false);
  const [activityPopup, setActivityPopup] = useState(false);
  const [assetsPopup, setAssetsPopup] = useState(false);

  const [showApiPopup, setShowAPIPopup] = useState(false);

  function toggleMenu() {
    if(headerMenuPopup) {
      setHeaderMenuVisible(false);
      setTimeout(() => {
        setHeaderMenuPopup(false);
      }, 200);
    } else {
      setHeaderMenuVisible(true);
      setHeaderMenuPopup(true);
    };
  };

  return (
    <>
      {headerMenuPopup && <HeaderMenuPopupComponent show={headerMenuVisible} toggleMenu={() => toggleMenu()} showApi={() => setShowAPIPopup(true)} showActivity={() => setActivityPopup(true)} showAssets={() => setAssetsPopup(true)} />}
      {showApiPopup && <APIPopupComponent show={(e) => setShowAPIPopup(e)} />}
      {activityPopup && <ActivityPopupComponent show={(e) => setActivityPopup(e)} address={user?.address} />}
      {assetsPopup && <AssetsPopupComponent show={(e) => setAssetsPopup(e)} context={[session, user]} />}
      <div>
        <div className="header">
          <div className="headerLeft">
            <a href="/"><img src="https://cdn.quorumdao.org/uwu-logo-black.png" width="55px" draggable="false" /></a>
            <ul>
              <li><NavLink to="/" className="navLink">Home</NavLink></li>
              <li><NavLink to="/borrow" className="navLink">Borrow</NavLink></li>
              <li><NavLink to="/earn" className="navLink">Earn</NavLink></li>
              <li><div style={{ display: "flex", gridGap: "0.5rem", alignItems: "center" }}><NavLink to="/swap" className="navLink">Swap</NavLink><a href="/swap" style={{ color: "#FFFFFF", backgroundColor: "#FF7BED", padding: "3px 7px", borderRadius: "100px", fontSize: "0.65rem", textDecoration: "none" }}>V2</a></div></li>
            </ul>
          </div>
          <div className="headerRight">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button className="imgButton smallButton" style={{ marginRight: "5px", width: "42px", height: "42px", borderColor: notificationsPopup && "#c3c3c3" }} onClick={() => setNotificationsPopup(!notificationsPopup)}>{hasUnreadNotifications() && <span className="unreadDot"></span>}<img draggable="false" src={notification} style={{ width: "20px", filter: notificationsPopup && "brightness(0)" }} /></button>
              {notificationsPopup && <NotificationsPopupComponent show={(e) => setNotificationsPopup(e)} />}
            </div>
            <a className="headerButton headerButtonWhite" style={{ display: "flex", alignItems: "center", padding: "0", paddingLeft: "1rem", borderColor: headerMenuPopup && "#c3c3c3" }} onClick={() => toggleMenu()}>{user ? (user?.bns ? trunc(user?.bns, true) : trunc(user?.address)) : "-"} <img src={gear} width="15px" style={{ filter: headerMenuPopup && "brightness(0)" }} /></a>
          </div>
        </div>
      </div>
    </>
  );
};