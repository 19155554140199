import { LPTokenName, TokenName } from '../enums/tokens'
import { ProtocolName } from '../enums/protocols'
import { LPToken, Token, WrappedToken } from '../interfaces/tokens'

export const tokensMap: Record<string, Token> = {
  [TokenName.STX]: {
    name: TokenName.STX,
    symbol: 'STX',
    icon: '/assets/icons/tokens/stx.svg',
    decimals: 1e6,
    metadata: '',
    contract: {
      mainnet: {
        address: ' ',
        name: ' ',
        asset: ' '
      },
      testnet: {
        address: ' ',
        name: ' ',
        asset: ' '
      }
    },
    coingeckoIdentifier: 'blockstack',
    enabled: true
  },
  [TokenName.UWU]: {
    name: TokenName.UWU,
    symbol: 'UWU',
    icon: '/assets/icons/tokens/uwu-v2.png',
    decimals: 1e6,
    metadata: '',
    contract: {
      mainnet: {
        address: 'SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4',
        name: 'uwu-token-v1-1-0',
        asset: 'uwu'
      }
    },
    enabled: true
  },
  [TokenName.SUSDT]: {
    name: TokenName.SUSDT,
    symbol: 'sUSDT',
    icon: '/assets/icons/tokens/susdt.svg',
    decimals: 1e8,
    metadata: '',
    contract: {
      mainnet: {
        address: 'SP2XD7417HGPRTREMKF748VNEQPDRR0RMANB7X1NK',
        name: 'token-susdt',
        asset: 'bridged-usdt'
      }
    },
    coingeckoIdentifier: 'alex-wrapped-usdt',
    enabled: true
  },
  [TokenName.AEUSDC]: {
    name: TokenName.AEUSDC,
    symbol: 'aeUSDC',
    icon: '/assets/icons/tokens/aeusdc.svg',
    decimals: 1e6,
    metadata: '',
    contract: {
      mainnet: {
        address: 'SP3Y2ZSH8P7D50B0VBTSX11S7XSG24M1VB9YFQA4K',
        name: 'token-aeusdc',
        asset: 'aeUSDC'
      }
    },
    coingeckoIdentifier: 'allbridge-bridged-usdc-stacks',
    enabled: true
  },
  [TokenName.XUSD]: {
    name: TokenName.XUSD,
    symbol: 'xUSD',
    icon: '/assets/icons/tokens/xusd.png',
    decimals: 1e8,
    metadata: '',
    contract: {
      mainnet: {
        address: 'SP2TZK01NKDC89J6TA56SA47SDF7RTHYEQ79AAB9A',
        name: 'Wrapped-USD',
        asset: 'wrapped-usd'
      }
    },
    coingeckoIdentifier: 'wrapped-usdc',
    enabled: true
  },
  [TokenName.XBTC]: {
    name: TokenName.XBTC,
    symbol: 'xBTC',
    icon: '/assets/icons/tokens/xbtc.png',
    decimals: 1e8,
    metadata: '',
    contract: {
      mainnet: {
        address: 'SP3DX3H4FEYZJZ586MFBS25ZW3HZDMEW92260R2PR',
        name: 'Wrapped-Bitcoin',
        asset: 'wrapped-bitcoin'
      }
    },
    coingeckoIdentifier: 'wrapped-bitcoin-stacks',
    enabled: true
  },
  [TokenName.ABTC]: {
    name: TokenName.ABTC,
    symbol: 'aBTC',
    icon: '/assets/icons/tokens/abtc.png',
    decimals: 1e8,
    metadata: '',
    contract: {
      mainnet: {
        address: 'SP2XD7417HGPRTREMKF748VNEQPDRR0RMANB7X1NK',
        name: 'token-abtc',
        asset: 'bridged-btc'
      }
    },
    coingeckoIdentifier: 'xlink-bridged-btc-stacks',
    enabled: true
  },
  [TokenName.ALEX]: {
    name: TokenName.ALEX,
    symbol: 'ALEX',
    icon: '/assets/icons/tokens/alex.png',
    decimals: 1e8,
    metadata: '',
    contract: {
      mainnet: {
        address: 'SP102V8P0F7JX67ARQ77WEA3D3CFB5XW39REDT0AM',
        name: 'token-alex',
        asset: 'alex'
      }
    },
    coingeckoIdentifier: 'alexgo',
    enabled: true
  },
  [TokenName.STSW]: {
    name: TokenName.STSW,
    symbol: 'STSW',
    icon: '/assets/icons/tokens/stsw.png',
    decimals: 1e6,
    metadata: '',
    contract: {
      mainnet: {
        address: 'SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275',
        name: 'stsw-token-v4a',
        asset: 'stsw'
      }
    },
    coingeckoIdentifier: 'stackswap',
    enabled: true
  },
  [TokenName.VIBES]: {
    name: TokenName.VIBES,
    symbol: 'VIBES',
    icon: '/assets/icons/tokens/vibes.png',
    decimals: 1e8,
    metadata: '',
    contract: {
      mainnet: {
        address: 'SP27BB1Y2DGSXZHS7G9YHKTSH6KQ6BD3QG0AN3CR9',
        name: 'vibes-token',
        asset: 'vibes-token'
      }
    },
    coingeckoIdentifier: 'hirevibes',
    enabled: true
  },
  [TokenName.FARI]: {
    name: TokenName.FARI,
    symbol: 'FARI',
    icon: '/assets/icons/tokens/fari.png',
    decimals: 1e8,
    metadata: '',
    contract: {
      mainnet: {
        address: 'SP213KNHB5QD308TEESY1ZMX1BP8EZDPG4JWD0MEA',
        name: 'fari-token-mn',
        asset: 'fari'
      }
    },
    enabled: true
  },
  [TokenName.WELSH]: {
    name: TokenName.WELSH,
    symbol: 'WELSH',
    icon: '/assets/icons/tokens/welsh.png',
    decimals: 1e6,
    metadata: '',
    contract: {
      mainnet: {
        address: 'SP3NE50GEXFG9SZGTT51P40X2CKYSZ5CC4ZTZ7A2G',
        name: 'welshcorgicoin-token',
        asset: 'welshcorgicoin'
      }
    },
    coingeckoIdentifier: 'welsh-corgi-coin',
    enabled: true
  },
  [TokenName.LEO]: {
    name: TokenName.LEO,
    symbol: 'LEO',
    icon: '/assets/icons/tokens/leo.png',
    decimals: 1e6,
    metadata: '',
    contract: {
      mainnet: {
        address: 'SP1AY6K3PQV5MRT6R4S671NWW2FRVPKM0BR162CT6',
        name: 'leo-token',
        asset: 'leo'
      }
    },
    enabled: true
  },
  [TokenName.GUS]: {
    name: TokenName.GUS,
    symbol: 'GUS',
    icon: '/assets/icons/tokens/gus.png',
    decimals: 1e6,
    metadata: '',
    contract: {
      mainnet: {
        address: 'SP1JFFSYTSH7VBM54K29ZFS9H4SVB67EA8VT2MYJ9',
        name: 'gus-token',
        asset: 'gus'
      }
    },
    enabled: true
  }
}

export const wrappedTokensMap: Record<string, Record<string, WrappedToken>> = {
  [ProtocolName.STACKSWAP]: {
    [TokenName.STX]: {
      contract: {
        mainnet: {
          address: 'SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275',
          name: 'wstx-token-v4a',
          asset: ''
        }
      }
    }
  },
  [ProtocolName.ALEX]: {
    [TokenName.STX]: {
      contract: {
        mainnet: {
          address: 'SP102V8P0F7JX67ARQ77WEA3D3CFB5XW39REDT0AM',
          name: 'token-wstx-v2',
          asset: ''
        }
      }
    }
  },
  [ProtocolName.BITFLOW]: {
    [TokenName.STX]: {
      contract: {
        mainnet: {
          address: 'SM1793C4R5PZ4NS4VQ4WMP7SKKYVH8JZEWSZ9HCCR',
          name: 'token-stx-v-1-1',
          asset: ''
        }
      }
    }
  }
}

export const lpTokensMap: Record<string, LPToken> = {
  [LPTokenName.STACKSWAP_STX_UWU]: {
    name: LPTokenName.STACKSWAP_STX_UWU,
    symbol: 'STACKSWAP_STX_UWU',
    icon: 'assets/icons/tokens/stackswap-stx-uwu.png',
    decimals: 1e6,
    metadata: '',
    protocol: ProtocolName.STACKSWAP,
    fee: 0.003,
    xToken: tokensMap[TokenName.STX],
    yToken: tokensMap[TokenName.UWU],
    lptContract: {
      mainnet: {
        address: 'SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275',
        name: 'liquidity-token-v5kglq1fqfp',
        asset: 'liquidity-token'
      }
    },
    enabled: true
  },
  [LPTokenName.ALEX_STX_SUSDT]: {
    name: LPTokenName.ALEX_STX_SUSDT,
    symbol: 'ALEX_STX_SUSDT',
    icon: 'assets/icons/tokens/alex-stx-susdt.png',
    decimals: 1e8,
    metadata: '',
    protocol: ProtocolName.ALEX,
    alexOptions: {
      tokenFactor: 1e8,
      poolFactor: 1e8
    },
    fee: 0.005,
    xToken: tokensMap[TokenName.STX],
    yToken: tokensMap[TokenName.SUSDT],
    lptContract: {
      mainnet: {
        address: 'SP102V8P0F7JX67ARQ77WEA3D3CFB5XW39REDT0AM',
        name: 'token-amm-pool-v2-01',
        asset: 'amm-pool-v2-01-token'
      }
    },
    enabled: true
  },
  [LPTokenName.BITFLOW_STX_AEUSDC]: {
    name: LPTokenName.BITFLOW_STX_AEUSDC,
    symbol: 'BITFLOW_STX_AEUSDC',
    icon: 'assets/icons/tokens/bitflow-stx-aeusdc.png',
    decimals: 1e6,
    metadata: '',
    protocol: ProtocolName.BITFLOW,
    fee: 0.005,
    xToken: tokensMap[TokenName.STX],
    yToken: tokensMap[TokenName.AEUSDC],
    lptContract: {
      mainnet: {
        address: 'SM1793C4R5PZ4NS4VQ4WMP7SKKYVH8JZEWSZ9HCCR',
        name: 'xyk-pool-stx-aeusdc-v-1-1',
        asset: 'pool-token'
      }
    },
    enabled: true
  }
}

export const userEnabledTokens = [
  TokenName.STX,
  TokenName.UWU,
  TokenName.SUSDT,
  TokenName.AEUSDC,
  TokenName.XUSD,
  TokenName.XBTC,
  TokenName.ABTC,
  TokenName.ALEX,
  TokenName.STSW,
  TokenName.VIBES,
  TokenName.FARI,
  TokenName.WELSH,
  TokenName.LEO,
  TokenName.GUS
]

export const lpEnabledTokens = [
  LPTokenName.STACKSWAP_STX_UWU
]

export const usmEnabledTokens = [
  TokenName.UWU,
  TokenName.SUSDT,
  TokenName.AEUSDC
]

export const swapEnabledTokens = [
  TokenName.STX,
  TokenName.UWU,
  TokenName.SUSDT,
  TokenName.AEUSDC
]