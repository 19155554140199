import React, { useState, useEffect, useRef } from "react";
import { PopupAnimation } from "../lib/animation";
import { validateStacksAddress } from '@stacks/transactions';
import { fetchAddress } from "../lib/api.js";
import { trunc } from "../lib/utils.js";

import checkmark from "../img/checkmark.png";
import loader from "../img/loader.svg";

export default function TransferPopupComponent({ show, call, address }) {
  const [visibleClass, setVisibleClass] = useState("");
  const [input, setInput] = useState("");
  const [finalAddress, setFinalAddress] = useState("");
  const [addressError, setAddressError] = useState(false);

  const timeoutIdRef = useRef(null);

  useEffect(() => {
    setTimeout(() => setVisibleClass('visible'), 25);
  }, []);

  useEffect(() => {
    if (visibleClass === "visible") {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    };
  }, [visibleClass]);

  const handleClosePopup = () => {
    setVisibleClass('');
    setTimeout(() => show(false), 300);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setInput(inputValue);
    setFinalAddress("");
    setAddressError(false);

    if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);

    if (inputValue) {
      timeoutIdRef.current = setTimeout(() => {
        if (validateStacksAddress(inputValue)) {
          setFinalAddress(inputValue);
          return;
        } else {
          fetchAddress(inputValue.toLowerCase()).then(res => {
            if (res && validateStacksAddress(res)) {
              setFinalAddress(res);
            } else {
              setAddressError(true);
            };
          });
        };
      }, 2000);
    };
  };

  const handleKeyPress = (e) => {
    if (e.charCode === 32) {
      e.preventDefault();
    };
  };

  return (
    <PopupAnimation visible={visibleClass} onClose={handleClosePopup}>
      <div className="popupInterface">
        <div className="popupHeader">
          <h1>Transfer Ownership</h1>
          <svg onClick={handleClosePopup} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M6 6L18 18" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
        </div>
        <div className="popupBody" style={{ textAlign: "left", margin: "2rem" }}>
          <h3 style={{ marginBottom: "1rem" }}>By transferring ownership of your Vault to another account, you are giving the new owner full control. Double-check the recipient address or name entered before proceeding.</h3>
          <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", marginBottom: "1.5rem" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934", marginBottom: "0", textAlign: "left", lineHeight: "1.36" }}>By proceeding with this transaction, you will no longer have control and ownership of your Vault</h1></div>
          <h1 style={{ fontSize: "0.85rem", marginBotom: "0.5rem" }}>Recipient Address or Name</h1>
          <span className="borrowInput" style={{ marginBottom: "1rem" }}><input autocomplete="off" placeholder="SP4MCDR..." value={input} onChange={handleInputChange} onKeyPress={handleKeyPress} style={{ fontSize: "1rem", padding: "10px" }} />{(input && !finalAddress && !addressError) && <img style={{ padding: "0", backgroundColor: "transparent", margin: "0px 7px 0px 0px", width: "20px", height: "20px" }} src={loader} draggable="false" width="20px" />}{(!validateStacksAddress(input) && finalAddress) && <a className="inputLink" style={{ fontSize: "0.85rem", marginRight: "10px" }} href={`https://explorer.hiro.so/address/${finalAddress}?chain=mainnet`} target="_blank">{trunc(finalAddress)}</a>}</span>
          {finalAddress === address && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", marginTop: "-5px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934", marginBottom: "0", textAlign: "left", lineHeight: "1.36" }}>You cannot transfer ownership to your own account</h1></div>}
          {addressError && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", marginTop: "-5px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934", marginBottom: "0", textAlign: "left", lineHeight: "1.36" }}>The address or name entered could not be found</h1></div>}
          <div style={{ marginTop: "2rem" }}>
            <a onClick={() => { (finalAddress !== address && validateStacksAddress(finalAddress)) && call(finalAddress); }} className="smallButton" style={{ color: "#FFFFFF", textDecoration: "none", lineHeight: "2rem", marginRight: "1rem", backgroundColor: (finalAddress !== address && validateStacksAddress(finalAddress)) ? "#000000" : "#575757", cursor: (finalAddress !== address && validateStacksAddress(finalAddress)) ? "pointer" : "default" }}>{!input && "Enter an Address or Name" || "Transfer Ownership"}</a>
            <a onClick={handleClosePopup}>Cancel</a>
          </div>
        </div>
      </div>
    </PopupAnimation>
  );
};