import { principalCV, uintCV } from '@stacks/transactions'
import { STACKS_ADDRESS_VERSION, STACKS_NETWORK } from '../../config/stacks'
import { UWU_CONTRACT_ADDRESSES, UWU_CONTRACT_NAMES, UWU_FACTORY_FUNCTION_NAMES } from '../../constants/smartContracts'
import { promiseWait, readOnlyRetry } from '../../utils/fetchUtils'
import { tokensMap } from '../../constants/tokens'
import { TokenName } from '../../enums/tokens'
import { getBNS } from './userService'
import { getBlockTimestamp } from './stacksService'
import { Vault } from '../../interfaces/vault'
import { c32address } from 'c32check'
import { formatTokenValue, formatValue } from '../../utils/formattingUtils'

export const getVault = async (id: number, limited: boolean = false): Promise<Vault> => {
  const response = await readOnlyRetry({
    contractAddress: UWU_CONTRACT_ADDRESSES.DEPLOYER,
    contractName: UWU_CONTRACT_NAMES.FACTORY,
    functionName: UWU_FACTORY_FUNCTION_NAMES.GET_VAULT,
    functionArgs: [uintCV(id)],
    network: STACKS_NETWORK,
    senderAddress: UWU_CONTRACT_ADDRESSES.DEPLOYER,
  })

  if (response?.value?.type === 9) {
    return {
      id: id,
      owner: {
        address: '',
        bns: null
      },
      balances: {
        collateral: {
          formatted: 0,
          unformatted: 0
        },
        debt: {
          formatted: 0,
          unformatted: 0
        }
      },
      date: {
        blockHeight: 0,
        timestamp: ''
      },
      liquidated: true,
      closed: true
    }
  }

  if (response?.value?.value === null) {
    throw new Error(`Failed to fetch Vault with ID '${id}'`)
  }

  const vault = response.value.value.data
  
  const address = c32address(STACKS_ADDRESS_VERSION, vault.owner.address.hash160)
  const bns = limited ? null : await getBNS(address)
  const blockHeight = formatValue(vault.height.value)
  const timestamp = limited ? 0 : await getBlockTimestamp(blockHeight)

  const stxToken = tokensMap[TokenName.STX]
  const uwuToken = tokensMap[TokenName.UWU]

  return {
    id: formatValue(vault.id.value),
    owner: {
      address: address,
      bns: bns,
    },
    balances: {
      collateral: {
        formatted: formatTokenValue(vault.collateral.value, stxToken),
        unformatted: formatValue(vault.collateral.value)
      },
      debt: {
        formatted: formatTokenValue(vault.debt.value, uwuToken),
        unformatted: formatValue(vault.debt.value)
      },
    },
    date: {
      blockHeight: blockHeight,
      timestamp: limited ? '0' : new Date(timestamp * 1000).toLocaleDateString('en-US'),
    },
    liquidated: vault.liquidated.type !== 4,
    closed: false
  }
}

export const getVaults = async (address: string): Promise<Vault[]> => {
  const response = await readOnlyRetry({
    contractAddress: UWU_CONTRACT_ADDRESSES.DEPLOYER,
    contractName: UWU_CONTRACT_NAMES.FACTORY,
    functionName: UWU_FACTORY_FUNCTION_NAMES.GET_VAULTS,
    functionArgs: [principalCV(address)],
    network: STACKS_NETWORK,
    senderAddress: UWU_CONTRACT_ADDRESSES.DEPLOYER,
  })

  if (response?.value?.list === null) {
    throw new Error(`Failed to fetch all Vaults for address '${address}'`)
  }

  return await Promise.all(response.value.list.map(async (data: { value: { value: { data: any } } }) => {
    const vault = data.value.value.data
    
    const address = c32address(STACKS_ADDRESS_VERSION, vault.owner.address.hash160)
    const bns = await getBNS(address)
    const blockHeight = formatValue(vault.height.value)
    const timestamp = await getBlockTimestamp(blockHeight)

    const stxToken = tokensMap[TokenName.STX]
    const uwuToken = tokensMap[TokenName.UWU]

    return {
      id: formatValue(vault.id.value),
      owner: {
        address: address,
        bns: bns,
      },
      balances: {
        collateral: {
          formatted: formatTokenValue(vault.collateral.value, stxToken),
          unformatted: formatValue(vault.collateral.value)
        },
        debt: {
          formatted: formatTokenValue(vault.debt.value, uwuToken),
          unformatted: formatValue(vault.debt.value)
        }
      },
      date: {
        blockHeight: blockHeight,
        timestamp: new Date(timestamp * 1000).toLocaleDateString('en-US'),
      },
      liquidated: vault.liquidated.type !== 4,
      closed: false
    }
  }))
}

export const getLiquidations = async (stxPrice: number, onProgress: (progress: { current: number; total: number }) => void): Promise<{ all: Vault[]; pending: Vault[]; purchasable: Vault[]; finished: Vault[]; }> => {
  const vaults = {
    all: [] as Vault[],
    pending: [] as Vault[],
    purchasable: [] as Vault[],
    finished: [] as Vault[],
    closed: [] as Vault[]
  }
  
  const totalVaults = await getTotalVaults()

  for (let id = 1; id <= totalVaults; id++) {
    if (!window.location.href.includes('vault-liquidations')) {
      return vaults
    }

    onProgress({ current: id, total: totalVaults })

    const vault = await getVault(id, true)

    if (vault.closed) {
      vaults.closed.push(vault)
    }

    const collateral = vault.balances.collateral.formatted
    const debt = vault.balances.debt.formatted
    const ratio = ((collateral * stxPrice) / debt) * 100
    
    if (!vault.liquidated && ratio < 150) {
      vaults.pending.push(vault)
    }

    if (vault.liquidated) {
      if (collateral > 0 && debt > 0) {
        vaults.purchasable.push(vault)
      }

      if (collateral === 0 && debt === 0) {
        vaults.finished.push(vault)
      }
    }

    vaults.all.push(vault)

    await promiseWait(800)
  }
  
  return vaults
}

export const getTotalVaults = async (): Promise<number> => {
  const response = await readOnlyRetry({
    contractAddress: UWU_CONTRACT_ADDRESSES.DEPLOYER,
    contractName: UWU_CONTRACT_NAMES.FACTORY,
    functionName: UWU_FACTORY_FUNCTION_NAMES.GET_LAST_VAULT_ID,
    functionArgs: [],
    network: STACKS_NETWORK,
    senderAddress: UWU_CONTRACT_ADDRESSES.DEPLOYER,
  })

  if (response?.value?.value === null) {
    throw new Error(`Failed to fetch total number of Vaults`)
  }

  return formatValue(response.value.value)
}