import { createContext, useState, useEffect } from "react";

const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const storedNotifications = localStorage.getItem("uwu-notifications");
    if (storedNotifications) {
      const parsedNotifications = JSON.parse(storedNotifications).map((n) => ({
        ...n,
        date: new Date(n.date),
      }));
      setNotifications(parsedNotifications);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("uwu-notifications", JSON.stringify(notifications));
  }, [notifications]);

  const addNotification = (notification) => {
    setNotifications((prevNotifications) => [...prevNotifications, notification]);
  };

  const removeNotification = (id) => {
    setNotifications((prevNotifications) => prevNotifications.filter((n) => n.id !== id));
  };

  const markAsRead = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((n) => (n.id === id ? { ...n, read: true } : n))
    );
  };

  const markAllAsRead = () => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) => ({
        ...notification,
        read: true,
      }))
    );
  };

  const clearAllNotifications = () => {
    setNotifications([]);
  };

  const hasUnreadNotifications = () => {
    return notifications.some((notification) => !notification.read);
  };

  return (
    <NotificationContext.Provider
      value={{ notifications, addNotification, removeNotification, markAsRead, clearAllNotifications, hasUnreadNotifications, markAllAsRead }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationProvider };
