import React, { useState, useEffect } from "react";
import { PopupAnimation } from "../lib/animation";

import checkmark from "../img/checkmark.png";

export default function DefaultPopupComponent({ show, options }) {
  const [visibleClass, setVisibleClass] = useState("");

  useEffect(() => {
    setTimeout(() => setVisibleClass('visible'), 25);
  }, []);

  useEffect(() => {
    if (visibleClass === "visible") {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    };
  }, [visibleClass]);

  const handleClosePopup = () => {
    setVisibleClass('');
    setTimeout(() => show(false), 300);
  };

  return (
    <PopupAnimation visible={visibleClass} onClose={handleClosePopup}>
      <div className="popupInterface">
        <div className="popupHeader">
          <h1>{options.windowTitle}</h1>
          <svg onClick={handleClosePopup} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M6 6L18 18" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
        </div>
        <div className="popupBody">
          <img src={checkmark} draggable="false" />
          <h1>{options.title}</h1>
          <h2 style={{ marginBottom: "3rem" }}>{options.description}</h2>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <a href={`https://explorer.stacks.co/txid/${options.tx}?chain=mainnet`} target="_blank" className="borrowButton" style={{ color: "#FFFFFF", textDecoration: "none", lineHeight: "3rem", margin: "auto", marginBottom: "1rem", fontSize: "0.85rem" }}>View Transaction</a>
            <a onClick={handleClosePopup}>Close</a>
          </div>
        </div>
      </div>
    </PopupAnimation>
  );
};