import React, { useState, useEffect, useContext } from "react";
import { useParams, useOutletContext, useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { fetchVault } from "../lib/api.js";
import { checkHour, trunc, convertMicrotokensToTokens, formatTokens, formatTokensDisplay, numberWithCommas } from "../lib/utils.js";
import {
  uintCV,
  standardPrincipalCV,
  makeStandardSTXPostCondition,
  makeContractSTXPostCondition,
  PostConditionMode,
  FungibleConditionCode,
  callReadOnlyFunction,
  makeStandardFungiblePostCondition,
  createAssetInfo,
  makeContractFungiblePostCondition,
  stringAsciiCV,
  bufferCV,
} from "@stacks/transactions";
import { stringCV } from "@stacks/transactions/dist/clarity/types/stringCV";
import { principalCV } from "@stacks/transactions/dist/clarity/types/principalCV";
import { StacksMainnet, StacksTestnet } from "@stacks/network";
import { useConnect as syConnect } from "@stacks/connect-react";

import { NotificationContext } from "../context/NotificationContext.js";

import LoaderComponent from "./LoaderComponent.js";
import ErrorComponent from "./ErrorComponent.js";
import LoaderOverlayComponent from "./LoaderOverlayComponent.js";
import DefaultPopupComponent from "../popups/DefaultPopupComponent.js";
import ConfirmationPopupComponent from "../popups/ConfirmationPopupComponent.js";
import TransferPopupComponent from "../popups/TransferPopupComponent.js";
import CloseVaultPopupComponent from "../popups/CloseVaultPopupComponent.js";
import ActivityPopupComponent from "../popups/ActivityPopupComponent.js";
import SliderComponent from "./SliderComponent.js";

import loader from "../img/loader.svg";
import liquidated from "../img/liquidated.png";
import stxtoken from "../img/stx-token-logo.svg";
import uwutoken from "../img/uwu-token-logo-v2.png";

import { getUserTransactions } from '../infra/services/userService.ts'
import { getVault } from '../infra/services/vaultService.ts'
import { TokenName } from '../enums/tokens.ts'

export default function VaultComponent() {
  const { id } = useParams();
  const [session, user] = useOutletContext();
  const navigate = useNavigate();
  const { addNotification } = useContext(NotificationContext);

  const { doContractCall } = syConnect();

  const [vault, setVault] = useState();

  const [vaultManager, setVaultManager] = useState("collateral");
  const [collateralManager, setCollateralManager] = useState("deposit");
  const [debtManager, setDebtManager] = useState("borrow");

  const [input, setInput] = useState("");
  const [repayInFull, setRepayInFull] = useState(false);

  const [showLoader, setShowLoader] = useState(false);
  const [showDefaultPopup, setShowDefaultPopup] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [showTransferPopup, setShowTransferPopup] = useState(false);
  const [showCloseVaultPopup, setShowCloseVaultPopup] = useState(false);
  const [activityPopup, setActivityPopup] = useState(false);

  const [pending, setPending] = useState([]);

  const [defaultPopupOptions, setDefaultPopupOptions] = useState({
    windowTitle: null,
    title: null,
    description: null,
    tx: null
  });

  const [confirmPopupOptions, setConfirmPopupOptions] = useState({
    content: [],
    call: null
  });

  useEffect(() => {
    if (!/^\d+$/.test(id)) {
      navigate('/');
    } else {
      getVault(id).then(res => setVault(res));
      document.title = `Vault #${id} | UWU Protocol`;
    };
  }, []);

  useEffect(() => {
    if(vault && session && user) {
      if (vault?.owner.address !== user?.address) {
        navigate('/');
      };

      fetchPending();
    };
  }, [vault, user, session]);

  function fetchPending() {
    getUserTransactions(user?.address, true).then(res => {
      const filteredTransactions = res.filter(transaction => {
        const { contract_call } = transaction;
        const { function_name, function_args } = contract_call;
        const args = getArgumentsAsObject(function_args);

        const names = ["borrow-vault", "collateralize-vault", "repay-vault", "deposit-vault", "transfer-vault", "close-vault"];

        return names.includes(function_name) && args.id === vault?.id;
      });

      setPending(filteredTransactions);
    });
  };

  function uintToNumber(uintString) {
    return parseInt(uintString.slice(1));
  };

  function getArgumentsAsObject(args) {
    return args.reduce((acc, arg) => {
      acc[arg.name] = arg.type === "uint" ? uintToNumber(arg.repr) : arg.repr;
      return acc;
    }, {});
  };

  const checkCharacter = (e) => {
    if (e.key === "Backspace" || e.key === "Delete" || e.key === "Tab" || e.key === "ArrowLeft" || e.key === "ArrowRight") {
      return;
    };

    if (e.key === "." && e.target.value.split(".").length - 1 === 0) {
      return;
    };

    if (!/^\d+$/.test(e.key)) {
      e.preventDefault();
    };
  };

  function getHealthFactor(ratio) {
    const difference = ratio - 150;

    if (difference > 50) {
      return "#20ba62";
    };

    if (difference > 20) {
      return "#d88934";
    };

    if (difference > 0 || difference < 0) {
      return "#d83434";
    };

    return "#000000";
  };

  function checkCallRequirements(type) {
    const numberInput = parseFloat(input);

    if (type === "deposit" && !vault?.liquidated) {
      if (numberInput > 0 && numberInput <= formatTokens(user?.balances[TokenName.STX]) && (formatTokens(user?.balances[TokenName.STX]) - numberInput) >= 1) {
        return true;
      };
    };

    if (type === "withdraw" && !vault?.liquidated) {
      if (numberInput > 0 && numberInput <= formatTokens(vault?.balances.collateral.formatted- ((vault?.balances.collateral.formatted* session?.oracle.price)-((vault?.balances.collateral.formatted* session?.oracle.price)-(vault?.balances.debt.formatted* 1.5))) / session?.oracle.price)) {
        return true;
      };
    };

    if (type === "borrow" && !vault?.liquidated) {
      if (numberInput >= (vault?.balances.debt.formatted>= 25 ? 1 : 25) && numberInput <= formatTokens(((66.666666 / 100) * (vault?.balances.collateral.formatted* session?.oracle.price)) - vault?.balances.debt.formatted)) {
        return true;
      };
    };

    if (type === "repay" && !vault?.liquidated) {
      if (numberInput > 0 && (vault?.balances.debt.formatted- numberInput).toFixed(12) >= 25 && numberInput <= user?.balances[TokenName.UWU] || repayInFull && vault?.balances.debt.formatted<= user?.balances[TokenName.UWU]) {
        return true;
      };
    };

    return false;
  };

  function createTxCall(type) {
    let numberInput = parseFloat(input);

    if (type === "deposit" && checkCallRequirements("deposit")) {
      setConfirmPopupOptions(prev => ({
        ...prev,
        content: 
        [
          [
            {
              title: "You Send",
              item: `${formatTokensDisplay(numberInput)} STX`
            }
          ],
          [
            {
              title: "Total Collateral",
              prev: `${formatTokensDisplay(vault?.balances.collateral.formatted)} STX`,
              new: `${formatTokensDisplay(vault?.balances.collateral.formatted+ numberInput)} STX`
            },
            {
              title: "Collateral Ratio",
              prev: `${formatTokensDisplay(((vault?.balances.collateral.formatted* session?.oracle.price) / vault?.balances.debt.formatted) * 100)}%`,
              new: `${formatTokensDisplay((((vault?.balances.collateral.formatted+ numberInput) * session?.oracle.price) / vault?.balances.debt.formatted) * 100)}%`
            },
            {
              title: "Liquidation Price",
              prev: `$${formatTokensDisplay((vault?.balances.debt.formatted* 1.5) / vault?.balances.collateral.formatted)}`,
              new: `$${formatTokensDisplay((vault?.balances.debt.formatted* 1.5) / (vault?.balances.collateral.formatted+ numberInput))}`
            }
          ]
        ],
        call: (() => callCollateralizeVault(input))
      }));
      setShowConfirmPopup(true);
    };

    if (type === "withdraw" && checkCallRequirements("withdraw")) {
      setConfirmPopupOptions(prev => ({
        ...prev,
        content: 
        [
          [
            {
              title: "You Receive",
              item: `${formatTokensDisplay(numberInput)} STX`
            }
          ],
          [
            {
              title: "Total Collateral",
              prev: `${formatTokensDisplay(vault?.balances.collateral.formatted)} STX`,
              new: `${formatTokensDisplay(vault?.balances.collateral.formatted- numberInput)} STX`
            },
            {
              title: "Collateral Ratio",
              prev: `${formatTokensDisplay(((vault?.balances.collateral.formatted* session?.oracle.price) / vault?.balances.debt.formatted) * 100)}%`,
              new: `${formatTokensDisplay((((vault?.balances.collateral.formatted- numberInput) * session?.oracle.price) / vault?.balances.debt.formatted) * 100)}%`
            },
            {
              title: "Liquidation Price",
              prev: `$${formatTokensDisplay((vault?.balances.debt.formatted* 1.5) / vault?.balances.collateral.formatted)}`,
              new: `$${formatTokensDisplay((vault?.balances.debt.formatted* 1.5) / (vault?.balances.collateral.formatted- numberInput))}`
            }
          ]
        ],
        call: (() => callWithdrawVault(input))
      }));
      setShowConfirmPopup(true);
    };

    if (type === "borrow" && checkCallRequirements("borrow")) {
      setConfirmPopupOptions(prev => ({
        ...prev,
        content: 
        [
          [
            {
              title: "You Receive",
              item: `${formatTokensDisplay(numberInput - (numberInput * 0.01), 4)} UWU`
            }
          ],
          [
            {
              title: "Total Debt",
              prev: `${formatTokensDisplay(vault?.balances.debt.formatted)} UWU`,
              new: `${formatTokensDisplay(vault?.balances.debt.formatted+ numberInput)} UWU`
            },
            {
              title: "Collateral Ratio",
              prev: `${formatTokensDisplay(((vault?.balances.collateral.formatted* session?.oracle.price) / vault?.balances.debt.formatted) * 100)}%`,
              new: `${formatTokensDisplay(((vault?.balances.collateral.formatted* session?.oracle.price) / (vault?.balances.debt.formatted+ numberInput)) * 100)}%`
            },
            {
              title: "Liquidation Price",
              prev: `$${formatTokensDisplay((vault?.balances.debt.formatted* 1.5) / vault?.balances.collateral.formatted)}`,
              new: `$${formatTokensDisplay(((vault?.balances.debt.formatted+ numberInput) * 1.5) / vault?.balances.collateral.formatted)}`
            }
          ],
          [
            {
              title: "Borrow Fee",
              item: `${formatTokensDisplay(input * 0.01, 4)} UWU (1.00%)`
            }
          ]
        ],
        call: (() => callBorrowVault(input))
      }));
      setShowConfirmPopup(true);
    };

    if (type === "repay" && checkCallRequirements("repay")) {
      setConfirmPopupOptions(prev => ({
        ...prev,
        content: 
        [
          [
            {
              title: "You Send",
              item: `${formatTokensDisplay(repayInFull ? vault?.balances.debt.formatted: numberInput)} UWU`
            }
          ],
          [
            {
              title: "Total Debt",
              prev: `${formatTokensDisplay(vault?.balances.debt.formatted)} UWU`,
              new: `${formatTokensDisplay(repayInFull ? 0 : (vault?.balances.debt.formatted- numberInput))} UWU`
            },
            {
              title: "Collateral Ratio",
              prev: `${formatTokensDisplay(((vault?.balances.collateral.formatted* session?.oracle.price) / vault?.balances.debt.formatted) * 100)}%`,
              new: `${formatTokensDisplay(repayInFull ? 0 : (((vault?.balances.collateral.formatted* session?.oracle.price) / (vault?.balances.debt.formatted- numberInput)) * 100))}%`
            },
            {
              title: "Liquidation Price",
              prev: `$${formatTokensDisplay((vault?.balances.debt.formatted* 1.5) / vault?.balances.collateral.formatted)}`,
              new: `$${formatTokensDisplay(repayInFull ? 0 : (((vault?.balances.debt.formatted- numberInput) * 1.5) / vault?.balances.collateral.formatted))}`
            }
          ]
        ],
        call: (() => callRepayVault(repayInFull ? vault?.balances.debt.formatted: input))
      }));
      setShowConfirmPopup(true);
    };
  };

  async function callCollateralizeVault(amount) {
    setShowLoader(true);
    await doContractCall({
      contractAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
      contractName: "uwu-factory-v1-1-0",
      functionName: "collateralize-vault",
      functionArgs: [uintCV(vault?.id), uintCV(formatTokens(amount * 1000000))],
      postConditionMode: PostConditionMode.Deny,
      postConditions: [makeStandardSTXPostCondition(user?.address, FungibleConditionCode.Equal, formatTokens(amount * 1000000))],
      network: new StacksMainnet(),
      onFinish: (result) => {
        setInput("");
        addNotification({
          id: Date.now(),
          title: "Deposit Confirmation",
          description: `You deposited ${formatTokensDisplay(amount)} STX into Vault #${vault?.id}. View the transaction details by clicking here.`,
          date: new Date(),
          link: `https://explorer.hiro.so/txid/${result.txId}`,
          read: false
        });
        setDefaultPopupOptions(prev => ({
          ...prev,
          tx: result.txId,
          windowTitle: "Deposit Confirmation",
          title: "Deposit Confirmation",
          description: "Your transaction was sent. Please allow 10-30 minutes for the transaction to be completed."
        }));
        setShowConfirmPopup(false);
        setShowDefaultPopup(true);
        fetchPending();
      },
    });
    setShowLoader(false);
  };

  async function callWithdrawVault(amount) {
    setShowLoader(true);
    await doContractCall({
      contractAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
      contractName: "uwu-factory-v1-1-0",
      functionName: "withdraw-vault",
      functionArgs: [uintCV(vault?.id), uintCV(formatTokens(amount * 1000000))],
      postConditionMode: PostConditionMode.Deny,
      postConditions: [makeContractSTXPostCondition("SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4", "uwu-factory-v1-1-0", FungibleConditionCode.Equal, formatTokens(amount * 1000000))],
      network: new StacksMainnet(),
      onFinish: (result) => {
        setInput("");
        addNotification({
          id: Date.now(),
          title: "Withdrawal Confirmation",
          description: `You withdrew ${formatTokensDisplay(amount)} STX from Vault #${vault?.id}. View the transaction details by clicking here.`,
          date: new Date(),
          link: `https://explorer.hiro.so/txid/${result.txId}`,
          read: false
        });
        setDefaultPopupOptions(prev => ({
          ...prev,
          tx: result.txId,
          windowTitle: "Withdrawal Confirmation",
          title: "Withdrawal Confirmation",
          description: "Your transaction was sent. Please allow 10-30 minutes for the transaction to be completed."
        }));
        setShowConfirmPopup(false);
        setShowDefaultPopup(true);
        fetchPending();
      },
    });
    setShowLoader(false);
  };

  async function callBorrowVault(amount) {
    setShowLoader(true);
    await doContractCall({
      contractAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
      contractName: "uwu-factory-v1-1-0",
      functionName: "borrow-vault",
      functionArgs: [uintCV(vault?.id), uintCV(formatTokens(amount * 1000000))],
      postConditionMode: PostConditionMode.Deny,
      postConditions: [makeContractFungiblePostCondition("SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4", "uwu-factory-v1-1-0", FungibleConditionCode.LessEqual, formatTokens(amount * 1000000), createAssetInfo("SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4", "uwu-token-v1-1-0", "uwu"))],
      network: new StacksMainnet(),
      onFinish: (result) => {
        setInput("");
        addNotification({
          id: Date.now(),
          title: "Borrow Confirmation",
          description: `You borrowed ${formatTokensDisplay(amount)} UWU from Vault #${vault?.id}. View the transaction details by clicking here.`,
          date: new Date(),
          link: `https://explorer.hiro.so/txid/${result.txId}`,
          read: false
        });
        setDefaultPopupOptions(prev => ({
          ...prev,
          tx: result.txId,
          windowTitle: "Borrow Confirmation",
          title: "Borrow Confirmation",
          description: "Your transaction was sent. Please allow 10-30 minutes for the transaction to be completed."
        }));
        setShowConfirmPopup(false);
        setShowDefaultPopup(true);
        fetchPending();
      },
    });
    setShowLoader(false);
  };

  async function callRepayVault(amount) {
    setShowLoader(true);
    await doContractCall({
      contractAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
      contractName: "uwu-factory-v1-1-0",
      functionName: "repay-vault",
      functionArgs: [uintCV(vault?.id), uintCV(formatTokens(amount * 1000000))],
      postConditionMode: PostConditionMode.Deny,
      postConditions: [makeStandardFungiblePostCondition(user?.address, FungibleConditionCode.Equal, formatTokens(amount * 1000000), createAssetInfo("SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4", "uwu-token-v1-1-0", "uwu"))],
      network: new StacksMainnet(),
      onFinish: (result) => {
        setInput("");
        addNotification({
          id: Date.now(),
          title: "Repayment Confirmation",
          description: `You repaid ${formatTokensDisplay(amount)} UWU of debt in Vault #${vault?.id}. View the transaction details by clicking here.`,
          date: new Date(),
          link: `https://explorer.hiro.so/txid/${result.txId}`,
          read: false
        });
        setDefaultPopupOptions(prev => ({
          ...prev,
          tx: result.txId,
          windowTitle: "Repayment Confirmation",
          title: "Repayment Confirmation",
          description: "Your transaction was sent. Please allow 10-30 minutes for the transaction to be completed."
        }));
        setShowConfirmPopup(false);
        setShowDefaultPopup(true);
        fetchPending();
      },
    });
    setShowLoader(false);
  };

  async function callTransferVault(address) {
    setShowLoader(true);
    await doContractCall({
      contractAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
      contractName: "uwu-factory-v1-1-0",
      functionName: "transfer-vault",
      functionArgs: [uintCV(vault?.id), principalCV(address)],
      postConditionMode: PostConditionMode.Deny,
      postConditions: [],
      network: new StacksMainnet(),
      onFinish: (result) => {
        addNotification({
          id: Date.now(),
          title: "Transfer Confirmation",
          description: `You transferred ownership of Vault #${vault?.id} to ${trunc(address)}. View the transaction details by clicking here.`,
          date: new Date(),
          link: `https://explorer.hiro.so/txid/${result.txId}`,
          read: false
        });
        setDefaultPopupOptions(prev => ({
          ...prev,
          tx: result.txId,
          windowTitle: "Transfer Confirmation",
          title: "Transfer Confirmation",
          description: "Your transaction was sent. Please allow 10-30 minutes for the transaction to be completed."
        }));
        setShowTransferPopup(false);
        setShowDefaultPopup(true);
        fetchPending();
      },
    });
    setShowLoader(false);
  };

  async function callCloseVault() {
    setShowLoader(true);
    await doContractCall({
      contractAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
      contractName: "uwu-factory-v1-1-0",
      functionName: "close-vault",
      functionArgs: [uintCV(vault?.id)],
      postConditionMode: PostConditionMode.Deny,
      postConditions: [],
      network: new StacksMainnet(),
      onFinish: (result) => {
        addNotification({
          id: Date.now(),
          title: "Close Confirmation",
          description: `You permanently closed Vault #${vault?.id}. View the transaction details by clicking here.`,
          date: new Date(),
          link: `https://explorer.hiro.so/txid/${result.txId}`,
          read: false
        });
        setDefaultPopupOptions(prev => ({
          ...prev,
          tx: result.txId,
          windowTitle: "Close Confirmation",
          title: "Close Confirmation",
          description: "Your transaction was sent. Please allow 10-30 minutes for the transaction to be completed."
        }));
        setShowCloseVaultPopup(false);
        setShowDefaultPopup(true);
        fetchPending();
      },
    });
    setShowLoader(false);
  };

  return (
    <>
      {showLoader && <LoaderOverlayComponent />}
      {showDefaultPopup && <DefaultPopupComponent show={(e) => setShowDefaultPopup(e)} options={defaultPopupOptions} />}
      {showConfirmPopup && <ConfirmationPopupComponent show={(e) => setShowConfirmPopup(e)} options={confirmPopupOptions} />}
      {showTransferPopup && <TransferPopupComponent show={(e) => setShowTransferPopup(e)} call={(e) => callTransferVault(e)} address={user?.address} />}
      {showCloseVaultPopup && <CloseVaultPopupComponent show={(e) => setShowCloseVaultPopup(e)} call={() => callCloseVault()} collateral={vault?.balances.collateral} debt={vault?.balances.debt} />}
      {activityPopup && <ActivityPopupComponent show={(e) => setActivityPopup(e)} address={user?.address} />}
      {(vault && session && user) ? <><div className="core animate__animated animate__fadeIn" style={{ marginTop: "auto" }}>
        <ReactTooltip className="defaultTooltip" effect="solid" multiline={true} arrowColor="#000000" padding="10px 12px" />
        <div className="grid2">
          <div className="vaultHeader">
            <div>
              <div className="vaultHeaderTitle" style={{ justifyContent: "inherit" }}>
                <a href="/" style={{ lineHeight: "0", marginRight: "0.5rem", fontSize: "1.25em", marginBottom: "0.1rem" }}>{`<-`}</a>
                <h1 style={{ marginBottom: "0" }}>Vault #{vault?.id}</h1>
              </div>
              <a href={`https://analytics.uwu.cash/vault/${vault?.id}`} target="_blank">View on UWU Analytics</a>
            </div>
            <div className="vaultHeaderDivider"></div>
          </div>
          {vault?.liquidated && <div className="pendingTxnHome" style={{ marginBottom: "15px", backgroundColor: "#fdf6f7", borderColor: "#d83434", justifyContent: "unset" }}>
            <img src={liquidated} draggable="false" style={{ width: "40px", height: "40px", padding: "0" }} />
            <div>
              <h1 style={{ color: "#d83434" }}>Vault #{vault?.id} has been liquidated</h1>
              <h2>The collateral in your Vault was sold to repay your debt. You're free to close your Vault.</h2>
            </div>
            <a onClick={() => setShowCloseVaultPopup(true)} className="smallButton">Close Vault</a>
          </div>}
          {pending.length > 0 && <div className="pendingTxnHome" style={{ marginBottom: "15px" }}>
            <img src={loader} draggable="false" />
            <div>
              <h1>You have {pending.length} pending transaction{pending.length > 1 && "s"} for Vault #{vault?.id}</h1>
              <h2>Please allow 10-30 minutes for the transaction{pending.length > 1 && "s"} to be completed.</h2>
            </div>
            <a onClick={() => setActivityPopup(true)} className="smallButton">View Recent Activity</a>
          </div>}
          <div className="vaultGrid" style={{ gridTemplateColumns: "1fr 0.7fr", opacity: vault?.liquidated && "0.5", pointerEvents: vault?.liquidated && "none" }}>
            <div>
              <div className="overview" style={{ height: "max-content", marginBottom: "15px" }}>
                <div className="overviewHeader">
                  <h1>Vault Overview</h1>
                  <div></div>
                </div>
                <div>
                  <div className="overviewGrid">
                    <div>
                      <h2 data-tip="The total value of STX collateral<br>in this Vault">Total Collateral</h2>
                      <h1>${formatTokensDisplay(vault?.balances.collateral.formatted* session?.oracle.price)}</h1>
                      <h3>{formatTokensDisplay(vault?.balances.collateral.formatted)} STX</h3>
                    </div>
                    <div>
                      <h2 data-tip="The total amount of UWU debt<br>generated by this Vault">Total Debt</h2>
                      <h1>{formatTokensDisplay(vault?.balances.debt.formatted)} UWU</h1>
                    </div>
                    <div>
                      <h2 data-tip="The ratio of total collateral to total<br>debt held in this Vault">Collateral Ratio</h2>
                      <h1 style={{ color: getHealthFactor(((vault?.balances.collateral.formatted* session?.oracle.price) / vault?.balances.debt.formatted) * 100) }}>{formatTokensDisplay(((vault?.balances.collateral.formatted* session?.oracle.price) / vault?.balances.debt.formatted) * 100)}%</h1>
                    </div>
                  </div>
                  <div className="gridDivider"></div>
                  <div className="overviewGrid">
                    <div>
                      <h2 data-tip="The current STX price reported<br>by the oracle">Oracle Price</h2>
                      <div style={{ display: "flex", padding: "unset" }}>
                        <h1>${formatTokensDisplay(session?.oracle.price)}</h1>
                        <span className="alertDot" style={{ backgroundColor: checkHour(session?.oracle.timestamp) < 1 ? "#20ba62" : "#d88934", animation: checkHour(session?.oracle.timestamp) < 1 ? "" : "pulse 1s infinite", marginTop: "0.5px", marginLeft: "5px" }} data-tip={checkHour(session?.oracle.timestamp) < 1 ? "The Oracle Price was recently updated" : "The Oracle Price may not reflect<br>the current market price"}></span>
                      </div>
                    </div>
                    <div>
                      <h2 data-tip="The STX price at which this Vault<br>will be liquidated">Liquidation Price</h2>
                      <h1>${formatTokensDisplay((vault?.balances.debt.formatted* 1.5) / vault?.balances.collateral.formatted)}</h1>
                      <h3>{formatTokensDisplay(((session?.oracle.price - ((vault?.balances.debt.formatted* 1.5) / vault?.balances.collateral.formatted)) * 100) / session?.oracle.price)}% below</h3>
                    </div>
                    <div>
                      <h2 data-tip="The amount of UWU that is currently<br>available to borrow">Available to Borrow</h2>
                      <h1>{formatTokensDisplay(((66.666666 / 100) * (vault?.balances.collateral.formatted* session?.oracle.price)) - vault?.balances.debt.formatted, undefined, false)} UWU</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overview">
                <div className="overviewHeader">
                  <h1>Vault Information</h1>
                  <div></div>
                </div>
                <div className="overviewGrid2">
                  <div>
                    <h2>Vault ID</h2>
                    <h1>{vault?.id}</h1>
                  </div>
                  <div className="overviewGrid2Divider"></div>
                  <div>
                    <h2>Vault Owner</h2>
                    <a href={`https://explorer.stacks.co/address/${vault?.owner.address}?chain=mainnet`} target="_blank">{vault?.owner.bns ? trunc(vault?.owner.bns, true) : trunc(vault?.owner.address)}</a>
                  </div>
                  <div className="overviewGrid2Divider"></div>
                  <div>
                    <h2>Date Opened</h2>
                    <h1>{vault?.date.timestamp}</h1>
                  </div>
                  <div className="overviewGrid2Divider"></div>
                  <div>
                    <h2>Liquidation Status</h2>
                    <h1>{vault?.liquidated ? "Liquidated" : "Not Liquidated"}</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="gridItemFirstMobile">
              <div className="overview borrowDisplay" style={{ height: "max-content", marginBottom: "15px" }}>
                <div className="overviewHeader manageVaultHeader">
                  <div className="inner">
                    <h1>Manage {vaultManager === "collateral" ? "Collateral" : "Debt"}</h1>
                    <a onClick={() => {setVaultManager(vaultManager === "collateral" ? "debt" : "collateral"); setInput(""); setCollateralManager("deposit"); setDebtManager("borrow"); setRepayInFull(false);}} className="smallButton" style={{ border: "1px solid #F0F0F0" }}>Manage {vaultManager === "collateral" ? "Debt" : "Collateral"}</a>
                  </div>
                  <div></div>
                </div>
                {vaultManager === "collateral" ? 
                <><div className="manageVaultSelector">
                  <div>
                    <a onClick={() => { setCollateralManager("deposit"); setInput(""); }} className={`headerButton ${collateralManager === "deposit" ? "active" : ""}`} style={{ marginRight: "5px" }}>Deposit</a>
                    <a onClick={() => { setCollateralManager("withdraw"); setInput(""); }} className={`headerButton ${collateralManager === "withdraw" ? "active" : ""}`} style={{ marginLeft: "5px" }}>Withdraw</a>
                  </div>
                </div>
                <div className="borrowDivider"></div>
                <div>
                  <div className="borrowInnerDiv manageVaultInnerDiv">
                    <div style={{ marginBottom: "20px" }}>
                      <div className="borrowInputTitle">
                        <h1>{collateralManager === "deposit" ? "Deposit" : "Withdraw"} STX</h1>
                        <h2 onClick={() => setInput(collateralManager === "deposit" ? formatTokens(user?.balances[TokenName.STX]) : formatTokens(vault?.balances.collateral.formatted- ((vault?.balances.collateral.formatted* session?.oracle.price)-((vault?.balances.collateral.formatted* session?.oracle.price)-(vault?.balances.debt.formatted* 1.5))) / session?.oracle.price, undefined, false))}>{collateralManager === "deposit" ? "Balance" : "Maximum"}: {collateralManager === "deposit" ? formatTokensDisplay(user?.balances[TokenName.STX]) : formatTokensDisplay(vault?.balances.collateral.formatted- ((vault?.balances.collateral.formatted* session?.oracle.price)-((vault?.balances.collateral.formatted* session?.oracle.price)-(vault?.balances.debt.formatted* 1.5))) / session?.oracle.price, undefined, false)} STX</h2>
                      </div>
                      <span className="borrowInput borrowInputPrice" style={{ marginBottom: "15px" }}><div style={{ width: "100%" }}><input inputmode="decimal" maxLength="10" type="text" placeholder="0.00 STX" onChange={(e) => setInput(e.target.value.toString().split(".").map((el, i) => i ? el.split("").slice(0, 2).join("") : el).join(".").replace(/^0+(?=\d)/, ""))} onKeyDown={(e) => checkCharacter(e)} value={input} /><h4>≈ ${formatTokensDisplay(input * session?.prices[TokenName.STX])}</h4></div><div className="swapOutputCurrency" style={{ marginRight: "15px" }}><img draggable="false" src={stxtoken} /><h1>STX</h1></div></span>
                      {(collateralManager === "deposit" && user?.balances[TokenName.STX] < input) && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-5px 20px 15px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>The amount entered exceeds your account balance</h1></div>}
                      {(collateralManager === "deposit" && (user?.balances[TokenName.STX] - input) < 1) && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-5px 20px 15px", maxWidth: "400px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>The amount entered may not leave sufficient STX to pay for the transaction fee. Please enter a smaller amount</h1></div>}
                      {(collateralManager === "withdraw" && formatTokens(vault?.balances.collateral.formatted- ((vault?.balances.collateral.formatted* session?.oracle.price) - ((vault?.balances.collateral.formatted* session?.oracle.price) - (vault?.balances.debt.formatted* 1.5))) / session?.oracle.price, undefined, false) < input) && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-5px 20px 15px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>The amount entered exceeds your maximum withdrawal</h1></div>}
                    </div>
                    <div className="borrowDivider"></div>
                    <a style={collateralManager === "deposit" ? { backgroundColor: checkCallRequirements("deposit") ? "#000000" : "#575757", cursor: checkCallRequirements("deposit") ? "pointer" : "default" } : { backgroundColor: checkCallRequirements("withdraw") ? "#000000" : "#575757", cursor: checkCallRequirements("withdraw") ? "pointer" : "default" }} onClick={() => collateralManager === "deposit" ? createTxCall("deposit") : createTxCall("withdraw")} className="borrowButton">{input ? (collateralManager === "deposit" ? "Deposit" : "Withdraw") : "Enter an Amount"}</a>
                  </div>
                </div></> :
                <><div className="manageVaultSelector">
                    <div>
                      <a onClick={() => { setDebtManager("borrow"); setInput(""); setRepayInFull(false); }} className={`headerButton ${debtManager === "borrow" ? "active" : ""}`} style={{ marginRight: "5px" }}>Borrow</a>
                      <a onClick={() => { setDebtManager("repay"); setInput(""); setRepayInFull(false); }} className={`headerButton ${debtManager === "repay" ? "active" : ""}`} style={{ marginLeft: "5px" }}>Repay</a>
                    </div>
                  </div>
                <div className="borrowDivider"></div>
                <div>
                  <div className="borrowInnerDiv manageVaultInnerDiv">
                    <div>
                      {debtManager === "repay" && <div style={{ border: "1px solid #c3c3c3", backgroundColor: "#f0f0f070", borderRadius: "10px", padding: "10px", margin: "25px 20px 15px", maxWidth: "400px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#838383" }}>After repayment, your Vault must maintain a minimum debt balance of 25 UWU, unless the entire debt is repaid in full</h1></div>}
                      <div className="borrowInputTitle">
                        <h1>{debtManager === "borrow" ? "Borrow UWU" : "Partial Repayment"}</h1>
                        <h2 onClick={() => { setInput(debtManager === "borrow" ? formatTokens(((66.666666 / 100) * (vault?.balances.collateral.formatted* session?.oracle.price)) - vault?.balances.debt.formatted, undefined, false) : formatTokens(vault?.balances.debt.formatted>= 25 ? vault?.balances.debt.formatted- 25 : 0)); debtManager === "repay" && setRepayInFull(false);}}>Maximum: {debtManager === "borrow" ? formatTokensDisplay(((66.666666 / 100) * (vault?.balances.collateral.formatted* session?.oracle.price)) - vault?.balances.debt.formatted, undefined, false) : formatTokensDisplay(vault?.balances.debt.formatted>= 25 ? vault?.balances.debt.formatted- 25 : 0)} UWU</h2>
                      </div>
                      <span className="borrowInput borrowInputPrice" style={{ marginBottom: "20px" }}><div style={{ width: "100%" }}><input inputmode="decimal" maxLength="10" type="text" placeholder="0.00 UWU" onChange={(e) => setInput(e.target.value.toString().split(".").map((el, i) => i ? el.split("").slice(0, 2).join("") : el).join(".").replace(/^0+(?=\d)/, ""))} onKeyDown={(e) => checkCharacter(e)} value={input} onFocus={() => (debtManager === "repay" && repayInFull) && setRepayInFull(false)} /><h4>≈ ${formatTokensDisplay(input * session?.prices[TokenName.UWU])}</h4></div><div className="swapOutputCurrency" style={{ marginRight: "15px" }}><img draggable="false" src={uwutoken} /><h1>UWU</h1></div></span>
                      {debtManager === "borrow" &&
                      <>
                      <div className="borrowInputTitle disabled">
                        <h1>Borrow Capacity</h1>
                        <h2>{formatTokensDisplay((input / formatTokens(((66.666666 / 100) * (vault?.balances.collateral.formatted* session?.oracle.price)) - vault?.balances.debt.formatted, undefined, false)) * 100)}%</h2>
                      </div>
                            <SliderComponent sliderValue={formatTokens(input)} maxSliderValue={formatTokens(((66.666666 / 100) * (vault?.balances.collateral.formatted* session?.oracle.price)) - vault?.balances.debt.formatted, undefined, false)} onChange={(value) => setInput(formatTokens(value))} disabled={formatTokens(((66.666666 / 100) * (vault?.balances.collateral.formatted* session?.oracle.price)) - vault?.balances.debt.formatted, undefined, false) <= 0} />
                      </>
                      }
                      {(debtManager === "borrow" && (vault?.balances.debt.formatted>= 25 ? input < 1 : input < 25)) && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-10px 20px 20px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>You have to borrow a minimum of {vault?.balances.debt.formatted>= 25 ? "1" : "25"} UWU</h1></div>}
                      {(debtManager === "borrow" && input > formatTokens(((66.666666 / 100) * (vault?.balances.collateral.formatted* session?.oracle.price)) - vault?.balances.debt.formatted, undefined, false)) && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-10px 20px 20px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>The amount entered exceeds your maximum borrow</h1></div>}
                      {(debtManager === "borrow" && input >= 0.5 * (session?.pools["0"].balances.usd + session?.usm.reserves.usd)) && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-10px 20px 20px", maxWidth: "400px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>The amount of UWU you're about to borrow is significant compared to the available liquidity on the open-market</h1></div>}
                      {(debtManager === "repay" && input > user?.balances[TokenName.UWU]) && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-10px 20px 20px", maxWidth: "400px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>The amount entered exceeds your account balance</h1></div>}
                      {(debtManager === "repay" && input > formatTokens(vault?.balances.debt.formatted>= 25 ? vault?.balances.debt.formatted- 25 : 0)) && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-10px 20px 20px", maxWidth: "400px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>The amount entered exceeds your maximum partial repayment. Consider a full repayment instead</h1></div>}
                      {debtManager === "borrow" ?
                      <div className="borrowOutputDetails" style={{ marginTop: "-5px" }}>
                        <h1>Borrow Fee</h1>
                        <h2>{formatTokensDisplay(input * 0.01, 4)} UWU (1.00%)</h2>
                        <h1>Total Received</h1>
                        <h2>{formatTokensDisplay(input - (input * 0.01), 4)} UWU</h2>
                      </div> : 
                      <div style={{ marginBottom: "15px" }}>
                        <div className="dividerWithText" style={{ marginTop: "-10px", marginBottom: "10px" }}>
                          <div></div>
                          <h1>or</h1>
                          <div></div>
                        </div>
                        <span style={{ marginBottom: "20px", border: repayInFull ? "1px solid #838383" : "1px solid #F0F0F0", pointerEvents: user?.balances[TokenName.UWU] < vault?.balances.debt.formatted&& "none", opacity: user?.balances[TokenName.UWU] < vault?.balances.debt.formatted&& "0.40" }} className="repayInFullButton" onClick={() => { setRepayInFull(true); setInput(""); }}><a>Repay in Full</a><a style={{ color: "#838383" }}>{formatTokensDisplay(vault?.balances.debt.formatted)} UWU</a></span>
                        {user?.balances[TokenName.UWU] < vault?.balances.debt.formatted&& <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-10px 20px 20px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>Your account doesn't have enough UWU to repay in full</h1></div>}
                      </div>
                      }
                    </div>
                    <div className="borrowDivider"></div>
                    <a style={debtManager === "borrow" ? { backgroundColor: checkCallRequirements("borrow") ? "#000000" : "#575757", cursor: checkCallRequirements("borrow") ? "pointer" : "default" } : { backgroundColor: checkCallRequirements("repay") ? "#000000" : "#575757", cursor: checkCallRequirements("repay") ? "pointer" : "default" }} onClick={() => debtManager === "borrow" ? createTxCall("borrow") : createTxCall("repay")} className="borrowButton">{debtManager === "borrow" ? input ? "Borrow" : "Enter an Amount" : repayInFull || input ? "Repay" : "Enter an Amount"}</a>
                  </div>
                </div></>}
              </div>
              <div className="overview borrowDisplay" style={{ height: "max-content" }}>
                <div className="overviewHeader">
                  <h1>Vault Settings</h1>
                  <div></div>
                </div>
                <div className="overviewGrid2">
                  <div onClick={() => setShowTransferPopup(true)} className="vaultSettings">
                    <h2 style={{ color: "#d83434" }}>Transfer Ownership</h2>
                    <h1 style={{ color: "#d83434" }}>></h1>
                  </div>
                  <div className="overviewGrid2Divider"></div>
                  <div onClick={() => setShowCloseVaultPopup(true)} className="vaultSettings">
                    <h2 style={{ color: "#d83434" }}>Close Vault</h2>
                    <h1 style={{ color: "#d83434" }}>></h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div></> : <LoaderComponent />}
    </>
  );
};