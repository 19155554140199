import { checkHour, convertMicrotokensToTokens, formatTokens, numberWithCommas } from "./utils.js";
import { StacksMainnet } from "@stacks/network";
import { uintCV } from "@stacks/transactions";
import { principalCV } from "@stacks/transactions/dist/clarity/types/principalCV";
import { c32address } from "c32check";

import { DEFAULT_RPC_URL, UWU_FACTORY_CONTRACT, UWU_ORACLE_CONTRACT, UWU_STABILITY_MODULE_CONTRACT, UWU_TOKEN_CONTRACT, SUSDT_TOKEN_CONTRACT, XUSD_TOKEN_CONTRACT, XBTC_TOKEN_CONTRACT, ABTC_TOKEN_CONTRACT, ALEX_TOKEN_CONTRACT, STSW_TOKEN_CONTRACT, VIBES_TOKEN_CONTRACT, FARI_TOKEN_CONTRACT, WELSH_TOKEN_CONTRACT, LEO_TOKEN_CONTRACT, GUS_TOKEN_CONTRACT, LTO_STSW_STXUWU_TOKEN_CONTRACT, STSW_SWAP_CONTRACT, UWU_TOKEN_IDENTIFIER, SUSDT_TOKEN_IDENTIFIER, XUSD_TOKEN_IDENTIFIER, XBTC_TOKEN_IDENTIFIER, ABTC_TOKEN_IDENTIFIER, ALEX_TOKEN_IDENTIFIER, STSW_TOKEN_IDENTIFIER, VIBES_TOKEN_IDENTIFIER, FARI_TOKEN_IDENTIFIER, WELSH_TOKEN_IDENTIFIER, LEO_TOKEN_IDENTIFIER, GUS_TOKEN_IDENTIFIER, LTO_STSW_STXUWU_TOKEN_IDENTIFIER } from "./constants.js";

import { fetchRetry, readOnlyRetry, promiseWait } from '../utils/fetchUtils.ts'
import { getBalances, getBNS, getUserTransactions } from '../infra/services/userService.ts'
import { getPrices } from '../infra/services/priceService.ts'
import { getBlockTimestamp, getNetworkStatus } from '../infra/services/stacksService.ts'
import { getOraclePrice, getOracleTimestamp } from '../infra/services/oracleService.ts'
import { getTotalVaults, getVault, getVaults } from '../infra/services/vaultService.ts'
import { getUSM } from '../infra/services/usmService.ts'
import { getStackswapLP } from '../infra/services/stswService.ts'
import { lpTokensMap } from '../constants/tokens.ts'
import { LPTokenName, TokenName } from '../enums/tokens.ts'
import { getAndCacheData } from '../infra/services/storageService.ts'
import { getUserSession } from '../infra/services/sessionService.ts'

const NETWORK = new StacksMainnet();

// DONE
export const fetchRPCURL = () => {
  try {
    const storedPreferences = JSON.parse(localStorage.getItem("uwu-preferences"));

    return storedPreferences?.api || DEFAULT_RPC_URL;
  } catch (err) {
    console.error(`Failed to fetch RPC URL from localStorage: ${err.message}`);

    return DEFAULT_RPC_URL;
  };
};

// DONE
export const fetchSwapSession = async (sources) => {
  const data = {
    pools: {
      "0": []
    },
    usm: {
      balances: {
        uwu: 0,
        susdt: 0
      }
    }
  };

  if (sources.stsw) {
    try {
      const stswSTXUWU = await getStackswapLP(lpTokensMap[LPTokenName.STACKSWAP_STX_UWU]);

      data.pools["0"] = stswSTXUWU;
    } catch (err) {
      console.error(`Failed to fetch data for swap session: ${err.message}`);

      return {};
    };
  };

  if (sources.usm) {
    try {
      const usm = await getUSM()

      data.usm.reserves.uwu = formatTokens(usm.reserves.UWU.unformatted / 1000000);
      data.usm.reserves.susdt = formatTokens(usm.reserves.sUSDT.unformatted / 100000000);
    } catch (err) {
      console.error(`Failed to fetch data for swap session: ${err.message}`);
    };
  };

  return data;
};

export const fetchSession = async () => {
  try {
    const stswSTXUWU = await getAndCacheData('uwu@stackswap-stx-uwu', getStackswapLP, [lpTokensMap[LPTokenName.STACKSWAP_STX_UWU]], 15000);
    const usm = await getAndCacheData('uwu@usm', getUSM, [], 15000)
    const oraclePrice = await getAndCacheData('uwu@oracle-price', getOraclePrice, [], 30000)
    const oracleTimestamp = await getAndCacheData('uwu@oracle-timestamp', getOracleTimestamp, [], 30000)
    const prices = await getAndCacheData('uwu@asset-prices', getPrices, [], 60000)
    const networkStatus = await getAndCacheData('uwu@network-status', getNetworkStatus, [], 15000)

    const stxPrice = formatTokens(prices[TokenName.STX], 6);
    const uwuPrice = stswSTXUWU.lpToken.price.formatted * stxPrice;
    const susdtPrice = formatTokens(prices[TokenName.SUSDT], 6);
    const aeusdcPrice = formatTokens(prices[TokenName.AEUSDC], 6);
    const xusdPrice = formatTokens(prices[TokenName.XUSD], 6);
    const xbtcPrice = formatTokens(prices[TokenName.XBTC], 6);
    const abtcPrice = formatTokens(prices[TokenName.ABTC], 6);
    const alexPrice = formatTokens(prices[TokenName.ALEX], 6);
    const stswPrice = formatTokens(prices[TokenName.STSW], 6);
    const vibesPrice = formatTokens(prices[TokenName.VIBES], 6);
    const fariPrice = formatTokens(prices[TokenName.FARI], 6);
    const welshPrice = formatTokens(prices[TokenName.WELSH], 6);
    const leoPrice = formatTokens(prices[TokenName.LEO], 6);
    const gusPrice = formatTokens(prices[TokenName.GUS], 6);

    stswSTXUWU.balances.usd = (stswSTXUWU.balances[TokenName.STX].formatted * stxPrice) + (stswSTXUWU.balances[TokenName.UWU].formatted * uwuPrice);

    return {
      prices: {
        [TokenName.STX]: stxPrice,
        [TokenName.UWU]: uwuPrice,
        [TokenName.SUSDT]: susdtPrice,
        [TokenName.AEUSDC]: aeusdcPrice,
        [TokenName.XUSD]: xusdPrice,
        [TokenName.XBTC]: xbtcPrice,
        [TokenName.ABTC]: abtcPrice,
        [TokenName.ALEX]: alexPrice,
        [TokenName.STSW]: stswPrice,
        [TokenName.VIBES]: vibesPrice,
        [TokenName.FARI]: fariPrice,
        [TokenName.WELSH]: welshPrice,
        [TokenName.LEO]: leoPrice,
        [TokenName.GUS]: gusPrice
      },
      oracle: {
        price: formatTokens(oraclePrice.unformatted / 1000000, 6),
        timestamp: oracleTimestamp
      },
      usm: usm,
      pools: {
        "0": stswSTXUWU
      },
      network: {
        api: networkStatus.apiStatus,
        height: networkStatus.blockHeight,
        mempool: networkStatus.mempoolCount
      },
      v2: {
        oracle: {
          price: oraclePrice,
          timestamp: oracleTimestamp
        },
        prices: prices,
        swap: {
          usm: usm,
          pools: {
            "0": stswSTXUWU
          }
        },
        network: {
          type: networkStatus.networkType,
          apiStatus: networkStatus.apiStatus,
          blockHeight: networkStatus.blockHeight,
          mempoolCount: networkStatus.mempoolCount
        }
      }
    };
  } catch (err) {
    console.error(`Failed to fetch data for session: ${err.message}`);

    return {};
  }
};

// DONE
export const fetchUser = async (address) => {
  try {
    const user = await getUserSession(address)

    const formattedBalances = {
      [TokenName.STX]: formatTokens(user.balances[TokenName.STX].unformatted / 1000000),
      [TokenName.UWU]: formatTokens(user.balances[TokenName.UWU].unformatted / 1000000),
      [TokenName.SUSDT]: formatTokens(user.balances[TokenName.SUSDT].unformatted / 100000000),
      [TokenName.AEUSDC]: formatTokens(user.balances[TokenName.AEUSDC].unformatted / 1000000),
      [TokenName.XUSD]: formatTokens(user.balances[TokenName.XUSD].unformatted / 100000000),
      [TokenName.XBTC]: formatTokens(user.balances[TokenName.XBTC].unformatted / 100000000, 6),
      [TokenName.ABTC]: formatTokens(user.balances[TokenName.ABTC].unformatted / 100000000, 6),
      [TokenName.ALEX]: formatTokens(user.balances[TokenName.ALEX].unformatted / 100000000),
      [TokenName.STSW]: formatTokens(user.balances[TokenName.STSW].unformatted / 1000000),
      [TokenName.VIBES]: formatTokens(user.balances[TokenName.VIBES].unformatted / 100000000),
      [TokenName.FARI]: formatTokens(user.balances[TokenName.FARI].unformatted / 100000000),
      [TokenName.WELSH]: formatTokens(user.balances[TokenName.WELSH].unformatted / 1000000),
      [TokenName.LEO]: formatTokens(user.balances[TokenName.LEO].unformatted / 1000000),
      [TokenName.GUS]: formatTokens(user.balances[TokenName.GUS].unformatted / 1000000),
      lto: {
        "0": formatTokens(user.balances[LPTokenName.STACKSWAP_STX_UWU].unformatted / 1000000)
      }
    }

    return {
      address: user.address,
      bns: user.bns,
      balances: formattedBalances,
      vaults: user.vaults,
      pending: user.transactions.pending,
      v2: {
        address: user.address,
        bns: user.bns,
        balances: user.balances,
        vaults: user.vaults,
        transactions: user.transactions
      }
    }
  } catch (err) {
    console.error(err)
  }
  /*try {
    const pending = await getUserTransactions(address, true)
    const vaults = await getAndCacheData('uwu@user-vaults', getVaults, [address], 5000)
    const bns = await getAndCacheData('uwu@user-bns', getBNS, [address], 5000);
    const balances = await getAndCacheData('uwu@user-balances', getBalances, [address], 5000);

    const formattedBalances = {
      stx: formatTokens(balances[TokenName.STX].unformatted / 1000000),
      uwu: formatTokens(balances[TokenName.UWU].unformatted / 1000000),
      susdt: formatTokens(balances[TokenName.SUSDT].unformatted / 100000000),
      xusd: formatTokens(balances[TokenName.XUSD].unformatted / 100000000),
      xbtc: formatTokens(balances[TokenName.XBTC].unformatted / 100000000, 6),
      abtc: formatTokens(balances[TokenName.ABTC].unformatted / 100000000, 6),
      alex: formatTokens(balances[TokenName.ALEX].unformatted / 100000000),
      stsw: formatTokens(balances[TokenName.STSW].unformatted / 1000000),
      vibes: formatTokens(balances[TokenName.VIBES].unformatted / 100000000),
      fari: formatTokens(balances[TokenName.FARI].unformatted / 100000000),
      welsh: formatTokens(balances[TokenName.WELSH].unformatted / 1000000),
      leo: formatTokens(balances[TokenName.LEO].unformatted / 1000000),
      gus: formatTokens(balances[TokenName.GUS].unformatted / 1000000),
      lto: {
        "0": formatTokens(balances[LPTokenName.STACKSWAP_STX_UWU].unformatted / 1000000)
      }
    };

    return {
      address,
      bns,
      balances: formattedBalances,
      vaults,
      pending,
    };
  } catch (err) {
    console.error(`Failed to fetch user data for address '${address}': ${err.message}`);

    return {};
  };*/
};

// DONE
export const fetchVaults = async (address) => {
  try {
    const response = await readOnlyRetry({
      contractAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
      contractName: "uwu-factory-v1-1-0",
      functionName: "get-vaults",
      functionArgs: [principalCV(address)],
      network: NETWORK,
      senderAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
    });

    if (response?.value?.list === null) {
      return [];
    };

    const vaults = await Promise.all(response.value.list.map(async (vault) => {
    const timestamp = await getBlockTimestamp(Number(vault.value.value.data.height.value));

      return {
        id: Number(vault.value.value.data.id.value),
        owner: {
          address: c32address(22, vault.value.value.data.owner.address.hash160),
          bns: null
        },
        balances: {
          collateral: formatTokens(Number(vault.value.value.data.collateral.value) / 1000000),
          debt: formatTokens(Number(vault.value.value.data.debt.value) / 1000000)
        },
        date: {
          height: Number(vault.value.value.data.height.value),
          timestamp: new Date(timestamp * 1000).toLocaleDateString("en-US")
        },
        liquidated: vault.value.value.data.liquidated.type !== 4
      };
    }));

    return vaults;
  } catch (err) {
    console.error(`Failed to fetch all Vaults for address '${address}': ${err.message}`);

    return [];
  };
};

// DONE
export const fetchVault = async (id, minimalData = false) => {
  try {
    const response = await readOnlyRetry({
      contractAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
      contractName: "uwu-factory-v1-1-0",
      functionName: "get-vault",
      functionArgs: [uintCV(id)],
      network: NETWORK,
      senderAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
    });

    if (!response.value.value) {
      return {};
    };

    const data = response.value.value.data;
    const bns = minimalData ? null : await fetchBNS(c32address(22, data.owner.address.hash160));
    const timestamp = minimalData ? 0 : await getBlockTimestamp(Number(data.height.value));

    return {
      id: Number(data.id.value),
      owner: {
        address: c32address(22, data.owner.address.hash160),
        bns: bns
      },
      balances: {
        collateral: formatTokens(Number(data.collateral.value) / 1000000),
        debt: formatTokens(Number(data.debt.value) / 1000000)
      },
      date: {
        height: Number(data.height.value),
        timestamp: minimalData ? 0 : new Date(timestamp * 1000).toLocaleDateString("en-US")
      },
      liquidated: data.liquidated.type !== 4
    };
  } catch (err) {
    console.error(`Failed to fetch Vault with ID '${id}': ${err.message}`);

    return {};
  };
};

// DONE
export const fetchLiquidations = async (price, onProgress) => {
  try {
    const totalVaults = await getTotalVaults();

    const vaults = {
      all: [],
      pending: [],
      purchasable: [],
      finished: []
    };

    const validateVault = (vault) => {
      return vault && vault.balances && typeof vault.balances.collateral.formatted === "number" && typeof vault.balances.debt.formatted === "number";
    };

    for (let id = 1; id <= totalVaults; id++) {
      if (!window.location.href.includes("vault-liquidations")) {
        return;
      };

      onProgress({ current: id, total: totalVaults });

      const vault = await getVault(id, true);

      if (!validateVault(vault)) {
        continue;
      };

      const ratio = ((vault.balances.collateral.formatted* price) / vault.balances.debt.formatted) * 100;

      if (!vault.liquidated && ratio < 150) {
        vaults.pending.push(vault);
      } else if (vault.liquidated) {
        if (vault.balances.collateral.formatted> 0 && vault.balances.debt.formatted> 0) {
          vaults.purchasable.push(vault);
        } else if (vault.balances.collateral.formatted=== 0 && vault.balances.debt.formatted=== 0) {
          vaults.finished.push(vault);
        };
      };

      vaults.all.push(vault);

      await promiseWait(800);
    };

    return vaults;
  } catch (err) {
    console.error(`Failed to fetch liquidation status for all Vaults: ${err.message}`);

    return { ready: [], started: [], finished: [] };
  };
};

// DONE
export const uintToNumber = (uint) => {
  return parseInt(uint.slice(1));
};

// DONE
export const getArgumentsAsObject = (args) => {
  return args.reduce((acc, arg) => {
    acc[arg.name] = arg.type === "uint" ? uintToNumber(arg.repr) : arg.repr;

    return acc;
  }, {});
};

// DONE
export const fetchUserTransactions = async (address, pending = false) => {
  try {
    if (pending) {
      const response = await fetchRetry(`${fetchRPCURL()}/extended/v1/address/${address}/mempool?limit=50&unanchored=true`);

      return response.results.filter(transaction => checkUserTransaction(transaction, true));
    };

    const response = await fetchRetry(`${fetchRPCURL()}/extended/v1/address/${address}/transactions_with_transfers?limit=50`);

    return response.results.filter(transaction => checkUserTransaction(transaction)).map(obj => obj.tx);
  } catch (err) {
    console.error(`Failed to fetch user${pending ? " pending " : " "}transactions for address '${address}': ${err.message}`);

    return [];
  };
};

// DONE
export const checkUserTransaction = (transaction, pending = false) => {
  const isRelevantContract = (id, call) => {
    const contracts = [
      UWU_FACTORY_CONTRACT,
      UWU_STABILITY_MODULE_CONTRACT,
      UWU_TOKEN_CONTRACT,
      SUSDT_TOKEN_CONTRACT,
      XUSD_TOKEN_CONTRACT,
      XBTC_TOKEN_CONTRACT,
      ABTC_TOKEN_CONTRACT,
      ALEX_TOKEN_CONTRACT,
      STSW_TOKEN_CONTRACT,
      VIBES_TOKEN_CONTRACT,
      FARI_TOKEN_CONTRACT,
      WELSH_TOKEN_CONTRACT,
      LEO_TOKEN_CONTRACT,
      GUS_TOKEN_CONTRACT,
      LTO_STSW_STXUWU_TOKEN_CONTRACT,
      STSW_SWAP_CONTRACT
    ];

    if (id === LEO_TOKEN_CONTRACT && call) {
      if (call.function_name === "send-many") {
        return false;
      };
    };

    if (id === STSW_SWAP_CONTRACT && call) {
      const args = getArgumentsAsObject(call.function_args);

      return args["token-y-trait"].slice(1) === UWU_TOKEN_CONTRACT &&
        ["swap-x-for-y", "swap-y-for-x", "add-to-position", "reduce-position"].includes(call.function_name);
    };

    if (id && contracts.includes(id)) {
      return true;
    };
  };

  if (pending) {
    const { contract_call, token_transfer } = transaction;

    return (
      (contract_call && isRelevantContract(contract_call.contract_id, contract_call)) || token_transfer !== undefined
    );
  };

  const { tx } = transaction;
  const { tx_status, contract_call } = tx;
  const contractId = contract_call && contract_call.contract_id;
  const tokenTransfer = tx.token_transfer;

  return (
    (tx_status === "success" || tx_status.includes("abort")) &&
    (tokenTransfer || isRelevantContract(contract_call && contractId, contract_call))
  );
};

// DONE
export const fetchDataWithCache = async (key, fetchFunction, fetchArgs, refreshInterval) => {
  const now = Date.now();
  const cachedData = localStorage.getItem(key);

  if (cachedData) {
    try {
      const parsedCache = JSON.parse(cachedData);

      if (parsedCache && typeof parsedCache === "object" && parsedCache.data !== undefined && parsedCache.lastFetch) {
        if (now - parsedCache.lastFetch < refreshInterval) {
          return parsedCache.data;
        };
      };
    } catch (err) {
      console.error(`Failed to parse cached data for '${key}': ${err.message}`);
    };
  };

  try {
    const newData = await fetchFunction.apply(null, fetchArgs);

    localStorage.setItem(key, JSON.stringify({ data: newData, lastFetch: now }));

    return newData;
  } catch (err) {
    console.error(`Failed to fetch data for '${key}': ${err.message}`);

    return cachedData ? JSON.parse(cachedData).data : null;
  };
};

// DONE
export const fetchSTSWSTXUWU = async () => {
  try {
    const response = await readOnlyRetry({
      contractAddress: "SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275",
      contractName: "liquidity-token-v5kglq1fqfp",
      functionName: "get-lp-data",
      functionArgs: [],
      network: NETWORK,
      senderAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
    });

    if (!response.value.data) {
      return {};
    };

    const data = response.value.data;

    return {
      id: 0,
      balances: {
        stx: formatTokens(Number(data["balance-x"].value) / 1000000, 4),
        uwu: formatTokens(Number(data["balance-y"].value) / 1000000, 4),
        usd: 0
      },
      lto: {
        shares: formatTokens(Number(data["shares-total"].value) / 1000000, 4),
        price: formatTokens(formatTokens(Number(data["balance-x"].value) / 1000000, 4) / formatTokens(Number(data["balance-y"].value) / 1000000, 4), 4)
      },
      fees: {
        stx: formatTokens(Number(data["fee-balance-x"].value) / 1000000, 4),
        uwu: formatTokens(Number(data["fee-balance-y"].value) / 1000000, 4)
      }
    };
  } catch (err) {
    console.error(`Failed to fetch data for liquidity pool 'STSW-STX-UWU': ${err.message}`);

    return {};
  };
};

// DONE
export const fetchAddress = async (bns) => {
  try {
    const response = await fetchRetry(`${fetchRPCURL()}/v1/names/${bns}`);

    return response.address;
  } catch (err) {
    console.error(`Failed to fetch address for BNS '${bns}': ${err.message}`);

    return null;
  };
};

// DONE
export const fetchBNS = async (address) => {
  try {
    const response = await fetchRetry(`${fetchRPCURL()}/v1/addresses/stacks/${address}`);

    return response.names[0];
  } catch (err) {
    console.error(`Failed to fetch BNS for address '${address}': ${err.message}`);

    return null;
  };
};