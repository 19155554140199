import React, { useState, useEffect } from 'react'

import { PopupAnimation } from '../../lib/animation'

import { Transaction } from '../../interfaces/transactions'

const TransactionReviewPopup: React.FC<{ show: (show: boolean) => void; options: Transaction['reviewOptions']; call: () => void }> = ({ show, options, call }) => {
  const [visibleClass, setVisibleClass] = useState<string>('')
  
  useEffect(() => {
    setTimeout(() => setVisibleClass('visible'), 25)
  }, [])

  useEffect(() => {
    if (visibleClass === 'visible') {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [visibleClass])

  const handleClosePopup = () => {
    setVisibleClass('')
    setTimeout(() => show(false), 300)
  }

  const hasCollateralRatio = () => {
    if (options.isVaultTransaction) {
      const collateralRatio = options.content.flatMap(section => section.find(item => item.name === 'Collateral Ratio'))
      const ratio = collateralRatio?.[0]?.value?.match(/([\d,]+)/)

      if (ratio && Number(ratio[0]) < 170) {
        return true
      }
    }
    return false
  }

  return (
    <PopupAnimation visible={visibleClass} onClose={handleClosePopup}>
      <div className='popupInterface'>
        <div className='popupHeader'>
          <h1>{options.title}</h1>
          <svg onClick={handleClosePopup} width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M18 6L6 18' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'></path>
            <path d='M6 6L18 18' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'></path>
          </svg>
        </div>
        <div className='popupBody' style={{ margin: '0' }}>
          <div className='popupScroll'>
            <h1>{options.header}</h1>
            <h2>{options.description}</h2>
            {hasCollateralRatio() &&
              <div style={{ border: '1px solid #f3dac2', backgroundColor: '#f3dac225', borderRadius: '10px', padding: '10px', marginTop: '2.5rem' }}>
                <h1 style={{ fontSize: '0.85em', fontWeight: '600', color: '#d88934', marginBottom: '0', textAlign: 'left', lineHeight: '1.36' }}>
                  By proceeding with this transaction, your Vault will be near liquidation due to a low collateral ratio
                </h1>
              </div>}
            {options.content.map((section, index) => (
              <div className='popupTable' key={index} style={{ marginBottom: index < options.content.length - 1 ? '0px' : undefined }}>
                {section.map((item, idx) => ( <>
                  <div className='popupTableContent' key={idx}>
                    <h3>{item.name}</h3>
                    <h1>{item.value}</h1>
                  </div>
                  {idx < section.length - 1 && <div className='borrowDivider' style={{ margin: '15px -15px' }}></div>}
                </>))}
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
            <a onClick={call} className='borrowButton' style={{ color: '#FFFFFF', textDecoration: 'none', lineHeight: '3rem', margin: '0px 10px 0px 20px', fontSize: '0.85rem', width: '100%', minWidth: 'fit-content' }}>Confirm Transaction</a>
            <a onClick={handleClosePopup} className='borrowButton lightButton' style={{ color: '#000000', textDecoration: 'none', lineHeight: '3rem', margin: '0px 20px 0px 10px', fontSize: '0.85rem', width: '100%' }}>Cancel</a>
          </div>
        </div>
      </div>
    </PopupAnimation>
  )
}

export default TransactionReviewPopup