import { FungibleConditionCode, makeContractFungiblePostCondition, makeContractSTXPostCondition, makeStandardFungiblePostCondition, makeStandardSTXPostCondition, PostCondition } from '@stacks/transactions'
import { TokenName } from '../enums/tokens'
import { Token } from '../interfaces/tokens'
import { getTokenAssetInfo } from './tokenUtils'

export const createUserPostCondition = (address: string, conditionCode: FungibleConditionCode, amount: number, token: Token): PostCondition | string => {
  const assetInfo = getTokenAssetInfo(token)

  return token.name === TokenName.STX
    ? makeStandardSTXPostCondition(address, conditionCode, amount)
    : makeStandardFungiblePostCondition(address, conditionCode, amount, assetInfo!)
}

export const createContractPostCondition = (address: string, contractName: string, conditionCode: FungibleConditionCode, amount: number, token: Token): PostCondition | string => {
  const assetInfo = getTokenAssetInfo(token)

  return token.name === TokenName.STX
    ? makeContractSTXPostCondition(address, contractName, conditionCode, amount)
    : makeContractFungiblePostCondition(address, contractName, conditionCode, amount, assetInfo!)
}