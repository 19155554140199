import React, { useState, useEffect } from "react";
import { PopupAnimation } from "../lib/animation";
import { useConnect } from "../lib/auth.js";

export default function AddressPopupComponent({ show, address }) {
  const [visibleClass, setVisibleClass] = useState("");

  const { handleSignOut } = useConnect();

  useEffect(() => {
    setTimeout(() => setVisibleClass('visible'), 25);
  }, []);

  return (
    <PopupAnimation visible={visibleClass} onClose={() => {}}>
      <div className="popupInterface">
        <div className="popupBody" style={{ textAlign: "left", margin: "2rem" }}>
          <h1 style={{ lineHeight: "1", marginBottom: "1rem" }}>Not Authorized</h1>
          <h3 style={{ marginBottom: "10px" }}>The address you're currently logged in with does not seem to be included in our closed beta. At this time, access to the Web App is exclusively available to beta testers.</h3>
          <h3 style={{ marginBottom: "10px" }}>To become a beta tester, click "Join the Beta" below. You can also try logging in using a different address.</h3>
          <h3 style={{ marginBottom: "10px", color: "#000000" }}>Logged in as: {address}</h3>
          <div style={{ marginTop: "2rem" }}>
            <a href="https://app.uwu.cash" target="_blank" className="smallButton" style={{ color: "#FFFFFF", textDecoration: "none", lineHeight: "2rem", marginRight: "1rem" }}>Join the Beta</a>
            <a onClick={handleSignOut}>Sign Out</a>
          </div>
        </div>
      </div>
    </PopupAnimation>
  );
};