import { useCallback } from "react";
import { AppConfig, UserSession } from "@stacks/connect-react";
import { showConnect } from "@stacks/connect";
import { atom, useAtom } from "jotai";
import { useUpdateAtom } from "jotai/utils";

const appConfig = new AppConfig(["store_write", "publish_data"]);

export const userSessionState = atom(new UserSession({ appConfig }));
export const userDataState = atom();
export const authResponseState = atom();

export const useConnect = () => {
  const [userSession] = useAtom(userSessionState);
  const setUserData = useUpdateAtom(userDataState);
  const setAuthResponse = useUpdateAtom(authResponseState);

  const onFinish = async (payload) => {
    const userData = await payload.userSession.loadUserData();
    
    setAuthResponse(payload.authResponse);
    setUserData(userData);
    window.location.reload();
  };

  const authOptions = {
    onFinish,
    userSession,
    redirectTo: "/",
    appDetails: {
      name: "UWU Protocol",
      icon: "https://cdn.quorumdao.org/uwu-logo.png",
    },
  };

  const handleOpenAuth = () => {
    showConnect(authOptions);
  };

  const handleSignOut = useCallback(() => {
    localStorage.removeItem("userBalances");
    localStorage.removeItem("userVaults");
    localStorage.removeItem("userBNS");
    userSession?.signUserOut("/");
  }, [userSession]);

  return { handleOpenAuth, handleSignOut, authOptions };
};