import React, { useState, useEffect } from 'react'

import { PopupAnimation } from '../../lib/animation'

import { tokensMap } from '../../constants/tokens'
import { protocolsMap } from '../../constants/protocols'

import { formatUIValue } from '../../utils/formattingUtils'

import { SwapRoute } from '../../interfaces/swap'

import checkmark from '../../img/checkmark.png'

const SwapRoutePopup: React.FC<{ show: (show: boolean) => void; routes: SwapRoute[] }> = ({ show, routes }) => {
  const [visibleClass, setVisibleClass] = useState<string>('')

  useEffect(() => {
    setTimeout(() => setVisibleClass('visible'), 25)
  }, [])

  useEffect(() => {
    if (visibleClass === 'visible') {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [visibleClass])

  const handleClosePopup = () => {
    setVisibleClass('')
    setTimeout(() => show(false), 300)
  }

  return (
    <PopupAnimation visible={visibleClass} onClose={handleClosePopup}>
      <div className='popupInterface'>
        <div className='popupHeader'>
          <h1>Swap Route</h1>
          <svg onClick={handleClosePopup} width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M18 6L6 18' stroke='currentcolor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'></path><path d='M6 6L18 18' stroke='currentcolor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'></path></svg>
        </div>
        <div className='popupBody activityView' style={{ textAlign: 'left', margin: '0', minHeight: '0' }}>
          <h1 style={{ fontSize: '0.85rem', marginBottom: '0.5rem' }}>Available Routes</h1>
          <h3 style={{ marginBottom: '20px' }}>UWU Swap evaluated the following routes and picked the one offering the most favorable rate for your swap.</h3>
          {(routes.map((route, index) => {
            const inputToken = tokensMap[route.from]
            const outputToken = tokensMap[route.to]
            const protocol = protocolsMap[route.protocol]
            const amounts = route.amounts

            return (
              <div className='orderRouteViewOuter'>
                <div className='orderRouteViewHeader'>
                  <div>
                    {index === 0 && <img draggable='false' src={checkmark} />}
                    <h1>Route #{index + 1}</h1>
                  </div>
                  <h2>Direct Swap</h2>
                </div>
                <div className='orderRouteView'>
                  <div>
                    <img draggable='false' src={inputToken.icon} />
                    <h1>{amounts && formatUIValue(amounts.amount)} {inputToken.symbol}</h1>
                  </div>
                  <span></span>
                  <div>
                    <img draggable='false' style={{ borderRadius: '10px' }} src={protocol.icon} />
                    <h1>{protocol.name}</h1>
                  </div>
                  <span></span>
                  <div>
                    <img draggable='false' src={outputToken.icon} />
                    <h1>{amounts && formatUIValue(amounts.est.formatted)} {outputToken.symbol}</h1>
                  </div>
                </div>
              </div>
            )
          }))}
        </div>
      </div>
    </PopupAnimation>
  )
}

export default SwapRoutePopup