export const DEFAULT_RPC_URL = "https://api.hiro.so";

export const UWU_FACTORY_CONTRACT = "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-factory-v1-1-0";

export const UWU_ORACLE_CONTRACT = "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-oracle-v1-1-3";
export const UWU_ORACLE_PROXY_CONTRACT = "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-oracle-proxy-v1-1-0";

export const UWU_STABILITY_MODULE_CONTRACT = "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-stability-module-v1-1-4";

export const UWU_TOKEN_CONTRACT = "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-token-v1-1-0";
export const SUSDT_TOKEN_CONTRACT = "SP2XD7417HGPRTREMKF748VNEQPDRR0RMANB7X1NK.token-susdt";
export const XUSD_TOKEN_CONTRACT = "SP2TZK01NKDC89J6TA56SA47SDF7RTHYEQ79AAB9A.Wrapped-USD";
export const XBTC_TOKEN_CONTRACT = "SP3DX3H4FEYZJZ586MFBS25ZW3HZDMEW92260R2PR.Wrapped-Bitcoin";
export const ABTC_TOKEN_CONTRACT = "SP2XD7417HGPRTREMKF748VNEQPDRR0RMANB7X1NK.token-abtc";
export const ALEX_TOKEN_CONTRACT = "SP102V8P0F7JX67ARQ77WEA3D3CFB5XW39REDT0AM.token-alex";
export const STSW_TOKEN_CONTRACT = "SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.stsw-token-v4a";
export const VIBES_TOKEN_CONTRACT = "SP27BB1Y2DGSXZHS7G9YHKTSH6KQ6BD3QG0AN3CR9.vibes-token";
export const FARI_TOKEN_CONTRACT = "SP213KNHB5QD308TEESY1ZMX1BP8EZDPG4JWD0MEA.fari-token-mn";
export const WELSH_TOKEN_CONTRACT = "SP3NE50GEXFG9SZGTT51P40X2CKYSZ5CC4ZTZ7A2G.welshcorgicoin-token";
export const LEO_TOKEN_CONTRACT = "SP1AY6K3PQV5MRT6R4S671NWW2FRVPKM0BR162CT6.leo-token";
export const GUS_TOKEN_CONTRACT = "SP1JFFSYTSH7VBM54K29ZFS9H4SVB67EA8VT2MYJ9.gus-token";
export const LTO_STSW_STXUWU_TOKEN_CONTRACT = "SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.liquidity-token-v5kglq1fqfp";

export const UWU_TOKEN_IDENTIFIER = UWU_TOKEN_CONTRACT + "::uwu";
export const SUSDT_TOKEN_IDENTIFIER = SUSDT_TOKEN_CONTRACT + "::bridged-usdt";
export const XUSD_TOKEN_IDENTIFIER = XUSD_TOKEN_CONTRACT + "::wrapped-usd";
export const XBTC_TOKEN_IDENTIFIER = XBTC_TOKEN_CONTRACT + "::wrapped-bitcoin";
export const ABTC_TOKEN_IDENTIFIER = ABTC_TOKEN_CONTRACT + "::bridged-btc";
export const ALEX_TOKEN_IDENTIFIER = ALEX_TOKEN_CONTRACT + "::alex";
export const STSW_TOKEN_IDENTIFIER = STSW_TOKEN_CONTRACT + "::stsw";
export const VIBES_TOKEN_IDENTIFIER = VIBES_TOKEN_CONTRACT + "::vibes-token";
export const FARI_TOKEN_IDENTIFIER = FARI_TOKEN_CONTRACT + "::fari";
export const WELSH_TOKEN_IDENTIFIER = WELSH_TOKEN_CONTRACT + "::welshcorgicoin";
export const LEO_TOKEN_IDENTIFIER = LEO_TOKEN_CONTRACT + "::leo";
export const GUS_TOKEN_IDENTIFIER = GUS_TOKEN_CONTRACT + "::gus";

export const LTO_STSW_STXUWU_TOKEN_IDENTIFIER = LTO_STSW_STXUWU_TOKEN_CONTRACT + "::liquidity-token";

export const STSW_SWAP_CONTRACT = "SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.stackswap-swap-v5k";