import { getTokensMapArray } from '../../utils/tokenUtils'
import { userEnabledTokens } from '../../constants/tokens'
import { fetchRetry } from '../../utils/fetchUtils'

/**
 * Fetches the current USD price data for a list of tokens from the CoinGecko API that are enabled for the user.
 * The function filters tokens that are enabled for the user and have a valid identifier for CoinGecko
 *
 * @returns {Promise<Record<string, number>>} The current price in USD for each token or zero if not available
 * @throws {Error} If the network request fails or returns a non-ok status
 */
export const getPrices = async (): Promise<Record<string, number>> => {
  const availableTokens = getTokensMapArray().filter(token =>
    userEnabledTokens.includes(token.name) && token.enabled && typeof token.coingeckoIdentifier === 'string'
  )

  const tokenIdentifiers = availableTokens.map(token => encodeURIComponent(token.coingeckoIdentifier!)).join('%2C')
  
  const endpoint = `https://api.coingecko.com/api/v3/simple/price?ids=${tokenIdentifiers}&vs_currencies=usd`
  const response = await fetchRetry(endpoint)

  if (!response.ok) {
    throw new Error(`Failed to fetch price data from CoinGecko`)
  }

  const data = await response.json()

  const prices: Record<string, number> = {}

  availableTokens.forEach(token => {
    const price = data[token.coingeckoIdentifier!]
    
    prices[token.name] = price?.usd ?? 0
  })

  return prices
}