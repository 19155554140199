import { STACKS_RPC_URL } from '../../config/stacks'

/**
 * Retrieves the Stacks RPC URL from user preferences stored in local storage.
 * If preferences are not available or there is an error parsing the stored JSON,
 * the function logs an error and returns a default RPC URL
 * 
 * @returns {string} The RPC URL from user preferences or a default if not available or on error
 */
export const getStacksRPC = (): string => {
  const preferences = localStorage.getItem('uwu-preferences')

  if (!preferences) {
    console.error(`Failed to fetch user preferences from local storage`)
    
    return STACKS_RPC_URL
  }

  try {
    const data = JSON.parse(preferences)

    return data?.api || STACKS_RPC_URL
  } catch (err) {
    console.error(`Failed to fetch Stacks RPC from user preferences: ${err instanceof Error ? err.message : String(err)}`)

    return STACKS_RPC_URL
  }
}