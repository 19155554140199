import { callReadOnlyFunction, ReadOnlyFunctionOptions } from '@stacks/transactions'

export const fetchRetry = async (url: string, options: RequestInit = {}, retry: number = 10, timeout: number = 10000): Promise<any> => {
  const handleErr = async (err: any) => {
    const remainingAttempts = retry - 1

    if (!remainingAttempts) {
      throw `Failed to fetch from URL '${url}': ${err instanceof Error ? err.message : String(err)}`
    }

    await promiseWait(timeout)

    return await fetchRetry(url, options, remainingAttempts, timeout * 2)
  }

  try {
    const res = await fetch(url, options)

    return res
  } catch (err) {
    return handleErr(err)
  }
}

export const readOnlyRetry = async (options: ReadOnlyFunctionOptions, retry: number = 10, timeout: number = 10000): Promise<any> => {
  const handleErr = async (err: any) => {
    const remainingAttempts = retry - 1

    if (!remainingAttempts) {
      throw `Failed to fetch from read-only function '${options.functionName}': ${err instanceof Error ? err.message : String(err)}`
    }

    await promiseWait(timeout)

    return await readOnlyRetry(options, remainingAttempts, timeout * 2)
  }

  try {
    return await callReadOnlyFunction(options)
  } catch (err) {
    return handleErr(err)
  }
}

export const promiseWait = (timeout: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, timeout))
}