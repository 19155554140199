export const TX_REVIEW_TITLES = {
  DEFAULT: 'Review Transaction'
}

export const TX_REVIEW_HEADERS = {
  DEFAULT: 'Review this Transaction'
}

export const TX_REVIEW_DESCRIPTIONS = {
  DEFAULT: 'If you confirm this transaction, the following will changes will be made to your account.'
}

export const TX_REVIEW_CONTENT_NAMES = {
  SEND: 'You Send',
  RECEIVE: 'You Receive',
  MINIMUM_RECEIVED: 'Minimum Received',
  SWAP_FEE: 'Swap Fee',
  SLIPPAGE_TOLERANCE: 'Slippage Tolerance'
}

export const TX_REVIEW_CONTENT_VALUES = {
  NOT_REQUIRED: 'Not Required'
}

export const TX_SUBMITTED_TITLES = {
  SWAP_CONFIRMATION: 'Swap Confirmation'
}

export const TX_SUBMITTED_HEADERS = {
  SWAP_CONFIRMATION: 'Swap Confirmation'
}

export const TX_SUBMITTED_DESCRIPTIONS = {
  DEFAULT: 'Your transaction was sent. Please allow 10-30 minutes for the transaction to be completed.'
}