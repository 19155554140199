import { swapEnabledProtocols } from '../constants/protocols'
import { swapEnabledTokens, tokensMap, usmEnabledTokens } from '../constants/tokens'
import { ProtocolName } from '../enums/protocols'
import { TokenName } from '../enums/tokens'
import { SwapRoute } from '../interfaces/swap'
import { Token, TokenList } from '../interfaces/tokens'
import { formatUIValue } from './formattingUtils'
import { getLPTokensMapArray } from './tokenUtils'

export const getSwapFee = (token: Token, amount: number, fee: number, decimals: number = 4) => {
  if (fee === 0) {
    return 'No Fee'
  }

  return `${formatUIValue(amount * fee, decimals)} ${token.symbol} (${formatUIValue((fee * 100), decimals)}%)`
}

export const getSwapRoutes = (inputToken: Token, outputToken: Token): SwapRoute[] => {
  const routes: SwapRoute[] = []

  if (swapEnabledProtocols.includes(ProtocolName.STACKSWAP) || swapEnabledProtocols.includes(ProtocolName.ALEX) || swapEnabledProtocols.includes(ProtocolName.BITFLOW)) {
    const inputTokenIsEnabled = swapEnabledTokens.includes(inputToken.name)
    const outputTokenIsEnabled = swapEnabledTokens.includes(outputToken.name)

    if (!inputTokenIsEnabled || !outputTokenIsEnabled) {
      return routes
    }

    const availableLPTokens = getLPTokensMapArray().filter(lpToken => (lpToken.xToken === inputToken && lpToken.yToken === outputToken) || (lpToken.xToken === outputToken && lpToken.yToken === inputToken))

    availableLPTokens.forEach(lpToken => {
      routes.push({
        from: inputToken.name,
        to: outputToken.name,
        lp: lpToken.name,
        protocol: lpToken.protocol,
        valid: true
      })
    })
  }

  if (swapEnabledProtocols.includes(ProtocolName.USM)) {
    const inputTokenIsEnabled = usmEnabledTokens.includes(inputToken.name)
    const outputTokenIsEnabled = usmEnabledTokens.includes(outputToken.name)

    if (!inputTokenIsEnabled || !outputTokenIsEnabled) {
      return routes
    }

    const routeHasUWU = (inputToken.name === TokenName.UWU) || (outputToken.name === TokenName.UWU)
    const tokensAreDifferent = inputToken.name !== outputToken.name

    if (routeHasUWU && tokensAreDifferent) {
      routes.push({
        from: inputToken.name,
        to: outputToken.name,
        protocol: ProtocolName.USM,
        valid: true
      })
    }
  }

  return routes
}

export const getSwapTokenList = (protocols?: ProtocolName[]): { inputTokenList: TokenList[], outputTokenList: TokenList[] } => {
  const inputTokenList: TokenList[] = []
  const outputTokenList: TokenList[] = []

  const addedTokens = new Set<string>()

  const addTokensToLists = (tokens: string[]) => {
    tokens.forEach(tokenName => {
      if (!addedTokens.has(tokenName)) {
        const token = tokensMap[tokenName]

        if (token) {
          inputTokenList.push({
            token: token,
            label: token.symbol,
            img: token.icon
          })

          outputTokenList.push({
            token: token,
            label: token.symbol,
            img: token.icon
          })

          addedTokens.add(tokenName)
        }
      }
    })
  }

  if (protocols && protocols.length > 0) {
    protocols.forEach(protocol => {
      if (protocol === ProtocolName.USM) {
        addTokensToLists(usmEnabledTokens)
      } else {
        addTokensToLists(swapEnabledTokens)
      }
    })
  } else {
    addTokensToLists(swapEnabledTokens)
    addTokensToLists(usmEnabledTokens)
  }

  return { inputTokenList, outputTokenList }
}