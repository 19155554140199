import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";

export default function FooterComponent({ session }) {
  const [buildID, setBuildID] = useState("");

  useEffect(() => {
    fetch('/build-id.txt')
      .then(response => response.text())
      .then(text => setBuildID(text));
  }, []);

  return (
    <>
      <ReactTooltip className="defaultTooltip" effect="solid" multiline={true} arrowColor="#000000" padding="10px 12px" />
      <div className="footer">
        <div>
          <span
            style={{
              backgroundColor: session
                ? session?.network?.api === "ready"
                  ? session?.network?.mempool < 10000
                    ? "#20ba62"
                    : "#d88934"
                  : "#d88934"
                : "",
              animation: session
                ? session?.network?.api === "ready"
                  ? session?.network?.mempool < 10000
                    ? ""
                    : "pulse 1s infinite"
                  : "pulse 1s infinite"
                : "pulse 1s infinite"
            }}
            data-tip={
              session
                ? session?.network?.api === "ready"
                  ? session?.network?.mempool < 10000
                    ? "Network Status: Normal"
                    : "Network Status: High Traffic"
                  : "Network Status: No Connection"
                : ""
            }>
          </span>
          <h1>UWU Protocol – Build ID: {buildID}</h1>
        </div>
        <ul>
          <li><a href="https://uwu.cash" target="_blank">Homepage</a></li>
          <li><a href="https://app.uwu.cash" target="_blank">Web App</a></li>
          <li><a href="https://docs.uwu.cash" target="_blank">Documentation</a></li>
        </ul>
        <ul>
          <li><a href="https://analytics.uwu.cash" target="_blank">Analytics</a></li>
          <li><a href="https://x.com/uwuprotocol" target="_blank">X</a></li>
          <li><a href="http://chat.uwu.cash" target="_blank">Discord</a></li>
          <li><a href="https://github.com/uwuprotocol" target="_blank">GitHub</a></li>
        </ul>
        <h2>Market data provided by <a href="https://coingecko.com" target="_blank">CoinGecko</a> and <a href="https://uwu.cash" target="_blank">UWU Swap</a>.</h2>
      </div>
    </>
  );
};