import { Token } from '../interfaces/tokens'
import { USM } from '../interfaces/usm'
import { getIsUSMSwapRoute, getIsUSMTokenApproved } from './alertUtils'
import { getSwapFee } from './swapUtils'

export const getUSMSwapFee = (usm: USM, inputToken: Token, outputToken: Token, amount: number, fee: number, decimals: number = 4) => {
  if (!getIsUSMSwapRoute(inputToken, outputToken) || !getIsUSMTokenApproved(usm, inputToken) || !getIsUSMTokenApproved(usm, outputToken)) {
    return `No Fee`
  }

  return getSwapFee(inputToken, amount, (fee / 10000), decimals)
}