import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { timeSince, trunc, convertMicrotokensToTokens, formatTokens, numberWithCommas, formatTokensDisplay } from "../lib/utils.js";

import LoaderComponent from "./LoaderComponent.js";

import earnliq from "../img/earn-liq.png";
import earnpr from "../img/earn-pr.png";
import earnstsw from "../img/earn-stsw.png";
import earnswap from "../img/earn-swap.png";
import earnusr from "../img/earn-usr.png";
import stxuwulp from "../img/stx-uwu-lp.png";

export default function ErrorComponent() {
  const [session, user] = useOutletContext();
  
  document.title = "Earn | UWU Protocol";

  return (
    <>
      {(session && user) ?
      <div className="core">
        <ReactTooltip className="defaultTooltip" effect="solid" multiline={true} arrowColor="#000000" padding="10px 12px" />
        <div className="borrowHeader">
          <h1>UWU Earn</h1>
          <h2>Earn more with UWU Earn. Utilize core protocol features and<br></br> third-party services to maximize your earning potential.</h2>
        </div>
        <div className="grid" style={{ gridTemplateColumns: "1fr 1fr 1fr", marginBottom: "2.85rem" }}>
          <div className="earnDiv">
            <div className="earnDivHeader">
              <img draggable="false" src={earnpr} />
              <h1>UWU Savings Rate</h1>
              <div>
                <h2>Earn savings with your UWU</h2>
              </div>
            </div>
            <div className="earnDivContent">
              <h1>Deposit UWU and earn the UWU Savings Rate, paid in UWU. No limits, fees, or lockups.</h1>
            </div>
            <div className="earnDivCard">
              <h2>Coming Soon</h2>
            </div>
          </div>
          <div className="earnDiv">
            <div className="earnDivHeader">
              <img draggable="false" src={earnliq} />
              <h1>Vault Liquidations</h1>
              <h2>Earn STX from liquidations</h2>
            </div>
            <div className="earnDivContent">
              <h1>Buy STX at a discounted price in exchange for repaying the debt of liquidated Vaults.</h1>
            </div>
            <a className="borrowButton" style={{ height: "42px", margin: "0", lineHeight: "2.58rem" }} href="/earn/vault-liquidations">Liquidate</a>
          </div>
          <div style={{ borderColor: '#ff7bed' }} className="earnDiv earnDivSwap">
            <div className="earnDivHeader">
              <img draggable="false" src={earnusr} />
              <div style={{ display: "flex", gridGap: "0.5rem", alignItems: "center", marginBottom: '0.85rem' }}><h1 style={{ marginBottom: 'unset' }}>Stability Module</h1><a style={{ color: "#FFFFFF", backgroundColor: "#FF7BED", padding: "3px 7px", borderRadius: "100px", fontSize: "0.65rem", textDecoration: "none", fontWeight: '600' }}>V2</a></div>
              <h2>Swap UWU without slippage</h2>
            </div>
            <div className="earnDivContent">
              <h1>Swap between UWU and various assets at predefined rates with low to no fees.</h1>
            </div>
            <a className="borrowButton" style={{ height: "42px", margin: "0", lineHeight: "2.58rem" }} href="/earn/stability-module">Swap</a>
          </div>
        </div>
        <div className="grid homeGrid" style={{ maxWidth: "unset" }}>
          <div className="vaultHeader" style={{ marginBottom: "0.5rem" }}>
            <h1 style={{ marginBottom: "10px" }}>Liquidity Provision</h1>
            <h2>Earn trading fees and rewards by providing liquidity.</h2>
          </div>
          <div className="overview overviewMinimized">
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={stxuwulp} draggable="false" />
              <div>
                <h1 style={{ marginBottom: "2.5px", fontFamily: "inherit", fontWeight: "600" }}>STX-UWU LP</h1>
                <h2 style={{ marginBottom: "0" }}>Stackswap</h2>
              </div>
            </div>
            <span className="overviewMinimizedDivider"></span>
            <div>
              <h2>Your Deposit</h2>
              <h1>${formatTokensDisplay(session?.pools["0"].balances.usd * (user?.balances.lto["0"] / session?.pools["0"].lpToken.shares.formatted))}</h1>
            </div>
            <div>
              <h2>Your Pool Share</h2>
              <h1>{formatTokensDisplay(user?.balances.lto["0"] / session?.pools["0"].lpToken.shares.formatted * 100)}%</h1>
            </div>
            <span className="overviewMinimizedDivider"></span>
            <div>
              <h2>Total Deposited</h2>
              <h1>${formatTokensDisplay(session?.pools["0"].balances.usd)}</h1>
            </div>
            <div>
              <h2>Estimated APR</h2>
              <h1>-</h1>
            </div>
            <span className="mobileDivider" style={{ marginBottom: "5px" }}></span>
            <a href="/earn/stx-uwu-lp" className="smallButton borrowButtonMobile">Deposit</a>
          </div>
        </div>
      </div>
      : <LoaderComponent />}
    </>
  );
};