export enum TokenName {
  STX = 'Stacks Token',
  UWU = 'UWU Cash',
  SUSDT = 'Bridged USDT',
  AEUSDC = 'AllBridge USDC',
  XUSD = 'Wrapped USD',
  XBTC = 'Wrapped BTC',
  ABTC = 'Bridged BTC',
  ALEX = 'ALEX Lab',
  STSW = 'Stackswap',
  VIBES = 'HireVibes',
  FARI = 'Fari Token',
  WELSH = 'Welsh Corgi Coin',
  LEO = 'Leo Token',
  GUS = 'Gus Token'
}

export enum LPTokenName {
  STACKSWAP_STX_UWU = 'STACKSWAP_STX_UWU',
  ALEX_STX_SUSDT = 'ALEX_STX_SUSDT',
  BITFLOW_STX_AEUSDC = 'BITFLOW_STX_AEUSDC'
}