import { ProtocolName } from '../enums/protocols'
import { Protocol } from '../interfaces/protocols'

export const protocolsMap: Record<string, Protocol> = {
  [ProtocolName.USM]: {
    name: ProtocolName.USM,
    icon: '/assets/icons/protocols/usm.png',
    enabled: true
  },
  [ProtocolName.STACKSWAP]: {
    name: ProtocolName.STACKSWAP,
    icon: '/assets/icons/protocols/stackswap.png',
    enabled: true
  },
  [ProtocolName.ALEX]: {
    name: ProtocolName.ALEX,
    icon: '/assets/icons/protocols/alex.png',
    enabled: true
  },
  [ProtocolName.BITFLOW]: {
    name: ProtocolName.BITFLOW,
    icon: '/assets/icons/protocols/bitflow.png',
    enabled: true
  }
}

export const swapEnabledProtocols = [
  ProtocolName.USM,
  ProtocolName.STACKSWAP,
  ProtocolName.ALEX,
  ProtocolName.BITFLOW
]