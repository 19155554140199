import React, { useState, useEffect } from "react";
import { PopupAnimation } from "../lib/animation";

import wgone from "../img/welcome-guide-p1.jpg";
import wgtwo from "../img/welcome-guide-p2.jpg";
import wgthree from "../img/welcome-guide-p3.jpg";

const guides = {
  0: [
    {
      title: "Welcome Guide",
      heading: "Welcome to UWU Protocol",
      description: ["UWU Protocol is a stablecoin protocol built on Stacks that offers zero-interest loans with no repayment date."],
      img: wgone
    },
    {
      title: "Welcome Guide",
      heading: "Before you get started",
      description: ["UWU Protocol is currently in its closed beta phase. If you encounter any issues, please click on the question mark located in the bottom right corner of the screen.", "Furthermore, we encourage all users to read the available documentation before interacting with UWU Protocol."],
      img: wgtwo
    },
    {
      title: "Welcome Guide",
      heading: "Have a suggestion?",
      description: ["Should you have a suggestion for a new feature or an improvement, we'd love to hear it. We are continually working to improve the Web App.", "To share your ideas, simply click on the question mark located in the bottom right corner of the screen."],
      img: wgthree
    },
    {
      title: "Welcome Guide",
      heading: "Need some help?",
      description: ["This guide, along with several others, is readily accessible anytime. Simply click on the question mark located in the bottom right corner of the screen to access them."],
      img: wgthree
    },
    {
      title: "Welcome Guide",
      heading: "Embarking on your journey",
      description: ["You're ready to use UWU Protocol! As you navigate the Web App, you'll come across various guides for products and features. Feel free to refer to these at any point."],
      img: wgthree,
      btnAction: (() => updatePreferences())
    }
  ]
};


function updatePreferences() {
  let storedPreferences = JSON.parse(localStorage.getItem("uwu-preferences"));

  if (storedPreferences) {
    storedPreferences.notice = false;
    localStorage.setItem("uwu-preferences", JSON.stringify(storedPreferences));
  };
};

export default function GuidePopupComponent({ show, context, id }) {
  const [visibleClass, setVisibleClass] = useState("");
  const [session, user] = context;
  
  const pages = guides[id];
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setTimeout(() => setVisibleClass('visible'), 25);
  }, []);

  useEffect(() => {
    if (visibleClass === "visible") {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    };
  }, [visibleClass]);

  const handleClosePopup = () => {
    setVisibleClass('');
    setTimeout(() => show(false), 300);
  };

  return (
    <PopupAnimation visible={visibleClass} onClose={handleClosePopup}>
      <div className="popupInterface">
        <div className="popupHeader">
          {currentPage > 0 && <a onClick={() => setCurrentPage(currentPage - 1)}>{`<-`}</a>}
          <h1>{pages[currentPage].title} • {currentPage + 1 + "/" + pages.length}</h1>
          <svg onClick={handleClosePopup} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M6 6L18 18" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
        </div>
        <div className="popupBody guideView">
          <img draggable="false" src={pages[currentPage].img} />
          <div>
            <h1>{pages[currentPage].heading}</h1>
            {pages[currentPage].description.map((line, index) => <h2 key={index} style={{ marginBottom: index === pages[currentPage].description.length - 1 ? '2rem' : '10px' }}>{line}</h2>)}
            <a className="smallButton" onClick={currentPage < pages.length - 1 ? () => setCurrentPage(currentPage + 1) : () => {pages[currentPage].btnAction(); handleClosePopup(); }}>{currentPage < pages.length - 1 ? "Continue" : "Finish Guide"}</a>
          </div>
        </div>
      </div>
    </PopupAnimation>
  );
};