import { FungibleConditionCode, PostConditionMode, principalCV, uintCV } from '@stacks/transactions'
import { STACKS_ADDRESS_VERSION, STACKS_NETWORK, STACKS_NETWORK_TYPE } from '../../config/stacks'
import { UWU_CONTRACT_ADDRESSES, UWU_CONTRACT_NAMES, USM_FUNCTION_NAMES } from '../../constants/smartContracts'
import { tokensMap, usmEnabledTokens } from '../../constants/tokens'
import { TokenName } from '../../enums/tokens'
import { Token, USMToken } from '../../interfaces/tokens'
import { USM } from '../../interfaces/usm'
import { fetchRetry, readOnlyRetry } from '../../utils/fetchUtils'
import { formatTokenValue, formatValue } from '../../utils/formattingUtils'
import { getTokensMapArray, getTokenContract } from '../../utils/tokenUtils'
import { getStacksRPC } from './rpcService'
import { c32address } from 'c32check'
import { SwapTransaction } from '../../interfaces/transactions'
import { User } from '../../interfaces/user'
import { useConnect as stacksConnect } from '@stacks/connect-react'
import { useCallback } from 'react'
import { getIsUSMTokenApproved } from '../../utils/alertUtils'
import { createContractPostCondition, createUserPostCondition } from '../../utils/postConditionUtils'
import { SwapAmounts } from '../../interfaces/swap'
import { ProtocolName } from '../../enums/protocols'

export const getUSM = async (): Promise<USM> => {
  try {
    const tokens = await getUSMTokens()
    const reserves = await getUSMReserves()
    const feeAddress = await getUSMFeeAddress()
    const swapStatus = await getUSMStatus()

    return {
      tokens: tokens,
      reserves: reserves,
      feeAddress: feeAddress,
      swapStatus: swapStatus
    }
  } catch (err) {
    throw new Error(`Failed to fetch data for USM: ${err instanceof Error ? err.message : String(err)}`)
  }
}

export const getUSMFeeAddress = async (): Promise<string> => {
  const response = await readOnlyRetry({
    contractAddress: UWU_CONTRACT_ADDRESSES.DEPLOYER,
    contractName: UWU_CONTRACT_NAMES.USM,
    functionName: USM_FUNCTION_NAMES.GET_FEE_ADDRESS,
    functionArgs: [],
    network: STACKS_NETWORK,
    senderAddress: UWU_CONTRACT_ADDRESSES.DEPLOYER,
  })

  if (response?.value === null) {
    throw new Error(`Failed to fetch fee address for USM`)
  }

  const data = response.value

  return c32address(STACKS_ADDRESS_VERSION, data.address.hash160)
}

export const getUSMStatus = async (): Promise<boolean> => {
  const response = await readOnlyRetry({
    contractAddress: UWU_CONTRACT_ADDRESSES.DEPLOYER,
    contractName: UWU_CONTRACT_NAMES.USM,
    functionName: USM_FUNCTION_NAMES.GET_SWAP_STATUS,
    functionArgs: [],
    network: STACKS_NETWORK,
    senderAddress: UWU_CONTRACT_ADDRESSES.DEPLOYER,
  })

  if (response?.value === null) {
    throw new Error(`Failed to fetch swap status for USM`)
  }

  const data = response.value

  return data.type !== 4
}

export const getUSMToken = async (token: Token): Promise<USMToken> => {
  const { contract } = token
  const contractDetails = contract[STACKS_NETWORK_TYPE]

  if (!contractDetails?.address || !contractDetails?.name) {
    throw new Error(`Contract details for Token '${token.name}' are missing`)
  }

  const tokenContract = getTokenContract(token, STACKS_NETWORK_TYPE, true)!

  const uwuToken = tokensMap[TokenName.UWU]

  if (token === uwuToken) {
    return {
      name: token.name,
      approved: true,
      factor: 1,
      xFee: 0,
      yFee: 0,
      rate: 1000000,
      oracle: '',
      oracleHeight: 0
    }
  }

  const response = await readOnlyRetry({
    contractAddress: UWU_CONTRACT_ADDRESSES.DEPLOYER,
    contractName: UWU_CONTRACT_NAMES.USM,
    functionName: USM_FUNCTION_NAMES.GET_TOKEN,
    functionArgs: [principalCV(tokenContract)],
    network: STACKS_NETWORK,
    senderAddress: UWU_CONTRACT_ADDRESSES.DEPLOYER,
  })

  if (response?.value?.value === null) {
    throw new Error(`Failed to fetch details for USM Token '${token.name}'`)
  }

  const { data } = response.value.value

  return {
    name: token.name,
    approved: data.approved.type !== 4,
    factor: formatValue(data.factor.value),
    xFee: formatValue(data['x-fee'].value),
    yFee: formatValue(data['y-fee'].value),
    rate: formatValue(data.rate.value),
    oracle: c32address(STACKS_ADDRESS_VERSION, data.oracle.address.hash160),
    oracleHeight: formatValue(data['oracle-height'].value)
  }
}

export const getUSMTokens = async (): Promise<Record<TokenName, USMToken>> => {
  const usmTokens = getTokensMapArray().filter(token => usmEnabledTokens.includes(token.name) && token.enabled)

  const tokens: Record<string, USMToken> = {}

  for (const token of usmTokens) {
    const data = await getUSMToken(token)

    tokens[token.name] = data
  }

  return tokens
}

export const getUSMReserves = async (): Promise<Record<TokenName, { formatted: number; unformatted: number; }>> => {
  const endpoint = `${getStacksRPC()}/extended/v1/address/${UWU_CONTRACT_ADDRESSES.DEPLOYER}.${UWU_CONTRACT_NAMES.USM}/balances`
  const response = await fetchRetry(endpoint)
  
  if (!response.ok) {
    throw new Error(`Failed to fetch reserves for USM`)
  }

  const data = await response.json()

  const usmTokens = getTokensMapArray().filter(token => usmEnabledTokens.includes(token.name) && token.enabled)
  
  const reserves: Record<string, { formatted: number; unformatted: number; }> = {}

  usmTokens.forEach(token => {
    reserves[token.name] = { formatted: 0, unformatted: 0 }
  })

  const stxToken = tokensMap[TokenName.STX]

  if (usmTokens.some(token => token === stxToken)) {
    reserves[TokenName.STX].formatted = formatTokenValue(data.stx.balance, stxToken)
    reserves[TokenName.STX].unformatted = formatValue(data.stx.balance)
  }

  Object.keys(data.fungible_tokens).forEach(token => {
    usmTokens.forEach(availToken => {
      if (token === getTokenContract(availToken, STACKS_NETWORK_TYPE)) {
        reserves[availToken.name].formatted = formatTokenValue(data.fungible_tokens[token].balance, availToken)
        reserves[availToken.name].unformatted = formatValue(data.fungible_tokens[token].balance)
      }
    })
  })
  
  return reserves
}

export const getUSMSwapAmounts = (usm: USM, inputToken: Token, outputToken: Token, amount: number): SwapAmounts => {
  const usmInputToken = usm?.tokens[inputToken.name]
  const usmOutputToken = usm?.tokens[outputToken.name]

  const swapFee = ((inputToken.name === TokenName.UWU ? usmOutputToken?.xFee : usmInputToken?.yFee) / 10000) ?? 0

  if (isNaN(amount) || amount === 0 || amount === null || amount === '' || !usmEnabledTokens.includes(inputToken.name) || !usmEnabledTokens.includes(outputToken.name)) {
    return {
      amount: amount,
      est: {
        formatted: 0,
        unformatted: 0
      },
      min: {
        formatted: 0,
        unformatted: 0
      },
      swapFee: (swapFee * 10000),
      priceImpact: 0,
      slippageTolerance: 0
    }
  }

  if (!getIsUSMTokenApproved(usm, inputToken) || !getIsUSMTokenApproved(usm, outputToken)) {
    return {
      amount: amount,
      est: {
        formatted: 0,
        unformatted: 0
      },
      min: {
        formatted: 0,
        unformatted: 0
      },
      swapFee: 0,
      priceImpact: 0,
      slippageTolerance: 0
    }
  }

  const scaledAmount = amount * inputToken.decimals

  if (inputToken.name === TokenName.UWU) {
    const fee = (scaledAmount * swapFee)
    const factor = usmOutputToken?.factor
    const rate = usmOutputToken?.rate

    const estDy = formatValue(((scaledAmount - fee) * factor) / rate, 0)
    const formattedEstDy = formatTokenValue(estDy, outputToken)

    return {
      amount: amount,
      est: {
        formatted: formattedEstDy,
        unformatted: estDy
      },
      min: {
        formatted: formattedEstDy,
        unformatted: estDy
      },
      swapFee: (swapFee * 10000),
      priceImpact: 0,
      slippageTolerance: 0
    }
  }

  if (outputToken.name === TokenName.UWU) {
    const fee = (scaledAmount * swapFee)
    const factor = usmInputToken?.factor
    const rate = usmInputToken?.rate

    const estDx = formatValue(((scaledAmount - fee) * rate) / factor, 0)
    const formattedEstDx = formatTokenValue(estDx, outputToken)
    
    return {
      amount: amount,
      est: {
        formatted: formattedEstDx,
        unformatted: estDx
      },
      min: {
        formatted: formattedEstDx,
        unformatted: estDx
      },
      swapFee: (swapFee * 10000),
      priceImpact: 0,
      slippageTolerance: 0
    }
  }

  return {
    amount: amount,
    est: {
      formatted: 0,
      unformatted: 0
    },
    min: {
      formatted: 0,
      unformatted: 0
    },
    swapFee: 0,
    priceImpact: 0,
    slippageTolerance: 0
  }
}

export const useUSMSwap = () => {
  const { doContractCall } = stacksConnect()

  const handleUSMSwap = useCallback(
    async (user: User, options: SwapTransaction) => {
      const inputToken = options.tokens.input
      const outputToken = options.tokens.output

      const inputTokenContractDetails = inputToken.contract[STACKS_NETWORK_TYPE]
      const outputTokenContractDetails = outputToken.contract[STACKS_NETWORK_TYPE]

      if (!inputTokenContractDetails?.address || !inputTokenContractDetails?.name) {
        throw new Error(`Contract details for Token '${inputToken.name}' are missing`)
      }

      if (!outputTokenContractDetails?.address || !outputTokenContractDetails?.name) {
        throw new Error(`Contract details for Token '${outputToken.name}' are missing`)
      }

      const token = inputToken.name === TokenName.UWU ? outputToken : inputToken
      const tokenContract = getTokenContract(token, STACKS_NETWORK_TYPE, true, token.name === TokenName.STX ? ProtocolName.USM : null)!

      const functionName = inputToken.name === TokenName.UWU ? USM_FUNCTION_NAMES.SWAP_X_FOR_Y : USM_FUNCTION_NAMES.SWAP_Y_FOR_X

      const scaledAmount = formatValue(options.amounts.amount * inputToken.decimals, 0)
      const minReceived = options.amounts.min.unformatted
      const minReceivedAdjusted = formatValue(options.amounts.min.unformatted - 1, 0)

      const postConditions = [
        createUserPostCondition(
          user?.address,
          FungibleConditionCode.Equal,
          scaledAmount,
          inputToken
        ),
        createContractPostCondition(
          UWU_CONTRACT_ADDRESSES.DEPLOYER,
          UWU_CONTRACT_NAMES.USM,
          FungibleConditionCode.GreaterEqual,
          minReceived,
          outputToken
        )
      ]

      const tx = await new Promise((resolve, reject) => {
        doContractCall({
          contractAddress: UWU_CONTRACT_ADDRESSES.DEPLOYER,
          contractName: UWU_CONTRACT_NAMES.USM,
          functionName: functionName,
          functionArgs: [
            principalCV(tokenContract),
            uintCV(scaledAmount),
            uintCV(minReceivedAdjusted)
          ],
          postConditionMode: PostConditionMode.Deny,
          postConditions: postConditions,
          network: STACKS_NETWORK,
          onFinish: (result) => {
            resolve(result)
          },
          onCancel: (error) => {
            reject(error)
          }
        })
      })
      
      return tx
    },
    [doContractCall]
  )

  return { handleUSMSwap }
}