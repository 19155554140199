import { AssetInfo, createAssetInfo } from '@stacks/transactions'
import { STACKS_NETWORK_TYPE } from '../config/stacks'
import { lpTokensMap, tokensMap, wrappedTokensMap } from '../constants/tokens'
import { TokenName } from '../enums/tokens'
import { LPToken, Token } from '../interfaces/tokens'
import { ProtocolName } from '../enums/protocols'

export const getTokensMapArray = () => {
  return Object.values(tokensMap)
}

export const getLPTokensMapArray = () => {
  return Object.values(lpTokensMap)
}

export const getTokenContract = (token: Token, network: 'mainnet' | 'testnet', limited: boolean = false, protocolName?: ProtocolName | null) => {
  let contract

  if (!protocolName) {
    contract = token.contract[network]
  } else {
    const wrappedContract = wrappedTokensMap?.[protocolName]?.[token.name]?.contract?.[network]
    
    contract = wrappedContract ?? token.contract[network]
  }

  if (!contract) {
    return null
  }

  let contractString = `${contract.address}.${contract.name}`

  if (!limited && contract.asset) {
    contractString += `::${contract.asset}`
  }
  
  return contractString
}

export const getLPTokenContract = (lpToken: LPToken, network: 'mainnet' | 'testnet', limited: boolean = false) => {
  const contract = lpToken.lptContract[network]

  if (!contract) {
    return null
  }

  let contractString = `${contract.address}.${contract.name}`

  if (!limited && contract.asset) {
    contractString += `::${contract.asset}`
  }

  return contractString
}

export const getTokenAssetInfo = (token: Token): AssetInfo | string => {
  if (token.name !== TokenName.STX) {
    const tokenContractDetails = token.contract[STACKS_NETWORK_TYPE]

    if (!tokenContractDetails?.address || !tokenContractDetails?.name) {
      throw new Error(`Contract details for Token '${token.name}' are missing`)
    }

    return createAssetInfo(
      tokenContractDetails.address,
      tokenContractDetails.name,
      tokenContractDetails.asset
    )
  }

  return ''
}

export const getTokenByContract = (network: 'mainnet' | 'testnet', contractAddress: string) => {
  const tokens = Object.entries(tokensMap).find(
    ([, tokenData]) => {
      const address = tokenData.contract[network]?.address
      const name = tokenData.contract[network]?.name

      return `${address}.${name}` === contractAddress
    }
  )

  return tokens ? tokens[1] : null
}