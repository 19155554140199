import React, { useState, useContext, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
  uintCV,
  standardPrincipalCV,
  makeStandardSTXPostCondition,
  makeContractSTXPostCondition,
  PostConditionMode,
  FungibleConditionCode,
  callReadOnlyFunction,
  makeStandardFungiblePostCondition,
  createAssetInfo,
  makeContractFungiblePostCondition,
  stringAsciiCV,
  bufferCV,
  bufferCVFromString,
  noneCV
} from "@stacks/transactions";
import { stringCV } from "@stacks/transactions/dist/clarity/types/stringCV";
import { principalCV } from "@stacks/transactions/dist/clarity/types/principalCV";
import { StacksMainnet, StacksTestnet } from "@stacks/network";
import { useConnect as syConnect } from "@stacks/connect-react";
import { convertMicrotokensToTokens, formatTokens, numberWithCommas, formatTokensDisplay } from "../lib/utils.js";

import earnusr from "../img/earn-usr.png";
import warn from "../img/warn.png";
import swap from "../img/switch.png";
import loader from "../img/loader.svg";
import stxtoken from "../img/stx-token-logo.svg";
import uwutoken from "../img/uwu-token-logo-v2.png";
import lpttoken from "../img/stx-uwu-lpt.png";
import stxuwulp from "../img/stx-uwu-lp.png";

import { NotificationContext } from "../context/NotificationContext.js";

import LoaderComponent from "./LoaderComponent.js";
import DefaultPopupComponent from "../popups/DefaultPopupComponent.js";
import ConfirmationPopupComponent from "../popups/ConfirmationPopupComponent.js";
import LoaderOverlayComponent from "./LoaderOverlayComponent.js";
import ActivityPopupComponent from "../popups/ActivityPopupComponent.js";

import { getUserTransactions } from '../infra/services/userService.ts'

import { TokenName } from '../enums/tokens.ts'

export default function ProvideLiquidityComponent() {
  const [session, user] = useOutletContext();
  const { addNotification } = useContext(NotificationContext);

  document.title = "STX-UWU LP | UWU Protocol";

  const { doContractCall } = syConnect();

  const [primaryInput, setPrimaryInput] = useState("");
  const [secondaryInput, setSecondaryInput] = useState("");
  const [withdrawInput, setWithdrawInput] = useState("");

  const [lpManager, setLPManager] = useState("deposit");

  const [showLoader, setShowLoader] = useState(false);
  const [activityPopup, setActivityPopup] = useState(false);

  const [showDefaultPopup, setShowDefaultPopup] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const [pending, setPending] = useState([]);

  const [defaultPopupOptions, setDefaultPopupOptions] = useState({
    windowTitle: null,
    title: null,
    description: null,
    tx: null
  });

  const [confirmPopupOptions, setConfirmPopupOptions] = useState({
    content: [],
    call: null
  });

  const checkCharacter = (e) => {
    if (e.key === "Backspace" || e.key === "Delete" || e.key === "Tab" || e.key === "ArrowLeft" || e.key === "ArrowRight") {
      return;
    };

    if (e.key === "." && e.target.value.split(".").length - 1 === 0) {
      return;
    };

    if (!/^\d+$/.test(e.key)) {
      e.preventDefault();
    };
  };

  useEffect(() => {
    if (session && user) {

      fetchPending();
    };
  }, [user, session]);

  function fetchPending() {
    getUserTransactions(user?.address, true).then(res => {
      const filteredTransactions = res.filter(transaction => {
        const { contract_call } = transaction;
        const { function_name, function_args } = contract_call;
        const args = getArgumentsAsObject(function_args);

        const names = ["add-to-position", "reduce-position"];

        return names.includes(function_name);
      });

      setPending(filteredTransactions);
    });
  };

  function checkCallRequirements() {
    const pI = parseFloat(primaryInput);
    const sI = parseFloat(secondaryInput);
    const wI = parseFloat(withdrawInput);

    if (lpManager === "deposit") {
      if (pI > 0 && pI <= formatTokens(user?.balances[TokenName.STX]) && (formatTokens(user?.balances[TokenName.STX]) - pI) >= 1 && sI > 0 && sI <= formatTokens(user?.balances[TokenName.UWU])) {
        return true;
      };
    };

    if (lpManager === "withdraw") {
      if (wI > 0 && wI <= formatTokens(user?.balances.lto["0"]) && wI >= user?.balances.lto["0"] * 0.01) {
        return true;
      };
    };

    return false;
  };

  function uintToNumber(uintString) {
    return parseInt(uintString.slice(1));
  };

  function getArgumentsAsObject(args) {
    return args.reduce((acc, arg) => {
      acc[arg.name] = arg.type === "uint" ? uintToNumber(arg.repr) : arg.repr;
      return acc;
    }, {});
  };

  function createTxCall() {
    const pI = parseFloat(primaryInput);
    const sI = parseFloat(secondaryInput);
    const wI = parseFloat(withdrawInput);

    if (lpManager === "deposit" && checkCallRequirements()) {
      setConfirmPopupOptions(prev => ({
        ...prev,
        desc: "If you confirm this transaction, the following will changes will be made to your account.",
        content:
          [
            [
              {
                title: "You Send (est.)",
                item: `${formatTokensDisplay(pI) + " STX"}`
              },
              {
                title: "You Send (est.)",
                item: `${formatTokensDisplay(sI) + " UWU"}`
              },
              {
                title: "You Receive",
                item: `${formatTokensDisplay((pI * session?.pools["0"].lpToken.shares.formatted) / session?.pools["0"].balances[TokenName.STX].formatted)} LTO`
              }
            ],
            [
              {
                title: "Your Deposit",
                prev: `$${formatTokensDisplay(session?.pools["0"].balances.usd * (user?.balances.lto["0"] / session?.pools["0"].lpToken.shares.formatted))}`,
                new: `$${formatTokensDisplay((pI * session?.prices[TokenName.STX] + sI * session?.prices[TokenName.UWU]) + (session?.pools["0"].balances.usd * (user?.balances.lto["0"] / session?.pools["0"].lpToken.shares.formatted)))}`
              },
              {
                title: "Your Pool Share",
                prev: `${formatTokensDisplay(user?.balances.lto["0"] / session?.pools["0"].lpToken.shares.formatted * 100)}%`,
                new: `${formatTokensDisplay(((((pI * session?.prices[TokenName.STX]) + (sI * session?.prices[TokenName.UWU])) / (session?.pools["0"].balances.usd + (pI * session?.prices[TokenName.STX]) + (sI * session?.prices[TokenName.UWU]))) * 100 + (user?.balances.lto["0"] / session?.pools["0"].lpToken.shares.formatted) * 100))}%`
              }
            ],
            [
              {
                title: "Slippage Tolerance",
                item: "1.00%"
              }
            ]
          ],
        call: (() => callDepositLP(pI, sI))
      }));
      setShowConfirmPopup(true);
    };

    if (lpManager === "withdraw" && checkCallRequirements()) {
      setConfirmPopupOptions(prev => ({
        ...prev,
        desc: "If you confirm this transaction, the following will changes will be made to your account.",
        content:
          [
            [
              {
                title: "You Send",
                item: `${formatTokensDisplay(wI) + " LTO"}`
              },
              {
                title: "You Receive (est.)",
                item: `${formatTokensDisplay((session?.pools["0"].balances.usd * (wI / session?.pools["0"].lpToken.shares.formatted) * 0.5) / session?.prices[TokenName.STX])} STX`
              },
              {
                title: "You Receive (est.)",
                item: `${formatTokensDisplay((session?.pools["0"].balances.usd * (wI / session?.pools["0"].lpToken.shares.formatted) * 0.5) / session?.prices[TokenName.UWU])} UWU`
              }
            ],
            [
              {
                title: "Your Deposit",
                prev: `$${formatTokensDisplay(session?.pools["0"].balances.usd * (user?.balances.lto["0"] / session?.pools["0"].lpToken.shares.formatted))}`,
                new: `$${formatTokensDisplay(session?.pools["0"].balances.usd * ((user?.balances.lto["0"] - wI) / session?.pools["0"].lpToken.shares.formatted))}`
              },
              {
                title: "Your Pool Share",
                prev: `${formatTokensDisplay(user?.balances.lto["0"] / session?.pools["0"].lpToken.shares.formatted * 100)}%`,
                new: `${formatTokensDisplay((user?.balances.lto["0"] - wI) / session?.pools["0"].lpToken.shares.formatted * 100)}%`
              }
            ]
          ],
        call: (() => callWithdrawLP(wI))
      }));
      setShowConfirmPopup(true);
    };
  };

  async function callDepositLP(primary, secondary) {
    setShowLoader(true);
    await doContractCall({
      contractAddress: "SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275",
      contractName: "stackswap-swap-v5k",
      functionName: "add-to-position",
      functionArgs: [principalCV("SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.wstx-token-v4a"), principalCV("SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-token-v1-1-0"), principalCV("SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.liquidity-token-v5kglq1fqfp"), uintCV(primary * 1000000), uintCV(secondary * 1000000)],
      postConditionMode: PostConditionMode.Deny,
      postConditions: [makeStandardSTXPostCondition(user?.address, FungibleConditionCode.LessEqual, primary * 1010000), makeStandardFungiblePostCondition(user?.address, FungibleConditionCode.LessEqual, secondary * 1010000, createAssetInfo("SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4", "uwu-token-v1-1-0", "uwu"))],
      network: new StacksMainnet(),
      onFinish: (result) => {
        addNotification({
          id: Date.now(),
          title: "LP Deposit Confirmation",
          description: `You deposited ${formatTokensDisplay(primary)} STX and ${formatTokensDisplay(secondary)} UWU into your STX-UWU LP position. View the transaction details by clicking here.`,
          date: new Date(),
          link: `https://explorer.hiro.so/txid/${result.txId}`,
          read: false
        });
        setDefaultPopupOptions(prev => ({
          ...prev,
          tx: result.txId,
          windowTitle: "LP Deposit Confirmation",
          title: "LP Deposit Confirmation",
          description: "Your transaction was sent. Please allow 10-30 minutes for the transaction to be completed."
        }));
        setShowConfirmPopup(false);
        setShowDefaultPopup(true);
        fetchPending();
      },
    });
    setShowLoader(false);
  };

  async function callWithdrawLP(amount) {
    setShowLoader(true);
    await doContractCall({
      contractAddress: "SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275",
      contractName: "stackswap-swap-v5k",
      functionName: "reduce-position",
      functionArgs: [principalCV("SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.wstx-token-v4a"), principalCV("SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-token-v1-1-0"), principalCV("SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.liquidity-token-v5kglq1fqfp"), uintCV(Math.max(1, (amount / user?.balances.lto["0"] * 100).toFixed()))],
      postConditionMode: PostConditionMode.Deny,
      postConditions: [makeStandardFungiblePostCondition(user?.address, FungibleConditionCode.Greater, 0, createAssetInfo("SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275", "liquidity-token-v5kglq1fqfp", "liquidity-token")), makeContractFungiblePostCondition("SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275", "liquidity-token-v5kglq1fqfp", FungibleConditionCode.Greater, 0, createAssetInfo("SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4", "uwu-token-v1-1-0", "uwu")), makeContractSTXPostCondition("SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275", "liquidity-token-v5kglq1fqfp", FungibleConditionCode.Greater, 0)],
      network: new StacksMainnet(),
      onFinish: (result) => {
        addNotification({
          id: Date.now(),
          title: "LP Withdrawal Confirmation",
          description: `You withdrew ${formatTokensDisplay(Math.max(1, (amount / user?.balances.lto["0"] * 100).toFixed()))}% of your STX-UWU LP position. View the transaction details by clicking here.`,
          date: new Date(),
          link: `https://explorer.hiro.so/txid/${result.txId}`,
          read: false
        });
        setDefaultPopupOptions(prev => ({
          ...prev,
          tx: result.txId,
          windowTitle: "LP Withdrawl Confirmation",
          title: "LP Withdrawal Confirmation",
          description: "Your transaction was sent. Please allow 10-30 minutes for the transaction to be completed."
        }));
        setShowConfirmPopup(false);
        setShowDefaultPopup(true);
        fetchPending();
      },
    });
    setShowLoader(false);
  };

  return (
    <>
      {activityPopup && <ActivityPopupComponent show={(e) => setActivityPopup(e)} address={user?.address} />}
      {showLoader && <LoaderOverlayComponent />}
      {showDefaultPopup && <DefaultPopupComponent show={(e) => setShowDefaultPopup(e)} options={defaultPopupOptions} />}
      {showConfirmPopup && <ConfirmationPopupComponent show={(e) => setShowConfirmPopup(e)} options={confirmPopupOptions} />}
      {(session && user) ?
      <div className="core" style={{ marginTop: "auto" }}>
        <ReactTooltip className="defaultTooltip" effect="solid" multiline={true} arrowColor="#000000" padding="10px 12px" />
        <div className="vaultHeader vaultHeaderPadding">
          <div className="vaultHeaderTitle" style={{ justifyContent: "inherit" }}>
            <a href="/earn" style={{ lineHeight: "0", marginRight: "0.5rem", fontSize: "1.25em", marginBottom: "0.1rem" }}>{`<-`}</a>
            <h1 style={{ marginBottom: "0" }}>STX-UWU LP</h1>
          </div>
          <div className="vaultHeaderDivider"></div>
        </div>
        {pending.length > 0 && <div className="pendingTxnHome pendingTxnHomeMobile" style={{ marginBottom: "15px" }}>
          <img src={loader} draggable="false" />
          <div>
            <h1>You have {pending.length} pending transaction{pending.length > 1 && "s"} via the STX-UWU LP</h1>
            <h2>Please allow 10-30 minutes for the transaction{pending.length > 1 && "s"} to be completed.</h2>
          </div>
          <a onClick={() => setActivityPopup(true)} className="smallButton">View Recent Activity</a>
        </div>}
        <div className="grid" style={{ gridTemplateColumns: "1.125fr 0.9fr" }}>
          <div className="earnDiv" style={{ maxWidth: "510px", height: "min-content" }}>
            <div className="earnDesc">
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "1.25rem" }}>
                <img className="lpTokenImg" draggable="false" src={stxuwulp} style={{ marginBottom: "0" }} />
                <a href="https://app.stackswap.org/pool" target="_blank" className="smallButton">View on Stackswap</a>
              </div>
              <h1>STX-UWU LP</h1>
              <div className="poolNotice">
                <h2>Volatile Pool</h2>
                <h2>0.30% Swap Fee</h2>
                <h2>Stackswap</h2>
              </div>
              <div style={{ margin: "25px -25px " }} className="borrowDivider"></div>
              <div className="poolOverviewGrid" style={{ marginBottom: "20px" }}>
                <div>
                  <h2>Your Deposit</h2>
                  <h1>${formatTokensDisplay(session?.pools["0"].balances.usd * (user?.balances.lto["0"] / session?.pools["0"].lpToken.shares.formatted))}</h1>
                </div>
                <div>
                  <h2>Your Pool Share</h2>
                  <h1>{formatTokensDisplay(user?.balances.lto["0"] / session?.pools["0"].lpToken.shares.formatted * 100)}%</h1>
                </div>
              </div>
              <h2 style={{ marginBottom: "0.5rem" }}>Pool Overview</h2>
              <div className="poolOverviewGrid" style={{ marginBottom: "0" }}>
                <div>
                  <h2>Total Deposited</h2>
                  <h1>${formatTokensDisplay(session?.pools["0"].balances.usd)}</h1>
                </div>
                <div>
                  <h2>Estimated APR</h2>
                  <h1>-</h1>
                </div>
                <div className="stabilityReservesDiv poolCompositionDiv" style={{ borderRadius: "10px" }}>
                  <h2 style={{ marginBottom: "1rem" }}>Pool Composition</h2>
                  <div style={{ marginBottom: "10px" }}>
                    <img draggable="false" src={stxtoken} />
                    <h1>STX</h1>
                    <h2>{formatTokensDisplay(session?.pools["0"].balances[TokenName.STX].formatted)} STX</h2>
                  </div>
                  <div>
                    <img draggable="false" src={uwutoken} />
                    <h1>UWU</h1>
                    <h2>{formatTokensDisplay(session?.pools["0"].balances[TokenName.UWU].formatted)} UWU</h2>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h2 style={{ marginBottom: "0" }}>Total Volume</h2>
                  <h1 style={{ margin: "15px 0" }}>-</h1>
                  <h3>Fees: ${formatTokensDisplay((session?.pools["0"].feeBalances[TokenName.STX].formatted * session?.prices[TokenName.STX] + session?.pools["0"].feeBalances[TokenName.UWU].formatted * session?.prices[TokenName.UWU])*6)}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="stabilityModuleSwapDiv" style={{ maxWidth: "450px" }}>
            <div className="overview borrowDisplay" style={{ height: "max-content" }}>
              <div className="overviewHeader manageVaultHeader">
                <div className="inner">
                  <h1 style={{ marginTop: "5px", marginBottom: "5px" }}>Manage Position</h1>
                </div>
                <div></div>
              </div>
              <div>
                <div className="manageVaultSelector" style={{ marginBottom: "20px" }}>
                  <div>
                    <a onClick={() => { setLPManager("deposit"); setWithdrawInput(""); }} className={`headerButton ${lpManager === "deposit" ? "active" : ""}`} style={{ marginRight: "5px" }}>Deposit</a>
                    <a onClick={() => { setLPManager("withdraw"); setPrimaryInput(""); setSecondaryInput(""); }} className={`headerButton ${lpManager === "withdraw" ? "active" : ""}`} style={{ marginLeft: "5px" }}>Withdraw</a>
                  </div>
                </div>
                <div className="borrowDivider"></div>
                <div className="borrowInnerDiv manageVaultInnerDiv">
                  {lpManager === "deposit" &&
                    <div>
                      <div className="borrowInputTitle">
                        <h1>Deposit STX</h1>
                        <h2 onClick={() => { setPrimaryInput(formatTokens(user?.balances[TokenName.STX])); setSecondaryInput(formatTokens((user?.balances[TokenName.STX] * session?.prices[TokenName.STX]) / session?.prices[TokenName.UWU])); }}>Balance: {formatTokensDisplay(user?.balances[TokenName.STX])} STX</h2>
                      </div>
                      <span className="borrowInput borrowInputPrice" style={{ marginBottom: "20px" }}><div style={{ width: "100%" }}><input inputmode="decimal" maxLength="10" type="text" placeholder="0.00 STX" onChange={(e) => { setPrimaryInput(e.target.value.toString().split(".").map((el, i) => i ? el.split("").slice(0, 2).join("") : el).join(".").replace(/^0+(?=\d)/, "")); setSecondaryInput(formatTokens((e.target.value * session?.prices[TokenName.STX]) / session?.prices[TokenName.UWU])); }} onKeyDown={(e) => checkCharacter(e)} value={primaryInput} /><h4>≈ ${formatTokensDisplay(primaryInput * session?.prices[TokenName.STX])}</h4></div><div className="swapOutputCurrency" style={{ marginRight: "15px" }}><img draggable="false" src={stxtoken} /><h1>STX</h1></div></span>
                      {user?.balances[TokenName.STX] < primaryInput && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-10px 20px 20px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>The amount entered exceeds your account balance</h1></div>}
                      {(user?.balances[TokenName.STX] - primaryInput) < 1 && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-10px 20px 20px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>The amount entered may not leave sufficient STX to pay for the transaction fee. Please enter a smaller amount</h1></div>}
                      <div className="dividerWithText" style={{ marginTop: "-10px", marginBottom: "10px" }}>
                        <div style={{ borderTopStyle: "dashed" }}></div>
                        <h1>+</h1>
                        <div style={{ borderTopStyle: "dashed" }}></div>
                      </div>
                      <div className="borrowInputTitle">
                        <h1>Deposit UWU</h1>
                        <h2 onClick={() => { setSecondaryInput(formatTokens(user?.balances[TokenName.UWU])); setPrimaryInput(formatTokens((user?.balances[TokenName.UWU] * session?.prices[TokenName.UWU]) / session?.prices[TokenName.STX])); }}>Balance: {formatTokensDisplay(user?.balances[TokenName.UWU])} UWU</h2>
                      </div>
                      <span className="borrowInput borrowInputPrice" style={{ marginBottom: "20px" }}><div style={{ width: "100%" }}><input inputmode="decimal" maxLength="10" type="text" placeholder="0.00 UWU" onChange={(e) => { setSecondaryInput(e.target.value.toString().split(".").map((el, i) => i ? el.split("").slice(0, 2).join("") : el).join(".").replace(/^0+(?=\d)/, "")); setPrimaryInput(formatTokens((e.target.value * session?.prices[TokenName.UWU]) / session?.prices[TokenName.STX])); }} onKeyDown={(e) => checkCharacter(e)} value={secondaryInput} /><h4>≈ ${formatTokensDisplay(secondaryInput * session?.prices[TokenName.UWU])}</h4></div><div className="swapOutputCurrency" style={{ marginRight: "15px" }}><img draggable="false" src={uwutoken} /><h1>UWU</h1></div></span>
                      {user?.balances[TokenName.UWU] < secondaryInput && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-10px 20px 20px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>The amount entered exceeds your account balance</h1></div>}
                      <div className="borrowOutputDetails" style={{ marginTop: "-5px" }}>
                        <h1>Pool Share</h1>
                        <h2>{formatTokensDisplay((((primaryInput * session?.prices[TokenName.STX]) + (secondaryInput * session?.prices[TokenName.UWU])) / (session?.pools["0"].balances.usd + (primaryInput * session?.prices[TokenName.STX]) + (secondaryInput * session?.prices[TokenName.UWU]))) * 100)}%</h2>
                        <h1>Total Deposited</h1>
                        <h2>${formatTokensDisplay((primaryInput * session?.prices[TokenName.STX]) + (secondaryInput * session?.prices[TokenName.UWU]))}</h2>
                      </div>
                    </div>
                  }
                  {lpManager === "withdraw" &&
                    <div>
                      <div style={{ border: "1px solid #c3c3c3", backgroundColor: "#f0f0f070", borderRadius: "10px", padding: "10px", margin: "25px 20px 15px", maxWidth: "400px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#838383" }}>By withdrawing LTO tokens, you'll receive your underlying assets in this pool, plus any accrued fees</h1></div>
                      <div className="borrowInputTitle">
                        <h1>Withdraw LTO</h1>
                        <h2 onClick={() => setWithdrawInput(formatTokens(user?.balances.lto["0"]))}>Balance: {formatTokensDisplay(user?.balances.lto["0"])} LTO</h2>
                      </div>
                      <span className="borrowInput borrowInputPrice" style={{ borderRadius: "10px 10px 0px 0px" }}><div style={{ width: "100%" }}><input inputmode="decimal" maxLength="10" type="text" placeholder="0.00 LTO" onChange={(e) => setWithdrawInput(e.target.value.toString().split(".").map((el, i) => i ? el.split("").slice(0, 2).join("") : el).join(".").replace(/^0+(?=\d)/, ""))} onKeyDown={(e) => checkCharacter(e)} value={withdrawInput} /><h4>≈ ${formatTokensDisplay(session?.pools["0"].balances.usd * (withdrawInput / session?.pools["0"].lpToken.shares.formatted))}</h4></div><div className="swapOutputCurrency" style={{ marginRight: "15px" }}><img draggable="false" src={lpttoken} /><h1>LTO</h1></div></span>
                      <div className="apiTable" style={{ borderTop: "none", borderRadius: "0px 0px 10px 10px", margin: "0px 20px 20px 20px" }}>
                        <a onClick={() => setWithdrawInput(formatTokens(Math.round((user?.balances.lto["0"] * 0.25) * 100) / 100))} className={`headerButton ${(formatTokens(withdrawInput) === formatTokens(Math.round((user?.balances.lto["0"] * 0.25) * 100) / 100) && user?.balances.lto["0"] > 0) && "active"}`}>25%</a>
                        <a onClick={() => setWithdrawInput(formatTokens(Math.round((user?.balances.lto["0"] * 0.50) * 100) / 100))} className={`headerButton ${(formatTokens(withdrawInput) === formatTokens(Math.round((user?.balances.lto["0"] * 0.50) * 100) / 100) && user?.balances.lto["0"] > 0) && "active"}`}>50%</a>
                        <a onClick={() => setWithdrawInput(formatTokens(Math.round((user?.balances.lto["0"] * 0.75) * 100) / 100))} className={`headerButton ${(formatTokens(withdrawInput) === formatTokens(Math.round((user?.balances.lto["0"] * 0.75) * 100) / 100) && user?.balances.lto["0"] > 0) && "active"}`}>75%</a>
                        <a onClick={() => setWithdrawInput(formatTokens(user?.balances.lto["0"]))} className={`headerButton ${(formatTokens(withdrawInput) === formatTokens(user?.balances.lto["0"]) && user?.balances.lto["0"] > 0) && "active"}`}>MAX</a>
                      </div>
                      {user?.balances.lto["0"] < withdrawInput && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-10px 20px 20px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>The amount entered exceeds your account balance</h1></div>}
                      {withdrawInput <= user?.balances.lto["0"] * 0.01 && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-10px 20px 20px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>You must withdraw at least 1% of your deposit</h1></div>}
                      <div className="borrowOutputDetails" style={{ marginTop: "-5px" }}>
                        <h1>Pool Share</h1>
                        <h2>{formatTokensDisplay((session?.pools["0"].balances.usd * (withdrawInput / session?.pools["0"].lpToken.shares.formatted)) / session?.pools["0"].balances.usd * 100)}%</h2>
                        <h1>Total Withdrawn</h1>
                        <h2>${formatTokensDisplay(session?.pools["0"].balances.usd * (withdrawInput / session?.pools["0"].lpToken.shares.formatted))} ({formatTokensDisplay(withdrawInput / user?.balances.lto["0"] * 100)}%)</h2>
                      </div>
                    </div>
                  }
                  {lpManager !== "overview" && <><div className="borrowDivider"></div>
                    <a onClick={() => createTxCall()} className="borrowButton" style={{ backgroundColor: checkCallRequirements() ? "#000000" : "#575757", cursor: checkCallRequirements() ? "pointer" : "default" }}>{lpManager === "deposit" ? (primaryInput && secondaryInput) ? "Deposit" : "Enter an Amount" : withdrawInput ? "Withdraw" : "Enter an Amount"}</a></>}
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
      : <LoaderComponent />}
    </>
  );
};