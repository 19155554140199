import { Alert } from '../interfaces/alert'

export const ALERT_TYPES: Record<string, Alert> = {
  WARNING: {
    fontColor: 'd88934',
    borderColor: 'f3dac2',
    backgroundColor: 'f3dac225'
  },
  NOTICE: {
    fontColor: '838383',
    borderColor: 'c3c3c3',
    backgroundColor: 'f0f0f070'
  },
  WARNING_POPUP: {
    fontColor: 'd88934',
    borderColor: 'f3dac2',
    backgroundColor: 'f3dac225',
    customMargin: '10px 0px 0px 0px'
  },
  NOTICE_POPUP: {
    fontColor: '838383',
    borderColor: 'c3c3c3',
    backgroundColor: 'f0f0f070',
    customMargin: '10px 0px 0px 0px'
  }
}

export const SWAP_ALERT_DESCRIPTIONS = {
  ENOUGH_STX_FOR_GAS: 'The amount entered may not leave sufficient STX to pay for the transaction fee. Please enter a smaller amount',
  EXCEEDS_BALANCE: 'The amount entered exceeds your account balance',
  INVALID_ROUTE: 'The specified trading pair is not available at this time',
  HIGH_PRICE_IMPACT: 'The amount received may be considerably less due to high price impact. Try swapping with a smaller amount',
  EXCEEDS_LIQUIDITY: 'The amount entered exceeds the available liquidity for this trading pair. Try swapping with a smaller amount',
  EXCEEDS_RESERVE: 'The amount entered exceeds the amount of ${symbol} in the Stability Module. Try swapping with a smaller amount',
  DIFFERENT_PRICE: 'The current price of ${symbol} through the Stability Module differs from the market price',
  ZERO_SLIPPAGE_TOLERANCE: 'Your slippage tolerance must be greater than 0.00%',
  HIGH_SLIPPAGE_TOLERANCE: 'Your swap may execute at an unfavorable exchange rate due to high slippage tolerance',
  MAXIMUM_SLIPPAGE_TOLERANCE: 'Your slippage tolerance cannot exceed 50.00%',
  NO_ACTIVE_LIQUIDITY_SOURCES: 'You must have at least one active liquidity source'
}