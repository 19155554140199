import React, { useState, useEffect } from "react";
import { PopupAnimation } from "../lib/animation";
import { trunc, timeSince, formatTokensDisplay } from "../lib/utils.js";


import checkmark from "../img/checkmark.png";
import unavailable from "../img/unavailable.png";
import loader from "../img/loader.svg";

import { getUserTransactions } from '../infra/services/userService.ts'
import { getTokenByContract } from '../utils/tokenUtils.ts';
import { STACKS_NETWORK_TYPE } from '../config/stacks.ts';
import { formatTokenValue } from '../utils/formattingUtils.ts';
import { tokensMap } from '../constants/tokens.ts';
import { TokenName } from '../enums/tokens.ts';

export default function ActivityPopupComponent({ show, address }) {
  const [visibleClass, setVisibleClass] = useState("");

  const [confirmed, setConfirmed] = useState([]);
  const [pending, setPending] = useState([]);

  useEffect(() => {
    setTimeout(() => setVisibleClass('visible'), 25);

    if(address) {
      getUserTransactions(address).then(res => setConfirmed(res));
      getUserTransactions(address, true).then(res => setPending(res));
    };
  }, []);

  useEffect(() => {
    if (visibleClass === "visible") {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    };
  }, [visibleClass]);
  
  const handleClosePopup = () => {
    setVisibleClass('');
    setTimeout(() => show(false), 300);
  };

  function uintToNumber(uintString) {
    return parseInt(uintString.slice(1));
  };

  function getArgumentsAsObject(args) {
    return args.reduce((acc, arg) => {
      acc[arg.name] = arg.type === "uint" ? uintToNumber(arg.repr) : arg.repr;
      return acc;
    }, {});
  };

  function formatTransactionTitle(transaction) {
    if (transaction.tx_type === "token_transfer") {
      if (transaction.token_transfer.recipient_address === address) {
        return "Received STX";
      } else {
        return "Sent STX";
      };
    };

    const { contract_call } = transaction;
    const { function_name, function_args } = contract_call;
    const args = getArgumentsAsObject(function_args);

    switch (function_name) {
      case "open-vault":
        return "Opened a Vault";

      case "collateralize-vault":
        return "Deposited Collateral";

      case "borrow-vault":
        return "Borrowed Debt";

      case "withdraw-vault":
        return "Withdrew Collateral";

      case "repay-vault":
        return "Repaid Debt";

      case "liquidate-vault":
        return "Started a Liquidation";

      case "purchase-vault":
        return "Purchased Collateral";

      case "transfer-vault":
        return "Transferred Ownership";

      case "close-vault":
        return "Closed a Vault";
      
      case "transfer":
        if (contract_call.contract_id === "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-token-v1-1-0") {
          if (args.sender.slice(1) === address) {
            return "Sent UWU";
          } else {
            return "Received UWU";
          };
        } else if (contract_call.contract_id === "SP2XD7417HGPRTREMKF748VNEQPDRR0RMANB7X1NK.token-susdt") {
          if (args.sender.slice(1) === address) {
            return "Sent sUSDT";
          } else {
            return "Received sUSDT";
          };
        } else if (contract_call.contract_id === "SP3Y2ZSH8P7D50B0VBTSX11S7XSG24M1VB9YFQA4K.token-aeusdc") {
          if (args.sender.slice(1) === address) {
            return "Sent aeUSDC";
          } else {
            return "Received aeUSDC";
          };
        } else if (contract_call.contract_id === "SP2TZK01NKDC89J6TA56SA47SDF7RTHYEQ79AAB9A.Wrapped-USD") {
          if (args.sender.slice(1) === address) {
            return "Sent xUSD";
          } else {
            return "Received xUSD";
          };
        } else if (contract_call.contract_id === "SP3DX3H4FEYZJZ586MFBS25ZW3HZDMEW92260R2PR.Wrapped-Bitcoin") {
          if (args.sender.slice(1) === address) {
            return "Sent xBTC";
          } else {
            return "Received xBTC";
          };
        } else if (contract_call.contract_id === "SP2XD7417HGPRTREMKF748VNEQPDRR0RMANB7X1NK.token-abtc") {
          if (args.sender.slice(1) === address) {
            return "Sent aBTC";
          } else {
            return "Received aBTC";
          };
        } else if (contract_call.contract_id === "SP102V8P0F7JX67ARQ77WEA3D3CFB5XW39REDT0AM.token-alex") {
          if (args.sender.slice(1) === address) {
            return "Sent ALEX";
          } else {
            return "Received ALEX";
          };
        } else if (contract_call.contract_id === "SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.stsw-token-v4a") {
          if (args.sender.slice(1) === address) {
            return "Sent STSW";
          } else {
            return "Received STSW";
          };
        } else if (contract_call.contract_id === "SP27BB1Y2DGSXZHS7G9YHKTSH6KQ6BD3QG0AN3CR9.vibes-token") {
          if (args.sender.slice(1) === address) {
            return "Sent VIBES";
          } else {
            return "Received VIBES";
          };
        } else if (contract_call.contract_id === "SP213KNHB5QD308TEESY1ZMX1BP8EZDPG4JWD0MEA.fari-token-mn") {
          if (args.sender.slice(1) === address) {
            return "Sent FARI";
          } else {
            return "Received FARI";
          };
        } else if (contract_call.contract_id === "SP3NE50GEXFG9SZGTT51P40X2CKYSZ5CC4ZTZ7A2G.welshcorgicoin-token") {
          if (args.sender.slice(1) === address) {
            return "Sent WELSH";
          } else {
            return "Received WELSH";
          };
        } else if (contract_call.contract_id === "SP1AY6K3PQV5MRT6R4S671NWW2FRVPKM0BR162CT6.leo-token") {
          if (args.sender.slice(1) === address) {
            return "Sent LEO";
          } else {
            return "Received LEO";
          };
        } else if (contract_call.contract_id === "SP1JFFSYTSH7VBM54K29ZFS9H4SVB67EA8VT2MYJ9.gus-token") {
          if (args.sender.slice(1) === address) {
            return "Sent GUS";
          } else {
            return "Received GUS";
          };
        } else if (contract_call.contract_id === "SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.liquidity-token-v5kglq1fqfp") {
          if (args.from.slice(1) === address) {
            return "Sent LTO";
          } else {
            return "Received LTO";
          };
        };

      case "swap-x-for-y":
        if (contract_call.contract_id === "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-stability-module-v1-1-4") {
          return `Swapped UWU for ${getTokenByContract(STACKS_NETWORK_TYPE, args['token-trait'].slice(1)).symbol}`;
        } else if (contract_call.contract_id === "SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.stackswap-swap-v5k") {
          return "Swapped STX for UWU";
        };
      
      case "swap-y-for-x":
        if (contract_call.contract_id === "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-stability-module-v1-1-4") {
          return `Swapped ${getTokenByContract(STACKS_NETWORK_TYPE, args['token-trait'].slice(1)).symbol} for UWU`;
        } else if (contract_call.contract_id === "SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.stackswap-swap-v5k") {
          return "Swapped UWU for STX";
        };
      
      case "add-to-position":
        return "Deposited into LP";

      case "reduce-position":
        return "Withdrew from LP";

      default:
        return "Unknown Transaction";
    };
  };

  function formatTransactionDesc(transaction) {
    if (transaction.tx_type === "token_transfer") {
      if (transaction.token_transfer.recipient_address === address) {
        return `You received ${formatTokensDisplay(transaction.token_transfer.amount / 1000000)} STX from ${trunc(transaction.token_transfer.recipient_address)}.`;
      } else {
        return `You sent ${formatTokensDisplay(transaction.token_transfer.amount / 1000000)} STX to ${trunc(transaction.token_transfer.recipient_address)}.`;
      };
    };

    if (transaction.tx_status.includes("abort")) {
      return `This transaction encountered an issue and was not confirmed as a result.`;
    };

    const { contract_call } = transaction;
    const { function_name, function_args } = contract_call;
    const args = getArgumentsAsObject(function_args);

    switch (function_name) {
      case "open-vault":
        return `You opened a new Vault with ${formatTokensDisplay(args.collateral / 1000000)} STX of collateral and ${formatTokensDisplay(args.debt / 1000000)} UWU as debt.`;

      case "collateralize-vault":
        return `You deposited ${formatTokensDisplay(args.amount / 1000000)} STX into Vault #${args.id}.`;

      case "borrow-vault":
        return `You borrowed ${formatTokensDisplay(args.amount / 1000000)} UWU from Vault #${args.id}.`;
      
      case "withdraw-vault":
        return `You withdrew ${formatTokensDisplay(args.amount / 1000000)} STX from Vault #${args.id}.`;

      case "repay-vault":
        return `You repaid ${formatTokensDisplay(args.amount / 1000000)} UWU of debt in Vault #${args.id}.`;
      
      case "liquidate-vault":
        return `You started the liquidation process for Vault #${args.id}.`;
      
      case "purchase-vault":
        return `You purchased ${formatTokensDisplay(args.amount / 1000000)} UWU worth of collateral from Vault #${args.id}.`;

      case "transfer-vault":
        return `You transferred ownership of Vault #${args.id} to ${trunc(args.account.slice(1))}.`;
      
      case "close-vault":
        return `You permanently closed Vault #${args.id}.`;

      case "transfer":
        if (contract_call.contract_id === "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-token-v1-1-0") {
          if (args.sender.slice(1) === address) {
            return `You sent ${formatTokensDisplay(args.amount / 1000000)} UWU to ${trunc(args.recipient.slice(1))}.`;
          } else {
            return `You received ${formatTokensDisplay(args.amount / 1000000)} UWU from ${trunc(args.sender.slice(1))}.`;
          };
        } else if (contract_call.contract_id === "SP2XD7417HGPRTREMKF748VNEQPDRR0RMANB7X1NK.token-susdt") {
          if (args.sender.slice(1) === address) {
            return `You sent ${formatTokensDisplay(args.amount / 100000000)} sUSDT to ${trunc(args.recipient.slice(1))}.`;
          } else {
            return `You received ${formatTokensDisplay(args.amount / 100000000)} sUSDT from ${trunc(args.sender.slice(1))}.`;
          };
        } else if (contract_call.contract_id === "SP3Y2ZSH8P7D50B0VBTSX11S7XSG24M1VB9YFQA4K.token-aeusdc") {
          if (args.sender.slice(1) === address) {
            return `You sent ${formatTokensDisplay(args.amount / 1000000)} aeUSDC to ${trunc(args.recipient.slice(1))}.`;
          } else {
            return `You received ${formatTokensDisplay(args.amount / 1000000)} aeUSDC from ${trunc(args.sender.slice(1))}.`;
          };
        } else if (contract_call.contract_id === "SP2TZK01NKDC89J6TA56SA47SDF7RTHYEQ79AAB9A.Wrapped-USD") {
          if (args.sender.slice(1) === address) {
            return `You sent ${formatTokensDisplay(args.amount / 100000000)} xUSD to ${trunc(args.recipient.slice(1))}.`;
          } else {
            return `You received ${formatTokensDisplay(args.amount / 100000000)} xUSD from ${trunc(args.sender.slice(1))}.`;
          };
        } else if (contract_call.contract_id === "SP3DX3H4FEYZJZ586MFBS25ZW3HZDMEW92260R2PR.Wrapped-Bitcoin") {
          if (args.sender.slice(1) === address) {
            return `You sent ${formatTokensDisplay(args.amount / 100000000, 8)} xBTC to ${trunc(args.recipient.slice(1))}.`;
          } else {
            return `You received ${formatTokensDisplay(args.amount / 100000000, 8)} xBTC from ${trunc(args.sender.slice(1))}.`;
          };
        } else if (contract_call.contract_id === "SP2XD7417HGPRTREMKF748VNEQPDRR0RMANB7X1NK.token-abtc") {
          if (args.sender.slice(1) === address) {
            return `You sent ${formatTokensDisplay(args.amount / 100000000, 8)} aBTC to ${trunc(args.recipient.slice(1))}.`;
          } else {
            return `You received ${formatTokensDisplay(args.amount / 100000000, 8)} aBTC from ${trunc(args.sender.slice(1))}.`;
          };
        } else if (contract_call.contract_id === "SP102V8P0F7JX67ARQ77WEA3D3CFB5XW39REDT0AM.token-alex") {
          if (args.sender.slice(1) === address) {
            return `You sent ${formatTokensDisplay(args.amount / 1000000)} ALEX to ${trunc(args.recipient.slice(1))}.`;
          } else {
            return `You received ${formatTokensDisplay(args.amount / 1000000)} ALEX from ${trunc(args.sender.slice(1))}.`;
          };
        } else if (contract_call.contract_id === "SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.stsw-token-v4a") {
          if (args.sender.slice(1) === address) {
            return `You sent ${formatTokensDisplay(args.amount / 1000000)} STSW to ${trunc(args.recipient.slice(1))}.`;
          } else {
            return `You received ${formatTokensDisplay(args.amount / 1000000)} STSW from ${trunc(args.sender.slice(1))}.`;
          };
        } else if (contract_call.contract_id === "SP27BB1Y2DGSXZHS7G9YHKTSH6KQ6BD3QG0AN3CR9.vibes-token") {
          if (args.sender.slice(1) === address) {
            return `You sent ${formatTokensDisplay(args.amount / 100000000)} VIBES to ${trunc(args.recipient.slice(1))}.`;
          } else {
            return `You received ${formatTokensDisplay(args.amount / 100000000)} VIBES from ${trunc(args.sender.slice(1))}.`;
          };
        } else if (contract_call.contract_id === "SP213KNHB5QD308TEESY1ZMX1BP8EZDPG4JWD0MEA.fari-token-mn") {
          if (args.sender.slice(1) === address) {
            return `You sent ${formatTokensDisplay(args.amount / 100000000)} FARI to ${trunc(args.recipient.slice(1))}.`;
          } else {
            return `You received ${formatTokensDisplay(args.amount / 100000000)} FARI from ${trunc(args.sender.slice(1))}.`;
          };
        } else if (contract_call.contract_id === "SP3NE50GEXFG9SZGTT51P40X2CKYSZ5CC4ZTZ7A2G.welshcorgicoin-token") {
          if (args.sender.slice(1) === address) {
            return `You sent ${formatTokensDisplay(args.amount / 1000000)} WELSH to ${trunc(args.recipient.slice(1))}.`;
          } else {
            return `You received ${formatTokensDisplay(args.amount / 1000000)} WELSH from ${trunc(args.sender.slice(1))}.`;
          };
        } else if (contract_call.contract_id === "SP1AY6K3PQV5MRT6R4S671NWW2FRVPKM0BR162CT6.leo-token") {
          if (args.sender.slice(1) === address) {
            return `You sent ${formatTokensDisplay(args.amount / 1000000)} LEO to ${trunc(args.recipient.slice(1))}.`;
          } else {
            return `You received ${formatTokensDisplay(args.amount / 1000000)} LEO from ${trunc(args.sender.slice(1))}.`;
          };
        } else if (contract_call.contract_id === "SP1JFFSYTSH7VBM54K29ZFS9H4SVB67EA8VT2MYJ9.gus-token") {
          if (args.sender.slice(1) === address) {
            return `You sent ${formatTokensDisplay(args.amount / 1000000)} GUS to ${trunc(args.recipient.slice(1))}.`;
          } else {
            return `You received ${formatTokensDisplay(args.amount / 1000000)} GUS from ${trunc(args.sender.slice(1))}.`;
          };
        } else if (contract_call.contract_id === "SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.liquidity-token-v5kglq1fqfp") {
          if (args.from.slice(1) === address) {
            return `You sent ${formatTokensDisplay(args.amount / 1000000)} LTO to ${trunc(args.to.slice(1))}.`;
          } else {
            return `You received ${formatTokensDisplay(args.amount / 1000000)} LTO from ${trunc(args.from.slice(1))}.`;
          };
        };

      case "swap-x-for-y":
        if (contract_call.contract_id === "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-stability-module-v1-1-4") {
          const outputToken = getTokenByContract(STACKS_NETWORK_TYPE, args['token-trait'].slice(1))

          return `You swapped ${formatTokenValue(args.amount, tokensMap[TokenName.UWU])} UWU for ${formatTokenValue(args['min-dx'] + 1, outputToken)} ${outputToken.symbol}.`;
        } else if (contract_call.contract_id === "SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.stackswap-swap-v5k") {
          const matches = transaction?.tx_result?.repr.match(/u\d+/g);
          if (matches && matches.length >= 2) {
            return `You swapped ${formatTokensDisplay(args.dx / 1000000)} STX for ${formatTokensDisplay(matches[1].slice(1) / 1000000)} UWU.`;
          } else {
            return `You swapped ${formatTokensDisplay(args.dx / 1000000)} STX for ${formatTokensDisplay(args["min-dy"] / 1000000)} UWU.`;
          };
        };
      
      case "swap-y-for-x":
        if (contract_call.contract_id === "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4.uwu-stability-module-v1-1-4") {
          const inputToken = getTokenByContract(STACKS_NETWORK_TYPE, args['token-trait'].slice(1));
          
          return `You swapped ${formatTokenValue(args.amount, inputToken)} ${inputToken.symbol} for ${formatTokenValue(args['min-dx'] + 1, tokensMap[TokenName.UWU])} UWU.`;
        } else if (contract_call.contract_id === "SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.stackswap-swap-v5k") {
          const matches = transaction?.tx_result?.repr.match(/u\d+/g);
          if (matches && matches.length >= 2) {
            return `You swapped ${formatTokensDisplay(args.dy / 1000000)} UWU for ${formatTokensDisplay(matches[0].slice(1) / 1000000)} STX.`;
          } else {
            return `You swapped ${formatTokensDisplay(args.dy / 1000000)} UWU for ${formatTokensDisplay(args["min-dx"] / 1000000)} STX.`;
          };
        };
      
      case "add-to-position":
        return `You deposited ${formatTokensDisplay(args.x / 1000000)} STX and ${formatTokensDisplay(args.y / 1000000)} UWU into your STX-UWU LP position.`;
      
      case "reduce-position":
        return `You withdrew ${formatTokensDisplay(args.percent)}% of your STX-UWU LP position.`;

      default:
        return "This is an unknown transaction.";
    };
  };

  return (
    <PopupAnimation visible={visibleClass} onClose={handleClosePopup}>
      <div className="popupInterface">
        <div className="popupHeader">
          <h1>Recent Activity</h1>
          <svg onClick={handleClosePopup} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M6 6L18 18" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
        </div>
        <div className="activityView" style={{ paddingTop: "15px" }}>
          {pending.length > 0 && <h2 style={{ marginTop: "0", marginBottom: "0.5rem" }}>In Progress</h2>}
          {pending.map(transaction => 
            <a key={transaction.tx_id} className="activityItem noSelect" href={`https://explorer.hiro.so/txid/${transaction.tx_id}?chain=mainnet`} target="_blank">
              <img draggable="false" src={loader} />
              <div>
                <h1>{formatTransactionTitle(transaction)}</h1>
                <h2>{formatTransactionDesc(transaction)}</h2>
                <h2 style={{ color: "#575757" }}>Pending • {timeSince(transaction.receipt_time)}</h2>
              </div>
            </a>
          )}
          {confirmed.length > 0 && <h2 style={{ marginTop: pending.length > 0 ? "10px" : "0", marginBottom: "0.5rem" }}>Complete</h2>}
          {confirmed.map(transaction =>
            <a key={transaction.tx_id} className="activityItem noSelect" href={`https://explorer.hiro.so/txid/${transaction.tx_id}?chain=mainnet`} target="_blank">
              <img draggable="false" src={transaction.tx_status === "success" ? checkmark : unavailable} style={{ width: "30px", padding: "0" }} />
              <div>
                <h1>{formatTransactionTitle(transaction)}</h1>
                <h2>{formatTransactionDesc(transaction)}</h2>
                <h2 style={{ color: "#575757" }}>{transaction.tx_status === "success" ? "Confirmed" : "Failed"} • {timeSince(transaction.burn_block_time)}</h2>
              </div>
            </a>
          )}
          {(confirmed.length === 0 && pending.length === 0) && <h2 style={{ margin: "auto" }}>You have no recent activity</h2>}
        </div>
      </div>
    </PopupAnimation>
  );
};