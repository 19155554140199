import React, { useState, useRef, useContext, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import {
  uintCV,
  standardPrincipalCV,
  makeStandardSTXPostCondition,
  makeContractSTXPostCondition,
  PostConditionMode,
  FungibleConditionCode,
  callReadOnlyFunction,
  makeStandardFungiblePostCondition,
  createAssetInfo,
  makeContractFungiblePostCondition,
  stringAsciiCV,
  bufferCV,
  bufferCVFromString,
  noneCV
} from "@stacks/transactions";
import { stringCV } from "@stacks/transactions/dist/clarity/types/stringCV";
import { principalCV } from "@stacks/transactions/dist/clarity/types/principalCV";
import { StacksMainnet, StacksTestnet } from "@stacks/network";
import { useConnect as syConnect } from "@stacks/connect-react";

import { fetchVault, fetchLiquidations } from "../lib/api.js";
import { trunc, convertMicrotokensToTokens, formatTokens, formatTokensDisplay, numberWithCommas } from "../lib/utils.js";

import earnliq from "../img/earn-liq.png";
import warn from "../img/warn.png";
import loader from "../img/loader.svg";
import uwutoken from "../img/uwu-token-logo-v2.png";

import LoaderComponent from "./LoaderComponent.js";
import LoaderOverlayComponent from "./LoaderOverlayComponent.js";
import DefaultPopupComponent from "../popups/DefaultPopupComponent.js";
import ConfirmationPopupComponent from "../popups/ConfirmationPopupComponent.js";
import ActivityPopupComponent from "../popups/ActivityPopupComponent.js";

import { NotificationContext } from "../context/NotificationContext.js";

import { getLiquidations } from '../infra/services/vaultService.ts'
import { getUserTransactions } from '../infra/services/userService.ts'
import { TokenName } from '../enums/tokens.ts'

export default function LiquidateComponent() {
  document.title = "Vault Liquidations | UWU Protocol";
  const { addNotification } = useContext(NotificationContext);

  const { doContractCall } = syConnect();
  const [session, user] = useOutletContext();

  const [showLoader, setShowLoader] = useState(false);
  const [activityPopup, setActivityPopup] = useState(false);
  const [showDefaultPopup, setShowDefaultPopup] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const [pending, setPending] = useState([]);

  const [defaultPopupOptions, setDefaultPopupOptions] = useState({
    windowTitle: null,
    title: null,
    description: null,
    tx: null
  });

  const [confirmPopupOptions, setConfirmPopupOptions] = useState({
    content: [],
    call: null,
    noLiquidationNotice: true
  });

  const [liquidateManager, setLiquidateManager] = useState("start");

  const [purchaseInFull, setPurchaseInFull] = useState(false);

  useEffect(() => {
    if (session && user) {
      fetchPending();
    };
  }, [user, session]);

  function fetchPending() {
    getUserTransactions(user?.address, true).then(res => {
      const filteredTransactions = res.filter(transaction => {
        const { contract_call } = transaction;
        const { function_name, function_args } = contract_call;
        const args = getArgumentsAsObject(function_args);

        const names = ["liquidate-vault", "purchase-vault"];

        return names.includes(function_name);
      });

      setPending(filteredTransactions);
    });
  };

  function uintToNumber(uintString) {
    return parseInt(uintString.slice(1));
  };

  function getArgumentsAsObject(args) {
    return args.reduce((acc, arg) => {
      acc[arg.name] = arg.type === "uint" ? uintToNumber(arg.repr) : arg.repr;
      return acc;
    }, {});
  };

  const checkCharacterAmount = (e) => {
    if (e.key === "Backspace" || e.key === "Delete" || e.key === "Tab" || e.key === "ArrowLeft" || e.key === "ArrowRight") {
      return;
    };

    if (e.key === "." && e.target.value.split(".").length - 1 === 0) {
      return;
    };

    if (!/^\d+$/.test(e.key)) {
      e.preventDefault();
    };
  };

  const createTransactionCall = (type, vault) => {
    if (type === "liquidate") {
      setConfirmPopupOptions(prev => ({
        ...prev,
        desc: `If you confirm this transaction, you will start the liquidation process for Vault #${vault?.id}.`,
        content:
          [
            [
              {
                title: "Vault ID",
                item: `#${vault?.id}`
              }
            ],
            [
              {
                title: "Total Collateral",
                item: `${formatTokensDisplay(vault?.balances.collateral.formatted)} STX`
              },
              {
                title: "Total Debt",
                item: `${formatTokensDisplay(vault?.balances.debt.formatted)} UWU`
              },
              {
                title: "Collateral Ratio",
                item: `${formatTokensDisplay(vault?.balances.collateral.formatted* session?.oracle.price / vault?.balances.debt.formatted* 100)}%`
              }
            ]
          ],
        call: (() => callLiquidateVault(vault?.id))
      }));
      setShowConfirmPopup(true);
    };

    if (type === "purchase") {
      setConfirmPopupOptions(prev => ({
        ...prev,
        desc: `If you confirm this transaction, you will purchase collateral from Vault #${vault?.id}.`,
        content:
          [
            [
              {
                title: "You Send",
                item: `${formatTokensDisplay(purchaseInFull ? vault?.balances.debt.formatted: purchaseAmount)} UWU`
              },
              {
                title: "You Receive (est.)",
                item: `${formatTokensDisplay(purchaseInFull ? vault?.balances.collateral.formatted: (vault?.balances.collateral.formatted* (purchaseAmount / vault?.balances.debt.formatted)).toFixed(2))} STX`
              },
            ],
            [
              {
                title: "Vault ID",
                item: `#${vault?.id}`
              },
              {
                title: "Total Collateral",
                prev: `${formatTokensDisplay(vault?.balances.collateral.formatted)} STX`,
                new: `${formatTokensDisplay(purchaseInFull ? 0 : (vault?.balances.collateral.formatted- vault?.balances.collateral.formatted* (purchaseAmount / vault?.balances.debt.formatted)).toFixed(2))} STX`
              },
              {
                title: "Total Debt",
                prev: `${formatTokensDisplay(vault?.balances.debt.formatted)} UWU`,
                new: `${formatTokensDisplay(purchaseInFull ? 0 : vault?.balances.debt.formatted- purchaseAmount)} UWU`
              }
            ]
          ],
        call: (() => callPurchaseVault(vault, purchaseInFull ? vault?.balances.debt.formatted: purchaseAmount))
      }));
      setShowConfirmPopup(true);
    };
  };

  async function callLiquidateVault(id) {
    setShowLoader(true);
    await doContractCall({
      contractAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
      contractName: "uwu-factory-v1-1-0",
      functionName: "liquidate-vault",
      functionArgs: [uintCV(id)],
      postConditionMode: PostConditionMode.Deny,
      postConditions: [],
      network: new StacksMainnet(),
      onFinish: (result) => {
        addNotification({
          id: Date.now(),
          title: "Liquidation Confirmation",
          description: `You started the liquidation process for Vault #${id}. View the transaction details by clicking here.`,
          date: new Date(),
          link: `https://explorer.hiro.so/txid/${result.txId}`,
          read: false
        });
        setDefaultPopupOptions(prev => ({
          ...prev,
          tx: result.txId,
          windowTitle: "Liquidation Confirmation",
          title: "Liquidation Confirmation",
          description: "Your transaction was sent. Please allow 10-30 minutes for the transaction to be completed."
        }));
        setShowConfirmPopup(false);
        setShowDefaultPopup(true);
        fetchPending();
      },
    });
    setShowLoader(false);
  };

  async function callPurchaseVault(vault, amount) {
    setShowLoader(true);
    await doContractCall({
      contractAddress: "SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4",
      contractName: "uwu-factory-v1-1-0",
      functionName: "purchase-vault",
      functionArgs: [uintCV(vault?.id), uintCV(formatTokens(amount * 1000000))],
      postConditionMode: PostConditionMode.Deny,
      postConditions: [makeStandardFungiblePostCondition(user?.address, FungibleConditionCode.Equal, formatTokens(amount * 1000000), createAssetInfo("SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4", "uwu-token-v1-1-0", "uwu")), makeContractSTXPostCondition("SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4", "uwu-factory-v1-1-0", FungibleConditionCode.LessEqual, vault?.balances.collateral.formatted* 1000000)],
      network: new StacksMainnet(),
      onFinish: (result) => {
        setPurchaseInFull(false);
        setPurchaseAmount("");
        addNotification({
          id: Date.now(),
          title: "Purchase Confirmation",
          description: `You purchased ${formatTokensDisplay(amount)} UWU worth of collateral from Vault #${vault?.id}. View the transaction details by clicking here.`,
          date: new Date(),
          link: `https://explorer.hiro.so/txid/${result.txId}`,
          read: false
        });
        setDefaultPopupOptions(prev => ({
          ...prev,
          tx: result.txId,
          windowTitle: "Purchase Confirmation",
          title: "Purchase Confirmation",
          description: "Your transaction was sent. Please allow 10-30 minutes for the transaction to be completed."
        }));
        setShowConfirmPopup(false);
        setShowDefaultPopup(true);
        fetchPending();
      },
    });
    setShowLoader(false);
  };


  const [vaults, setVaults] = useState();
  const [vault, setVault] = useState();

  const [scanProgress, setScanProgress] = useState();

  const [purchaseAmount, setPurchaseAmount] = useState("");

  const getLiquidationData = async () => {
    const response = await getLiquidations(session?.oracle.price, setScanProgress);
    
    setVaults(response);
    setLiquidateManager("results");
    setScanProgress();
  };

  function getHealthFactor(ratio) {
    const difference = ratio - 150;

    if (difference > 50) {
      return "#20ba62";
    };

    if (difference > 20) {
      return "#d88934";
    };

    if (difference > 0 || difference < 0) {
      return "#d83434";
    };

    return "#000000";
  };

  return (
    <>
      {activityPopup && <ActivityPopupComponent show={(e) => setActivityPopup(e)} address={user?.address} />}
      {showLoader && <LoaderOverlayComponent />}
      {showDefaultPopup && <DefaultPopupComponent show={(e) => setShowDefaultPopup(e)} options={defaultPopupOptions} />}
      {showConfirmPopup && <ConfirmationPopupComponent show={(e) => setShowConfirmPopup(e)} options={confirmPopupOptions} />}
      {(session && user) ?
      <div className="core" style={{ marginTop: "auto" }}>
        <ReactTooltip className="defaultTooltip" effect="solid" multiline={true} arrowColor="#000000" padding="10px 12px" />
        <div className="vaultHeader vaultHeaderPadding">
          <div className="vaultHeaderTitle" style={{ justifyContent: "inherit" }}>
            <a href="/earn" style={{ lineHeight: "0", marginRight: "0.5rem", fontSize: "1.25em", marginBottom: "0.1rem" }}>{`<-`}</a>
            <h1 style={{ marginBottom: "0" }}>Vault Liquidations</h1>
          </div>
          <div className="vaultHeaderDivider"></div>
        </div>
        {pending.length > 0 && <div className="pendingTxnHome pendingTxnHomeMobile" style={{ marginBottom: "15px" }}>
          <img src={loader} draggable="false" />
          <div>
            <h1>You have {pending.length} pending transaction{pending.length > 1 && "s"} via Vault Liquidations</h1>
            <h2>Please allow 10-30 minutes for the transaction{pending.length > 1 && "s"} to be completed.</h2>
          </div>
          <a onClick={() => setActivityPopup(true)} className="smallButton">View Recent Activity</a>
        </div>}
        <div className="grid" style={{ gridTemplateColumns: "1.125fr 0.9fr" }}>
          <div className="earnDiv" style={{ maxWidth: "510px", height: "min-content" }}>
            <div className="earnDesc">
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "1.25rem" }}>
                <img draggable="false" src={earnliq} style={{ marginBottom: "0" }} />
                <a href="https://docs.uwu.cash/uwu-protocol/liquidations" target="_blank" className="smallButton">Learn More</a>
              </div>
              <h1>Vault Liquidations</h1>
              <h2>Liquidations are a key mechanism in UWU Protocol that ensures that UWU is backed by sufficient collateral.</h2>
              <h3>How does it work?</h3>
              <h2>In the event that a Vault fails to maintain a minimum collateral ratio of 150%, the Vault is liquidated (closed). During a liquidation, liquidators are able to acquire the collateral held in a Vault by repaying the Vault's debt.</h2>
              <h3>Why should I participate?</h3>
              <h2>You can buy STX at a discounted price in exchange for repaying the debt of liquidated Vaults. As liquidations happen just below a collateral ratio of 150%, you will most likely experience a net gain when liquidating a Vault.</h2>
              <h3>What are the requirements?</h3>
              <h2 style={{ marginBottom: "0" }}>To participate in liquidations, you will need some UWU to liquidate and STX to cover transaction fees. You can start liquidating with a small amount of each.</h2>
            </div>
          </div>
          <div className="gridItemFirstMobile" style={{ maxWidth: "450px", minWidth: "100%" }}>
            <div className="overview borrowDisplay" style={{ height: "max-content", marginBottom: "15px" }}>
              <div className="overviewHeader manageVaultHeader" style={{ paddingBottom: "5px" }}>
                <div className="inner" style={{ justifyContent: "inherit" }}>
                  {(liquidateManager === "purchase" || liquidateManager === "results") && <a className="innerBackButton" style={{ lineHeight: "0", marginRight: "0.5rem", fontSize: "1.25em", marginBottom: "0.1rem" }} onClick={() => { (liquidateManager === "results" ? setLiquidateManager("start") : setLiquidateManager("results")); setPurchaseAmount(""); setPurchaseInFull(false); setVault(); }}>{`<-`}</a>}
                  <h1 style={{ marginTop: "5px", marginBottom: "5px" }}>{liquidateManager === "start" && "Liquidation Scanner" || liquidateManager === "scan" && "Scanning for Liquidations" || liquidateManager === "results" && "Scan Results" || liquidateManager === "purchase" && `Purchase Vault #${vault?.id}`}</h1>
                </div>
                <div></div>
              </div>
              {liquidateManager === "start" &&
                <div className="popupBody" style={{ paddingTop: "5px" }}>
                  <img src={earnliq} draggable="false" style={{ backgroundColor: "#FF7BED25" }} />
                  <h1>Liquidation Scanner</h1>
                    <h2 style={{ marginBottom: "3rem" }}>Automatically scan all Vaults to identify those purchasable or pending liquidation.</h2>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <a onClick={() => { getLiquidationData(); setLiquidateManager("scan"); }} className="borrowButton" style={{ color: "#FFFFFF", textDecoration: "none", lineHeight: "3rem", margin: "auto", fontSize: "0.85rem" }}>Start a Liquidation Scan</a>
                  </div>
                </div>
              }
              {liquidateManager === "scan" &&
                <div className="popupBody" style={{ paddingTop: "5px" }}>
                  <img src={loader} draggable="false" style={{ width: "38px", height: "38px", outline: "5px solid #F0F0F050", border: "1px solid #F0F0F0", backgroundColor: "#F0F0F0", marginTop: "5px", marginBottom: "17px" }} />
                  <h1>Scanning for Liquidations</h1>
                  <h2 style={{ marginBottom: "3rem" }}>Scanning all Vaults to determine liquidation eligibility. This may take a few minutes.</h2>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <a className="borrowButton liquidatedVaultStatus" style={{ color: "#FFFFFF", textDecoration: "none", lineHeight: "3rem", margin: "auto", fontSize: "0.85rem" }}>{scanProgress ? `Vault #${scanProgress.current} of ${scanProgress.total}` : "Preparing to Scan"}</a>
                  </div>
                </div>
              }
              {liquidateManager === "results" &&
                <div className="popupBody" style={{ margin: "-5px 0px -15px 0px" }}>
                  <div className="popupScroll popupScrollTrackPadding" style={{ border: "none", maxHeight: "533.688px" }}>
                  <img src={earnliq} draggable="false" style={{ backgroundColor: "#FF7BED25", marginTop: "10px" }} />
                  <h1>Scan Results</h1>
                  <h2 style={{ marginBottom: "2.75rem" }}>The following Vaults are either available for purchase or are pending liquidation.</h2>
                  <div style={{ margin: "0px 0px -12px 0px" }}>
                    <h2 style={{ marginLeft: "0", marginBottom: "0.5rem", textAlign: "left" }}>Ready for Purchase</h2>
                    {vaults.purchasable.map(vault =>
                      <div className="liquidatedVaultOverview" onClick={() => { setVault(vault); setLiquidateManager("purchase"); }}>
                        <div className="liquidatedVaultHeader">
                          <h1>Vault #{vault.id}</h1>
                          <div></div>
                        </div>
                        <div className="liquidatedVaultGrid">
                          <div>
                            <h2>Total Collateral</h2>
                            <h1>{formatTokensDisplay(vault.balances.collateral.formatted)} STX</h1>
                          </div>
                          <div className="liquidatedVaultGridDivider"></div>
                          <div>
                            <h2>Total Debt</h2>
                            <h1>{formatTokensDisplay(vault.balances.debt.formatted)} UWU</h1>
                          </div>
                          <div className="liquidatedVaultGridDivider"></div>
                          <div>
                            <h2>Collateral Ratio</h2>
                            <h1 style={{ color: getHealthFactor(((vault.balances.collateral.formatted * session?.oracle.price) / vault.balances.debt.formatted) * 100) }}>{formatTokensDisplay(((vault.balances.collateral.formatted * session?.oracle.price) / vault.balances.debt.formatted) * 100)}%</h1>
                          </div>
                        </div>
                      </div>
                    )}
                    {vaults.purchasable.length === 0 &&
                    <div style={{ marginBottom: "32px"}}>
                      <div className="activityItem assetItem" style={{ justifyContent: "center", border: "1px solid #F0F0F0", borderRadius: "15px" }}>
                        <h2 style={{ marginBottom: "unset", width: "100%" }}>There are currently no purchasable Vaults</h2>
                      </div>
                    </div>
                    }
                  </div>
                  <div style={{ margin: "0px 0px 32px 0px" }}>
                    <h2 style={{ marginLeft: "0", marginBottom: "0.5rem", textAlign: "left" }}>Pending Liquidation</h2>
                    {vaults.pending.map(vault =>
                      <div onClick={() => { createTransactionCall("liquidate", vault); }} className="liquidatedVaultOverview">
                        <div className="liquidatedVaultHeader">
                          <h1>Vault #{vault.id}</h1>
                          <div></div>
                        </div>
                        <div className="liquidatedVaultGrid">
                          <div>
                            <h2>Total Collateral</h2>
                            <h1>{formatTokensDisplay(vault.balances.collateral.formatted)} STX</h1>
                          </div>
                          <div className="liquidatedVaultGridDivider"></div>
                          <div>
                            <h2>Total Debt</h2>
                            <h1>{formatTokensDisplay(vault.balances.debt.formatted)} UWU</h1>
                          </div>
                          <div className="liquidatedVaultGridDivider"></div>
                          <div>
                            <h2>Collateral Ratio</h2>
                            <h1 style={{ color: getHealthFactor(((vault.balances.collateral.formatted* session?.oracle.price) / vault.balances.debt.formatted) * 100) }}>{formatTokensDisplay(((vault.balances.collateral.formatted* session?.oracle.price) / vault.balances.debt.formatted) * 100)}%</h1>
                          </div>
                        </div>
                      </div>
                    )}
                    {vaults.pending.length === 0 &&
                      <div className="activityItem assetItem" style={{ justifyContent: "center", border: "1px solid #F0F0F0", borderRadius: "15px" }}>
                        <h2 style={{ marginBottom: "unset", width: "100%" }}>There are currently no Vaults pending liquidation</h2>
                      </div>
                    }
                  </div>
                </div>
              </div>}
              {liquidateManager === "purchase" &&
                <div className="borrowInnerDiv manageVaultInnerDiv">
                  <div className="borrowDivOverflow" style={{ marginTop: "0px", maxHeight: "unset" }}>
                    <div style={{ border: "1px solid #c3c3c3", backgroundColor: "#f0f0f070", borderRadius: "10px", padding: "10px", margin: "5px 20px 18px", maxWidth: "400px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#838383" }}>If a liquidated Vault has a debt balance of 25 UWU, it must be purchased in full. Otherwise, partial purchase is allowed</h1></div>
                    <div>
                      <div className="borrowInputTitle">
                        <h1>Partial Purchase</h1>
                        <h2 onClick={() => setPurchaseAmount(vault?.balances.debt.formatted>= 25 ? vault?.balances.debt.formatted- 25 : 0)}>Maximum: {formatTokensDisplay(vault?.balances.debt.formatted>= 25 ? vault?.balances.debt.formatted- 25 : 0)} UWU</h2>
                      </div>
                      <span className="borrowInput borrowInputPrice" style={{ marginBottom: "20px" }}><div style={{ width: "100%" }}><input inputmode="decimal" maxLength="10" type="text" placeholder="0.00 UWU" onChange={(e) => setPurchaseAmount(e.target.value.toString().split(".").map((el, i) => i ? el.split("").slice(0, 2).join("") : el).join(".").replace(/^0+(?=\d)/, ""))} onKeyDown={(e) => checkCharacterAmount(e)} value={purchaseAmount} onFocus={() => purchaseInFull && setPurchaseInFull(false)} /><h4>≈ ${formatTokensDisplay(purchaseAmount * session?.prices[TokenName.UWU])}</h4></div><div className="swapOutputCurrency" style={{ marginRight: "15px" }}><img draggable="false" src={uwutoken} /><h1>UWU</h1></div></span>
                      {user?.balances[TokenName.UWU] < purchaseAmount && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-10px 20px 20px", maxWidth: "400px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>The amount entered exceeds your account balance</h1></div>}
                      {(purchaseAmount > formatTokens(vault?.balances.debt.formatted>= 25 ? vault?.balances.debt.formatted- 25 : 0)) && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-10px 20px 20px", maxWidth: "400px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>The amount entered exceeds the maximum partial purchase for this Vault. Consider purchasing in full instead</h1></div>}
                      <div className="dividerWithText" style={{ marginTop: "-10px", marginBottom: "10px" }}>
                        <div></div>
                        <h1>or</h1>
                        <div></div>
                      </div>
                      <span style={{ border: purchaseInFull ? "1px solid #838383" : "1px solid #F0F0F0", pointerEvents: user?.balances[TokenName.UWU] < vault?.balances.debt.formatted&& "none", opacity: user?.balances[TokenName.UWU] < vault?.balances.debt.formatted&& "0.4", marginBottom: "20px" }} className="repayInFullButton" onClick={() => { setPurchaseAmount(""); setPurchaseInFull(true); }}><a>Purchase in Full</a><a style={{ color: "#838383" }}>{formatTokensDisplay(vault?.balances.debt.formatted)} UWU</a></span>
                      {(user?.balances[TokenName.UWU] < vault?.balances.debt.formatted) && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", margin: "-10px 20px 20px" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934" }}>Your account doesn't have enough UWU to purchase in full</h1></div>}
                      <div className="borrowOutputDetails" style={{ marginTop: "-5px" }}>
                        <h1>Vault Share</h1>
                        <h2>{formatTokensDisplay(purchaseInFull ? 100 : purchaseAmount / vault?.balances.debt.formatted* 100)}%</h2>
                        <h1>Total Received</h1>
                        <h2>{formatTokensDisplay(purchaseInFull ? vault?.balances.collateral.formatted: (vault?.balances.collateral.formatted* (purchaseAmount / vault?.balances.debt.formatted)).toFixed(2))} STX</h2>
                      </div>
                    </div>
                  </div>
                  <div className="borrowDivider"></div>
                  <a onClick={() => ((purchaseAmount > 0 && (vault?.balances.debt.formatted- purchaseAmount).toFixed(12) >= 25 && purchaseAmount <= user?.balances[TokenName.UWU] || purchaseInFull && vault?.balances.debt.formatted<= user?.balances[TokenName.UWU])) && createTransactionCall("purchase", vault)} style={{ backgroundColor: ((purchaseAmount > 0 && (vault?.balances.debt.formatted- purchaseAmount).toFixed(12) >= 25 && purchaseAmount <= user?.balances[TokenName.UWU] || purchaseInFull && vault?.balances.debt.formatted<= user?.balances[TokenName.UWU])) ? "#000000" : "#575757", cursor: ((purchaseAmount > 0 && (vault?.balances.debt.formatted- purchaseAmount).toFixed(12) >= 25 && purchaseAmount <= user?.balances[TokenName.UWU] || purchaseInFull && vault?.balances.debt.formatted<= user?.balances[TokenName.UWU])) ? "pointer" : "default" }} className="borrowButton">{(!purchaseInFull && !purchaseAmount) && "Enter an Amount" || "Purchase"}</a>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    : <LoaderComponent />}
    </>
  );
};