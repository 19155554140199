export const UWU_CONTRACT_ADDRESSES = {
  DEPLOYER: 'SP2AKWJYC7BNY18W1XXKPGP0YVEK63QJG4793Z2D4'
}

export const STACKSWAP_CONTRACT_ADDRESSES = {
  DEPLOYER: 'SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275'
}

export const ALEX_CONTRACT_ADDRESSES = {
  DEPLOYER: 'SP102V8P0F7JX67ARQ77WEA3D3CFB5XW39REDT0AM'
}

export const BITFLOW_CONTRACT_ADDRESSES = {
  DEPLOYER: 'SM1793C4R5PZ4NS4VQ4WMP7SKKYVH8JZEWSZ9HCCR'
}

export const UWU_CONTRACT_NAMES = {
  FACTORY: 'uwu-factory-v1-1-0',
  ORACLE: 'uwu-oracle-v1-1-3',
  PROXY: 'uwu-oracle-proxy-v1-1-0',
  USM: 'uwu-stability-module-v1-1-4'
}

export const STACKSWAP_CONTRACT_NAMES = {
  SWAP: 'stackswap-swap-v5k'
}

export const ALEX_CONTRACT_NAMES = {
  POOL: 'amm-pool-v2-01',
  VAULT: 'amm-vault-v2-01'
}

export const BITFLOW_CONTRACT_NAMES = {
  CORE: 'xyk-core-v-1-1'
}

export const UWU_FACTORY_FUNCTION_NAMES = {
  GET_VAULT: 'get-vault',
  GET_VAULTS: 'get-vaults',
  GET_LAST_VAULT_ID: 'get-last-vault-id'
}

export const UWU_PROXY_FUNCTION_NAMES = {
  GET_STX_PRICE: 'get-stx-price'
}

export const USM_FUNCTION_NAMES = {
  GET_SWAP_STATUS: 'get-swap-status',
  GET_FEE_ADDRESS: 'get-fee-address',
  GET_TOKEN: 'get-token',
  SWAP_X_FOR_Y: 'swap-x-for-y',
  SWAP_Y_FOR_X: 'swap-y-for-x'
}

export const STACKSWAP_FUNCTION_NAMES = {
  GET_LP_DATA: 'get-lp-data',
  SWAP_X_FOR_Y: 'swap-x-for-y',
  SWAP_Y_FOR_X: 'swap-y-for-x'
}

export const ALEX_FUNCTION_NAMES = {
  GET_HELPER: 'get-helper',
  SWAP_HELPER: 'swap-helper'
}

export const BITFLOW_FUNCTION_NAMES = {
  GET_DX: 'get-dx',
  GET_DY: 'get-dy',
  SWAP_X_FOR_Y: 'swap-x-for-y',
  SWAP_Y_FOR_X: 'swap-y-for-x'
}