import React, { useState } from "react";

import loader from "../img/loader.svg";

export default function LoaderComponent() {
  return (
    <div className="loader">
      <img src={loader} width="50px" draggable="false" />
    </div>
  );
};