type GetFunction<T> = (...args: any[]) => Promise<T>

export const getAndCacheData = async <T>(key: string, getFn: GetFunction<T>, args: any[], interval: number): Promise<T | null> => {
  const now = Date.now()
  const cached = localStorage.getItem(key)

  if (cached) {
    const cache = JSON.parse(cached)

    if (cache && now - cache.time < interval && cache.data !== undefined) {
      return cache.data as T
    }
  }

  try {
    const data = await getFn(...args)

    localStorage.setItem(key, JSON.stringify({ data: data, time: now }))

    return data
  } catch (err) {
    console.error(`Error fetching data for '${key}': ${err instanceof Error ? err.message : String(err)}`)

    return cached ? JSON.parse(cached).data as T : null
  }
}