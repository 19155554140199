import React, { useContext, useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react'

import { NotificationContext } from '../context/NotificationContext.js'

import LoaderOverlayComponent from './LoaderOverlayComponent'
import TransactionReviewPopup from '../popups/new/TransactionReviewPopup.tsx'
import TransactionSubmittedPopup from '../popups/new/TransactionSubmittedPopup.tsx'
import { Transaction } from '../interfaces/transactions.ts'

const TransactionHandler = forwardRef(({ }, ref) => {
  const { addNotification } = useContext(NotificationContext)

  const [txCall, setTxCall] = useState(() => () => Promise.resolve())
  const [txData, setTxData] = useState()

  const [showLoaderOverlay, setShowLoaderOverlay] = useState(false)
  const [showTxSubmittedPopup, setShowTxSubmittedPopup] = useState(false)
  const [showTxReviewPopup, setShowTxReviewPopup] = useState(false)

  const [txSubmittedPopupOptions, setTxSubmittedPopupOptions] = useState<Transaction['submittedOptions']>()
  const [txReviewPopupOptions, setTxReviewPopupOptions] = useState<Transaction['reviewOptions']>()
  const [notificationOptions, setNotificationOptions] = useState<Transaction['notificationOptions']>()

  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useImperativeHandle(ref, () => ({
    handleInitiate
  }))

  const handleInitiate = (txCall: any, txData: any) => {
    setTxCall(() => txCall)
    setTxData(txData)

    setTxReviewPopupOptions(txData.reviewOptions)
    setTxSubmittedPopupOptions(txData.submittedOptions)
    setNotificationOptions(txData.notificationOptions)

    setShowTxReviewPopup(true)
  }

  const handleCall = async () => {
    setShowLoaderOverlay(true)

    try {
      const tx = await txCall()

      if (!isMounted.current) return

      if (!notificationOptions) {
        throw new Error('Required notification options are missing from transaction data')
      }

      setShowLoaderOverlay(false)
      setShowTxReviewPopup(false)
      
      setTxSubmittedPopupOptions(prev => ({ ...prev, txId: tx.txId}))

      const link = notificationOptions?.isExplorerLink ? notificationOptions.link.replace('${txId}', tx.txId) : notificationOptions.link

      addNotification({
        id: Date.now(),
        title: notificationOptions.title,
        description: notificationOptions.description,
        date: new Date(),
        link: link,
        read: false
      })

      setShowTxSubmittedPopup(true)
    } catch (error) {
      if (!isMounted.current) return
      
      setShowLoaderOverlay(false)
    } finally {
      if (!isMounted.current) return

      setShowLoaderOverlay(false)
    }
  }

  return (
    <>
      {showLoaderOverlay && <LoaderOverlayComponent />}
      {showTxSubmittedPopup && <TransactionSubmittedPopup show={(e) => setShowTxSubmittedPopup(e)} options={txSubmittedPopupOptions} />}
      {showTxReviewPopup && <TransactionReviewPopup show={(e) => setShowTxReviewPopup(e)} options={txReviewPopupOptions} call={handleCall} />}
    </>
  )
})

export default TransactionHandler