import React, { useState, useEffect } from "react";
import { PopupAnimation } from "../lib/animation";

import checkmark from "../img/checkmark.png";

export default function ConfirmationPopupComponent({ show, options }) {
  const [visibleClass, setVisibleClass] = useState("");

  useEffect(() => {
    setTimeout(() => setVisibleClass('visible'), 25);
  }, []);

  useEffect(() => {
    if (visibleClass === "visible") {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    };
  }, [visibleClass]);

  const handleClosePopup = () => {
    setVisibleClass('');
    setTimeout(() => show(false), 300);
  };

  function hasCollateralRatio() {
    for (let i = 0; i < options.content.length; i++) {
      let collateralRatio = options.content[i].find(item => item.title === "Collateral Ratio");

      if (collateralRatio) {
        
        const match = collateralRatio.hasOwnProperty("new") ? collateralRatio.new.match(/([\d,]+)/) : collateralRatio.item.match(/([\d,]+)/);
        if (match) {
          if (Number(match[0]) < 170) {
            if (Number(match[0]) <= 1) {
              return null;
            } else {
              return true;
            };
          } else {
            return null;
          };
        } else {
          return null;
        };
      };
      };
    return null;
  };
  
  return (
    <PopupAnimation visible={visibleClass} onClose={handleClosePopup}>
      <div className="popupInterface">
        <div className="popupHeader">
          <h1>Review Transaction</h1>
          <svg onClick={handleClosePopup} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M6 6L18 18" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
        </div>
        <div className="popupBody" style={{ margin: "0" }}>
          <div className="popupScroll">
            <h1>Review this Transaction</h1>
            <h2>{options?.desc ? options?.desc : "If you confirm this transaction, the following changes will be made to your Vault."}</h2>
            {options?.noLiquidationNotice ? null : hasCollateralRatio() && <div style={{ border: "1px solid #f3dac2", backgroundColor: "#f3dac225", borderRadius: "10px", padding: "10px", marginTop: "2.5rem" }}><h1 style={{ fontSize: "0.85em", fontWeight: "600", color: "#d88934", marginBottom: "0", textAlign: "left", lineHeight: "1.36" }}>By proceeding with this transaction, your Vault will be near liquidation due to a low collateral ratio</h1></div>}
            {options.content.map((itemArray, index) =>
              <div className="popupTable" key={index} style={{ marginBottom: index < options.content.length - 1 && "0px" }}>
                {itemArray.map((e, i) => <>
                  <div className="popupTableContent">
                    <h3>{e.title}</h3>
                    {e.prev && <h1>{e.prev} -> {e.new}</h1>}
                    {e.item && <h1>{e.item}</h1>}
                  </div>
                  {i < itemArray.length - 1 && <div className="borrowDivider" style={{ margin: "15px -15px" }}></div>}
                </>)}
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "center", margin: "20px 0" }}>
            <a onClick={() => { options.call(); }} className="borrowButton" style={{ color: "#FFFFFF", textDecoration: "none", lineHeight: "3rem", margin: "0px 10px 0px 20px", fontSize: "0.85rem", width: "100%", minWidth: "fit-content" }}>Confirm Transaction</a>
            <a onClick={handleClosePopup} className="borrowButton lightButton" style={{ color: "#000000", textDecoration: "none", lineHeight: "3rem", margin: "0px 20px 0px 10px", fontSize: "0.85rem", width: "100%" }}>Cancel</a>
          </div>
        </div>
      </div>
    </PopupAnimation>
  );
};