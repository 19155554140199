import { LPToken, Token } from '../interfaces/tokens'

export const valueWithCommas = (amount: number | string | bigint, decimalPlaces: number = 2): string => {
  const value = typeof amount === 'string' ? parseFloat(amount) : typeof amount === 'bigint' ? Number(amount) : amount

  const formattedValue = formatValue(value, decimalPlaces)
  const [integerPart, fractionalPart] = formattedValue.toString().split('.')
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const paddedFractionalPart = fractionalPart?.padEnd(decimalPlaces, '0') ?? '00'

  let finalFormattedValue = `${formattedIntegerPart}.${paddedFractionalPart}`
  finalFormattedValue = finalFormattedValue.replace(/(\.\d{2}\d*?)0+$/, '$1')

  return finalFormattedValue
}

export const formatValue = (amount: number | string | bigint, decimalPlaces: number = 2, allowNegative: boolean = true): number => {
  const value = typeof amount === 'string' ? parseFloat(amount) : typeof amount === 'bigint' ? Number(amount) : amount

  if (isNaN(value) || !isFinite(value)) {
    return 0
  }

  if (value < 0 && !allowNegative) {
    return 0
  }

  const valueString = value.toString()
  const [integerPart, fractionalPart] = valueString.split('.')

  const truncatedFractionalPart = fractionalPart?.slice(0, decimalPlaces) ?? ''
  const truncatedValueString = `${integerPart}.${truncatedFractionalPart}`
  const truncatedValue = parseFloat(truncatedValueString)

  return truncatedValue
}

export const formatUIValue = (amount: number | string | bigint, decimalPlaces: number = 2, allowNegative: boolean = true, truncate: boolean = false): string => {
  const value = typeof amount === 'string' ? parseFloat(amount) : typeof amount === 'bigint' ? Number(amount) : amount

  if (isNaN(value) || value === 0 || amount === null || amount === '') {
    return '0.00'
  }

  if (value < 0 && !allowNegative) {
    return '0.00'
  }

  const formattedValue = formatValue(value, decimalPlaces)
  const [integerPart, fractionalPart] = formattedValue.toString().split('.')
  const paddedFractionalPart = fractionalPart?.padEnd(decimalPlaces, '0') ?? '00'

  let finalFormattedValue = `${integerPart}.${paddedFractionalPart}`
  finalFormattedValue = finalFormattedValue.replace(/(\.\d{2}\d*?)0+$/, '$1')

  if (truncate) {
    return truncateValue(finalFormattedValue)
  }

  return valueWithCommas(parseFloat(finalFormattedValue), decimalPlaces)
}

export const truncateValue = (amount: number | string | bigint): string => {
  const value = typeof amount === 'string' ? parseFloat(amount) : typeof amount === 'bigint' ? Number(amount) : amount

  const scales = [
    { threshold: 1e9, divisor: 1e9, suffix: 'B' },
    { threshold: 1e6, divisor: 1e6, suffix: 'M' },
    { threshold: 1000, divisor: 1000, suffix: 'K' }
  ]

  for (const scale of scales) {
    if (value >= scale.threshold) {
      return `${(value / scale.divisor).toFixed(2)}${scale.suffix}`
    }
  }

  return value.toString()
}

export const formatTokenValue = (amount: number | string | bigint, token: Token | LPToken): number => {
  const value = typeof amount === 'string' ? parseFloat(amount) : typeof amount === 'bigint' ? Number(amount) : amount

  if (isNaN(value) || !isFinite(value) || value < 0) {
    return 0
  }

  const decimalPlaces = Number(token.decimals.toExponential().toString().split('e')[1])
  
  return formatValue(value / token.decimals, decimalPlaces)
}