import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { timeSince, trunc, convertMicrotokensToTokens, formatTokens, numberWithCommas, formatTokensDisplay } from "../lib/utils.js";

import "../styles/animations.css";

import loader from "../img/loader.svg";
import ascending from "../img/ascending.png";
import descending from "../img/descending.png";
import vault from "../img/vault-logo.png";
import earn from "../img/earn-logo.png";
import stxuwulp from "../img/stx-uwu-lp.png";
import hide from "../img/hide.png";
import show from "../img/show.png";

import LoaderComponent from "./LoaderComponent.js";
import DropdownComponent from "../popups/DropdownComponent.js";
import ActivityPopupComponent from "../popups/ActivityPopupComponent.js";
import AssetsPopupComponent from "../popups/AssetsPopupComponent.js";

import { getUserTransactions } from '../infra/services/userService.ts'
import { TokenName } from '../enums/tokens.ts'

export default function HomeComponent() {
  const [session, user] = useOutletContext();

  const [showPortfolio, setShowPortfolio] = useState(true);
  const [activityPopup, setActivityPopup] = useState(false);
  const [assetsPopup, setAssetsPopup] = useState(false);

  const [pending, setPending] = useState([]);

  useEffect(() => {
    const storedPreferences = JSON.parse(localStorage.getItem("uwu-preferences"));

    if (storedPreferences) {
      setShowPortfolio(JSON.parse(storedPreferences.portfolio));
    };
  }, []);

  useEffect(() => {
    if (session && user) {

      fetchPending();
    };
  }, [user, session]);

  function fetchPending() {
    getUserTransactions(user?.address, true).then(res => {
      const filteredTransactions = res.filter(transaction => {
        const { contract_call } = transaction;
        const { function_name, function_args } = contract_call;
        const args = getArgumentsAsObject(function_args);

        const names = ["open-vault"];

        return names.includes(function_name);
      });

      setPending(filteredTransactions);
    });
  };

  function uintToNumber(uintString) {
    return parseInt(uintString.slice(1));
  };

  function getArgumentsAsObject(args) {
    return args.reduce((acc, arg) => {
      acc[arg.name] = arg.type === "uint" ? uintToNumber(arg.repr) : arg.repr;
      return acc;
    }, {});
  };

  useEffect(() => {
    let storedPreferences = JSON.parse(localStorage.getItem("uwu-preferences"));

    if(storedPreferences) {
      if (showPortfolio) {
        storedPreferences.portfolio = true;
        localStorage.setItem("uwu-preferences", JSON.stringify(storedPreferences));
      } else {
        storedPreferences.portfolio = false;
        localStorage.setItem("uwu-preferences", JSON.stringify(storedPreferences));
      };
    };
  }, [showPortfolio]);

  document.title = "Home | UWU Protocol";
  
  function getHealthFactor(ratio) {
    const difference = ratio - 150;

    if (difference > 50) {
      return "#20ba62";
    };

    if (difference > 20) {
      return "#d88934";
    };

    if (difference > 0) {
      return "#d83434";
    };

    return "#000000";
  };

  function getGreeting() {
    let date = new Date();
    let hour = date.getHours();

    if (hour >= 0 && hour < 12) {
      return "Good morning";
    } else if (hour >= 12 && hour < 17) {
      return "Good afternoon";
    } else {
      return "Good evening";
    };
  };

  const [vaultSortOption, setVaultSortOption] = useState('');
  const [positionSortOption, setPositionSortOption] = useState('');
  const [isVaultAscending, setIsVaultAscending] = useState(false);
  const [isPositionAscending, setIsPositionAscending] = useState(false);

  const getSortedData = () => {
    if (!vaultSortOption) return user?.vaults;

    const sortedData = [...user?.vaults];
    const sortDirection = isVaultAscending ? 1 : -1;

    if (vaultSortOption === 'ratio') {
      return sortedData.sort((a, b) => (((a.balances.collateral.formatted* session?.oracle.price) * a.balances.debt.formatted) - ((b.balances.collateral.formatted* session?.oracle.price) * b.balances.debt.formatted)) * sortDirection);
    } else if (vaultSortOption === 'liquidation') {
      return sortedData.sort((a, b) => (a.liquidated === b.liquidated ? 0 : a.liquidated ? -1 : 1) * sortDirection);
    } else if (vaultSortOption === 'collateral') {
      return sortedData.sort((a, b) => (a.balances.collateral.formatted- b.balances.collateral.formatted) * sortDirection);
    } else if (vaultSortOption === 'debt') {
      return sortedData.sort((a, b) => (a.balances.debt.formatted- b.balances.debt.formatted) * sortDirection);
    } else if (vaultSortOption === 'date') {
      return sortedData.sort((a, b) => (new Date(a.date.timestamp) - new Date(b.date.timestamp)) * sortDirection);
    } else if (vaultSortOption === 'id') {
      return sortedData.sort((a, b) => (a.id - b.id) * sortDirection);
    };

    return user?.vaults;
  };

  const vaultSortOptions = [
    { value: '', label: 'Default' },
    { value: 'id', label: 'Vault ID' },
    { value: 'date', label: 'Date Opened' },
    { value: 'ratio', label: 'Collateral Ratio' },
    { value: 'liquidation', label: 'Liquidation Status' },
    { value: 'collateral', label: 'Total Collateral' },
    { value: 'debt', label: 'Total Debt' }
  ];

  const positionSortOptions = [
    { value: '', label: 'Default' },
    { value: 'id', label: 'Position ID' },
    { value: 'date', label: 'Date Opened' },
    { value: 'provider', label: 'Position Provider' },
    { value: 'deposit', label: 'Your Deposit' },
    { value: 'share', label: 'Your Pool Share' },
    { value: 'apr', label: 'Estimated APR' }
  ];

  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(getSortedData()?.length / itemsPerPage);

  const getCurrentPageData = () => {
    const sortedData = getSortedData();
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return sortedData.slice(startIndex, endIndex);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    };

    return pageNumbers;
  };

  return (
    <>
      {activityPopup && <ActivityPopupComponent show={(e) => setActivityPopup(e)} address={user?.address} />}
      {assetsPopup && <AssetsPopupComponent show={(e) => setAssetsPopup(e)} context={[session, user]} />}
      { (session && user) ? <div className="core homeCore animate__animated animate__fadeIn">
        <ReactTooltip className="defaultTooltip" effect="solid" multiline={true} arrowColor="#000000" padding="10px 12px" />
        <div className="borrowHeader">
          <h1>{getGreeting()}, {user?.bns ? trunc(user?.bns, true) : trunc(user?.address)}</h1>
          <h2>This is your Home page, a place where you can keep track of your<br></br> assets, Vaults, and more as you interact with UWU Protocol.</h2>
        </div>
        <div className="homeInner">
          <div className="homePortfolio">
            <div className="homeTopOverview">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h1>Account Balance</h1>
                {showPortfolio ? <h2 style={{ fontSize: "2rem" }}>${formatTokensDisplay((user?.balances[TokenName.STX] * session?.prices[TokenName.STX]) + (user?.balances[TokenName.UWU] * session?.prices[TokenName.UWU]) + (user?.balances[TokenName.SUSDT] * session?.prices[TokenName.SUSDT]) + (user?.balances[TokenName.AEUSDC] * session?.prices[TokenName.AEUSDC]) + (user?.balances[TokenName.XUSD] * session?.prices[TokenName.XUSD]) + (user?.balances[TokenName.XBTC] * session?.prices[TokenName.XBTC]) + (user?.balances[TokenName.ABTC] * session?.prices[TokenName.ABTC]) + (user?.balances[TokenName.ALEX] * session?.prices[TokenName.ALEX]) + (user?.balances[TokenName.STSW] * session?.prices[TokenName.STSW]) + (user?.balances[TokenName.VIBES] * session?.prices[TokenName.VIBES]) + (user?.balances[TokenName.FARI] * session?.prices[TokenName.FARI]) + (user?.balances[TokenName.WELSH] * session?.prices[TokenName.WELSH]) + (user?.balances[TokenName.LEO] * session?.prices[TokenName.LEO]) + (user?.balances[TokenName.GUS] * session?.prices[TokenName.GUS]))}</h2> : <h2 style={{ fontSize: "2rem" }}>-</h2>}
                <div className="homeTopOverviewButtons">
                  <a onClick={() => setAssetsPopup(true)} className="smallButton" style={{ border: "1px solid #F0F0F0" }}>Manage Account</a>
                  <button className="imgButton smallButton" style={{ marginTop: "auto", boxShadow: "none" }} onClick={() => setShowPortfolio(!showPortfolio)}><img src={showPortfolio ? show : hide} draggable="false" /></button>
                </div>
              </div>
              <div>
                <h1>Your Collateral</h1>
                {showPortfolio ? <h2>${formatTokensDisplay(user?.vaults.reduce((acc, curr) => acc + curr.balances.collateral.formatted, 0) * session?.oracle.price)}</h2> : <h2>-</h2>}
                <span></span>
                <h1>Your Debt</h1>
                {showPortfolio ? <h2>{formatTokensDisplay(user?.vaults.reduce((acc, curr) => acc + parseFloat(curr.balances.debt.formatted), 0))} UWU</h2> : <h2>-</h2>}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h1>Your Positions</h1>
                {showPortfolio ? <h2 style={{ fontSize: "2rem" }}>${formatTokensDisplay(session?.pools["0"].balances.usd * (user?.balances.lto["0"] / session?.pools["0"].lpToken.shares.formatted))}</h2> : <h2 style={{ fontSize: "2rem" }}>-</h2>}
                <h1 className="homeTopOverviewPositionsDesc" style={{ marginTop: "auto", lineHeight: "unset", marginBottom: "0", maxWidth: "228px" }}>The present total value of your positions through UWU Earn.</h1>
              </div>
            </div>
          </div>
          <div className="grid homeGrid" style={{ marginBottom: "2.5rem" }}>
            <div className="vaultHeader">
              <div>
                <h1 style={{ fontSize: "1rem", fontWeight: "700", lineHeight: "1.41", marginBottom: user?.vaults.length === 0 && "5px" }}>Your Vaults</h1>
                {user?.vaults.length > 0 && <div style={{ minWidth: "200px" }}>
                  <DropdownComponent title="Sort by" options={vaultSortOptions} value={vaultSortOption} onChange={(e) => setVaultSortOption(e.value)} />
                  <button className="imgButton smallButton" style={{ marginLeft: "5px" }} onClick={() => setIsVaultAscending(!isVaultAscending)}><img draggable="false" src={isVaultAscending ? ascending : descending} /></button>
                </div>}
              </div>
            </div>
            {pending.length > 0 && <div className="pendingTxnHome">
              <img src={loader} draggable="false" />
              <div>
                <h1>Your new {pending.length > 1 ? "Vaults are" : "Vault is"} being created</h1>
                <h2>Please allow 10-30 minutes for the transaction{pending.length > 1 && "s"} to be completed.</h2>
              </div>
              <a onClick={() => setActivityPopup(true)} className="smallButton">View Recent Activity</a>
            </div>}
            {getCurrentPageData().map(vault =>
              <div className="overview overviewMinimized" style={vault?.liquidated ? { backgroundColor: "#fdf6f7", borderColor: "#d83434" } : ((vault?.balances.collateral.formatted* session?.oracle.price) / vault?.balances.debt.formatted) * 100 < 200 ? { backgroundColor: "#fefaf7", borderColor: "#d88934" } : {}}>
                <div>
                  <h1 style={{ marginBottom: "2.5px", fontFamily: "inherit", fontWeight: "600" }}>Vault #{vault?.id}</h1>
                  <h2 style={{ marginBottom: "0" }}>Opened {vault?.date.timestamp}</h2>
                </div>
                <span className="mobileDivider" style={vault?.liquidated ? { borderColor: "#d83434" } : ((vault?.balances.collateral.formatted* session?.oracle.price) / vault?.balances.debt.formatted) * 100 < 200 ? { borderColor: "#d88934" } : {}}></span>
                <div>
                  <h2 data-tip="">Total Collateral</h2>
                  {showPortfolio ? <h1>${formatTokensDisplay(vault?.balances.collateral.formatted* session?.oracle.price)}</h1> : <h1>-</h1>}
                </div>
                <div>
                  <h2 data-tip="">Total Debt</h2>
                  {showPortfolio ? <h1>{formatTokensDisplay(vault?.balances.debt.formatted)} UWU</h1> : <h1>-</h1>}
                </div>
                <div>
                  { !vault?.liquidated &&
                    <>
                      <h2 data-tip="">Collateral Ratio</h2>
                      <h1 style={{ color: getHealthFactor(((vault?.balances.collateral.formatted* session?.oracle.price) / vault?.balances.debt.formatted) * 100) }}>{formatTokensDisplay(((vault?.balances.collateral.formatted* session?.oracle.price) / vault?.balances.debt.formatted) * 100)}%</h1>
                    </>
                  }
                  { vault?.liquidated &&
                    <h1 style={{ fontFamily: "Manrope", fontSize: "0.75rem", fontWeight: "600", color: "#ffffff", backgroundColor: "#d83434", padding: "10px", borderRadius: "10px", maxWidth: "max-content" }}>Vault Liquidated</h1>
                  }
                </div>
                <span className="mobileDivider" style={vault?.liquidated ? { borderColor: "#d83434", marginBottom: "5px" } : ((vault?.balances.collateral.formatted* session?.oracle.price) / vault?.balances.debt.formatted) * 100 < 200 ? { borderColor: "#d88934", marginBottom: "5px" } : { marginBottom: "5px" }}></span>
                <a href={`vault/${vault?.id}`} className="smallButton borrowButtonMobile">Manage</a>
              </div>
            )}
            {user?.vaults.length === 0 ? 
            <div className="overview overviewNoVaults">
                <img src={vault} draggable="false" />
                <h1>You don't currently have any Vaults</h1>
              <h2>To start borrowing, you'll need to open a Vault.</h2>
              <a href="/borrow" className="smallButton">Open a Vault</a>
            </div> : null}
            {totalPages > 1 && <div className="pageSelector">
              {getPageNumbers().map((pageNumber) => (
                <button key={pageNumber} onClick={() => setCurrentPage(pageNumber)} disabled={pageNumber === currentPage}>{pageNumber}</button>
              ))}
            </div>}
          </div>
          <div className="grid homeGrid" style={{ marginBottom: "2.5rem" }}>
            <div className="vaultHeader">
              <div>
                <h1 style={{ fontSize: "1rem", fontWeight: "700", lineHeight: "1.41", marginBottom: user?.balances.lto["0"] > 0 ? "0px" : "5px" }}>Your Positions</h1>
                {user?.balances.lto["0"] > 0 && <div style={{ minWidth: "200px" }}>
                  <DropdownComponent title="Sort by" options={positionSortOptions} value={positionSortOption} onChange={(e) => setPositionSortOption(e.value)} />
                  <button className="imgButton smallButton" style={{ marginLeft: "5px" }} onClick={() => setIsPositionAscending(!isPositionAscending)}><img draggable="false" src={isPositionAscending ? ascending : descending} /></button>
                </div>}
              </div>
            </div>
            {user?.balances.lto["0"] > 0 ?
            <>
              <div className="overview overviewMinimized">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={stxuwulp} draggable="false" />
                  <div>
                    <h1 style={{ marginBottom: "2.5px", fontFamily: "inherit", fontWeight: "600" }}>STX-UWU LP</h1>
                    <h2 style={{ marginBottom: "0" }}>Stackswap</h2>
                  </div>
                </div>
                <span className="mobileDivider"></span>
                <div>
                  <h2 data-tip="">Your Deposit</h2>
                  {showPortfolio ? <h1>${formatTokensDisplay(session?.pools["0"].balances.usd * (user?.balances.lto["0"] / session?.pools["0"].lpToken.shares.formatted))}</h1> : <h1>-</h1>}
                </div>
                <div>
                  <h2 data-tip="">Your Pool Share</h2>
                  <h1>{formatTokensDisplay(user?.balances.lto["0"] / session?.pools["0"].lpToken.shares.formatted * 100)}%</h1>
                </div>
                <div>
                  <h2>Estimated APR</h2>
                  <h1>-</h1>
                </div>
                <span className="mobileDivider" style={{ marginBottom: "5px" }}></span>
                <a href="/earn/stx-uwu-lp" className="smallButton borrowButtonMobile">Manage</a>
              </div>
            </>
            :
            <div className="overview overviewNoVaults">
              <img src={earn} draggable="false" />
              <h1>You don't currently hold any Positions</h1>
              <h2>Begin earning with your assets by using UWU Earn.</h2>
              <a href="/earn" className="smallButton">Open a Position</a>
            </div>}
          </div>
        </div>
      </div> : <LoaderComponent /> }
    </> 
  );
};