import React, { useState, useEffect } from 'react';
import '../styles/slider.css';

const SliderComponent = ({ sliderValue = 0, minSliderValue = 0, maxSliderValue = 100, onChange, disabled }) => {
  const [value, setValue] = useState(value || 0);

  const handleChange = (event) => {
    if (disabled) {
      return;
    };
    const newValue = event.target.value;
    setValue(newValue);
    onChange && onChange(newValue);
  };

  useEffect(() => {
    setValue(sliderValue);
  }, [sliderValue]);

  return (
    <div className={`sliderComponentOuter ${disabled ? 'disabled' : ''}`}>
      <input
        type="range"
        min={minSliderValue}
        max={maxSliderValue}
        value={value}
        step="0.01"
        className="sliderComponent"
        onChange={handleChange}
        disabled={disabled}
      />
    </div>
  );
};

export default SliderComponent;
