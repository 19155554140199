import { STACKS_NETWORK_TYPE } from '../../config/stacks'
import { fetchRetry } from '../../utils/fetchUtils'
import { formatValue } from '../../utils/formattingUtils'
import { getStacksRPC } from './rpcService'

/**
 * Fetches the timestamp of a specific block from the Stacks blockchain
 * @param {number} block The block height for which to fetch the timestamp
 * @returns {Promise<number>} The timestamp of the specified block or zero if not available
 * @throws {Error} If the network request fails or returns a non-ok status
 */
export const getBlockTimestamp = async (block: number): Promise<number> => {
  const endpoint = `${getStacksRPC()}/extended/v1/block/by_height/${block}`
  const response = await fetchRetry(endpoint)

  if (!response.ok) {
    throw new Error(`Failed to fetch timestamp for block '${block}'`)
  }

  const data = await response.json()

  return formatValue(data.burn_block_time ?? 0)
}

/**
 * Fetches and returns the current status of the Stacks layer.
 * It retrieves the general API status, current block height, and the count of transactions in the mempool
 * 
 * @returns {Promise<Object>} The network type, API status, block height, and mempool transaction count, with default values if not available
 * @throws {Error} If the network request fails or returns a non-ok status
 */
export const getNetworkStatus = async (): Promise<object> => {
  const networkType = STACKS_NETWORK_TYPE
  
  const statusEndpoint = `${getStacksRPC()}/extended/v1/status`
  const statusResponse = await fetchRetry(statusEndpoint)

  if (!statusResponse.ok) {
    throw new Error(`Failed to fetch Stacks Blockchain API status`)
  }

  const statusData = await statusResponse.json()
  
  const apiStatus = statusData.status || ''
  const blockHeight = formatValue(statusData.chain_tip?.block_height || 0)

  const mempoolEndpoint = `${getStacksRPC()}/extended/v1/tx/mempool/stats`
  const mempoolResponse = await fetchRetry(mempoolEndpoint)

  if (!mempoolResponse.ok) {
    throw new Error(`Failed to fetch Stacks mempool stats`)
  }

  const mempoolData = await mempoolResponse.json()

  const mempoolCount = Object.values(mempoolData.tx_type_counts).reduce((acc, count) => acc + count, 0) || 0


  return { networkType, apiStatus, blockHeight, mempoolCount }
}