import { FungibleConditionCode, PostConditionMode, principalCV, uintCV } from '@stacks/transactions'
import { STACKS_NETWORK, STACKS_NETWORK_TYPE } from '../../config/stacks'
import { BITFLOW_CONTRACT_ADDRESSES, BITFLOW_CONTRACT_NAMES, BITFLOW_FUNCTION_NAMES, UWU_CONTRACT_ADDRESSES } from '../../constants/smartContracts'
import { ProtocolName } from '../../enums/protocols'
import { LPToken, Token } from '../../interfaces/tokens'
import { readOnlyRetry } from '../../utils/fetchUtils'
import { formatTokenValue, formatValue } from '../../utils/formattingUtils'
import { SwapTransaction } from '../../interfaces/transactions'
import { User } from '../../interfaces/user'
import { useCallback } from 'react'
import { getLPTokenContract, getTokenContract } from '../../utils/tokenUtils'
import { useConnect as stacksConnect } from '@stacks/connect-react'
import { createContractPostCondition, createUserPostCondition } from '../../utils/postConditionUtils'
import { swapEnabledTokens } from '../../constants/tokens'
import { SwapAmounts } from '../../interfaces/swap'

export const getBitFlowSwapAmounts = async (lpToken: LPToken, inputToken: Token, outputToken: Token, amount: number, slippageTolerance: number): Promise<SwapAmounts> => {
  if (isNaN(amount) || amount === 0 || amount === null || amount === '' || !swapEnabledTokens.includes(inputToken.name) || !swapEnabledTokens.includes(outputToken.name) || lpToken.protocol !== ProtocolName.BITFLOW || !lpToken.enabled) {
    return {
      amount: amount,
      est: {
        formatted: 0,
        unformatted: 0
      },
      min: {
        formatted: 0,
        unformatted: 0
      },
      swapFee: lpToken.fee,
      priceImpact: 0,
      slippageTolerance: slippageTolerance
    }
  }

  const inputTokenContractDetails = inputToken.contract[STACKS_NETWORK_TYPE]
  const outputTokenContractDetails = outputToken.contract[STACKS_NETWORK_TYPE]
  const lpTokenContractDetails = lpToken.lptContract[STACKS_NETWORK_TYPE]

  if (!inputTokenContractDetails?.address || !inputTokenContractDetails?.name) {
    throw new Error(`Contract details for Token '${inputToken.name}' are missing`)
  }

  if (!outputTokenContractDetails?.address || !outputTokenContractDetails?.name) {
    throw new Error(`Contract details for Token '${outputToken.name}' are missing`)
  }

  if (!lpTokenContractDetails?.address || !lpTokenContractDetails?.name) {
    throw new Error(`Contract details for LP Token '${lpToken.name}' are missing`)
  }

  const fee = (amount * lpToken.fee)
  const scaledAmount = formatValue((amount - fee) * inputToken.decimals, 0)

  const functionName = inputToken.name === lpToken.xToken.name ? BITFLOW_FUNCTION_NAMES.GET_DY : BITFLOW_FUNCTION_NAMES.GET_DX

  const response = await readOnlyRetry({
    contractAddress: lpTokenContractDetails.address,
    contractName: lpTokenContractDetails.name,
    functionName: functionName,
    functionArgs: [
      uintCV(scaledAmount)
    ],
    network: STACKS_NETWORK,
    senderAddress: UWU_CONTRACT_ADDRESSES.DEPLOYER,
  })

  if (response?.value?.value === null) {
    throw new Error(`Failed to fetch estimated amount received using LP Token '${lpToken.name}'`)
  }

  const formattedResponse = formatValue(response.value.value)

  const estReceived = formatValue(formattedResponse, 0)
  const minReceived = formatValue(estReceived * (1 - slippageTolerance), 0)

  const formattedEstReceived = formatTokenValue(estReceived, outputToken)
  const formattedMinReceived = formatTokenValue(minReceived, outputToken)

  return {
    amount: amount,
    est: {
      formatted: formattedEstReceived,
      unformatted: estReceived
    },
    min: {
      formatted: formattedMinReceived,
      unformatted: minReceived
    },
    swapFee: lpToken.fee,
    priceImpact: 0,
    slippageTolerance: slippageTolerance
  }
}

export const useBitFlowSwap = () => {
  const { doContractCall } = stacksConnect()

  const handleBitFlowSwap = useCallback(
    async (user: User, options: SwapTransaction) => {
      const inputToken = options.tokens.input
      const outputToken = options.tokens.output
      const lpToken = options.tokens.lp

      if (!lpToken) {
        throw new Error(`No valid LP Token was provided for protocol '${ProtocolName.BITFLOW}'`)
      }

      const lpTokenContractDetails = lpToken.lptContract[STACKS_NETWORK_TYPE]

      if (lpToken.protocol !== ProtocolName.BITFLOW) {
        throw new Error(`Protocol used by LP Token '${lpToken.name}' doesn't match protocol '${ProtocolName.BITFLOW}'`)
      }

      if (!lpTokenContractDetails?.address || !lpTokenContractDetails?.name) {
        throw new Error(`Contract details for LP Token '${lpToken.name}' are missing`)
      }

      const xTokenContract = getTokenContract(lpToken.xToken, STACKS_NETWORK_TYPE, true, ProtocolName.BITFLOW)!
      const yTokenContract = getTokenContract(lpToken.yToken, STACKS_NETWORK_TYPE, true, ProtocolName.BITFLOW)!
      const lpTokenContract = getLPTokenContract(lpToken, STACKS_NETWORK_TYPE, true)!

      const functionName = inputToken.name === lpToken.xToken.name ? BITFLOW_FUNCTION_NAMES.SWAP_X_FOR_Y : BITFLOW_FUNCTION_NAMES.SWAP_Y_FOR_X

      const scaledAmount = formatValue(options.amounts.amount * inputToken.decimals, 0)
      const minReceived = options.amounts.min.unformatted

      const postConditions = [
        createUserPostCondition(
          user?.address,
          FungibleConditionCode.Equal,
          scaledAmount,
          inputToken
        ),
        createContractPostCondition(
          lpTokenContractDetails.address,
          lpTokenContractDetails.name,
          FungibleConditionCode.GreaterEqual,
          minReceived,
          outputToken
        )
      ]

      const tx = await new Promise((resolve, reject) => {
        doContractCall({
          contractAddress: BITFLOW_CONTRACT_ADDRESSES.DEPLOYER,
          contractName: BITFLOW_CONTRACT_NAMES.CORE,
          functionName: functionName,
          functionArgs: [
            principalCV(lpTokenContract),
            principalCV(xTokenContract),
            principalCV(yTokenContract),
            uintCV(scaledAmount),
            uintCV(minReceived)
          ],
          postConditionMode: PostConditionMode.Deny,
          postConditions: postConditions,
          network: STACKS_NETWORK,
          onFinish: (result) => {
            resolve(result)
          },
          onCancel: (error) => {
            reject(error)
          }
        })
      })

      return tx
    },
    [doContractCall]
  )

  return { handleBitFlowSwap }
}