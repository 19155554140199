import React, { useState, useEffect, useRef } from "react";
import { useConnect } from "../lib/auth.js";
import { NavLink, useNavigate } from "react-router-dom";
import { trunc } from "../lib/utils.js";

import feature from "../img/feature.png";
import guide from "../img/guide.png";
import error from "../img/error.png";

export default function HelpButtonMenuPopupComponent({ show, toggleMenu, showGuide }) {
  const [visibleClass, setVisibleClass] = useState("");
  const navigate = useNavigate();

  const menuRef = useRef();

  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (show) {
      setVisibleClass('visible');
    } else {
      setVisibleClass('');
    };
  }, [show]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        toggleMenu();
      };
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  return (
    <>
      <div className={`helpButtonMenu ${visibleClass}`} ref={menuRef}>
        <div className="popupInterface">
          <div className="popupHeader">
            {currentPage > 0 && <a onClick={() => setCurrentPage(currentPage - 1)}>{`<-`}</a>}
            <h1>{currentPage === 0 ? "Help Center" : "Explore Guides"}</h1>
            <svg onClick={toggleMenu} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M6 6L18 18" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
          </div>
          {currentPage === 0 &&
            <div className="activityView helpButtonMenuView" style={{ minHeight: "unset", maxHeight: "unset" }}>
              <a className="activityItem noSelect" href="http://chat.uwu.cash" target="_blank">
                <img draggable="false" style={{ width: "30px", height: "30px", padding: "0" }} src={error} />
                <div>
                  <h1>Report a Bug</h1>
                </div>
              </a>
              <a className="activityItem noSelect" href="http://chat.uwu.cash" target="_blank">
                <img draggable="false" style={{ width: "30px", height: "30px", padding: "0" }} src={feature} />
                <div>
                  <h1>Suggest a Feature</h1>
                </div>
              </a>
              <a className="activityItem noSelect" onClick={() => setCurrentPage(1)}>
                <img draggable="false" style={{ width: "30px", height: "30px", padding: "0" }} src={guide} />
                <div>
                  <h1>Explore Guides</h1>
                </div>
              </a>
            </div>
          }
          {currentPage === 1 &&
            <div className="activityView helpButtonMenuView" style={{ minHeight: "unset", maxHeight: "unset" }}>
              <h2 style={{ marginTop: "0", marginBottom: "20px" }}>More guides will be added soon.</h2>
              <a className="activityItem noSelect" onClick={() => showGuide(0)} target="_blank">
                <img draggable="false" style={{ width: "30px", height: "30px", padding: "0" }} src={guide} />
                <div>
                  <h1>1. Welcome Guide</h1>
                </div>
              </a>
            </div>
          }
        </div>
      </div>
    </>
  );
};