import { ALERT_TYPES } from '../constants/alerts'
import { TX_REVIEW_HEADERS, TX_REVIEW_DESCRIPTIONS, TX_REVIEW_CONTENT_NAMES, TX_REVIEW_CONTENT_VALUES, TX_REVIEW_TITLES, TX_SUBMITTED_TITLES, TX_SUBMITTED_HEADERS, TX_SUBMITTED_DESCRIPTIONS } from '../constants/popups'
import { Alert } from '../interfaces/alert'
import { LPToken, Token } from '../interfaces/tokens'
import { SwapTransaction, TransactionArgument } from '../interfaces/transactions'
import { formatUIValue } from './formattingUtils'
import { getSwapFee } from './swapUtils'
import { NOTIFICATION_DESCRIPTIONS, NOTIFICATION_LINKS, NOTIFICATION_TITLES } from '../constants/notifications'
import { SwapAmounts } from '../interfaces/swap'
import { ProtocolName } from '../enums/protocols'

export const uintToNumber = (uint: string): number => {
  return parseInt(uint.slice(1))
}

export const getArgumentsAsObject = (args: TransactionArgument[]): { [key: string]: any } => {
  return args.reduce<{ [key: string]: any }>((acc, arg) => {
    acc[arg.name] = arg.type === 'uint' ? uintToNumber(arg.repr) : arg.repr
    
    return acc
  }, {})
}

export const getSwapTransaction = (alerts: { message: string, type: Alert }[], protocol: ProtocolName, inputToken: Token, outputToken: Token, amounts: SwapAmounts, lpToken?: LPToken): SwapTransaction => {
  const meetsTxRequirements = () => {
    return amounts.amount > 0 && alerts.filter(alert => alert.type === ALERT_TYPES.WARNING).length === 0
  }

  return {
    reviewOptions: {
      title: TX_REVIEW_TITLES.DEFAULT,
      header: TX_REVIEW_HEADERS.DEFAULT,
      description: TX_REVIEW_DESCRIPTIONS.DEFAULT,
      content: [
        [
          {
            name: TX_REVIEW_CONTENT_NAMES.SEND,
            value: formatUIValue(amounts.amount) + ` ${inputToken.symbol}`
          },
          {
            name: TX_REVIEW_CONTENT_NAMES.RECEIVE,
            value: formatUIValue(amounts.est.formatted) + ` ${outputToken.symbol}`
          },
        ],
        [
          {
            name: TX_REVIEW_CONTENT_NAMES.SWAP_FEE,
            value: getSwapFee(inputToken, amounts.amount, amounts.swapFee)
          },
          {
            name: TX_REVIEW_CONTENT_NAMES.MINIMUM_RECEIVED,
            value: formatUIValue(amounts.min.formatted, 4) + ` ${outputToken.symbol}`
          },
          {
            name: TX_REVIEW_CONTENT_NAMES.SLIPPAGE_TOLERANCE,
            value: protocol === ProtocolName.USM ? TX_REVIEW_CONTENT_VALUES.NOT_REQUIRED : formatUIValue(amounts.slippageTolerance * 100) + '%'
          }
        ]
      ],
    },
    submittedOptions: {
      title: TX_SUBMITTED_TITLES.SWAP_CONFIRMATION,
      header: TX_SUBMITTED_HEADERS.SWAP_CONFIRMATION,
      description: TX_SUBMITTED_DESCRIPTIONS.DEFAULT,
      icon: '/assets/checkmark.png'
    },
    notificationOptions: {
      title: NOTIFICATION_TITLES.SWAP,
      description: NOTIFICATION_DESCRIPTIONS.SWAP.replace('${inputValue}', formatUIValue(amounts.amount) + ` ${inputToken.symbol}`).replace('${outputValue}', formatUIValue(amounts.est.formatted) + ` ${outputToken.symbol}`),
      link: NOTIFICATION_LINKS.EXPLORER,
      isExplorerLink: true
    },
    tokens: {
      lp: lpToken,
      input: inputToken,
      output: outputToken
    },
    amounts: amounts,
    protocol: protocol,
    valid: meetsTxRequirements()
  }
}