import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useOutletContext } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import { ProtocolName } from '../enums/protocols.ts'
import { TokenName } from '../enums/tokens.ts'
import { Token } from '../interfaces/tokens.ts'
import { tokensMap } from '../constants/tokens.ts'
import { UWU_CONTRACT_ADDRESSES } from '../constants/smartContracts.ts'
import { getArgumentsAsObject, getSwapTransaction } from '../utils/transactionUtils.ts'
import { formatUIValue, formatValue } from '../utils/formattingUtils.ts'
import { getUserTransactions } from '../infra/services/userService.ts'
import { getSwapTokenList } from '../utils/swapUtils.ts'
import { getUSMSwapAmounts, useUSMSwap } from '../infra/services/usmService.ts'
import { getUSMSwapFee } from '../utils/usmUtils.ts'
import { getUSMAlerts } from '../infra/services/alertService.ts'

import LoaderComponent from '../components/LoaderComponent.js'
import ActivityPopupComponent from '../popups/ActivityPopupComponent.js'
import DropdownTokenComponent from '../popups/DropdownTokenComponent.js'
import TransactionHandler from '../components/TransactionHandlerComponent.tsx'
import WarningComponent from '../components/WarningComponent.tsx'

import earnusr from '../img/earn-usr.png'
import warn from '../img/warn.png'
import swap from '../img/switch.png'
import loader from '../img/loader.svg'

const StabilityModulePage = () => {
  document.title = 'Stability Module | UWU Protocol'
  
  const [session, user] = useOutletContext<{ session: any; user: any }>()

  const newSession = session?.v2
  const newUser = user?.v2

  const transactionHandlerRef = useRef(null)

  const { handleUSMSwap } = useUSMSwap()

  const [sendInputField, setSendInputField] = useState<string | number>('')

  const { inputTokenList, outputTokenList } = getSwapTokenList([ProtocolName.USM])
  const [selectedInputToken, setSelectedInputToken] = useState(tokensMap[TokenName.UWU])
  const [selectedOutputToken, setSelectedOutputToken] = useState(tokensMap[TokenName.SUSDT])

  let usmSwapAmounts = getUSMSwapAmounts(newSession?.swap?.usm, selectedInputToken, selectedOutputToken, sendInputField)
  usmSwapAmounts.swapFee = usmSwapAmounts.swapFee / 10000

  const usmAlerts = getUSMAlerts(newSession, newUser, selectedInputToken, selectedOutputToken, usmSwapAmounts)
  const usmTransactionData = getSwapTransaction(usmAlerts, ProtocolName.USM, selectedInputToken, selectedOutputToken, usmSwapAmounts)

  const [showActivityPopup, setShowActivityPopup] = useState(false)

  const [pendingTransactions, setPendingTransactions] = useState<any[]>([])

  const handleNumericInput = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    const allowedKeys = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight', '.']
    const inputValue = e.currentTarget.value

    const isAllowedKey = allowedKeys.includes(e.key)
    const isSingleDot = e.key === '.' && !inputValue.includes('.')

    if (isAllowedKey && (e.key !== '.' || isSingleDot)) {
      return
    }

    if (!/^\d$/.test(e.key)) {
      e.preventDefault()
    }
  }

  const handleTokenChange = useCallback((e: { token: Token } | null = null, mode: 'switch' | 'input' | 'output'): void => {
    if (mode === 'switch') {
      const inputToken = selectedInputToken

      setSelectedInputToken(selectedOutputToken)
      setSelectedOutputToken(inputToken)

      return
    }

    if (e === null) return

    const token = e.token

    if (mode === 'input') {
      if (token === selectedOutputToken) {
        setSelectedOutputToken(selectedInputToken)
      }

      setSelectedInputToken(token)
    } else if (mode === 'output') {
      if (token === selectedInputToken) {
        setSelectedInputToken(selectedOutputToken)
      }

      setSelectedOutputToken(token)
    }
  }, [selectedInputToken, selectedOutputToken])

  const fetchPendingTransactions = async () => {
    try {
      const res = await getUserTransactions(user?.address, true)
      const filteredTransactions = res.filter(transaction => {
        const { contract_call } = transaction
        const { function_name, function_args } = contract_call
        const args = getArgumentsAsObject(function_args)

        const names = ['swap-x-for-y', 'swap-y-for-x']

        return names.includes(function_name) && contract_call.contract_id.includes(UWU_CONTRACT_ADDRESSES.DEPLOYER)
      })

      setPendingTransactions(filteredTransactions)
    } catch (error) {

    }
  }

  const handleStacksTransaction = async () => {
    if (usmTransactionData && usmTransactionData.valid && transactionHandlerRef.current) {
      transactionHandlerRef.current.handleInitiate(() => handleUSMSwap(newUser, usmTransactionData), usmTransactionData)
    }
  }

  useEffect(() => {
    if (session && user) {

      fetchPendingTransactions()
    }
  }, [user, session])

  return (
    <>
      {showActivityPopup && <ActivityPopupComponent show={(e) => setShowActivityPopup(e)} address={user?.address} />}
      {(session && user) ?
      <div className='core' style={{ marginTop: 'auto' }}>
        <TransactionHandler ref={transactionHandlerRef} />
        <ReactTooltip className='defaultTooltip' effect='solid' multiline={true} arrowColor='#000000' padding='10px 12px' />
        <div className='vaultHeader vaultHeaderPadding'>
          <div className='vaultHeaderTitle' style={{ justifyContent: 'inherit' }}>
            <a href='/earn' style={{ lineHeight: '0', marginRight: '0.5rem', fontSize: '1.25em', marginBottom: '0.1rem' }}>{`<-`}</a>
            <div style={{ display: "flex", gridGap: "0.5rem", alignItems: "center", marginBottom: '0', flexDirection: 'row' }}><h1 style={{ marginBottom: 'unset' }}>Stability Module</h1><a style={{ color: "#FFFFFF", backgroundColor: "#FF7BED", padding: "3px 7px", borderRadius: "100px", fontSize: "0.65rem", textDecoration: "none", lineHeight: 'unset', cursor: 'unset' }}>V2</a></div>
          </div>
          <div className='vaultHeaderDivider'></div>
        </div>
        <div className='pendingTxnHome pendingTxnHomeMobile' style={{ marginBottom: '15px', backgroundColor: '#fefaf7', borderColor: '#f3dac2', justifyContent: 'unset' }}>
          <img src={warn} draggable='false' style={{ width: '40px', height: '40px', padding: '0' }} />
          <div>
            <h1 style={{ color: '#d88934' }}>Legacy sUSDT is no longer supported</h1>
            <h2>If you have legacy sUSDT, you can migrate your tokens on XLink.</h2>
          </div>
          <a href='https://app.xlink.network/' target='_blank' className='smallButton'>Migrate on XLink</a>
        </div>
        {pendingTransactions.length > 0 && <div className='pendingTxnHome pendingTxnHomeMobile' style={{ marginBottom: '15px' }}>
          <img src={loader} draggable='false' />
          <div>
            <h1>You have {pendingTransactions.length} pending swap{pendingTransactions.length > 1 && 's'} via the Stability Module</h1>
            <h2>Please allow 10-30 minutes for the transaction{pendingTransactions.length > 1 && 's'} to be completed.</h2>
          </div>
          <a onClick={() => setShowActivityPopup(true)} className='smallButton'>View Recent Activity</a>
        </div>}
        <div className='grid' style={{ gridTemplateColumns: '1.125fr 0.9fr' }}>
          <div className='earnDiv' style={{ maxWidth: '510px', height: 'min-content' }}>
            <div className='earnDesc'>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1.25rem' }}>
                <img draggable='false' src={earnusr} style={{ marginBottom: '0' }} />
                <a href='https://docs.uwu.cash/optional-services/stability-module' target='_blank' className='smallButton'>Learn More</a>
              </div>
              <h1>Stability Module</h1>
              <h2>Swap between UWU and various assets at predefined rates. With the Stability Module, you can swap without slippage and with low to no fees.</h2>
              <h3>What is the Stability Module?</h3>
              <h2>The Stability Module is a tool designed to stabilize the price of UWU by enabling direct swaps between UWU and various assets, such as highly liquid stablecoins. Users can swap UWU and these stablecoins at predefined rates, typically subject to a swap fee.</h2>
              <h3>How does it work?</h3>
              <h2>The Stability Module holds reserves of UWU and various assets. Users can swap UWU for another asset, or vice versa, at predefined rates, typically subject to a swap fee. For example, if the rate between UWU and sUSDT is 1:1, users receive 1 sUSDT for every 1 UWU swapped, minus any swap fee.</h2>
              <h2>This approach differs from conventional Peg Stability Modules (PSMs) because it does not mint or burn UWU, meaning the assets held in the Stability Module's reserves do not back UWU.</h2>
              <div style={{ margin: '25px -25px '}} className='borrowDivider'></div>
              <div className='poolOverviewGrid' style={{ marginBottom: '0' }}>
                <div className='stabilityReservesDiv poolCompositionDiv' style={{ borderRadius: '10px' }}>
                  <h2 style={{ marginBottom: '1rem' }}>Reserves Composition</h2>
                  {Object.keys(newSession?.swap?.usm?.reserves).map((reserve) => {
                    const token = tokensMap[reserve]

                    return (
                      <div>
                        <img draggable='false' src={token.icon} />
                        <h1>{token.symbol}</h1>
                        <h2>{formatUIValue(newSession?.swap?.usm?.reserves[token.name].formatted)} {token.symbol}</h2>
                      </div>
                    )
                })}
                </div>
                <div className='stabilityReservesDiv poolCompositionDiv' style={{ borderRadius: '10px' }}>
                  <h2 style={{ marginBottom: '1rem' }}>Price Comparison</h2>
                  {Object.keys(newSession?.swap?.usm?.reserves).map((reserve) => {
                    const token = tokensMap[reserve]

                    return (
                      <div className='overviewGrid3'>
                        <img draggable='false' src={token.icon} />
                        <h1>{token.symbol}</h1>
                        <h2 data-tip={`The current price of ${token.symbol} through the<br>Stability Module and on the open market`}>${formatUIValue(newSession?.swap?.usm?.tokens[reserve].rate / 1000000)} • ${formatUIValue(session?.prices[token.name])}</h2>
                      </div>
                    )
                })}
                </div>
              </div>
            </div>
          </div>
          <div className='stabilityModuleSwapDiv' style={{ maxWidth: '450px' }}>
            <div className='overview borrowDisplay' style={{ height: 'max-content' }}>
              <div className='overviewHeader manageVaultHeader'>
                <div className='inner'>
                  <h1 style={{ marginTop: '5px', marginBottom: '5px' }}>Swap</h1>
                </div>
                <div></div>
              </div>
              <div className='borrowInnerDiv manageVaultInnerDiv'>
                <div style={{ marginTop: '5px', maxHeight: 'fit-content' }}>
                  <div className='borrowInputTitle'>
                    <h1>Send {selectedInputToken?.symbol}</h1>
                    <h2 onClick={() => { setSendInputField(formatValue(newUser?.balances?.[selectedInputToken.name]?.formatted)) }}>Balance: {formatUIValue(newUser?.balances?.[selectedInputToken.name]?.formatted)} {selectedInputToken?.symbol}</h2>
                  </div>
                  <span className='borrowInput borrowInputPrice' style={{ marginBottom: '20px' }}><div style={{ width: '100%' }}><input inputmode='decimal' maxLength='10' type='text' placeholder={`0.00 ${selectedInputToken?.symbol}`} onChange={(e) => setSendInputField(e.target.value.toString().split('.').map((el, i) => i ? el.split('').slice(0, 2).join('') : el).join('.').replace(/^0+(?=\d)/, ''))} onKeyDown={(e) => handleNumericInput(e)} value={sendInputField} /><h4>≈ ${formatUIValue(sendInputField * session?.prices?.[selectedInputToken.name])}</h4></div><div className='swapOutputCurrency' style={{ border: '0', marginRight: '12px' }}><DropdownTokenComponent options={inputTokenList} label={selectedInputToken?.symbol} onChange={(e) => handleTokenChange(e, 'input')} /></div></span>
                  {usmAlerts.filter(alert => alert.section === 0).map((alert, index) => (
                    <WarningComponent key={index} message={alert.message} type={alert.type} />
                  ))}
                  <div className='swapSwitcher'>
                    <a onClick={() => { handleTokenChange(null, 'switch') }} className='imgButton smallButton' style={{ border: '1px solid #F0F0F0', boxShadow: 'none', outline: '4px solid #FFFFFF' }}><img draggable='false' src={swap} /></a>
                  </div>
                  <div className='borrowInputTitle'>
                    <h1>Receive {selectedOutputToken?.symbol}</h1>
                  </div>
                  <div className='swapOutput borrowInputPrice' style={{ padding: '10px 15px' }}><div style={{ width: '100%' }}><h1>{formatUIValue(usmSwapAmounts?.est?.formatted)}</h1><h4 style={{ margin: '0' }}>≈ ${formatUIValue(usmSwapAmounts?.est?.formatted * session?.prices?.[selectedOutputToken.name])}</h4></div><div className='swapOutputCurrency' style={{ border: '0', marginRight: '-3px' }}><DropdownTokenComponent options={outputTokenList} label={selectedOutputToken?.symbol} onChange={(e) => handleTokenChange(e, 'output')} /></div></div>
                  <div className='borrowOutputDetails' style={{ marginTop: '-5px' }}>
                    <h1>Swap Fee</h1>
                    <h2>{getUSMSwapFee(newSession?.swap?.usm, selectedInputToken, selectedOutputToken, sendInputField, (usmSwapAmounts.swapFee * 10000))}</h2>
                    <h1>Minimum Received</h1>
                    <h2>{formatUIValue(usmSwapAmounts?.est?.formatted, 4)} {selectedOutputToken?.symbol}</h2>
                  </div>
                </div>
                <div className='borrowDivider'></div>
                <a onClick={() => { handleStacksTransaction() }} style={{ backgroundColor: usmTransactionData?.valid ? '#000000' : '#575757', cursor: usmTransactionData?.valid ? 'pointer' : 'default' }} className='borrowButton'>{sendInputField ? 'Swap' : 'Enter an Amount'}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      : <LoaderComponent />}
    </>
  )
}

export default StabilityModulePage