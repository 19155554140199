import React, { useState, useEffect, useRef } from "react";
import { useConnect } from "../lib/auth.js";
import { NavLink, useNavigate } from "react-router-dom";
import { trunc } from "../lib/utils.js";

import checkmark from "../img/checkmark.png";

export default function HeaderMenuPopupComponent({show, toggleMenu, showApi, showActivity, showAssets}) {
  const [visibleClass, setVisibleClass] = useState("");
  const { handleSignOut } = useConnect();
  const [theme, setTheme] = useState("light-mode");
  const [api, setApi] = useState("Hiro");
  const navigate = useNavigate();

  const menuRef = useRef();

  useEffect(() => {
    if (show) {
      setVisibleClass('visible');
    } else {
      setVisibleClass('');
    };
  }, [show]);

  useEffect(() => {
    let storedPreferences = JSON.parse(localStorage.getItem("uwu-preferences"));
    if (storedPreferences) {
      if (storedPreferences.theme) {
        setTheme(storedPreferences.theme);
      };

      if (storedPreferences.api) {
        if (storedPreferences.api === "https://api.hiro.so") {
          setApi("Hiro");
        } else if (storedPreferences.api === "https://stacks-blockchain-lb.alexlab.co") {
          setApi("ALEX");
        } else {
          setApi("Custom");
        };
      };
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        toggleMenu();
      };
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  function toggleTheme() {
    let storedPreferences = JSON.parse(localStorage.getItem("uwu-preferences"));
    if (storedPreferences) {
      if(storedPreferences.theme === "light-mode") {
        storedPreferences.theme = "dark-mode";
        setTheme("dark-mode");
      } else if(storedPreferences.theme === "dark-mode") {
        storedPreferences.theme = "light-mode";
        setTheme("light-mode");
      };
      localStorage.setItem("uwu-preferences", JSON.stringify(storedPreferences));
      document.querySelector('html').setAttribute('data-theme', storedPreferences.theme);
      document.querySelector('body').setAttribute('data-theme', storedPreferences.theme);
    };
  };

  return (
    <>
      <div className={`headerMenu ${visibleClass}`} ref={menuRef}>
        <div onClick={() => { navigate('/'); toggleMenu(); }} className="headerOnMobile">
          <h1><NavLink to="/" className="navLink">Home</NavLink></h1>
        </div>
        <div onClick={() => { navigate('/borrow'); toggleMenu(); }} className="headerOnMobile">
          <h1><NavLink to="/borrow" className="navLink">Borrow</NavLink></h1>
        </div>
        <div onClick={() => { navigate('/earn'); toggleMenu(); }} className="headerOnMobile">
          <h1><NavLink to="/earn" className="navLink">Earn</NavLink></h1>
        </div>
        <div onClick={() => { navigate('/swap'); toggleMenu(); }} className="headerOnMobile">
          <h1><div style={{ padding: "0" }}><NavLink to="/swap" className="navLink">Swap</NavLink><a href="/swap" style={{ color: "#FFFFFF", backgroundColor: "#FF7BED", padding: "3px 7px", borderRadius: "100px", fontSize: "0.65rem", textDecoration: "none" }}>V2</a></div></h1>
        </div>
        <div className="divider headerOnMobile"></div>
        <div onClick={() => { showAssets(); toggleMenu(); }}>
          <h1>Manage Account</h1>
        </div>
        <div onClick={() => { showActivity(); toggleMenu(); }}>
          <h1>Recent Activity</h1>
        </div>
        <div className="divider"></div>
        <div onClick={() => { showApi(); toggleMenu(); }}>
          <h1>API Provider</h1>
          <h2>{api}</h2>
        </div>
        <div onClick={toggleTheme}>
          <h1>Dark Mode</h1>
          <h2>{theme === "dark-mode" ? "On" : "Off"}</h2>
        </div>
        <div className="divider"></div>
        <div onClick={handleSignOut}>
          <h1>Sign Out</h1>
        </div>
      </div>
    </>
  );
};