import { STACKS_NETWORK_TYPE } from '../config/stacks'
import { UWU_CONTRACT_NAMES, UWU_CONTRACT_ADDRESSES, STACKSWAP_CONTRACT_NAMES, STACKSWAP_CONTRACT_ADDRESSES, ALEX_CONTRACT_NAMES, ALEX_CONTRACT_ADDRESSES, BITFLOW_CONTRACT_NAMES, BITFLOW_CONTRACT_ADDRESSES } from '../constants/smartContracts'
import { userEnabledTokens, lpEnabledTokens } from '../constants/tokens'
import { getTokensMapArray, getTokenContract, getLPTokensMapArray, getLPTokenContract } from './tokenUtils'

export const getUserRelevantContracts = (): Array<string> => {
  const contracts: Array<string> = []

  const availableTokens = getTokensMapArray().filter(token => userEnabledTokens.includes(token.name) && token.enabled)

  availableTokens.forEach(token => {
    const contract = getTokenContract(token, STACKS_NETWORK_TYPE, true)

    if (contract !== null) {
      contracts.push(contract)
    }
  })

  const availableLPTokens = getLPTokensMapArray().filter(lpToken => lpEnabledTokens.includes(lpToken.name) && lpToken.enabled)

  availableLPTokens.forEach(lpToken => {
    const contract = getLPTokenContract(lpToken, STACKS_NETWORK_TYPE, true)

    if (contract !== null) {
      contracts.push(contract)
    }
  })

  Object.values(UWU_CONTRACT_NAMES).forEach(name => contracts.push(`${UWU_CONTRACT_ADDRESSES.DEPLOYER}.${name}`))
  Object.values(STACKSWAP_CONTRACT_NAMES).forEach(name => contracts.push(`${STACKSWAP_CONTRACT_ADDRESSES.DEPLOYER}.${name}`))
  //Object.values(ALEX_CONTRACT_NAMES).forEach(name => contracts.push(`${ALEX_CONTRACT_ADDRESSES.DEPLOYER}.${name}`))
  //Object.values(BITFLOW_CONTRACT_NAMES).forEach(name => contracts.push(`${BITFLOW_CONTRACT_ADDRESSES.DEPLOYER}.${name}`))

  return contracts
}