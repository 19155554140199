import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useOutletContext } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import { ProtocolName } from '../enums/protocols.ts'
import { lpTokensMap, tokensMap } from '../constants/tokens.ts'
import { TokenName } from '../enums/tokens.ts'
import { getSwapSession } from '../infra/services/sessionService.ts'
import { getSwapFee, getSwapRoutes, getSwapTokenList } from '../utils/swapUtils.ts'
import { getStackswapSwapAmounts, useStackswapSwap } from '../infra/services/stswService.ts'
import { getUSMSwapAmounts, useUSMSwap } from '../infra/services/usmService.ts'
import { Token } from '../interfaces/tokens.ts'
import { getSwapTransaction } from '../utils/transactionUtils.ts'
import { formatUIValue, formatValue } from '../utils/formattingUtils.ts'
import { getAlexSwapAlerts, getBitFlowSwapAlerts, getStackswapSwapAlerts, getUSMAlerts } from '../infra/services/alertService.ts'
import { Alert } from '../interfaces/alert.ts'
import { Transaction, SwapTransaction } from '../interfaces/transactions.ts'
import { ALERT_TYPES, SWAP_ALERT_DESCRIPTIONS } from '../constants/alerts.ts'
import { SwapAmounts, SwapData, SwapRoute, SwapSettings } from '../interfaces/swap.ts'
import { swapEnabledProtocols } from '../constants/protocols.ts'
import { getLPTokensMapArray } from '../utils/tokenUtils.ts'
import { getAlexSwapAmounts, useAlexSwap } from '../infra/services/alexService.ts'
import { getBitFlowSwapAmounts, useBitFlowSwap } from '../infra/services/bitflowService.ts'

import DropdownTokenComponent from '../popups/DropdownTokenComponent.js'
import SwapSettingsPopup from '../popups/new/SwapSettingsPopup.tsx'
import LoaderComponent from '../components/LoaderComponent.js'
import TransactionHandler from '../components/TransactionHandlerComponent.tsx'
import WarningComponent from '../components/WarningComponent.tsx'
import SwapRoutePopup from '../popups/new/SwapRoutePopup.tsx'

import earnswap from '../img/earn-swap.png'
import swap from '../img/switch.png'
import gear from '../img/gear.png'
import reload from '../img/reload.png'
import loader from '../img/loader.svg'

const SwapPage = () => {
  document.title = 'Swap | UWU Protocol'

  const [session, user] = useOutletContext<{ session: any; user: any }>()
  const [swapSession, setSwapSession] = useState<SwapData>()

  const newSession = session?.v2
  const newUser = user?.v2

  const transactionHandlerRef = useRef(null)

  const { handleUSMSwap } = useUSMSwap()
  const { handleStackswapSwap } = useStackswapSwap()
  const { handleAlexSwap } = useAlexSwap()
  const { handleBitFlowSwap } = useBitFlowSwap()

  const [swapSettings, setSwapSettings] = useState<SwapSettings>({
    liquiditySources: swapEnabledProtocols,
    slippageTolerance: 0.01,
    refreshRate: 60000
  })

  const availableLPTokens = getLPTokensMapArray().filter(lpToken => lpToken.enabled && swapSettings.liquiditySources.includes(lpToken.protocol))

  const [sendInputField, setSendInputField] = useState<string | number>('')

  const { inputTokenList, outputTokenList } = getSwapTokenList([ProtocolName.STACKSWAP, ProtocolName.USM])
  const [selectedInputToken, setSelectedInputToken] = useState(tokensMap[TokenName.STX])
  const [selectedOutputToken, setSelectedOutputToken] = useState(tokensMap[TokenName.UWU])

  const initialSwapAmounts: SwapAmounts = { amount: 0, est: { formatted: 0, unformatted: 0 }, min: { formatted: 0, unformatted: 0 }, swapFee: 0, priceImpact: 0, slippageTolerance: 0.01 }
  const initialSwapAlerts: { section: number; type: Alert; message: string }[] = []
  const initialSwapTransactionData: Transaction | null = null

  const [swapRoutes, setSwapRoutes] = useState<SwapRoute[]>([])
  const [swapAmounts, setSwapAmounts] = useState<SwapAmounts>(initialSwapAmounts)
  const [swapAlerts, setSwapAlerts] = useState<{ section: number; type: Alert; message: string }[]>(initialSwapAlerts)
  const [swapTransactionData, setSwapTransactionData] = useState<Transaction | SwapTransaction | null>(initialSwapTransactionData)

  const [showSwapSettingsPopup, setShowSwapSettingsPopup] = useState(false)
  const [showSwapRoutePopup, setShowSwapRoutePopup] = useState(false)

  const [isRefreshing, setIsRefreshing] = useState(false)
  const refreshRef = useRef(null)
  const lastRefreshRef = useRef(0)

  useEffect(() => {
    const startTimeout = () => {
      refreshRef.current = setTimeout(() => {
        refreshSwapSession(swapSettings.liquiditySources, true)
      }, swapSettings.refreshRate)
    }

    startTimeout()

    return () => {
      if (refreshRef.current) {
        clearTimeout(refreshRef.current)
      }
    }
  }, [swapSettings])

  const refreshSwapSession = (sources: ProtocolName[], bypass = false) => {
    const now = Date.now()
    const timeSinceLastRefresh = now - lastRefreshRef.current
    const timeLimit = 6000

    if (bypass || timeSinceLastRefresh >= timeLimit) {
      lastRefreshRef.current = now

      setIsRefreshing(true)

      getSwapSession(sources, availableLPTokens).then(res => {
        setSwapSession(res)

        setTimeout(() => {
          setIsRefreshing(false)
        }, 3000)
      })
    }
  }

  const handleNumericInput = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    const allowedKeys = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight', '.']
    const inputValue = e.currentTarget.value

    const isAllowedKey = allowedKeys.includes(e.key)
    const isSingleDot = e.key === '.' && !inputValue.includes('.')

    if (isAllowedKey && (e.key !== '.' || isSingleDot)) {
      return
    }

    if (!/^\d$/.test(e.key)) {
      e.preventDefault()
    }
  }

  const handleTokenChange = useCallback((e: { token: Token } | null = null, mode: 'switch' | 'input' | 'output'): void => {
    if (mode === 'switch') {
      const inputToken = selectedInputToken

      setSwapAmounts(initialSwapAmounts)

      setSelectedInputToken(selectedOutputToken)
      setSelectedOutputToken(inputToken)

      return
    }

    if (e === null) return

    const token = e.token

    if (mode === 'input') {
      if (token !== selectedInputToken) {
        setSwapAmounts(initialSwapAmounts)

        if (token === selectedOutputToken) {
          setSelectedOutputToken(selectedInputToken)
        }

        setSelectedInputToken(token)
      }
    } else if (mode === 'output') {
      if (token !== selectedOutputToken) {
        setSwapAmounts(initialSwapAmounts)

        if (token === selectedInputToken) {
          setSelectedInputToken(selectedOutputToken)
        }

        setSelectedOutputToken(token)
      }
    }
  }, [selectedInputToken, selectedOutputToken])

  const getSwapRouteData = async () => {
    const routes = getSwapRoutes(selectedInputToken, selectedOutputToken).filter(route => swapSettings.liquiditySources.includes(route.protocol))
    const route = routes[0]

    if (!route) {
      setSwapRoutes([])
      setSwapAmounts(initialSwapAmounts)
      setSwapAlerts([{ section: 0, type: ALERT_TYPES.WARNING, message: SWAP_ALERT_DESCRIPTIONS.INVALID_ROUTE }])
      setSwapTransactionData(initialSwapTransactionData)

      return
    }

    let newSwapRoutes: SwapRoute[] = []
    let newSwapAmounts
    let newSwapAlerts
    let newSwapTransactionData

    if (route.protocol === ProtocolName.USM) {
      newSwapAmounts = getUSMSwapAmounts(swapSession.usm, selectedInputToken, selectedOutputToken, sendInputField)
      newSwapAmounts.swapFee = newSwapAmounts.swapFee / 10000

      newSwapAlerts = getUSMAlerts(newSession, newUser, selectedInputToken, selectedOutputToken, newSwapAmounts)
      newSwapTransactionData = getSwapTransaction(newSwapAlerts, ProtocolName.USM, selectedInputToken, selectedOutputToken, newSwapAmounts)
    } else if (route.lp && route.protocol === ProtocolName.STACKSWAP) {
      const lpToken = lpTokensMap[route.lp]

      newSwapAmounts = getStackswapSwapAmounts(swapSession, lpToken, selectedInputToken, selectedOutputToken, sendInputField, swapSettings.slippageTolerance)
      newSwapAlerts = getStackswapSwapAlerts(newUser, lpToken, selectedInputToken, selectedOutputToken, newSwapAmounts)
      newSwapTransactionData = getSwapTransaction(newSwapAlerts, ProtocolName.STACKSWAP, selectedInputToken, selectedOutputToken, newSwapAmounts, lpToken)
    } else if (route.lp && route.protocol === ProtocolName.ALEX) {
      const lpToken = lpTokensMap[route.lp]

      newSwapAmounts = await getAlexSwapAmounts(lpToken, selectedInputToken, selectedOutputToken, sendInputField, swapSettings.slippageTolerance)
      newSwapAlerts = getAlexSwapAlerts(newUser, lpToken, selectedInputToken, selectedOutputToken, newSwapAmounts)
      newSwapTransactionData = getSwapTransaction(newSwapAlerts, ProtocolName.ALEX, selectedInputToken, selectedOutputToken, newSwapAmounts, lpToken)
    } else if (route.lp && route.protocol === ProtocolName.BITFLOW) {
      const lpToken = lpTokensMap[route.lp]

      newSwapAmounts = await getBitFlowSwapAmounts(lpToken, selectedInputToken, selectedOutputToken, sendInputField, swapSettings.slippageTolerance)
      newSwapAlerts = getBitFlowSwapAlerts(newUser, lpToken, selectedInputToken, selectedOutputToken, newSwapAmounts)
      newSwapTransactionData = getSwapTransaction(newSwapAlerts, ProtocolName.BITFLOW, selectedInputToken, selectedOutputToken, newSwapAmounts, lpToken)
    }
    
    routes.forEach((route, index) => {
      if (index === 0) {
        route.amounts = newSwapAmounts!
        newSwapRoutes.push(route)
      } else {
        newSwapRoutes.push(route)
      }
    })

    setSwapRoutes(newSwapRoutes!)
    setSwapAmounts(newSwapAmounts!)
    setSwapAlerts(newSwapAlerts!)
    setSwapTransactionData(newSwapTransactionData!)
  }

  const handleStacksTransaction = async () => {
    if (swapTransactionData && swapTransactionData.valid && transactionHandlerRef.current) {
      if (swapTransactionData.protocol && swapTransactionData.protocol === ProtocolName.USM) {
        transactionHandlerRef.current.handleInitiate(() => handleUSMSwap(newUser, swapTransactionData), swapTransactionData)
      }

      if (swapTransactionData.protocol && swapTransactionData.protocol === ProtocolName.STACKSWAP) {
        transactionHandlerRef.current.handleInitiate(() => handleStackswapSwap(newUser, swapTransactionData), swapTransactionData)
      }

      if (swapTransactionData.protocol && swapTransactionData.protocol === ProtocolName.ALEX) {
        transactionHandlerRef.current.handleInitiate(() => handleAlexSwap(newUser, swapTransactionData), swapTransactionData)
      }

      if (swapTransactionData.protocol && swapTransactionData.protocol === ProtocolName.BITFLOW) {
        transactionHandlerRef.current.handleInitiate(() => handleBitFlowSwap(newUser, swapTransactionData), swapTransactionData)
      }
    }
  }

  useEffect(() => {
    getSwapSession(swapSettings.liquiditySources, availableLPTokens).then(res => setSwapSession(res))
  }, [])

  useEffect(() => {
    if (session && user && swapSession && swapSettings) {
      (async function () {
        await getSwapRouteData()
      })()
    }
  }, [selectedInputToken, selectedOutputToken, sendInputField])

  useEffect(() => {
    if (session && user && swapSession && swapSettings) {
      (async function () {
        await getSwapRouteData()
      })()
    }
  }, [session, user, swapSession, swapSettings])

  return (
    <>
      {showSwapSettingsPopup && <SwapSettingsPopup show={(e) => setShowSwapSettingsPopup(e)} setSettings={(e) => setSwapSettings(e)} swapSettings={swapSettings} />}
      {showSwapRoutePopup && <SwapRoutePopup show={(e) => setShowSwapRoutePopup(e)} routes={swapRoutes} />}
      {(session && user && swapSession && swapSettings) ?
      <div className='core'>
        <TransactionHandler ref={transactionHandlerRef} />
        <ReactTooltip className='defaultTooltip' effect='solid' multiline={true} arrowColor='#000000' padding='10px 12px' />
        <div className='borrowHeaderWithSelector borrowHeader' style={{ marginBottom: '4rem'}}>
          <h1>UWU Swap</h1>
          <h2 style={{ marginBottom: '2rem' }}>Swap between UWU and other assets seamlessly. UWU Swap<br></br> aggregates multiple liquidity sources to find the best rate.</h2>
          <div className='manageVaultSelector'>
            <div style={{ backgroundColor: '#FFFFFF', boxShadow: '0px 4px 6px rgba(17,17,26,0.025)' }}>
              <a className='headerButton active' style={{ marginRight: '5px' }}>Swap</a>
              <a href='/earn/stx-uwu-lp' className='headerButton' style={{ marginLeft: '5px' }}>Provide Liquidity</a>
            </div>
          </div>
        </div>
        <div className='stabilityModuleSwapDiv grid' style={{ maxWidth: '450px', margin: 'auto', gridTemplateColumns: '1fr' }}>
          <div className='pendingTxnHome' style={{ minWidth: 'unset', borderColor: '#ffc75c' }}>
            <img src={earnswap} draggable='false' style={{ width: '40px', height: '40px', padding: '0' }} />
            <div>
              <h2>Discover new routes, liquidity sources, tokens, and more with the latest version of UWU Swap</h2>
            </div>
          </div>
          <div className='overview borrowDisplay' style={{ height: 'max-content' }}>
            <div className='overviewHeader manageVaultHeader'>
              <div className='inner'>
                <h1 style={{ marginTop: '5px', marginBottom: '5px' }}>Swap</h1>
                <div style={{ border: 'unset', margin: 'unset' }}>
                  <a onClick={() => refreshSwapSession(swapSettings.liquiditySources)} className='imgButton smallButton' style={{ border: '1px solid #F0F0F0', boxShadow: 'none', outline: '4px solid #FFFFFF', marginRight: '0.5rem' }}>{isRefreshing ? <img draggable='false' style={{ transition: 'unset', width: '70%' }} src={loader} /> : <img draggable='false' style={{ transition: 'unset' }} src={reload} />}</a>
                  <a onClick={() => setShowSwapSettingsPopup(true)} className='imgButton smallButton' style={{ border: '1px solid #F0F0F0', boxShadow: 'none', outline: '4px solid #FFFFFF' }}><img draggable='false' src={gear} /></a>
                </div>
              </div>
              <div></div>
            </div>
            <div className='borrowInnerDiv manageVaultInnerDiv'>
              <div style={{ marginTop: '5px' }}>
                <div className='borrowInputTitle'>
                  <h1>Send {selectedInputToken?.symbol}</h1>
                  <h2 onClick={() => { setSendInputField(formatValue(newUser?.balances?.[selectedInputToken.name]?.formatted)) }}>Balance: {formatUIValue(newUser?.balances?.[selectedInputToken.name]?.formatted)} {selectedInputToken?.symbol}</h2>
                </div>
                <span className='borrowInput borrowInputPrice' style={{ marginBottom: '20px' }}><div style={{ width: '100%' }}><input inputmode='decimal' maxLength='10' type='text' placeholder={`0.00 ${selectedInputToken.symbol}`} onChange={(e) => setSendInputField(e.target.value.toString().split('.').map((el, i) => i ? el.split('').slice(0, 2).join('') : el).join('.').replace(/^0+(?=\d)/, ''))} onKeyDown={(e) => handleNumericInput(e)} value={sendInputField} /><h4>≈ ${formatUIValue(sendInputField * session?.prices?.[selectedInputToken.name])}</h4></div><div className='swapOutputCurrency' style={{ border: '0', marginRight: '12px' }}><DropdownTokenComponent options={inputTokenList} label={selectedInputToken?.symbol} onChange={(e) => handleTokenChange(e, 'input')} /></div></span>
                {swapAlerts.filter(alert => alert.section === 0).map((alert, index) => (
                  <WarningComponent key={index} message={alert.message} type={alert.type} />
                ))}
                <div className='swapSwitcher'>
                  <a onClick={() => { handleTokenChange(null, 'switch') }} className='imgButton smallButton' style={{ border: '1px solid #F0F0F0', boxShadow: 'none', outline: '4px solid #FFFFFF' }}><img draggable='false' src={swap} /></a>
                </div>
                <div className='borrowInputTitle'>
                  <h1>Receive {selectedOutputToken?.symbol}</h1>
                </div>
                  <div className='swapOutput borrowInputPrice' style={{ padding: '10px 15px' }}><div style={{ width: '100%' }}><h1>{formatUIValue(swapAmounts?.est?.formatted)}</h1><h4 style={{ margin: '0' }}>≈ ${formatUIValue(swapAmounts?.est?.formatted * session?.prices?.[selectedOutputToken.name])}</h4></div><div className='swapOutputCurrency' style={{ border: '0', marginRight: '-3px' }}><DropdownTokenComponent options={outputTokenList} label={selectedOutputToken?.symbol} onChange={(e) => handleTokenChange(e, 'output')} /></div></div>
                {swapAlerts.filter(alert => alert.section === 1).map((alert, index) => (
                  <WarningComponent key={index} message={alert.message} type={alert.type} />
                ))}
                <div className='borrowOutputDetails' style={{ marginTop: '-5px' }}>
                  <h1>Swap Fee</h1>
                  <h2>{getSwapFee(selectedInputToken, sendInputField, swapAmounts?.swapFee)}</h2>
                  <h1 style={{ minWidth: 'max-content' }}>Minimum Received</h1>
                  <h2>{formatUIValue(swapAmounts?.min?.formatted, 4)} {selectedOutputToken?.symbol}</h2>
                </div>
              </div>
              <div className='borrowDivider'></div>
              <a onClick={() => { handleStacksTransaction() }} style={{ backgroundColor: swapTransactionData?.valid ? '#000000' : '#575757', cursor: swapTransactionData?.valid ? 'pointer' : 'default' }} className='borrowButton'>{sendInputField ? 'Swap' : 'Enter an Amount'}</a>
            </div>
          </div>
          <div className={`swapRouteButton ${swapAmounts.amount > 0 && swapTransactionData && 'active'}`}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '15px' }}>
              <h1 style={{ fontSize: '0.85rem', fontWeight: '600' }}>Swap Route</h1>
              <a onClick={() => swapTransactionData && setShowSwapRoutePopup(true)}>View Details</a>
            </div>
          </div>
        </div>
      </div>
      : <LoaderComponent />}
    </>
  )
}

export default SwapPage