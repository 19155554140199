import React, { useState, useEffect } from "react";

import loader from "../img/loader.svg";

export default function LoaderOverlayComponent() {
  const [visibleClass, setVisibleClass] = useState("");

  useEffect(() => {
    setTimeout(() => setVisibleClass('visible'), 25);
  }, []);

  return (
    <div className={`popupModal ${visibleClass}`} style={{ zIndex: "1000" }}>
      <div className="loader">
        <img src={loader} width="50px" draggable="false" />
      </div>
    </div>
  );
};