import React from "react";

import icon from "../img/404.png";

export default function ErrorComponent() {
  document.title = "Not Found | UWU Protocol";

  return (
    <div className="loader error">
      <img src={icon} draggable="false" style={{ backgroundColor: "#d8343425" }} />
      <h1 style={{ textAlign: "center", marginBottom: "2.5rem" }}>The page you are looking for could not be found</h1>
      <a href="/">Go Back</a>
    </div>
  );
};