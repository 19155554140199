import React, { useState, useEffect, useRef } from 'react';
import "../styles/dropdown.css";

export default function CustomDropdown({ title, options, value, onChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const [visibleClass, setVisibleClass] = useState("");
  const dropdownRef = useRef(null);
  const optionsRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setVisibleClass('visible');
    } else {
      setVisibleClass('');
    };
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (optionsRef.current && !optionsRef.current.contains(e.target)) {
        setVisibleClass('');
        setTimeout(() => setIsOpen(false), 300);
      };
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [optionsRef]);

  const handleToggleDropdown = () => {
    if(isOpen) {
      setTimeout(() => setIsOpen(false), 300);
    } else {
      setIsOpen(true);
    };
  };

  const handleOptionClick = (option) => {
    onChange(option);
    setVisibleClass('');
    setTimeout(() => setIsOpen(false), 300);
  };

  const renderOptions = () => {
    return options.map((option, index) => (
      <div key={index} className={`dropdownOption${option.value === value ? ' dropdownOptionSelected' : ''}`} onClick={() => handleOptionClick(option)}>
        {option.label}
      </div>
    ));
  };

  return (
    <div className="dropdownContainer" ref={dropdownRef}>
      <div className="dropdownHeader" style={{ borderColor: isOpen && "#c3c3c3" }} onClick={handleToggleDropdown}>
        {title}: {options.find((option) => option.value === value)?.label || 'Select an option'}
      </div>
      {isOpen && <div className={`dropdownOptions ${visibleClass}`} ref={optionsRef}>{renderOptions()}</div>}
    </div>
  );
};